<template>
  <b-modal ref="skillPrivacyModal" hide-footer title="Privacy Setting" centered lazy size="md" @hide="hideModal">
    <b-form @submit.prevent="addSkill">
      <b-form-group label="Privacy" label-for="privacy" label-class="form-label" class="col-md-12">
        <div class="custom-select-icon">
          <b-form-select class="custom-select-icon__select" v-model="selected" :options="options" required>
            ></b-form-select
          >
          <span class="material-icons custom-select-icon__icon">sort</span>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <b-btn variant="primary" style="width: 150px" type="submit" :disabled="isSaving" class="btn-normal">
            <i v-if="isSaving" class="fas fa-circle-notch fa-spin"></i>
            <span v-else> Continue</span>
          </b-btn>
        </div>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'ResumeSkillModal',

  components: {},

  props: {
    showModal: { type: Boolean, default: false },
    updateId: { type: Number, default: null },
    isUpdate: { type: Boolean, default: false },
    access: { type: String, default: null },
  },

  data() {
    return {
      selected: null,
      isSaving: false,
      options: [
        { value: null, text: 'Please Select' },
        { value: 'public', text: 'Public' },
        { value: 'private', text: 'Private' },
      ],
    };
  },

  computed: {},

  watch: {
    showModal(value) {
      if (value) {
        this.$refs.skillPrivacyModal.show();
        if (this.access) {
          this.selected = this.access;
        }
      }
    },
  },

  methods: {
    ...mapActions('skills', ['getSkillVideo', 'updateSkillVideoPrivacy']),
    async addSkill() {
      this.isSaving = true;
      try {
        const data = {
          skill_video_access: this.selected,
        };
        if (this.isUpdate) {
          await this.updateSkillVideoPrivacy({ id: this.updateId, data });
          this.makeToast({ variant: 'success', msg: 'Privacy Setting Updated Successfully!' });
          this.$emit('fetch');
        } else {
          const resp = await this.getSkillVideo({ ...data });
          window.location.href = resp.data.skill_video_ask_url;
        }
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.hideModalManual();
      this.isSaving = false;
    },
    hideModal() {
      this.selected = null;
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.skillPrivacyModal.hide();
      this.hideModal();
    },
  },

  async mounted() {},
};
</script>
