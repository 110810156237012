<template>
  <b-modal
    ref="requestPartnershipDetailsModal"
    hide-footer
    title="Request Partnership "
    centered
    lazy
    scrollable
    @hide="hideModal"
    size="lg"
  >
    <h2 class="text-center">{{ `Partner with ${this.entity.name}` }}</h2>

    <h6 class="text-center mb-4">{{ subtitle }}</h6>

    <div class="d-flex justify-content-center">
      <div class="col-md-7">
        <div class="card" style="border-radius: 1.5rem">
          <div class="card-body">
            <b-skeleton-wrapper :loading="isLoading">
              <template #loading>
                <h5 class="card-title mb-2"><b-skeleton width="100%"></b-skeleton></h5>
                <h2 class="mb-4"><b-skeleton width="100%"></b-skeleton></h2>
              </template>
              <h5 class="card-title text-center mb-2">
                {{ partnershipFee.recurring_fee ? 'Premium Plan' : 'Free Plan' }}
              </h5>
              <h2 class="text-center mb-2">
                ${{ $n(partnershipFee.recurring_fee) }}
                {{ getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL ? '*' : '' }}
              </h2>
            </b-skeleton-wrapper>
            <p class="text-muted text-center mb-4">Billed monthly, cancel anytime</p>

            <!-- Use if needed -->
            <!-- <div class="d-flex align-items-start">
              <span v-if="!isLoading && get(partnershipFee, 'computed_fee')">
                <md-icon class="mr-2 text-70 icon-16pt">check_circle</md-icon>An initiation fee of
                <b>${{ $n(partnershipFee.computed_fee) }}</b> (<b>${{ $n(partnershipFee.initial_fee) }}</b> / Recently
                Active Student
                <i
                  class="material-icons icon-16pt clickable-item text-black-50"
                  v-b-popover.hover.top="ACTIVE_STD_INFO_DESC"
                  >info</i
                >) will be charged once at the time of acceptance.
                {{ getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL ? '*' : '' }}
              </span>
            </div> -->

            <div class="d-flex align-items-start" v-for="(benefit, index) in benefitsChecklist" :key="index">
              <span><md-icon class="mr-2 text-70 icon-16pt">check_circle</md-icon>{{ benefit }}</span>
            </div>

            <div class="mt-2">
              <b v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL">* Will be charged to the employer.</b>
            </div>
            <div class="d-flex justify-content-center mt-4 mb-2">
              <router-link
                :to="{
                  name:
                    getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER
                      ? 'employer-request-partnership'
                      : 'school-request-partnership',
                  params: { entity: entity, schoolId: schoolId, type: type, id: this.entity.id },
                }"
                class="btn btn-primary w-100"
              >
                Continue
              </router-link>
            </div>
            <!-- </b-skeleton-wrapper> -->
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';
import { USER_ROLE_TYPES, ACTIVE_STD_INFO_DESC } from '../../common/constants';
export default {
  name: 'RequestPartnershipDetailsModal',

  props: {
    schoolId: Number,
    type: String,
    entity: Object,
    showModal: { type: Boolean, default: false },
  },

  data() {
    return {
      partnershipFee: {
        recurring_fee: 0,
        computed_fee: 0,
      },
      isLoading: false,
      USER_ROLE_TYPES,
      subtitle: '',
      benefitsChecklist: [],
      ACTIVE_STD_INFO_DESC,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    isPartnershipFree() {
      return !this.isLoading && !get(this.partnershipFee, 'recurring_fee') && !get(this.partnershipFee, 'computed_fee');
    },
  },

  watch: {
    showModal(value) {
      if (value) {
        this.fetchPartnershipFee();
        if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER) {
          this.subtitle = 'Get direct access to student candidates by choosing a plan below.';
          this.benefitsChecklist = [
            'Post and edit unlimited job listings to Career Center.',
            "View profiles and send messages to student's email.",
          ];
        } else {
          this.subtitle =
            'Become partners for employers to start posting jobs for your school and enjoy more partnership benefits';
          this.benefitsChecklist = [
            'Employer can post unlimited local job posts for the school.',
            "Allow the employers to interact with school's students.",
          ];
        }
        this.$refs.requestPartnershipDetailsModal.show();
      }
    },
  },
  methods: {
    ...mapActions('partnership', ['getCalcPartnershipFee']),
    get,
    openRequestPage() {
      this.$router.push({ name: 'request-partnership' });
    },
    async fetchPartnershipFee() {
      this.isLoading = true;
      this.partnershipFee = { recurring_fee: 0, computed_fee: 0 };
      const response = await this.getCalcPartnershipFee({
        school:
          this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
            ? this.getLoggedInUser.linked_entity.id
            : this.schoolId,
      });

      this.partnershipFee = response.data;
      this.isLoading = false;
    },
    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.requestPartnershipDetailsModal.hide();
      this.hideModal();
    },
    emitSubmit() {
      this.emit('submit');
    },
  },

  async mounted() {},
};
</script>
