<template>
  <div style="padding-top: 16px" id="page-container">
    <div class="page-section" :class="{ jobs_container: get(getLoggedInUser, 'role') === USER_ROLES.STUDENT }">
      <div :class="{ container: get(getLoggedInUser, 'role') === USER_ROLES.STUDENT }">
        <div class="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt" style="white-space: nowrap">
          <h2 class="mb-0 d-sm-block" v-if="!isMobSmallScreen">
            {{ pageTitle || 'Simulations' }}
          </h2>
          <div
            v-if="get(getLoggedInUser, 'role_type') === USER_ROLES.STUDENT"
            class="w-auto ml-sm-auto table d-flex align-items-center mb-sm-0"
            :class="{ 'flex-column': isMobSmallScreen }"
          >
            <form
              style="width: 100%"
              class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0"
              @submit.prevent=""
            >
              <b-form-input class="w-lg-auto" placeholder="Search..." v-model="searchTerm" @input="onSearch" />
              <b-btn variant="flush" type="submit">
                <md-icon v-text="'search'" />
              </b-btn>
            </form>
          </div>
        </div>

        <page-separator
          :title="
            !isLoading
              ? `showing ${
                  totalSimulations ? `${pageFromCount}-${pageToCount}` : 0
                } of ${totalSimulations} ${pageTitle || 'Simulations'}`
              : ''
          "
        />

        <div class="d-flex justify-content-center" v-if="!totalSimulations && !isLoading">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No {{ pageTitle || 'Simulations' }} Found</h4>
              <p class="text-muted">
                No {{ pageTitle.toLowerCase() || 'simulations' }} currently available. Please check again later.
              </p>
            </div>
          </div>
        </div>

        <b-skeleton-wrapper :loading="isLoading" v-else>
          <template #loading>
            <div class="row">
              <div class="col-md-6" v-for="item in [1, 2]" :key="item">
                <div class="card">
                  <b-skeleton-img width="100%"></b-skeleton-img>

                  <div class="p-2">
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="row">
            <div class="col-md-6" v-for="simulation in simulations" :key="simulation.id">
              <router-link
                :to="{
                  name: `student-simulation-details`,
                  params: { id: simulation.slug },
                  ...($route.query.uniId && { query: { uniId: $route.query.uniId }}),
                }"
              >
                <div class="card clickable-item" style="height: 95%;">
                  <img
                    class=""
                    style="object-fit: cover; border-radius: 8px"
                    :src="simulation.image_url ? simulation.image_url : DefaultAvatar"
                    width="100%"
                    height="230px"
                  />
                  <div class="d-flex align-items-center p-2">
                    <img
                      class="mx-2"
                      :src="
                        get(simulation, 'posted_by.logo_url') ? get(simulation, 'posted_by.logo_url') : DefaultAvatar
                      "
                      width="50"
                      height="auto"
                    />

                    <div class="d-flex flex-column justify-content-center">
                      <div class="card-title">{{ simulation.title }}</div>
                      <div class="">
                        {{ truncate(simulation.instructions, { length: 50 }) }}
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </b-skeleton-wrapper>

        <pagination
          v-if="totalSimulations > simulations.length"
          v-model="currentPage"
          :total-rows="totalSimulations"
          :per-page="perPage"
          @change="onPageChange"
          aria-controls="simulations-table"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { debounce, get, truncate } from 'lodash';
import Pagination from '@/components/Ui/Pagination.vue';
import { mapActions, mapGetters } from 'vuex';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import { USER_ROLES, DASHBOARD_TYPES } from '@/common/constants';
import Page from '@/components/Page.vue';
import { formatFullDate } from '@/common/utils';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  name: 'SimulationsListing',
  components: { Pagination, PageSeparator },
  extends: Page,
  props: {
    pageTitle: { type: String, default: 'Simulations' },
  },

  data() {
    return {
      DefaultAvatar,
      title: 'Simulations',
      isLoading: true,
      perPage: 14,
      currentPage: 1,
      totalSimulations: 0,
      simulations: [],
      searchTerm: '',
      ordering: '-id',
      pageFromCount: 0,
      pageToCount: 0,
      windowWidth: window.innerWidth,
      USER_ROLES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser', 'getLoggedInUserDashboard']),

    isMobSmallScreen() {
      return this.windowWidth <= 776;
    },
  },

  watch: {},

  methods: {
    ...mapActions('simulations', ['getEntitySimulations']),
    get,
    truncate,
    formatFullDate,
    openSimulationDetail(id) {
      this.$router.push({ name: 'student-simulation-details', params: { id } });
    },
    async fetchSimulations(pageNum = 1, params = {}) {
      this.isLoading = true;

      if (!this.$route.query.uniId && this.getLoggedInUserDashboard === DASHBOARD_TYPES.CARECAMPUS) {
        this.$router.push({ name: 'student-dashboard' });
        return;
      }

      try {
        const response = await this.getEntitySimulations({
          limit: this.perPage,
          offset: (pageNum - 1) * this.perPage,
          ...(this.ordering && { ordering: this.ordering }),
          ...(this.searchTerm && { search: this.searchTerm }),
          is_active: true,

          ...(this.getLoggedInUserDashboard === DASHBOARD_TYPES.CARECAMPUS && {
            entity_id: this.$route.query.uniId,
            entity_type: 'university',
          }),
          ...(this.getLoggedInUserDashboard !== DASHBOARD_TYPES.CARECAMPUS && {
            entity_id: this.getLoggedInUser.linked_entity.id,
            entity_type: this.getLoggedInUser.linked_entity.entity_type,
          }),

          ...params,
        });

        this.simulations = response.data.results;
        this.currentPage = pageNum;
        this.totalSimulations = response.data.count;
        this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
        this.pageToCount = this.pageFromCount + get(response.data, 'results.length', 0) - 1;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchSimulations(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchSimulations();
    },

    onSearch() {
      this.debouncedSearchSimulations(this);
    },

    debouncedSearchSimulations: debounce(vm => {
      vm.fetchSimulations();
    }, 500),

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    await this.fetchSimulations();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
