<template>
  <b-modal
    ref="securityModal"
    hide-footer
    title="Device Authorization"
    centered
    lazy
    scrollable
    @hide="hideModal"
    size="md"
  >
    <b-form @submit.prevent="onSubmit">
      <div class="d-flex justify-content-center">
        <i class="fas fa-shield-alt fa-4x text-primary mt-4 mb-2"></i>
      </div>
      <div class="d-flex justify-content-center" style="height: 2rem">
        <h3>Confirm that it's you</h3>
      </div>
      <div class="d-flex justify-content-center">
        <span>Please re-enter your password for the logged-in account</span>
      </div>
      <b-form-group label="Password" label-for="name" label-class="form-label" class="mt-3">
        <b-form-input id="password" placeholder="Enter password" v-model="password" required type="password" />
      </b-form-group>
      <!-- </div> -->
      <div class="d-flex justify-content-end mt-2">
        <b-btn variant="primary" :disabled="isLoading" style="width: 100px" type="submit" class="btn-normal">
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>Authorize</span>
        </b-btn>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { get } from 'lodash';
export default {
  name: 'SecurityModal',
  props: {
    showModal: { type: Boolean, default: false },
    viewTitle: { type: String, default: '' },
    viewId: { type: Number, default: null },
    viewType: { type: String, default: '' },
    action: { type: String, default: '' },
  },

  data() {
    return {
      isLoading: false,
      password: '',
    };
  },

  computed: {},

  watch: {
    showModal(value) {
      if (value) {
        this.$refs.securityModal.show();
      }
    },
  },

  methods: {
    ...mapActions('fileDownload', ['downloadFile']),
    ...mapActions('auth', ['validateUser']),
    async onSubmit() {
      this.isLoading = true;
      try {
        await this.validateUser({ password: this.password });
        if (!this.genReport) {
          this.$emit('validated', this.viewTitle, this.viewId, this.viewType);
        }
        if (this.action === 'fetch') {
          this.$emit('fetch');
        } else if (this.action === 'generate') {
          this.$emit('generate');
        } else if (this.action === 'sharableReport') {
          this.$emit('sharable');
        }

        this.hideModal();
      } catch (err) {
        if (get(err.response, 'status') === 403) {
          this.makeToast({ variant: 'danger', msg: 'Invalid Password!' });
        } else this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
    hideModal() {
      this.$emit('close');
      this.$refs.securityModal.hide();
      this.password = '';
      this.isLoading = false;
    },

    hideModalManual() {
      this.$refs.securityModal.hide();
      this.hideModal();
    },
  },

  async mounted() {},
};
</script>
