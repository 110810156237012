<template>
  <scholarships-listing />
</template>

<script>
import ScholarshipsListing from '../../../components/Scholarships/ScholarshipsListing.vue';

export default {
  components: { ScholarshipsListing },
};
</script>

<style>
</style>