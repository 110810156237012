<template>
  <fmv-sidebar type="light" variant="white" class="py-16pt" wheel-propagation>
    <div class="sidebar-heading mb-4" style="font-size: 18px; font-weight: bolder">Filter Companies</div>
    <div class="sidebar-heading">Location</div>
    <div class="sidebar-block">
      <div class="form-group">
        <input type="text" class="form-control" placeholder="Zip code" v-model="filters.zipcode" />
      </div>
      <!-- <div class="custom-select-icon">
        <b-form-select
          class="custom-select-icon__select"
          v-model="filters.zipcodeRange"
          :options="zipcodeRangeOptions"
        ></b-form-select>
        <span class="material-icons custom-select-icon__icon">sort</span>
      </div> -->
    </div>

    <div class="sidebar-heading">Facility Type</div>
    <div class="sidebar-block">
      <b-form-group>
        <b-form-checkbox-group
          id="type-checks"
          v-model="filters.companyType"
          :options="typeOptions"
          stacked
        ></b-form-checkbox-group>
      </b-form-group>
    </div>

    <div class="sidebar-heading">Company Size</div>
    <div class="sidebar-block">
      <b-form-group>
        <b-form-checkbox-group
          id="work-time-checks"
          v-model="filters.companySize"
          :options="companySizeOptions"
          stacked
        ></b-form-checkbox-group>
      </b-form-group>
    </div>
  </fmv-sidebar>
</template>

<script>
import { FmvSidebar } from 'fmv-layout';

import { EMPLOYER_COMP_TYPE_OPTIONS, EMPLOYER_COMP_SIZE_OPTIONS } from '../../../common/constants';
import { mapActions } from 'vuex';

export default {
  name: 'EmployerFiltersSidebar',

  components: { FmvSidebar },

  data() {
    return {
      filters: {
        zipcode: null,
        zipcodeRange: 30,

        companyType: [],
        companySize: [],
      },

      zipcodeRangeOptions: [{ value: 30, text: 'Within 30 Miles' }],
      typeOptions: EMPLOYER_COMP_TYPE_OPTIONS,
      companySizeOptions: EMPLOYER_COMP_SIZE_OPTIONS,
    };
  },

  computed: {},

  methods: {
    ...mapActions('employer', ['updateEmployerFilters']),
  },

  watch: {
    filters: {
      deep: true,
      handler(filters) {
        this.updateEmployerFilters({ filters });
      },
    },
  },

  async mounted() {},
};
</script>
