<template>
  <fmv-sidebar type="light" variant="white" class="py-16pt" wheel-propagation>
    <div class="sidebar-heading mb-4" style="font-size: 18px; font-weight: bolder">Filter Jobs</div>

    <div class="sidebar-block" v-if="isMobileScreen">
      <div class="custom-select-icon">
        <b-form-select
          class="custom-select-icon__select"
          v-model="filters.job_type"
          :options="jobOptions"
        ></b-form-select>
        <span class="material-icons custom-select-icon__icon">sort</span>
      </div>
    </div>

    <div class="sidebar-heading">Location</div>
    <div class="sidebar-block">
      <div class="form-group">
        <input type="text" class="form-control" placeholder="Zip code" v-model="filters.zipCode" />
      </div>
      <!-- <div class="custom-select-icon">
        <b-form-select
          class="custom-select-icon__select"
          v-model="filters.zipCodeRange"
          :options="zipCodeRangeOptions"
        ></b-form-select>
        <span class="material-icons custom-select-icon__icon">sort</span>
      </div> -->
    </div>

    <div class="sidebar-heading">Experience Required</div>
    <div class="sidebar-block">
      <div class="custom-select-icon">
        <b-form-select
          class="custom-select-icon__select"
          v-model="filters.experience"
          :options="workExpOptions"
        ></b-form-select>
        <span class="material-icons custom-select-icon__icon">sort</span>
      </div>
    </div>

    <div class="sidebar-heading">Work Type</div>
    <div class="sidebar-block">
      <b-form-group>
        <b-form-checkbox-group
          id="type-checks"
          v-model="filters.type"
          :options="typeOptions"
          stacked
        ></b-form-checkbox-group>
      </b-form-group>
    </div>

    <div class="sidebar-heading">Work Schedule</div>
    <div class="sidebar-block">
      <b-form-group>
        <b-form-checkbox-group
          id="work-time-checks"
          v-model="filters.schedule"
          :options="scheduleOptions"
          stacked
        ></b-form-checkbox-group>
      </b-form-group>
    </div>

    <div class="sidebar-heading">Certifications</div>
    <div class="sidebar-block">
      <b-form-group>
        <b-form-checkbox-group
          id="certifications-checks"
          v-model="filters.certifications"
          :options="certificationOptions"
          stacked
        ></b-form-checkbox-group>
      </b-form-group>
    </div>

    <div class="sidebar-heading">Required Records</div>
    <div class="sidebar-block">
      <b-form-group>
        <b-form-checkbox-group
          id="records-checks"
          v-model="filters.records"
          :options="recordOptions"
          stacked
        ></b-form-checkbox-group>
      </b-form-group>
    </div>
  </fmv-sidebar>
</template>

<script>
import { FmvSidebar } from 'fmv-layout';

import {
  JOB_CERT_OPTIONS,
  JOB_RECORD_OPTIONS,
  JOB_TYPE_OPTIONS,
  JOB_SCHEDULE_OPTIONS,
  JOB_EXP_OPTIONS,
} from '../../../../common/constants';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'JobFiltersSidebar',

  components: { FmvSidebar },

  data() {
    return {
      filters: {
        zipCode: null,
        zipCodeRange: 30,

        experience: null,
        certifications: [],
        records: [],

        type: [],
        schedule: [],
        job_type: null,
      },

      workExpOptions: [{ value: null, text: 'Any' }, ...JOB_EXP_OPTIONS],
      jobOptions: [
        { value: null, text: 'All Jobs' },
        { value: 'local', text: 'Featured Jobs' },
      ],
      zipCodeRangeOptions: [{ value: 30, text: 'Within 30 Miles' }],
      recordOptions: JOB_RECORD_OPTIONS,
      certificationOptions: JOB_CERT_OPTIONS,
      typeOptions: JOB_TYPE_OPTIONS,
      scheduleOptions: JOB_SCHEDULE_OPTIONS,
      windowWidth: window.innerWidth,
    };
  },

  computed: {
    ...mapGetters('job', ['getResetJobFilters']),
    isMobileScreen() {
      return this.windowWidth <= 575;
    },
  },

  methods: {
    ...mapActions('job', ['updateJobFilters', 'setResetJobFilters']),
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  watch: {
    filters: {
      deep: true,
      handler(filters) {
        this.updateJobFilters({ filters });
      },
    },

    getResetJobFilters(resetVal) {
      if (resetVal) {
        this.filters = {
          zipCode: null,
          zipCodeRange: 30,

          experience: null,
          certifications: [],
          records: [],

          type: [],
          schedule: [],
        };
        this.setResetJobFilters(false);
      }
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
