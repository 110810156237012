<template>
  <div :class="containerClass">
    <b-skeleton-wrapper :loading="isPartnershipLoading || isResponding || isPartnershipResponding">
      <template #loading>
        <b-skeleton type="button"></b-skeleton>
      </template>
      <div>
        <a
          v-if="isEmpty(partnership) && get(getLoggedInUser, 'role_type') !== USER_ROLE_TYPES.SCHOOL"
          class="btn"
          :class="customClass"
          @click.prevent="viewRequestPartnershipModal"
          href="#"
          data-toggle="modal"
          ><md-icon class="mr-1">group_add</md-icon>Request Partnership</a
        >
        <a
          v-if="get(partnership, 'status') === PARTNERSHIP_STATUS.RECEIVED"
          class="btn"
          :class="customClass"
          @click.prevent="viewRespondRequestModal"
          href="#"
          data-toggle="modal"
          ><md-icon class="mr-1">group_add</md-icon>Respond to Request</a
        >
        <a
          v-if="get(partnership, 'status') === PARTNERSHIP_STATUS.SENT"
          class="btn btn-white"
          @click.prevent="viewSentModal"
          v-b-popover.hover.top="'Cancel Partnership Request'"
          href="#"
          data-toggle="modal"
          ><md-icon class="mr-1">group_add</md-icon>Request Sent</a
        >
        <a
          v-if="get(partnership, 'status') === PARTNERSHIP_STATUS.ACCEPTED"
          class="btn btn-white"
          @click.prevent="viewDetailsModal"
          href="#"
          v-b-popover.hover.top="'Show Details'"
          data-toggle="modal"
          ><i class="fas fa-handshake mr-1"></i>Partners</a
        >
      </div>
    </b-skeleton-wrapper>

    <request-partnership-details-modal
      :show-modal="showRequestPartnershipModal"
      :school-id="schoolId"
      @close="hideRequestPartnershipModal"
      :entity="entity"
      :type="type"
      @submit="emitFetchPartnership"
    ></request-partnership-details-modal>
    <partnership-details-modal
      :partnership="partnership"
      :show-modal="showDetailsModal"
      @close="hideDetailsModal"
      @fetch="emitFetchPartnership"
    ></partnership-details-modal>
    <respond-request-modal
      :entity="entity"
      :partnership="partnership"
      :show-modal="showRespondModal"
      @close="hideRespondRequestModal"
      @emitIsResponding="emitIsRespondingInModal"
      @fetch="emitFetchPartnership"
    ></respond-request-modal>
  </div>
</template>

<script>
import PartnershipDetailsModal from './PartnershipDetailsModal.vue';
import RequestPartnershipDetailsModal from './RequestPartnershipDetailModal.vue';
import RespondRequestModal from './RespondRequestModal.vue';
import { PARTNERSHIP_STATUS, PAYMENT_STATUSES, USER_ROLE_TYPES } from '../../common/constants';
import { mapActions, mapGetters } from 'vuex';
import { get, isEmpty } from 'lodash';
export default {
  name: 'PartnershipRequestButton',
  components: {
    PartnershipDetailsModal,
    RequestPartnershipDetailsModal,
    RespondRequestModal,
  },
  props: {
    entity: Object,
    partnership: Object,
    customClass: String,
    containerClass: String,
    type: String,
    isPartnershipLoading: Boolean,
    isPartnershipResponding: Boolean,
    schoolId: Number,
    openDetailsModal: Boolean,
    openRequestPartnershipDetailModal: Boolean,
  },
  data() {
    return {
      showRequestPartnershipModal: false,
      PARTNERSHIP_STATUS,
      showDetailsModal: false,
      showRespondModal: false,
      isResponding: false,
      USER_ROLE_TYPES,
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },
  watch: {
    openDetailsModal(value) {
      if (value) {
        this.viewDetailsModal();
      }
    },
    openRequestPartnershipDetailModal(value) {
      if (value) {
        this.viewRequestPartnershipModal();
      }
    },
  },
  methods: {
    ...mapActions('partnership', [
      'createPartnership',
      'respondToPartnershipRequest',
      'deletePartnership',
      'cancelRequestPartnership',
      'updatePendingPartnershipStatus',
    ]),
    get,
    isEmpty,
    hideDetailsModal() {
      this.showDetailsModal = false;
    },
    viewDetailsModal() {
      this.showDetailsModal = true;
    },
    hideRespondRequestModal() {
      this.showRespondModal = false;
    },
    viewRespondRequestModal() {
      this.showRespondModal = true;
    },
    viewRequestPartnershipModal() {
      this.showRequestPartnershipModal = true;
    },
    hideRequestPartnershipModal() {
      this.showRequestPartnershipModal = false;
      this.$emit('hideReqDetailsModal');
    },
    async viewRespondModal() {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
          ? `${this.entity.name} has sent you a partnership request. Please confirm that you want to accept or deny the partnership with ${this.entity.name}. After accepting the request, ${this.entity.name} will be able to post local jobs for your school and view your active students.`
          : `${this.entity.name} has sent you a partnership request. Please confirm that you want to accept or deny the partnership with ${this.entity.name}. After accepting the request, you will be able to post local jobs for the school and view ${this.entity.name}'s active students. On acceptance the partnership fees will be charged.`,
        {
          title: 'Respond to Request',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'success',
          cancelVariant: 'danger',
          okTitle: 'Accept',
          cancelTitle: 'Deny',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }
      );
      try {
        this.isResponding = true;
        this.$emit('isResponding', true);
        if (isConfirmed) {
          const data = { status: PARTNERSHIP_STATUS.ACCEPTED };
          const resp = await this.respondToPartnershipRequest({ id: this.partnership.id, data });
          if (resp.data.status_code === 303) {
            window.location.href = resp.data.data.session_url;
          } else {
            this.makeToast({ variant: 'success', msg: 'Request Accepted!' });
            this.emitFetchPartnership();
          }
        } else if (isConfirmed === false) {
          const data = { status: PARTNERSHIP_STATUS.DECLINED };
          await this.respondToPartnershipRequest({ id: this.partnership.id, data });
          this.makeToast({ variant: 'danger', msg: 'Request Declined!' });
          this.emitFetchPartnership();
        }
        this.updatePendingPartnershipStatus();
      } catch (err) {
        if (get(err, 'response.data.data.status_msg') === PAYMENT_STATUSES.PAYMENT_FAILURE) {
          if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL) {
            this.makeToast({ variant: 'danger', msg: 'Cannot accept partnership due to payment issue from employer' });
          } else if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER) {
            this.makeToast({
              variant: 'danger',
              msg: 'Cannot accept partnership due to payment issue. Please verify your payment method',
            });
          }
        } else {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      }
      this.isResponding = false;
      this.$emit('isResponding', false);
    },
    async viewSentModal() {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        'Please ensure that you want to cancel the partnership request.',
        {
          title: 'Cancel Request',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (isConfirmed) {
        await this.cancelRequestPartnership(this.partnership.id);
        this.makeToast({ variant: 'success', msg: 'Request Canceled!' });
        this.emitFetchPartnership();
      }
    },
    async viewAcceptModal() {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        'Please ensure that you want to dissolve the partnership. You will not be able to access any of the partnership benefits after dissolution.',
        {
          title: 'Dissolve Partnership',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (isConfirmed) {
        await this.deletePartnership(this.partnership.id);
        this.makeToast({ variant: 'success', msg: 'Partnership Dissolved!' });
        this.emitFetchPartnership();
      }
    },
    emitIsRespondingInModal(value) {
      this.$emit('isResponding', value);
    },
    emitFetchPartnership() {
      this.$emit('fetchPartnership');
    },
  },
};
</script>