<template>
  <div :class="{ container: !isPublic }">
    <div class="col-sm-12 card-group-row__col" v-for="job in jobs" :key="job.id">
      <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card">
        <!-- <div class="card-header d-flex align-items-center">
          <a href="#" @click.prevent="openJobProfile(job.id)" class="card-title flex mr-12pt">{{ job.title }}</a>
          <span>
            <i class="material-icons icon-16pt mr-1 text-dark">business</i>
            <a v-if="job.job_type === JOB_TYPES.EXTERNAL" class="text-primary" style="text-decoration: none">{{
              job.extra.company
            }}</a>
            <router-link
              v-else
              :to="{ name: 'student-employer-profile', params: { id: job.employer.id } }"
              class="text-primary"
              style="text-decoration: underline"
              >{{ job.employer.name }}</router-link
            >
          </span>
        </div> -->

        <div class="card-header d-flex align-items-center justify-content-between">
          <div :class="isMediumScreen ? '' : 'd-flex align-items-center'">
            <a href="#" @click.prevent="openJobProfile(job.id)" class="card-title flex mr-12pt">
              {{ job.title }}
            </a>
            <b-badge pill variant="primary" v-if="job.job_type !== JOB_TYPES.EXTERNAL"> Featured </b-badge>
          </div>

          <span>
            <i class="material-icons icon-16pt mb-1 mr-1 text-dark">business</i>
            <span v-if="job.job_type === JOB_TYPES.EXTERNAL" class="text-primary" style="text-decoration: none">{{
              job.extra.company
            }}</span>
            <router-link
              v-else-if="job.employer"
              :to="{ name: 'student-employer-profile', params: { id: job.employer.id } }"
              class="text-primary"
              event
              @click.native.prevent="openEmployerProfile(job.employer.id)"
              style="text-decoration: underline"
              >{{ job.employer && job.employer.name }}
            </router-link>
            <a v-else href="#" @click.prevent>{{ job.ext_posted_by && job.ext_posted_by.name }}</a>
          </span>
        </div>
        <div class="card-body d-flex flex-column" @click.prevent="openJobProfile(job.id)">
          <div class="d-flex align-items-center">
            <div class="flex">
              <div class="mb-16pt" :class="{ 'd-flex align-items-center': !isMobSmallScreen }">
                <div class="mr-12pt" :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen }">
                  <a
                    href="#"
                    @click.prevent
                    class="avatar avatar-xl overlay js-overlay overlay--primary rounded p-0 o-hidden d-flex justify-content-center align-items-center"
                    style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl;object-fit: cover; height: inherit"
                  >
                    <img
                      class="avatar-img m-0 rounded"
                      style=""
                      :src="
                        job.job_type === JOB_TYPES.EXTERNAL
                          ? job.extra.logo
                          : job.employer && job.employer.small_logo_url
                          ? job.employer.small_logo_url
                          : job.ext_posted_by && job.ext_posted_by.small_logo_url
                          ? job.ext_posted_by.small_logo_url
                          : DefaultAvatar
                      "
                      width="160"
                      height="auto"
                    />
                  </a>
                </div>
                <div class="flex">
                  <div class="mb-0" style="text-transform: none">
                    <div>
                      <i class="material-icons icon-16pt">location_on</i
                      ><strong style="font-weight: bold">{{ job.city }}, {{ job.state }}</strong>
                    </div>
                    <div v-if="job.type">
                      <i class="material-icons icon-16pt">timelapse</i> Seeking candidates for
                      <strong style="font-weight: bold">{{ $t(`jobWorkTypeOpts.${job.type}`) }}</strong>
                      position
                    </div>
                    <div v-if="job.caregiving_experience">
                      <span>
                        <i class="material-icons icon-16pt">favorite</i>
                        <strong style="font-weight: bold"> {{ job.caregiving_experience }} year(s)</strong>
                        caregiving experience required
                      </span>
                    </div>
                    <div>
                      <span>
                        <i class="material-icons icon-16pt">calendar_today</i> Posted
                        <strong style="font-weight: bold">{{ formatTimeAgo(job.created_at) }}</strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-button">
            <p class="flex mb-0 mt-1">
              {{
                truncate(trim(stripHtmlTags(job.description)), {
                  length: 250,
                  separator: /,? +/,
                })
              }}
            </p>
            <!-- <router-link
              v-if="job.description.length > 2"
              :to="{ name: 'student-job-details', params: { id: job.id } }"
              class="text-primary"
              >Read More</router-link
            > -->
            <ul>
              <li v-for="(benefit, index) in job.benefitCarAdv" :key="`${benefit}-${index}`">
                <a href="#" @click.prevent><span class="material-icons icon-16pt text-100">check</span>{{ benefit }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatTimeAgo, stripHtmlTags } from '@/common/utils';
import { mapGetters } from 'vuex';
import { USER_ROLES, JOB_TYPES } from '../../common/constants';
import { truncate, trim } from 'lodash';

export default {
  name: 'JobCard',
  props: {
    jobs: { type: Array, default: () => [] },
    isPublic: { type: Boolean, default: false },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      formatTimeAgo,
      USER_ROLES,
      JOB_TYPES,
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isMediumScreen() {
      return this.windowWidth <= 776;
    },
    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
  },
  methods: {
    truncate,
    trim,
    stripHtmlTags,
    openEmployerProfile(id) {
      if (this.isPublic) {
        this.$emit('viewMore', { name: 'redirect-employer-profile', params: { id: id } });
      } else {
        this.$router.push({ name: 'student-employer-profile', params: { id: id } });
      }
    },
    openJobProfile(id) {
      if (this.isPublic) {
        this.$emit('viewMore', { name: 'student-job-details', params: { id: id } });
      } else {
        if (this.$route.name === 'student-local-jobs-listing') {
          this.$router.push({ name: 'student-local-job-details', params: { id } });
        } else {
          this.$router.push({
            name: this.getLoggedInUser.role === USER_ROLES.PARTNER ? 'partner-job-details' : 'student-job-details',
            params: { id },
          });
        }
      }
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style>
</style>