<template>
  <div :class="containerClass">
    <div v-if="isMobileScreen">
      <img
        class=""
        :src="get(simulations, 'image_url') || DefaultAvatar"
        width="100%"
        height="200px"
        style="object-fit: cover; border-radius: 12px"
      />
      <b-btn v-if="get(simulations, 'agent')" @click.prevent="openSimulationModal" style="width: 100%;" class="my-2">
        Start Simulation<i class="ml-2 fas fa-play" style="font-size: 12px;"></i>
      </b-btn>
      <div
        v-else
        style="min-height: 10px; border: 1px solid; border-radius: 12px"
        class="text-warning text-center my-3 p-2"
      >
        <i class="material-icons my-1" style="font-size: 30px">info</i>
        <p>
          This simulation isn't available for your school. Please contact your admin to take this simulation.
        </p>
      </div>
    </div>
    <div style="font-size: 2rem; font-weight:600">
      {{ get(simulations, 'title') }}
    </div>

    <div class="mt-2" style="font-size: 1.1rem">
      <b-skeleton-wrapper :loading="isLoading" class="w-100">
        <template #loading>
          <div>
            <b-skeleton width="35%"></b-skeleton>
          </div>
        </template>
        <span class="mr-1">Offered By</span><b class="text-black">{{ get(simulations, 'posted_by.name') || 'None' }}</b>
      </b-skeleton-wrapper>
    </div>

    <page-separator title="" />

    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div class="row">
          <div class="col-lg-2">
            <div class="d-flex justify-content-center">
              <a
                href="#"
                @click.prevent
                class="avatar avatar-xxl overlay js-overlay overlay--primary rounded-circle p-relative o-hidden mb-16pt align-self-center"
                style="border: 1px solid #dfe2e6;"
              >
                <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
              </a>
            </div>

            <div class="mb-3">
              <b-skeleton type="button" width="100%"></b-skeleton>
            </div>
          </div>

          <div class="col-lg-10 d-flex mt-3">
            <div class="flex" style="max-width: 100%">
              <h3><b-skeleton width="100%"></b-skeleton></h3>
              <div class="mb-0 mt-3" style="text-transform: none">
                <b-skeleton width="100%"></b-skeleton>
                <b-skeleton width="55%"></b-skeleton>
                <b-skeleton width="70%"></b-skeleton>
                <b-skeleton width="65%"></b-skeleton>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="d-flex">
        <div class="d-flex mb-5" :style="isMobileScreen ? '' : 'width: 80%;'">
          <div class="flex" style="max-width: 100%">
            <div class="mb-0">
              <div class="mb-1" style="font-size: 15px">
                <strong class="text-black mr-1">Type: </strong>
                {{ $t(`simulationTypes.${get(simulations, 'type')}`) }}
              </div>

              <div class="mb-1 mt-2" style="font-size: 15px">
                <strong class="text-black mr-1">Description: </strong>

                <div style="font-size: 15px" v-html="get(simulations, 'details')"></div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isMobileScreen" style="width: 50%;" class="ml-2">
          <img
            :src="get(simulations, 'image_url') || DefaultAvatar"
            width="100%"
            height="200px"
            style="object-fit: cover; border-radius: 12px"
          />
          <b-btn
            v-if="get(simulations, 'agent')"
            @click.prevent="openSimulationModal"
            style="width: 100%;"
            class="my-2"
          >
            Start Simulation<i class="ml-2 fas fa-play" style="font-size: 12px;"></i>
          </b-btn>
          <div
            v-else
            style="min-height: 10px; border: 1px solid; border-radius: 12px"
            class="text-warning text-center mt-3 p-2"
          >
            <i class="material-icons my-1" style="font-size: 30px">info</i>
            <p>
              {{
                get(this.getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL
                  ? `This simulation isn't available for your school. Please contact us at support@jointransition.com for further info.`
                  : `This simulation isn't available for your school. Please contact your admin to take this simulation.`
              }}
            </p>
          </div>
        </div>
      </div>
    </b-skeleton-wrapper>
    <simulation-modal :simulation="simulations" :show-modal="showSimulationModal" @close="hideSimulationModal" />
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Page from '@/components/Page.vue';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import { USER_ROLE_TYPES, DASHBOARD_TYPES } from '@/common/constants';
import SimulationModal from './SimulationModal.vue';

export default {
  components: {
    SimulationModal,
    PageSeparator,
  },

  extends: Page,

  data() {
    return {
      title: 'Simulation Details',
      DefaultAvatar,
      isLoading: false,
      isStatusUpdating: false,
      simulations: {},
      showSimulationModal: false,
      USER_ROLE_TYPES,
      windowWidth: window.innerWidth,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser', 'getLoggedInUserDashboard']),
    isMobileScreen() {
      return this.windowWidth <= 576;
    },
  },

  methods: {
    ...mapActions('simulations', ['getSimulationFromSlug']),
    get,
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    openSimulationModal() {
      this.showSimulationModal = true;
    },
    hideSimulationModal() {
      this.showSimulationModal = false;
    },
    async fetchSimulation() {
      this.isLoading = true;
      try {
        const res = await this.getSimulationFromSlug({
          slug: this.$route.params.id,

          ...(this.getLoggedInUserDashboard === DASHBOARD_TYPES.CARECAMPUS && {
            entity_id: this.$route.query.uniId,
            entity_type: 'university',
          }),
          ...(this.getLoggedInUserDashboard !== DASHBOARD_TYPES.CARECAMPUS && {
            entity_id: this.getLoggedInUser.linked_entity.id,
            entity_type: this.getLoggedInUser.linked_entity.entity_type,
          }),
        });

        this.simulations = res.data;

        this.title = this.simulations.title;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    if (this.$route.params.id) {
      await this.fetchSimulation();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
