<template>
  <fmv-drawer id="job-filters" align="end">
    <job-filters-sidebar></job-filters-sidebar>
  </fmv-drawer>
</template>

<script>
import { FmvDrawer } from 'fmv-layout';
import JobFiltersSidebar from './JobFiltersSidebar.vue';

export default {
  name: 'JobFilters',

  components: { FmvDrawer, JobFiltersSidebar },

  data() {
    return {};
  },

  computed: {},

  methods: {},

  async mounted() {},
};
</script>
