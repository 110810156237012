<template>
  <div data-perfect-scrollbar>
    <b-skeleton-wrapper :loading="isLoading" v-if="isLoading">
      <template #loading>
        <div class="page-section pb10 student-page">
          <div class="container page__container d-flex align-items-center">
            <p class="backResult text-50">
              <router-link
                :to="{ name: 'student-jobs-listing' }"
                style="text-decoration: underline"
                v-if="get(getLoggedInUser, 'role') === USER_ROLES.STUDENT && $route.name === 'student-job-details'"
              >
                <span class="material-icons icon-18pt text-80">navigate_before</span>Back to Jobs
              </router-link>
              <router-link
                :to="{ name: 'student-local-jobs-listing' }"
                style="text-decoration: underline"
                v-else-if="
                  get(getLoggedInUser, 'role') === USER_ROLES.STUDENT && $route.name === 'student-local-job-details'
                "
              >
                <span class="material-icons icon-18pt text-80">navigate_before</span>Back to Featured Jobs
              </router-link>
              <router-link
                :to="{ name: 'jobs-management-list' }"
                style="text-decoration: underline"
                v-else-if="
                  $route.params.backTo &&
                  [USER_ROLE_TYPES.SUPER_ADMIN, USER_ROLE_TYPES.ORGANIZATION, USER_ROLE_TYPES.EMPLOYER].includes(
                    getLoggedInUser.role_type
                  )
                "
              >
                <span class="material-icons icon-18pt text-80">navigate_before</span>Back to Jobs
              </router-link>
              <router-link
                :to="{ name: 'edit-job', params: { id: $route.params.id } }"
                style="text-decoration: underline"
                v-else
              >
                <span class="material-icons icon-18pt text-80">navigate_before</span>Back to Job
              </router-link>
            </p>
          </div>

          <div
            class="container page__container d-flex flex-column flex-md-row align-items-center text-center text-md-left"
          >
            <b-col md="2">
              <b-skeleton-img height="113px" no-aspect></b-skeleton-img>
            </b-col>
            <div class="flex mb-32pt mb-md-0 mobilemb0">
              <h4 class="mb-0"><b-skeleton width="60%" height="1.5rem"></b-skeleton></h4>
              <p class="d-flex mt-2 mb-3">
                <b-skeleton width="85%"></b-skeleton>
              </p>

              <b-row>
                <b-col md="2" sm="12">
                  <b-skeleton type="button" width="100%"></b-skeleton>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>

        <div class="container page__container page-section student-card mt-0 pt-0 mb-32pt">
          <div class="card">
            <div class="card-body">
              <div class="d-flex mb-1">
                <div class="flex">
                  <div class="mb-1">
                    <h5 class="card-title fw600"><b-skeleton width="50%"></b-skeleton></h5>
                  </div>

                  <div class="d-flex">
                    <div class="flex">
                      <span class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">location_on</i><b-skeleton width="75%"></b-skeleton>
                      </span>
                      <span class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">timelapse</i><b-skeleton width="50%"></b-skeleton>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <div class="d-flex mb-1">
                <div class="flex">
                  <div class="mb-1">
                    <h5 class="card-title fw600"><b-skeleton width="50%"></b-skeleton></h5>
                  </div>

                  <span>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="60%"></b-skeleton>
                  </span>

                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-skeleton-wrapper>

    <div v-else>
      <div class="page-section pb10 student-page">
        <div class="container page__container d-flex align-items-center">
          <p class="backResult text-50">
            <router-link
              :to="{ name: 'student-jobs-listing' }"
              style="text-decoration: underline"
              v-if="get(getLoggedInUser, 'role') === USER_ROLES.STUDENT && $route.name === 'student-job-details'"
            >
              <span class="material-icons icon-18pt text-80">navigate_before</span>Back to Jobs
            </router-link>
            <router-link
              :to="{ name: 'student-local-jobs-listing' }"
              style="text-decoration: underline"
              v-else-if="
                get(getLoggedInUser, 'role') === USER_ROLES.STUDENT && $route.name === 'student-local-job-details'
              "
            >
              <span class="material-icons icon-18pt text-80">navigate_before</span>Back to Featured Jobs
            </router-link>
            <router-link
              :to="{ name: 'jobs-management-list' }"
              style="text-decoration: underline"
              v-else-if="
                $route.params.backTo &&
                [USER_ROLE_TYPES.SUPER_ADMIN, USER_ROLE_TYPES.ORGANIZATION, USER_ROLE_TYPES.EMPLOYER].includes(
                  getLoggedInUser.role_type
                )
              "
            >
              <span class="material-icons icon-18pt text-80">navigate_before</span>Back to Jobs
            </router-link>
            <router-link
              :to="{ name: 'edit-job', params: { id: $route.params.id } }"
              style="text-decoration: underline"
              v-else
            >
              <span class="material-icons icon-18pt text-80">navigate_before</span>Back to Job
            </router-link>
          </p>
        </div>

        <div
          class="container page__container d-flex flex-column flex-md-row align-items-center text-center text-md-left"
        >
          <img
            :src="
              job.job_type === JOB_TYPES.EXTERNAL
                ? job.extra.logo
                : (job.employer && job.employer.logo_url) || job.ext_posted_by.logo_url || DefaultAvatar
            "
            width="115"
            class="mr-md-32pt mb-32pt mb-md-0 rounded mobilemb10"
            alt="Employer Logo"
          />
          <div class="flex mb-32pt mb-md-0 mobilemb0">
            <h4 class="mb-0">{{ job.title }}</h4>
            <p class="d-flex align-items-center quote mt-1 mb-4">
              <i class="material-icons icon-12pt mr-8pt text-50">business</i>
              <a
                v-if="job.job_type === JOB_TYPES.EXTERNAL"
                class="text-primary mr-2 mt-1"
                style="display: inline; font-weight: 600"
              >
                {{ job.extra.company }}</a
              >
              <router-link
                v-else-if="get(getLoggedInUser, 'role') === USER_ROLES.STUDENT && job.employer"
                :to="{ name: 'student-employer-profile', params: { id: job.employer.id } }"
                class="text-primary mr-2 mt-1"
                style="display: inline; font-weight: 600"
                target="_blank"
                >{{ job.employer && job.employer.name }}</router-link
              >
              <a href="#" @click.prevent class="text-primary mr-2" style="display: inline; font-weight: 600" v-else>{{
                (job.employer && job.employer.name) || job.ext_posted_by.name
              }}</a>
            </p>
            <a
              @click.prevent="editJob"
              class="btn btn-primary"
              v-if="
                [USER_ROLE_TYPES.SUPER_ADMIN].includes(getLoggedInUser.role_type) ||
                ([USER_ROLE_TYPES.EMPLOYER, USER_ROLE_TYPES.ORGANIZATION].includes(getLoggedInUser.role_type) &&
                  !job.is_expired)
              "
              target="_blank"
              ><md-icon class="mr-1">edit</md-icon>Edit Job</a
            >

            <a
              :href="getClickableLink(job.application_link)"
              class="btn btn-primary"
              v-else-if="getLoggedInUser.role_type === USER_ROLE_TYPES.STUDENT && job.application_link"
              target="_blank"
              ><md-icon class="mr-1">open_in_new</md-icon>Apply Now</a
            >
            <b-dropdown
              text="Contact"
              variant="primary"
              class="ml-2"
              v-if="
                (job.email_contact_allowed && job.contact_email) || (job.phone_contact_allowed && job.contact_phone)
              "
            >
              <b-dropdown-item
                :href="`mailto:${job.contact_email}`"
                v-if="job.email_contact_allowed && job.contact_email"
                ><i class="material-icons mr-1">email</i>Email</b-dropdown-item
              >
              <b-dropdown-item :href="`tel:${job.contact_phone}`" v-if="job.phone_contact_allowed && job.contact_phone"
                ><i class="material-icons mr-1">phone</i>Call</b-dropdown-item
              >
            </b-dropdown>
          </div>
        </div>
      </div>

      <div class="container page__container page-section mt-0 pt-0 mb-32pt">
        <div class="student-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex mb-1">
                <div class="flex">
                  <div class="d-flex align-items-center mb-1">
                    <h5 class="card-title fw600">Basic Info</h5>
                  </div>

                  <div class="d-flex">
                    <div class="flex">
                      <span v-if="job.city && job.state && job.zipcode && job.country" class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">location_on</i>

                        {{ job.address ? `${job.address},` : '' }} {{ job.city }}, {{ job.state }}, {{ job.zipcode }},
                        {{ job.country }}
                      </span>
                      <span v-if="job.type" class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">timelapse</i>Seeking candidates for
                        {{ $t(`jobWorkTypeOpts.${job.type}`) }} position.
                      </span>
                      <span v-if="job.schedule" class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">access_time</i>Schedule/Shift:
                        {{ $t(`jobScheduleOpts.${job.schedule}`) }}
                      </span>
                      <span v-if="job.no_of_hires" class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">group</i>No. of positions: {{ job.no_of_hires }}
                      </span>
                      <span v-if="job.minimum_pay || job.maximum_pay" class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">attach_money</i>
                        <span v-if="job.maximum_pay"
                          >Salary Range: ${{ $n(job.minimum_pay) }} - ${{ $n(job.maximum_pay) }}</span
                        >
                        <span v-else>Salary starting from ${{ $n(job.minimum_pay) }}.</span>
                      </span>
                      <span v-if="job.pay_rate" class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">local_atm</i>Pay Rate:
                        {{ $t(`jobPayRateOpts.${job.pay_rate}`) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="event-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex mb-1">
                <div class="flex">
                  <div class="d-flex align-items-center mb-1">
                    <h5 class="card-title fw600">Job Description</h5>
                  </div>

                  <div v-safe-html="job.description" />

                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="job.caregiving_experience" class="student-card">
          <div class="row card-group-row">
            <div class="col-sm-12 col-xl-12">
              <div class="card card-sm">
                <div class="card-body">
                  <div class="">
                    <div class="">
                      <div class="">
                        <h5 class="card-title fw600">Care Experience</h5>
                      </div>
                      <span class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">favorite</i>{{ job.caregiving_experience }} year(s)
                        caregiving experience required.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card" v-if="certifications.length">
            <div class="card-body">
              <div class="d-flex mb-1">
                <div class="flex">
                  <div class="d-flex align-items-center mb-1">
                    <h5 class="card-title fw600">Required Certifications</h5>
                  </div>

                  <ul>
                    <li v-for="(cert, index) in certifications" :key="`${cert}-${index}`">
                      <span><i class="material-icons icon-18pt mr-8pt">assignment_turned_in</i>{{ cert }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="card" v-if="records.length">
            <div class="card-body">
              <div class="d-flex mb-1">
                <div class="flex">
                  <div class="d-flex align-items-center mb-1">
                    <h5 class="card-title fw600">Required Records</h5>
                  </div>

                  <ul>
                    <li v-for="(record, index) in records" :key="`${record}-${index}`">
                      <span><i class="material-icons icon-18pt mr-8pt">description</i>{{ record }}</span>
                    </li>
                  </ul>

                  <div></div>
                </div>
              </div>
            </div>
          </div>

          <div class="card" v-if="benefitsTexts.length">
            <div class="card-body">
              <div class="d-flex mb-1">
                <div class="flex">
                  <div class="d-flex align-items-center mb-1">
                    <h5 class="card-title fw600">Benefits &amp; Perks</h5>
                  </div>

                  <ul>
                    <li v-for="(benefit, index) in benefitsTexts" :key="`${benefit}-${index}`">
                      <span><i class="material-icons icon-18pt mr-8pt">check</i>{{ benefit }}</span>
                    </li>
                  </ul>

                  <div></div>
                </div>
              </div>
            </div>
          </div>

          <div class="card" v-if="careerAdvancements.length">
            <div class="card-body">
              <div class="d-flex mb-1">
                <div class="flex">
                  <div class="d-flex align-items-center mb-1">
                    <h5 class="card-title fw600">Career Advancements</h5>
                  </div>

                  <span>{{ job.career_offerings_details }}</span>

                  <ul class="mt-2">
                    <li v-for="(adv, index) in careerAdvancements" :key="`${adv}-${index}`">
                      <span><i class="material-icons icon-18pt mr-8pt">local_hospital</i>{{ adv }}</span>
                    </li>
                  </ul>

                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';

import Page from '@/components/Page.vue';
import { mapActions, mapGetters } from 'vuex';
import { get, keyBy } from 'lodash';

import { formatFullDate, getClickableLink } from '../../common/utils';
import {
  JOB_BENEFITS_OPTONS,
  JOB_CERT_OPTIONS,
  JOB_RECORD_OPTIONS,
  JOB_TYPES,
  USER_ROLES,
  USER_ROLE_TYPES,
} from '../../common/constants';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';

export default {
  name: 'JobShowPage',
  components: {},
  extends: Page,

  data() {
    return {
      DefaultAvatar,
      USER_ROLES,
      title: 'Job Details',

      isLoading: true,
      job: {},

      certifications: [],
      records: [],
      benefitsTexts: [],
      careerAdvancements: [],
      USER_ROLE_TYPES,
      JOB_TYPES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Job Details', active: true },
      ];
    },
  },

  methods: {
    ...mapActions('job', ['getJob']),
    ...mapActions('fileDownload', ['downloadFile']),
    formatFullDate,
    getClickableLink,
    get,
    editJob() {
      this.$router.push({ name: 'edit-job', params: { id: this.job.id } });
    },
    async fetchJob() {
      this.isLoading = true;
      try {
        const resp = (await this.getJob(this.$route.params.id)).data;

        // Redirect inactive job to main jobs page.
        if (get(this.getLoggedInUser, 'role') === USER_ROLES.STUDENT) {
          if (resp.job_type !== JOB_TYPES.EXTERNAL ? false : !resp.is_active) {
            this.$router.push({ name: 'student-jobs-listing' });
          }
        }

        // Benefits
        JOB_BENEFITS_OPTONS.forEach((benefit) => {
          if (resp.benefits.includes(benefit.value) && benefit.value !== 'other') {
            this.benefitsTexts.push(benefit.text);
          }
        });
        if (resp.benefits.includes('other') && resp.other_benefits) {
          this.benefitsTexts.push(resp.other_benefits);
        }

        // Career Advancements
        if (resp.offers_career_advancement) {
          this.careerAdvancements = (resp.career_offerings || []).map((career) => career.name);
          if (resp.other_career_offering) {
            this.careerAdvancements.push(resp.other_career_offering);
          }
        }

        // Required Certifications
        const certByValue = keyBy(JOB_CERT_OPTIONS, 'value');
        resp.required_certs.forEach((cert) => {
          if (certByValue[cert] && cert !== 'other') {
            this.certifications.push(certByValue[cert].fullText);
          }
        });
        if (resp.required_certs.includes('other') && resp.other_required_certs) {
          this.certifications.push(resp.other_required_certs);
        }

        // Required Records
        const recordsByValue = keyBy(JOB_RECORD_OPTIONS, 'value');
        resp.required_records.forEach((record) => {
          if (recordsByValue[record] && record !== 'other') {
            this.records.push(recordsByValue[record].text);
          }
        });
        if (resp.required_records.includes('other') && resp.other_required_records) {
          this.records.push(resp.other_required_records);
        }

        this.job = resp;
      } catch (_err) {
        if (get(this.getLoggedInUser, 'role') === USER_ROLES.STUDENT) {
          if (this.$route.name === 'student-local-job-details') {
            this.$router.push({ name: 'student-local-jobs-listing' });
          } else {
            this.$router.push({ name: 'student-jobs-listing' });
          }
        } else {
          this.$router.push({ name: 'jobs-management-list' });
        }
      }
      this.isLoading = false;
    },

    async downloadGivenFile(url) {
      const h = this.$createElement;

      this.makeToast({
        variant: 'primary',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
        toaster: 'b-toaster-bottom-left',
      });

      await this.downloadFile({ fileUrl: url, removeTimestamp: true });

      this.makeToast({
        variant: 'success',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
        toaster: 'b-toaster-bottom-left',
      });
    },
  },

  async mounted() {
    this.fetchJob();
  },
};
</script>
