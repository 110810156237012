<template>
  <div style="padding-top: 16px" id="page-container">
    <div class="page-section jobs_container">
      <div :class="containerClass">
        <div class="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt" style="white-space: nowrap">
          <h2 class="mb-0">
            Offers
          </h2>

          <div class="w-auto ml-sm-auto mb-sm-0" :class="{ 'd-flex': !isMobileScreen }">
            <div :class="isMobileScreen ? 'mt-2 ml-auto' : 'ml-3'">
              <b-btn :to="{ name: 'add-offer' }" variant="primary" :class="{ 'w-100': isMobileScreen }"
                >Add Offer</b-btn
              >
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center" v-if="!totalOffers && !isLoading">
          <div class="card card-block card-stretch card-height col-md-12">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Offers</h4>

              <p class="text-muted">
                Couldn't find any offers right now.
              </p>
            </div>
          </div>
        </div>

        <div class="card mb-0" v-if="totalOffers">
          <b-table
            :fields="tableColumns"
            :items="offers"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <router-link
                :to="{
                  name: 'edit-offer',
                  params: { id: data.item.id },
                }"
                class="svg-icon mr-2"
                v-b-popover.hover.right
                :title="'Edit'"
              >
                <i class="material-icons">edit</i>
              </router-link>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalOffers"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="schools-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce, get } from 'lodash';
import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import { mapActions, mapGetters } from 'vuex';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import { formatDateTime } from '../../../common/utils';

export default {
  components: { Pagination },
  extends: Page,

  data() {
    return {
      DefaultAvatar,
      title: 'Offers',
      isLoading: true,
      perPage: 14,
      currentPage: 1,
      totalOffers: 0,
      offers: [],
      windowWidth: window.innerWidth,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Offers', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'offer_type', label: 'Type' },
        { key: 'organization', label: 'Organization' },
        { key: 'offer_date', label: 'Offer Date' },
        { key: 'status', label: 'status' },
        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },

    isMobileScreen() {
      return this.windowWidth <= 720;
    },
  },

  methods: {
    ...mapActions('student', ['getAllOffers']),
    get,
    formatDateTime,

    async fetchOffers(pageNum = 1, params = {}) {
      this.isLoading = true;
      const response = await this.getAllOffers({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...params,
      });

      this.offers = response.data.results;
      this.currentPage = pageNum;
      this.totalOffers = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchOffers(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchOffers();
    },

    onSearch() {
      this.debouncedSearchOffers(this);
    },

    debouncedSearchOffers: debounce(vm => {
      vm.fetchOffers();
    }, 500),

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    this.fetchOffers();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
