<template>
  <div>
    <b-modal v-model="show" title="View More" ref="view-more-modal" @hide="hideModal">
      <b-container fluid>
        <p>{{ bodyText }}</p>
        <div class="d-flex align-items-center justify-content-center">
          <!-- <a href="#" @click.prevent="hideModalManual">Cancel</a> -->
          <b-btn
            class="ml-2"
            target="_blank"
            :href="mainDomain ? mainPath : null"
            :to="mainDomain ? null : loginPath"
            variant="primary"
            >Sign In</b-btn
          >
        </div>
      </b-container>

      <template #modal-footer>
        <div class="w-100">
          <!-- <div class="d-flex justify-content-center mt-0">
            Don't have an account?
            <a class="ml-1">
              <router-link :to="{ name: 'student-signup' }" style="text-decoration: underline">
                Sign Up Now</router-link
              ></a
            >
          </div> -->
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    showModal: Boolean,
    loginPath: { type: Object, default: () => ({ name: 'login' }) },
    mainPath: { type: String, default: '/' },
    mainDomain: { type: Boolean, default: () => false },
    bodyText: { type: String, default: 'You must login to view more details about this job/employer.' },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    hideModal() {
      this.$emit('close');
    },
    hideModalManual() {
      this.$refs['view-more-modal'].hide();
      this.hideModal();
    },
  },
  watch: {
    showModal(value) {
      if (value) {
        if (this.loginPath) {
          this.$refs['view-more-modal'].show();
        }
      }
    },
  },
};
</script>