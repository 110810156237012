<template>
  <div :class="containerClass">
    <div class="back-result-lg font-weight-bold">
      <a href="#" @click.prevent="goBack()" style="text-decoration: underline">
        <span class="material-icons icon-32pt">navigate_before</span>
      </a>
    </div>

    <h2>Add Course</h2>

    <b-form-group label="College Name*" label-for="college" label-class="form-label">
      <b-form-input
        id="college"
        placeholder="Enter College name in which you took this course"
        v-model="college"
        :state="!$v.college.required && $v.college.$dirty ? false : null"
      />
      <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
    </b-form-group>

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group label="Course Name*" label-for="course_name" label-class="form-label">
        <b-form-input
          id="course_name"
          placeholder="Enter Course name"
          v-model="course_name"
          :state="!$v.course_name.required && $v.course_name.$dirty ? false : null"
        />
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Term*" label-for="term" label-class="form-label">
        <v-select
          id="term"
          class="form-control v-select-custom"
          v-model="term"
          label="text"
          :reduce="ins => ins.value"
          placeholder="Enter question title"
          :options="allCoursesTypes"
          :state="!$v.term.required && $v.term.$dirty ? 'form-control is-invalid' : ''"
        >
        </v-select>
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Course Code*" label-for="course_code" label-class="form-label">
        <b-form-input
          id="course_code"
          placeholder="e.g. CS 101"
          v-model="course_code"
          :state="!$v.course_code.required && $v.course_code.$dirty ? false : null"
        />
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Completion Date*" label-for="year_taken" class="form-label">
        <date-picker
          id="year_taken"
          v-model="year_taken"
          type="date"
          format="MM/DD/YYYY"
          value-type="YYYY-MM-DD"
          style="width: 100%"
          lang="en"
          placeholder="MM/DD/YYYY"
          :state="!$v.year_taken.required && $v.year_taken.$dirty ? false : null"
        >
        </date-picker>
        <b-form-invalid-feedback :state="!$v.year_taken.required && $v.year_taken.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-btn variant="primary" :disabled="isLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('update') : 'Add' }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Page from '@/components/Page.vue';
import { required } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/en';

export default {
  extends: Page,
  components: { DatePicker },

  data() {
    return {
      title: 'Add Course',
      term: '',
      course_code: '',
      year_taken: null,
      course_name: '',
      college: '',
      isLoading: false,
      allCoursesTypes: [
        { text: 'Spring', value: 'spring' },
        { text: 'Summer', value: 'summer' },
        { text: 'Fall', value: 'fall' },
      ],
    };
  },

  validations() {
    return {
      term: { required },
      course_name: { required },
      course_code: { required },
      college: { required },
      year_taken: { required },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
  },

  methods: {
    ...mapActions('student', ['createCourse', 'getCourse', 'updateCourse', 'updateShowEarnedPointToast']),
    get,
    goBack() {
      this.$router.go(-1);
    },
    async fetchCourse() {
      try {
        const res = await this.getCourse(this.$route.params.id);
        this.term = res.data.term;
        this.course_code = res.data.course_code;
        this.year_taken = res.data.year_taken;
        this.course_name = res.data.course_name;
        this.college = res.data.college;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    async onSubmit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isLoading = true;
        try {
          const data = {
            term: this.term,
            course_code: this.course_code,
            year_taken: this.year_taken,
            course_name: this.course_name,
            college: this.college,
          };

          // let response;
          if (this.$route.params.id) {
            await this.updateCourse({ id: this.$route.params.id, data });
            this.makeToast({ variant: 'success', msg: 'Course updated!' });
          } else {
            const courseResp=await this.createCourse(data);
            this.makeToast({ variant: 'success', msg: 'Course asked!' });
            if (courseResp.data.data.points_earned > 0) {
              this.updateShowEarnedPointToast(courseResp.data.data.points_earned);
            }
          }
          setTimeout(() => {
          this.$router.push({ name: 'my-courses' });}, 1000);
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
        this.isLoading = false;
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields.' });
      }
    },
  },
  async mounted() {
    if (this.$route.params.id) {
      this.fetchCourse();
    }
  },
};
</script>

<style></style>
