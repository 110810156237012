<template>
  <b-modal
    ref="videoViewerModal"
    hide-footer
    :title="`${stdName}'s Introduction Video`"
    centered
    lazy
    scrollable
    @hide="hideModal"
    size="lg"
  >
    <b-skeleton-img class="w-100" height="500px" v-if="isLoading" no-aspect></b-skeleton-img>
    <div v-if="introVideo" class="h-100 w-100">
      <video class="h-100 w-100" id="video-preview" controls :src="introVideo" />
    </div>
  </b-modal>
</template>

<script>
//import { mapActions } from 'vuex';

export default {
  name: 'VideoPreviewModal',

  props: {
    showModal: { type: Boolean, default: false },
    introVideo: { type: String, default: null },
    stdName: { type: String, default: '' },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {},

  watch: {
    showModal(value) {
      if (value) {
        this.$refs.videoViewerModal.show();
      }
    },
  },

  methods: {
    hideModal() {
      this.$emit('close');
      this.isLoading = false;
    },

    hideModalManual() {
      this.$refs.videoViewerModal.hide();
      this.hideModal();
    },
  },

  async mounted() {},
};
</script>
