<template>
  <b-modal ref="addPiiModal" hide-footer :title="title" centered lazy scrollable @hide="hideModal" size="lg">
    <b-form @submit.prevent="onSubmit">
      <b-form-group :label="title" :label-for="type" label-class="form-label" class="row-align-items-center">
        <!-- Upload progress while uploading -->
        <b-progress :max="100" animated v-if="isUploading">
          <b-progress-bar :value="uploadPercent">
            <span>
              Uploading (<strong>{{ uploadPercent }} %</strong>)
            </span>
          </b-progress-bar>
        </b-progress>

        <span v-else-if="piiTypes">
          <div class="row">
            <div class="col-md-10">
              <a
                href="#"
                @click.prevent="
                  downloadFile({
                    fileUrl: piiTypes,
                    removeTimestamp: true,
                  })
                "
                v-b-popover.hover.top="'Download'"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(piiTypes, true) }}
              </a>
            </div>
            <div class="text-right col-md-2">
              <a href="#" class="text-danger" @click.prevent="removeFile(type, type)"
                ><i class="fas fa-times-circle"></i> Remove
              </a>
            </div>
          </div>
        </span>
        <b-media class="align-items-center" vertical-align="center" v-else>
          <b-form-file
            :id="type"
            placeholder="Select Document (max size: 10MB, only .pdf and image files allowed)"
            :browse-text="$t('generalMsgs.browse')"
            v-model="selectedUploadFile"
            @input="uploadFile()"
            :disabled="isUploading"
            accept=".pdf, image/*"
            required
          />
        </b-media>
      </b-form-group>
      <b-form-group v-if="isDocDateReq" label="Expiration Date" label-for="expireDate" label-class="form-label">
        <date-picker
          v-model="expireDate"
          type="date"
          format="MM/DD/YYYY"
          value-type="YYYY-MM-DD"
          style="width: 100%"
          lang="en"
          placeholder="MM/DD/YYYY"
          :id="type"
          :input-attr="{ required: isDocDateReq }"
        ></date-picker>
      </b-form-group>

      <div v-if="questionsAnswers.length">
        <div v-for="(question, index) in questionsAnswers" :key="index">
          <b-form-group
            :label="`${question.question}${isRequired ? '*' : ''}`"
            label-for="question"
            label-class="form-label"
          >
            <b-form-textarea :required="isRequired" id="question" :rows="3" v-model="question.answer" />
          </b-form-group>
        </div>
      </div>

      <div class="d-flex justify-content-end mt-2">
        <b-btn
          variant="primary"
          style="width: 100px"
          type="submit"
          class="btn-normal"
          :disabled="isUploading || isLoading"
        >
          <md-icon class="mr-1">file_upload</md-icon>Upload
        </b-btn>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { parseFileNameFromUrl } from '../../../common/utils';
import { EXPIRATION_REQ_DOCS } from '../../../common/constants';
import DatePicker from 'vue2-datepicker';
import axios from 'axios';
export default {
  name: 'AddPiiModal',
  props: {
    showModal: { type: Boolean, default: false },
    isRequired: { type: Boolean, default: false },
    isDocDateReq: { type: Boolean, default: false },
    title: { type: String, default: '' },
    type: { type: String, default: '' },
    questions: { type: Array, default: () => [] },
  },
  components: { DatePicker },
  data() {
    return {
      isLoading: false,
      expireDate: null,
      piiTypes: '',
      isUploading: false,
      selectedUploadFile: null,
      uploadPercent: 0,
      uploadCancelTokenSource: null,
      EXPIRATION_REQ_DOCS,
      questionsAnswers: [],
    };
  },

  computed: {
    btnDisable() {
      return ['cpr', 'state_id', 'social_sec_card'].includes(this.type) && !this.expireDate && !this.piiTypes;
    },
  },

  watch: {
    showModal(value) {
      if (value) {
        this.resetData();
        this.questions.length
          ? this.questions.map((question) => this.questionsAnswers.push({ question: question, answer: '' }))
          : (this.questionsAnswers = []);
        this.$refs.addPiiModal.show();
      }
    },
  },

  methods: {
    ...mapActions('fileDownload', ['downloadFile']),
    ...mapActions('student', ['getStudent', 'createStudent', 'updateStudent', 'getStudentUploadPresignedUrl']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    parseFileNameFromUrl,
    resetData() {
      this.expireDate = null;
      this.piiTypes = '';
      this.isUploading = false;
      this.selectedUploadFile = null;
      this.uploadPercent = 0;
      this.uploadCancelTokenSource = null;
      this.questionsAnswers = [];
    },
    onSubmit() {
      this.$emit('add', this.selectedUploadFile, this.piiTypes, this.type, this.expireDate, this.questionsAnswers);
      this.$refs.addPiiModal.hide();
    },
    async uploadFile(uploadType = 'documents') {
      this.uploadCancelTokenSource = axios.CancelToken.source();
      this.isUploading = true;

      try {
        const urlResp = await this.getStudentUploadPresignedUrl({
          file_name: this.selectedUploadFile.name,
          content_type: this.selectedUploadFile.type,
          upload_type: uploadType,
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.selectedUploadFile,
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource.token,
          },
        });
        this.piiTypes = urlResp.upload_url.split('?')[0];
      } catch (error) {
        this.selectedUploadFile = null;
      }

      this.uploadCancelTokenSource = null;
      this.uploadPercent = 0;

      this.isUploading = false;
    },
    removeFile() {
      this.selectedUploadFile = null;
      this.piiTypes = null;
    },
    hideModal() {
      this.$emit('close');
      this.resetData();
      this.$refs.addPiiModal.hide();
    },

    hideModalManual() {
      this.$refs.addPiiModal.hide();
      this.hideModal();
    },
  },

  async mounted() {},
};
</script>
