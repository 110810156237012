<template>
  <div>
    <div>
      <!-- <div class="row mb-8pt">
        <div class="col-lg-12 d-flex">
          <div class="flex" style="max-width: 100%">
            <div class="mb-0">
              <p class="text-90" style="font-size: 16px">
                The following are job opportunities available for students to begin applying their skills into the
                workplace. <b>Note: Students must be logged in to view full job details.</b>
              </p>
            </div>
          </div>
        </div>
      </div> -->
      <page-separator
        :title="
          isLoading
            ? 'Jobs'
            : `Showing ${totalJobs ? `${$n(pageFromCount)}-${$n(pageToCount)}` : 0} of ${$n(totalJobs)} Jobs`
        "
      />
    </div>
    <div class="page-section">
      <div class="">
        <div class="d-flex justify-content-center" v-if="!totalJobs && !isLoading">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Jobs Found</h4>

              <p class="text-muted">
                Couldn't find any open jobs right. However, there is a constant need for great candidates like you, so
                please check again after a while.
              </p>

              <!-- <a href="#" @click.prevent="" class="btn btn-primary mt-2"> Sample Button </a> -->
            </div>
          </div>
        </div>

        <b-skeleton-wrapper :loading="isLoading" v-else>
          <template #loading>
            <div class="row card-group-row mb-lg-12pt home-card">
              <div class="col-sm-12 card-group-row__col" v-for="item in [1, 2]" :key="item">
                <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card">
                  <div class="card-header d-flex align-items-center">
                    <a href="#" @click.prevent class="card-title flex"> <b-skeleton width="100%"></b-skeleton></a>
                  </div>
                  <div class="card-body d-flex flex-column">
                    <div class="d-flex align-items-center">
                      <div class="flex">
                        <div class="mb-16pt" :class="{ 'd-flex align-items-center': !isMobSmallScreen }">
                          <div class="mr-12pt" :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen }">
                            <a
                              href="#"
                              @click.prevent
                              class="avatar avatar-xl overlay js-overlay overlay--primary rounded p-relative o-hidden mb-16pt align-self-center"
                              style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                            >
                              <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                            </a>
                          </div>
                          <div class="flex">
                            <div class="mb-0" style="text-transform: none">
                              <b-skeleton width="100%"></b-skeleton>
                              <b-skeleton width="55%"></b-skeleton>
                              <b-skeleton width="70%"></b-skeleton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div class="row card-group-row mb-lg-12pt home-card">
            <job-card :jobs="jobs" :isPublic="isPublic" @viewMore="openViewMoreModal" />
          </div>
        </b-skeleton-wrapper>

        <pagination
          v-if="totalJobs > jobs.length"
          v-model="currentPage"
          :total-rows="totalJobs"
          :per-page="perPage"
          @change="onPageChange"
          class="mb-4"
        />

        <div class="row mb-32pt">
          <div class="col-lg-12 d-flex">
            <div class="flex" style="max-width: 100%">
              <div class="alert alert-light mb-2 border border-primary" role="alert" style="">
                <div class="d-flex flex-wrap text-center align-items-start">
                  <div class="flex" style="min-width: 180px">
                    <small class="text-black-100">
                      <p class="mr-1" style="font-size: 16px">
                        Are you interested in connecting with our students? Learn how to partner with
                        {{ get(getCurrentSchool, 'name', 'Transition') }}.
                      </p>
                      <a class="btn btn-primary text-white">
                        <router-link class="text-white" :to="{ name: 'sch-public-parnerships' }"
                          >For Employers</router-link
                        >
                      </a>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <view-more-modal
      :show-modal.sync="modals.showViewMoreModal"
      :login-path="modals.loginPath"
      :body-text="modals.viewMoreBodyText"
      @close="hideViewMoreModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { get } from 'lodash';
import Page from '@/components/Page.vue';
import { JOB_BENEFITS_OPTONS } from '../../../common/constants';
import { formatTimeAgo } from '../../../common/utils';
import ViewMoreModal from '../ViewMoreModal.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import JobCard from '../../Jobs/JobCard.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  components: { ViewMoreModal, Pagination, JobCard, PageSeparator },
  extends: Page,
  name: 'PublicJobs',
  props: { showBreadcrumb: { Boolean, default: false } },

  data() {
    return {
      title: 'Home',
      completeHeadTitle: undefined,

      isLoading: false,
      totalJobs: 0,
      perPage: 14,
      currentPage: 1,
      jobs: [],

      pageFromCount: 0,
      pageToCount: 0,

      windowWidth: window.innerWidth,

      modals: {
        loginPath: {},
        viewMoreBodyText: '',
        showViewMoreModal: false,
      },
      isPublic: true,
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: { name: 'sch-public-home' } },
        { text: 'Jobs', active: true },
      ];
    },
    ...mapGetters('school', ['getCurrentSchool']),

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },

    mainAppUrl() {
      return process.env.VUE_APP_MAIN_DOMAIN;
    },
  },

  methods: {
    ...mapActions('settings', ['setSettings']),
    ...mapActions('school', ['getLocalJobsForCurrentSchool']),
    get,
    formatTimeAgo,

    async openJobProfile(id) {
      this.$router.push({ name: 'student-job-details', params: { id } });
    },
    openViewMoreModal(obj) {
      this.modals.loginPath = obj;
      if (obj.name === 'student-job-details') {
        this.modals.viewMoreBodyText = 'You must login to view more details about this job.';
      } else if (obj.name === 'redirect-employer-profile') {
        this.modals.viewMoreBodyText = 'You must login to view more details about this employer.';
      } else {
        this.modals.viewMoreBodyText = 'You must login to view more jobs.';
      }
      this.modals.showViewMoreModal = true;
    },

    hideViewMoreModal() {
      this.modals.showViewMoreModal = false;
      this.modals.viewMoreBodyText = '';
    },

    async fetchPublicJobs(pageNum = 1, params = {}) {
      this.isLoading = true;
      try {
        const response = await this.getLocalJobsForCurrentSchool({
          limit: this.perPage,
          offset: (pageNum - 1) * this.perPage,
          school: this.getCurrentSchool.id,
          ...params,
        });

        response.data.results.forEach((job) => {
          job['benefitCarAdv'] = [];

          // Benefits
          JOB_BENEFITS_OPTONS.forEach((benefit) => {
            if (job.benefits.includes(benefit.value) && benefit.value !== 'other') {
              job['benefitCarAdv'].push(benefit.text);
            }
          });
          if (job.benefits.includes('other') && job.other_benefits) {
            job['benefitCarAdv'].push(job.other_benefits);
          }

          // Career Advancements
          if (job.offers_career_advancement) {
            job['benefitCarAdv'] = job['benefitCarAdv'].concat(
              (job.career_offerings || []).map((career) => career.name)
            );
            if (job.other_career_offering) {
              job['benefitCarAdv'].push(job.other_career_offering);
            }
          }
        });
        this.jobs = response.data.results;
        this.totalJobs = response.data.count;
      } catch (_err) {
        this.jobs = [];
        this.totalJobs = 0;
      }

      this.currentPage = pageNum;
      this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
      this.pageToCount = this.pageFromCount + get(this.jobs, 'length', 0) - 1;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchPublicJobs(pageNum);
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  watch: {
    getCurrentSchool: {
      immediate: true,
      deep: true,
      handler(value) {
        if (get(value, 'id')) {
          this.fetchPublicJobs();
          this.completeHeadTitle = `${get(this.getCurrentSchool, 'name', 'Home')} - ${get(
            this.getCurrentSchool,
            'pp_home_title',
            'Transition'
          )}`;
        }
      },
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);

    // Set page layout if used as a page (use if needed).
    // this.setSettings({ layout: { layout: 'fixed' } });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
