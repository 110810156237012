<template>
  <div />
</template>

<script>
import routeToMixin from '@/mixins/route-to';
import { get } from 'lodash';
import { USER_ROLE_TYPES } from '../../common/constants';
import { mapGetters } from 'vuex';

export default {
  layout: 'blank',
  mixins: [routeToMixin],
  props: {},
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },

  mounted() {
    if (this.$route.params.id) {
      if (get(this.getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL) {
        this.$router.push({ name: 'schl-employer-profile', params: { id: this.$route.params.id } });
      } else {
        this.$router.push({
          name: 'student-employer-profile',
          params: { id: this.$route.params.id },
        });
      }
    } else {
      if (get(this.getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL) {
        this.$router.push({ name: 'schl-employers-list' });
      } else {
        this.$router.push({
          name: 'student-employers-list',
        });
      }
    }
  },
};
</script>