<template>
  <div style="padding-top: 16px" id="page-container">
    <!-- <page-header :title="title" :container-class="containerClass" /> -->

    <div class="page-section jobs_container">
      <div :class="containerClass">
        <div class="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt" style="white-space: nowrap">
          <h2 class="mb-0">
            My Questions

            <i class="material-icons icon-16pt clickable-item" v-b-popover.hover.top="'View questions you have asked.'"
              >info</i
            >
          </h2>
          <div class="w-auto ml-sm-auto mb-sm-0" :class="{ 'd-flex': !isMobileScreen }">
            <div class="custom-select-icon">
              <b-form-select
                class="custom-select-icon__select"
                style="min-width: 185px"
                v-model="filters.topic"
                :options="quesOptions"
                @change="onFilterApplied"
              ></b-form-select>
              <span class="material-icons custom-select-icon__icon">sort</span>
            </div>
            <div :class="isMobileScreen ? 'mt-2 ml-auto' : 'ml-3'">
              <b-btn @click.prevent="addQues()" variant="primary" :class="{ 'w-100': isMobileScreen }"
                >Ask a Question</b-btn
              >
            </div>
          </div>
        </div>

        <page-separator
          :title="
            !isLoading
              ? `showing ${totalQuestions ? `${pageFromCount}-${pageToCount}` : 0} of ${totalQuestions} Questions`
              : ''
          "
        />

        <div class="d-flex justify-content-center" v-if="!totalQuestions && !isLoading">
          <div class="card card-block card-stretch card-height col-md-12">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Questions Asked</h4>

              <p class="text-muted">
                You haven't asked any question yet. Feel free to ask questions and get help from the students and
                instructors of your school.
              </p>

              <!-- <a href="#" @click.prevent="" class="btn btn-primary mt-2"> Sample Button </a> -->
            </div>
          </div>
        </div>

        <b-skeleton-wrapper :loading="isLoading" v-else>
          <template #loading>
            <div class="row card-group-row mb-lg-12pt home-card">
              <div class="col-sm-12 card-group-row__col" v-for="item in [1, 2]" :key="item">
                <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card">
                  <div class="card-header d-flex align-items-center">
                    <a href="#" @click.prevent class="card-title flex"> <b-skeleton width="100%"></b-skeleton></a>
                  </div>
                  <div class="card-body d-flex flex-column">
                    <div class="d-flex align-items-center">
                      <div class="flex">
                        <div class="mb-16pt" :class="{ 'd-flex align-items-center': !isMobSmallScreen }">
                          <div class="mr-12pt" :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen }">
                            <a
                              href="#"
                              @click.prevent
                              class="avatar avatar-xl overlay js-overlay overlay--primary rounded-circle p-relative o-hidden mb-16pt align-self-center"
                              style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                            >
                              <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                            </a>
                          </div>
                          <div class="flex">
                            <div class="mb-0" style="text-transform: none">
                              <b-skeleton width="100%"></b-skeleton>
                              <b-skeleton width="55%"></b-skeleton>
                              <b-skeleton width="70%"></b-skeleton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div class="row card-group-row mb-lg-12pt home-card">
            <div class="col-sm-12 card-group-row__col" v-for="ques in questions" :key="ques.id">
              <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card">
                <div class="card-header d-flex align-items-center">
                  <router-link
                    :to="{
                      name: 'student-question-details',
                      params: { id: ques.id, back: true },
                    }"
                    class="card-title flex mr-12pt"
                    >{{ ques.title }}</router-link
                  >
                  <div class="d-flex justify-content-end">
                    <b-dropdown variant="flush" toggle-class="text-muted" no-caret right>
                      <template #button-content>
                        <i class="material-icons icon-24pt card-course__icon-favorite">more_vert</i
                        ><span class="sr-only">Menu</span>
                      </template>
                      <b-dropdown-item href="#" @click.prevent="editQues(ques.id)"
                        ><i class="material-icons text-primary">edit</i>Edit</b-dropdown-item
                      >
                      <b-dropdown-item href="#" @click.prevent="removeQues(ques.id)"
                        ><i class="material-icons text-danger">remove_circle</i>Remove</b-dropdown-item
                      >
                    </b-dropdown>
                  </div>
                </div>

                <router-link
                  :to="{ name: 'student-question-details', params: { id: ques.id, back: true } }"
                  class="text-black"
                >
                  <div class="card-body d-flex flex-column">
                    <div class="d-flex align-items-center">
                      <div class="flex">
                        <div class="mb-16pt" :class="{ 'd-flex align-items-center': !isMobSmallScreen }">
                          <div class="mr-12pt" :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen }">
                            <user-avatar
                              slot="aside"
                              :user="get(ques, 'questioner')"
                              circle="lg"
                              size="5rem"
                              :file-url="get(ques, 'questioner.avatar_url')"
                              variant="dark"
                            >
                            </user-avatar>
                          </div>
                          <div class="flex">
                            <div class="mb-0" style="text-transform: none">
                              <!-- for ques description -->
                              <div>
                                <span
                                  ><b
                                    >{{ get(ques, 'questioner.first_name') }} {{ get(ques, 'questioner.last_name')
                                    }}{{ get(ques, 'questioner.title') ? `(${get(ques, 'questioner.title')})` : '' }}</b
                                  ></span
                                >
                              </div>
                              <div class="mb-2 d-flex justify-content-between flex-wrap">
                                <span class="text-muted">{{ formatDateTime(get(ques, 'updated_at')) }}</span>
                                <div class="badge badge-dark" style="text-transform: none">
                                  {{ $t(`audienceTypes.${ques.audience}`) }}
                                </div>
                              </div>

                              <div>
                                {{
                                  truncate(trim(get(ques, 'description')), {
                                    length: 250,
                                    separator: /,? +/,
                                  })
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card-button">
                      <ul>
                        <li class="mb-0">
                          <a href="#" @click.prevent
                            ><span class="icon-16pt text-100">#</span>{{ $t(`topics.${get(ques, 'topic')}`) }}</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>

        <pagination
          v-if="totalQuestions > questions.length"
          v-model="currentPage"
          :total-rows="totalQuestions"
          :per-page="perPage"
          @change="onPageChange"
          aria-controls="jobs-table"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, get, trim, truncate } from 'lodash';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import UserAvatar from '@/components/User/UserAvatar.vue';
import { mapActions, mapGetters } from 'vuex';

import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import {} from '../../../common/constants';
import { formatDateTime } from '../../../common/utils';

export default {
  components: { Pagination, UserAvatar, PageSeparator },
  extends: Page,

  data() {
    return {
      DefaultAvatar,

      title: 'Questions',
      isLoading: true,
      perPage: 14,
      currentPage: 1,
      totalQuestions: 0,
      questions: [],
      quesOptions: [
        { value: null, text: 'All Questions' },
        { value: 'jobs_options', text: 'Jobs/Options' },
        { value: 'exam_testing', text: 'Exam/Testing' },
        { value: 'skills', text: 'Skills' },
        { value: 'class', text: 'Class' },
        { value: 'equipment', text: 'Equipment' },
        { value: 'general', text: 'General' },
        { value: 'miscellaneous', text: 'Miscellaneous' },
      ],
      pageFromCount: 0,
      pageToCount: 0,
      filters: {
        topic: null,
      },
      windowWidth: window.innerWidth,
      firstLoad: true,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Questions', active: true },
      ];
    },

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
    isMobileScreen() {
      return this.windowWidth <= 720;
    },
  },

  methods: {
    ...mapActions('questions', ['getStudentQuestions', 'deleteQuestion']),
    get,
    trim,
    truncate,
    formatDateTime,
    addQues() {
      this.$router.push({ name: 'add-ques' });
    },
    onFilterApplied() {
      this.fetchQuestions();
    },
    editQues(id) {
      this.$router.push({ name: 'edit-ques', params: { id: id } });
    },
    async removeQues(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(`Please confirm that you want to remove the question.`, {
          title: 'Are you sure?',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        });
        if (isConfirmed) {
          await this.deleteQuestion(id);
          const refreshPage = this.questions.length > 1 ? this.currentPage : this.currentPage - 1;
          this.fetchQuestions(refreshPage || 1);
          this.makeToast({ variant: 'success', msg: 'Question Successfully Removed' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    async fetchQuestions(pageNum = 1, params = {}) {
      this.isLoading = true;
      if (document.getElementById('page-container')) {
        document.getElementById('page-container').scrollIntoView();
      }
      const response = await this.getStudentQuestions({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        topic: this.filters.topic,
        ...params,
      });

      this.questions = response.data.results;
      this.currentPage = pageNum;
      this.totalQuestions = response.data.count;
      this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
      this.pageToCount = this.pageFromCount + get(response.data, 'results.length', 0) - 1;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchQuestions(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchQuestions();
    },

    onSearch() {
      this.debouncedSearchQuestions(this);
    },

    debouncedSearchQuestions: debounce((vm) => {
      vm.fetchQuestions();
    }, 500),

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    this.fetchQuestions();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
