<template>
  <b-modal
    ref="respondRequestModal"
    hide-footer
    title="Respond to Request"
    centered
    lazy
    scrollable
    @hide="hideModal"
    size="md"
  >
    <div class="d-flex align-items-start">
      <span>
        {{
          getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
            ? `${entity.name} has sent you a partnership request. Please confirm that you want to accept or deny the partnership with ${entity.name}. After accepting the request, ${entity.name} will be able to post local jobs for your school and view your active students.`
            : `${entity.name} has sent you a partnership request. Please confirm that you want to accept or deny the partnership with ${entity.name}. After accepting the request, you will be able to post local jobs for the school and view ${entity.name}'s active students. On acceptance the partnership fees will be charged.`
        }}
      </span>
    </div>
    <b-form-group label="Signature" label-for="signature" label-class="form-label" class="mt-3">
      <b-form-input
        id="signature"
        placeholder="Add Signature"
        v-model="signature"
        :state="!$v.signature.required && $v.signature.$dirty ? false : null"
        maxlength="60"
      />

      <b-form-invalid-feedback v-if="!$v.signature.required">This field is required.</b-form-invalid-feedback>
    </b-form-group>

    <div class="text-dark" v-if="signature">Preview</div>
    <h1 class="signature-font">{{ signature }}</h1>

    <div class="d-flex justify-content-end">
      <b-btn
        variant="danger"
        style="width: 70px"
        :disabled="isResponding || isLoading"
        @click.prevent="denyRequest"
        href="#"
        data-toggle="modal"
      >
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>Deny</span>
      </b-btn>
      <b-btn
        variant="success"
        class="ml-2"
        style="width: 70px"
        :disabled="isResponding || isLoading"
        @click.prevent="acceptRequest"
        href="#"
        data-toggle="modal"
      >
        <i v-if="isResponding" class="fas fa-circle-notch fa-spin"></i> <span v-else>Accept</span></b-btn
      >
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';
import { PARTNERSHIP_STATUS, PAYMENT_STATUSES, USER_ROLE_TYPES } from '../../common/constants';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'RespondRequestModal',

  props: {
    entity: Object,
    partnership: Object,
    showModal: { type: Boolean, default: false },
  },

  data() {
    return {
      isResponding: false,
      isLoading: false,
      signature: '',
      USER_ROLE_TYPES,
    };
  },
  validations() {
    return {
      signature: { required },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },

  watch: {
    showModal(value) {
      if (value) {
        this.$refs.respondRequestModal.show();
      }
    },
  },
  methods: {
    ...mapActions('partnership', ['respondToPartnershipRequest', 'updatePendingPartnershipStatus']),
    get,
    async acceptRequest() {
      // Ensuring one call at a time.
      if (this.isResponding || this.isLoading) return;

      this.isResponding = true;
      this.$emit('emitIsResponding', true);
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const data = { status: PARTNERSHIP_STATUS.ACCEPTED, signature_to: this.signature };
          const resp = await this.respondToPartnershipRequest({ id: this.partnership.id, data });
          if (resp.data.status_code === 303) {
            window.location.href = resp.data.data.session_url;
          } else {
            this.makeToast({ variant: 'success', msg: 'Request Accepted!' });
            this.hideModalManual();
            this.$emit('fetch');
          }
          this.updatePendingPartnershipStatus();
        } catch (err) {
          if (get(err, 'response.data.data.status_msg') === PAYMENT_STATUSES.PAYMENT_FAILURE) {
            if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL) {
              this.makeToast({
                variant: 'danger',
                msg: 'Cannot accept partnership due to payment issue from employer',
              });
            } else if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER) {
              this.makeToast({
                variant: 'danger',
                msg: 'Cannot accept partnership due to payment issue. Please verify your payment method',
              });
            }
          } else {
            this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
          }
          this.hideModalManual();
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill field correctly.' });
      }
      this.isResponding = false;
      this.$emit('emitIsResponding', false);
    },
    async denyRequest() {
      // Ensuring one call at a time.
      if (this.isResponding || this.isLoading) return;

      this.isLoading = true;
      this.$emit('emitIsResponding', true);
      try {
        const data = { status: PARTNERSHIP_STATUS.DECLINED };
        await this.respondToPartnershipRequest({ id: this.partnership.id, data });
        this.makeToast({ variant: 'danger', msg: 'Request Declined!' });
        this.hideModalManual();
        this.$emit('fetch');
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
      this.$emit('emitIsResponding', false);
    },

    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.respondRequestModal.hide();
      this.hideModal();
    },
  },

  async mounted() {},
};
</script>
