<template>
  <div style="padding-top: 16px" id="page-container">
    <div class="page-section" :class="{ jobs_container: get(getLoggedInUser, 'role') === USER_ROLES.STUDENT }">
      <div :class="{ container: get(getLoggedInUser, 'role') === USER_ROLES.STUDENT }">
        <div
          class="d-flex justify-content-center"
          v-if="get(getLoggedInUser, 'role') === USER_ROLES.STUDENT && !get(getStoryExists, 'story_existed')"
        >
          <div class="card card-block card-stretch card-height col-md-12">
            <div class="card-body text-center">
              <p class="text-90">
                Are you eager to mark your presence in the healthcare industry and want local healthcare companies to
                provide financial assistance for your training journey? Post your spotlight to let Healthcare Companies
                sponsor you.
              </p>
              <b-btn :to="{ name: 'student-sponsorship-spotlight' }" target="_blank" variant="primary"
                ><i class="material-icons mr-1">videocam</i>Post Spotlight</b-btn
              >
            </div>
          </div>
        </div>

        <div class="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt" style="white-space: nowrap">
          <h2 class="mb-0 d-sm-block" v-if="!isMobSmallScreen">
            {{ pageTitle || 'Sponsorships' }}

            <i
              class="material-icons icon-16pt clickable-item"
              v-b-popover.hover.top="`Explore sponsorship opportunities tailored to your profile.`"
              >info</i
            >
          </h2>
          <div
            v-if="get(getLoggedInUser, 'role_type') === USER_ROLES.STUDENT"
            class="w-auto ml-sm-auto table d-flex align-items-center mb-sm-0"
            :class="{ 'flex-column': isMobSmallScreen }"
          >
            <b-button
              v-if="get(getStoryExists, 'story_existed')"
              :to="{ name: 'update-sponsorship-spotlight' }"
              :class="isMobSmallScreen ? 'mb-3' : 'mr-3'"
              style="width: 100%"
              >My Spotlight</b-button
            >

            <form
              style="width: 100%"
              class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0"
              @submit.prevent=""
            >
              <b-form-input class="w-lg-auto" placeholder="Search..." v-model="searchTerm" @input="onSearch" />
              <b-btn variant="flush" type="submit">
                <md-icon v-text="'search'" />
              </b-btn>
            </form>
          </div>
        </div>

        <page-separator
          :title="
            !isLoading
              ? `showing ${totalScholarships ? `${pageFromCount}-${pageToCount}` : 0} of ${totalScholarships} ${
                  pageTitle || 'Sponsorships'
                }`
              : ''
          "
        />

        <div class="d-flex justify-content-center" v-if="!totalScholarships && !isLoading">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No {{ pageTitle || 'Sponsorships' }} Found</h4>
              <p class="text-muted">
                No {{ pageTitle.toLowerCase() || 'sponsorships' }} currently available. Please check again later.
              </p>
            </div>
          </div>
        </div>

        <b-skeleton-wrapper :loading="isLoading" v-else>
          <template #loading>
            <div class="row card-group-row mb-lg-12pt home-card">
              <div class="col-sm-12 card-group-row__col" v-for="item in [1, 2]" :key="item">
                <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card">
                  <div class="card-header d-flex align-items-center">
                    <a href="#" @click.prevent class="card-title flex"> <b-skeleton width="100%"></b-skeleton></a>
                  </div>
                  <div class="card-body d-flex flex-column">
                    <div class="d-flex align-items-center">
                      <div class="flex">
                        <div class="mb-16pt" :class="{ 'd-flex align-items-center': !isMobSmallScreen }">
                          <div class="mr-12pt" :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen }">
                            <a
                              href="#"
                              @click.prevent
                              class="avatar avatar-xl overlay js-overlay overlay--primary rounded-circle p-relative o-hidden mb-16pt align-self-center"
                              style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                            >
                              <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                            </a>
                          </div>
                          <div class="flex">
                            <div class="mb-0" style="text-transform: none">
                              <b-skeleton width="100%"></b-skeleton>
                              <b-skeleton width="55%"></b-skeleton>
                              <b-skeleton width="70%"></b-skeleton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="row card-group-row mb-lg-12pt home-card">
            <div
              class="col-sm-12 card-group-row__col clickable-item"
              v-for="scholarship in scholarships"
              :key="scholarship.id"
              @click.prevent="openScholarshipDetail(scholarship.id)"
            >
              <div class="card card-lg overlay--primary-dodger-blue card-group-row__card">
                <div class="card-header d-flex align-items-center">
                  <span class="card-title flex mr-12pt">{{ scholarship.title }}</span>

                  <span>
                    <i class="material-icons icon-16pt mr-1 text-dark">business</i>
                    <span v-if="scholarship.posted_by_org" class="text-dark">{{ scholarship.posted_by_org.name }}</span>
                    <span v-for="emp in scholarship.employers" :key="emp.id" v-else>
                      <router-link
                        :to="{
                          name:
                            getLoggedInUser && getLoggedInUser.role_type === USER_ROLES.STUDENT
                              ? 'student-employer-profile'
                              : 'redirect-employer-profile',
                          params: { id: emp.id },
                        }"
                        class="text-primary"
                        style="text-decoration: underline"
                        >{{ emp.name }}</router-link
                      >
                    </span>
                  </span>
                </div>

                <div class="card-body d-flex flex-column">
                  <div class="d-flex align-items-center">
                    <div class="flex">
                      <div class="mb-16pt" :class="{ 'd-flex align-items-center': !isMobSmallScreen }">
                        <div class="mr-12pt" :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen }">
                          <a
                            href="#"
                            @click.prevent
                            class="avatar avatar-xl overlay js-overlay overlay--primary rounded p-0 o-hidden d-flex justify-content-center align-items-center"
                            style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl;object-fit: cover; height: inherit"
                          >
                            <img
                              class="avatar-img m-0 rounded"
                              :src="scholarship.image_url ? scholarship.image_url : DefaultAvatar"
                              width="160"
                              height="auto"
                            />
                          </a>
                        </div>
                        <div class="flex">
                          <div class="mb-0" style="text-transform: none">
                            <div v-if="get(scholarship, 'min_amount') && get(scholarship, 'max_amount')">
                              <i class="material-icons icon-16pt">attach_money</i> Amount:
                              <strong style="font-weight: bold"
                                >${{ scholarship.min_amount }} - ${{ scholarship.max_amount }}</strong
                              >
                            </div>
                            <div v-if="get(scholarship, 'deadline')">
                              <i class="material-icons icon-16pt">calendar_today</i> Deadline:
                              <strong style="font-weight: bold">{{ formatFullDate(scholarship.deadline) }}</strong>
                            </div>
                            <div>
                              <i class="material-icons icon-16pt">bookmark_border</i> Category:
                              <strong style="font-weight: bold">{{
                                $t(`sponsorshipCategories.${scholarship.categories}`)
                              }}</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-button">
                    <div class="mb-3">
                      {{ scholarship.summary }}
                    </div>
                    <ul>
                      <li v-for="(program, index) in scholarship.programs" :key="`schlr-prgrm-${index}`">
                        <a href="#" @click.prevent
                          ><span class="material-icons icon-16pt text-100">check</span>{{ program.title }}</a
                        >
                      </li>
                    </ul>
                    <b-btn
                      style="width: 100%; color: white"
                      href="#"
                      class="btn-primary"
                      v-if="appliedScholarships[scholarship.id] !== true"
                      variant="light"
                      @click.prevent="openScholarshipDetail(scholarship.id)"
                    >
                      <i class="material-icons icon-16pt mr-1">launch</i>

                      Apply Now</b-btn
                    >
                    <div v-else class="text-center">
                      <i class="text-primary"> Already applied for this sponsorship. </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>

        <pagination
          v-if="totalScholarships > scholarships.length"
          v-model="currentPage"
          :total-rows="totalScholarships"
          :per-page="perPage"
          @change="onPageChange"
          aria-controls="scholarships-table"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { debounce, get } from 'lodash';
import Pagination from '../Ui/Pagination.vue';
import { mapActions, mapGetters } from 'vuex';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import { USER_ROLES } from '../../common/constants';
import Page from '@/components/Page.vue';
import { formatFullDate } from '../../common/utils';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  name: 'ScholarshipsListing',
  components: { Pagination, PageSeparator },
  extends: Page,
  props: {
    pageTitle: { type: String, default: 'Sponsorships' },
  },

  data() {
    return {
      DefaultAvatar,
      title: 'Sponsorships',
      isLoading: true,
      perPage: 14,
      currentPage: 1,
      totalScholarships: 0,
      scholarships: [],
      searchTerm: '',
      ordering: '-id',
      pageFromCount: 0,
      pageToCount: 0,
      windowWidth: window.innerWidth,
      firstLoad: true,
      appliedScholarships: {},
      USER_ROLES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser', 'isLoggedIn']),
    ...mapGetters('school', ['getCurrentSchool']),
    ...mapGetters('stories', ['getStoryExists']),

    isMobSmallScreen() {
      return this.windowWidth <= 776;
    },
  },

  watch: {},

  methods: {
    ...mapActions('scholarships', ['getAllScholarship', 'getAllScholarshipList', 'getAppliedScholarshipsList']),
    get,
    formatFullDate,
    openScholarshipDetail(id) {
      // if (this.getLoggedInUser) {
      this.$router.push({ name: 'student-scholarship-details', params: { id } });
      // }
      //  else {
      //   let resolvedRoute = this.$router.resolve({
      //     name: 'public-scholarship',
      //     params: { id },
      //   });
      //   window.open(resolvedRoute.href, '_blank');
      // }
    },
    async fetchScholarships(pageNum = 1, params = {}) {
      this.isLoading = true;

      if (this.getLoggedInUser && this.getLoggedInUser.role_type === USER_ROLES.STUDENT) {
        try {
          const response = await this.getAllScholarship({
            limit: this.perPage,
            offset: (pageNum - 1) * this.perPage,
            ...(this.ordering && { ordering: this.ordering }),
            ...(this.searchTerm && { search: this.searchTerm }),
            is_active: true,
            ...params,
          });

          this.scholarships = response.data.results;
          this.currentPage = pageNum;
          this.totalScholarships = response.data.count;
          this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
          this.pageToCount = this.pageFromCount + get(response.data, 'results.length', 0) - 1;
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        try {
          const response = await this.getAllScholarshipList({
            limit: this.perPage,
            offset: (pageNum - 1) * this.perPage,
            ...(this.ordering && { ordering: this.ordering }),
            ...(this.searchTerm && { search: this.searchTerm }),
            id: this.getCurrentSchool.id,
            is_active: true,
            ...params,
          });

          this.scholarships = response.data;
          this.currentPage = pageNum;
          this.totalScholarships = response.data.length;
          this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
          this.pageToCount = this.pageFromCount + get(response, 'data.length', 0) - 1;
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      }

      this.isLoading = false;
    },
    async fetchAppliedScholarshipsList() {
      const scholarships = this.scholarships.map((scholarship) => scholarship.id);

      const appliedList = await this.getAppliedScholarshipsList({
        scholarship_ids: scholarships,
      });

      this.appliedScholarships = appliedList.data;
    },
    onPageChange(pageNum) {
      this.fetchScholarships(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchScholarships();
    },

    onSearch() {
      this.debouncedSearchScholarships(this);
    },

    debouncedSearchScholarships: debounce((vm) => {
      vm.fetchScholarships();
    }, 500),

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    await this.fetchScholarships();

    if (get(this.getLoggedInUser, 'role_type') === USER_ROLES.STUDENT) {
      this.fetchAppliedScholarshipsList();
    }

    this.firstLoad = false;
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
