<template>
  <fmv-drawer id="employer-filters" align="end">
    <employer-filters-sidebar></employer-filters-sidebar>
  </fmv-drawer>
</template>

<script>
import { FmvDrawer } from 'fmv-layout';
import EmployerFiltersSidebar from './EmployerFiltersSidebar.vue';

export default {
  name: 'EmployerFilters',

  components: { FmvDrawer, EmployerFiltersSidebar },

  data() {
    return {};
  },

  computed: {},

  methods: {},

  async mounted() {},
};
</script>
