<template>
  <div class="card mb-0">
    <!-- <div class="card-header"></div> -->
    <form-wizard
      title=""
      subtitle=""
      @on-complete="onComplete"
      shape="tab"
      :color="primaryColor"
      ref="invoiceForm"
      class="onboarding-form"
    >
      <tab-content title="" icon="fas fa-info-circle" :before-change="() => onSubmit('aboutForm')">
        <about-std-ob-form
          :user-data="user"
          :profile-data="profile"
          :school-name="school.name"
          :allPrograms="allPrograms"
          :areProgramsLoading="areProgramsLoading"
          @isUploading="onUploading"
          ref="aboutForm"
        ></about-std-ob-form>
      </tab-content>

      <tab-content title="" icon="fas fa-calendar-day" :before-change="() => onSubmit('gradDateForm')">
        <grad-date-form
          :profile-data="profile"
          :school-name="school.name"
          :program-name="selectedProgramName"
          ref="gradDateForm"
        />
      </tab-content>

      <tab-content title="" :before-change="() => onSubmit('avatarForm')">
        <avatar-form :user-data="user" @isUploading="onUploading" ref="avatarForm" />
      </tab-content>

      <!-- PIIs removed from onboarding for easier flow. -->
      <!-- <tab-content title="" icon="fas fa-user" :before-change="() => onSubmit('personalForm')">
        <personal-id-form
          :piiData="piiTypes"
          :expires-data="expiresAt"
          @isUploading="onUploading"
          ref="personalForm"
        ></personal-id-form>
      </tab-content> -->

      <tab-content title="" icon="fas fa-sliders-h" :before-change="() => onSubmit('preferencesForm')">
        <preferences-std-ob-form
          :profile-data="profile"
          ref="preferencesForm"
          @areCareerPrefsLoading="(val) => (areCareerPrefsLoading = val)"
        ></preferences-std-ob-form>
      </tab-content>

      <tab-content title="" icon="fas fa-hand-holding-medical" :before-change="() => onSubmit('experienceForm')">
        <experience-std-ob-form
          :profile-data="profile"
          @isUploading="onUploading"
          ref="experienceForm"
        ></experience-std-ob-form>
      </tab-content>

      <template v-slot:prev>
        <button class="btn btn-light" :disabled="isFormLoading">Back</button>
      </template>

      <template v-slot:next>
        <b-button type="submit" variant="dark" :disabled="isFormLoading" style="min-width: 150px">
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>Next</span>
        </b-button>
      </template>

      <!-- <template v-slot:custom-buttons-right="props">
              <button
                class="btn btn-secondary mr-3"
                :disabled="isFormLoading"
                style="min-width: 130px"
                v-if="!$route.params.id || props.activeTabIndex === 0"
                @click.prevent="onSubmit(null, true)"
              >
                <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
                <span v-else> Extra Btn </span>
              </button>
            </template> -->

      <template v-slot:finish>
        <button
          class="btn btn-primary"
          type="primary"
          :disabled="isFormLoading"
          style="min-width: 150px; height: 2.3rem"
        >
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>Accept Invite</span>
        </button>
      </template>

      <template #step="props" v-if="isFormLoading">
        <wizard-step :tab="props.tab" :transition="props.transition" :index="props.index" />
      </template>
    </form-wizard>
    <!-- <div class="card-footer"></div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { find, get } from 'lodash';
import AboutStdObForm from './components/AboutStdObForm.vue';
import PreferencesStdObForm from './components/PreferencesStdObForm.vue';
import ExperienceStdObForm from './components/ExperienceStdObForm.vue';
import { formatToAPIDate } from '@/common/utils';
import GradDateForm from './components/GradDateForm.vue';
import AvatarForm from './components/AvatarForm.vue';
export default {
  name: 'StudentInviteOnboarding',
  components: {
    AboutStdObForm,
    PreferencesStdObForm,
    ExperienceStdObForm,
    AvatarForm,
    GradDateForm,
  },
  data() {
    return {
      title: 'Welcome',
      piiTypes: { tb_test: '', covid_vacc: '', social_sec_card: '', state_id: '', cpr: '' },
      profile: {
        grad_date: null,
        cna_license_no: '',
        work_experience: '',
        curr_employer: '',
        quote: '',
        start_date: null,
        cma_license_no: '',
        expiresAt: { tb_test: null, covid_vacc: null, social_sec_card: null, state_id: null, cpr: null },

        desired_work_type: [],
        desired_work_time: [],
        desired_work_day: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
        work_travel: '',
        career_preferences: [],
        lang_preference: '',

        enrolled_program: null,
        prog_weeks: null,

        resume_url: '',
        program_type: '',
        address: '',
        zipcode: '',
        city: '',
        state: '',
        country: 'United States',
        tag_id: null,
      },
      user: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        repeatPassword: '',
        phone: '',
        bio: '',
        avatar_url: '',
        gender: '',
      },
      school: {
        name: '',
      },
      primaryColor: '#214e6f',

      isLoading: false,
      areCareerPrefsLoading: false,
      isUploading: { avatar: false },
      selectedUploadFile: { avatar: null },

      errorStates: { password: null },

      fieldTypes: { password: 'text', repeatPassword: 'text' },
      areProgramsLoading: false,
      allPrograms: [],
      allTags: [],
      areTagsLoading: false,
      selectedProgramName: '',
    };
  },
  computed: {
    ...mapGetters('invite', ['getInviteData']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Welcome', active: true },
      ];
    },
    isFormLoading() {
      return this.isLoading || this.areCareerPrefsLoading || Object.values(this.isUploading).some((val) => val);
    },
  },

  methods: {
    ...mapActions('invite', ['acceptInvite']),
    ...mapActions('program', ['getPublicSchoolPrograms']),
    ...mapActions('school', ['getAllTags']),
    async fetchPrograms() {
      this.areProgramsLoading = true;
      const response = await this.getPublicSchoolPrograms({ school: this.getInviteData.linked_entity_id });
      this.allPrograms = response.data;
      this.areProgramsLoading = false;
    },
    async fetchTags() {
      this.areTagsLoading = true;
      const response = await this.getAllTags({
        school: this.getInviteData.linked_entity_id,
      });
      this.allTags = response.data;
      this.areTagsLoading = false;
    },

    onUploading(uploadingStatus) {
      this.isUploading = { ...this.isUploading, ...uploadingStatus };
    },

    onSubmit(formRef) {
      let dataValidation = { isValid: false, formData: {} };
      if (this.$refs[formRef]) {
        dataValidation = this.$refs[formRef].validateFormData();

        if (formRef === 'aboutForm') {
          this.user = { ...this.user, ...dataValidation.formData.user };
          this.profile = { ...this.profile, ...dataValidation.formData.profile };

          const program = find(this.allPrograms, (prg) => this.profile.enrolled_program == prg.id);
          this.selectedProgramName = get(program, 'title', '');
        } else if (formRef === 'gradDateForm') {
          this.profile = { ...this.profile, ...dataValidation.formData.profile };
        } else if (formRef === 'personalForm') {
          this.piiTypes = { ...this.piiTypes, ...dataValidation.formData.pii };
          this.expiresAt = { ...this.expiresAt, ...dataValidation.formData.expire };
        } else if (formRef === 'avatarForm') {
          this.user = { ...this.user, ...dataValidation.formData.user };
        } else {
          this.profile = { ...this.profile, ...dataValidation.formData };
        }
      }
      if (!dataValidation.isValid) {
        this.makeToast({ variant: 'danger', msg: 'Please fill the form correctly.' });
      }
      return dataValidation.isValid;
    },

    async onComplete() {
      this.isLoading = true;
      try {
        let piisArr = [];

        // PIIs removed from onboarding for easier flow.
        // for (const item in this.piiTypes) {
        //   if (this.piiTypes[item]) {
        //     piisArr.push({ type: item, document_url: this.piiTypes[item], expire_at: this.expiresAt[item] });
        //   }
        // }

        this.profile.grad_date = this.profile.grad_date ? formatToAPIDate(moment(this.profile.grad_date)) : null;
        this.profile.start_date = this.profile.start_date ? formatToAPIDate(moment(this.profile.start_date)) : null;

        const res = await this.acceptInvite({
          data: { ...this.user, student: { ...this.profile, student_piis: piisArr } },
          params: { token: this.getInviteData.token },
        });

        if (res.status === 200) {
          let data = { email: this.getInviteData.email, password: this.user.password };
          try {
            await this.$store.dispatch('auth/login', data);
            await this.$store.dispatch('student/getMyStudentProfile');
            await this.$store.dispatch('student/getPiiStatus');
            this.makeToast({ variant: 'success', msg: 'Invite accepted' });
            setTimeout(() => this.$router.push({ name: 'student-home' }), 250);
          } catch (e) {
            this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
          }
        }
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    this.fetchPrograms();
    this.fetchTags();
  },
};
</script>

<style>
</style>