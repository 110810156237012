<template>
  <div>
    <!-- <page-header :title="title" :container-class="containerClass" /> -->
    <div class="page-section">
      <div :class="containerClass">
        <div class="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt" style="white-space: nowrap">
          <h2 class="mb-0">Careers</h2>
          <div class="w-auto ml-sm-auto mb-sm-0 justify-content-end">
            <!-- Category Filter -->
            <div class="custom-select-icon">
              <b-form-select
                class="custom-select-icon__select"
                style="min-width: 185px"
                v-model="filters.category"
                :options="categoryOptions"
                @input="onFilterApplied"
              ></b-form-select>
              <span class="material-icons custom-select-icon__icon">sort</span>
            </div>
          </div>
        </div>

        <page-separator
          :title="
            !isLoading
              ? `showing ${totalCareers ? `${pageFromCount}-${pageToCount}` : 0} of ${totalCareers} careers`
              : ''
          "
        />

        <div class="page-section d-flex justify-content-center" v-if="!careers.length && !isLoading">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Careers Found</h4>

              <p class="text-muted">
                Couldn't find any careers. We are constantly adding new career options so please check again after a
                while.
              </p>
            </div>
          </div>
        </div>

        <b-skeleton-wrapper :loading="isLoading" v-else>
          <template #loading>
            <div class="row">
              <div class="col-lg-3 col-md-4 col-sm-6" v-for="item in [1, 2, 3, 4]" :key="item">
                <div class="card card-sm card--elevated p-relative">
                  <span class="js-image">
                    <b-skeleton-img width="100%" class="pb-0 mb-0"></b-skeleton-img>
                  </span>

                  <div class="card-body">
                    <div class="d-flex">
                      <div class="flex">
                        <a class="card-title" href="#" @click.prevent><b-skeleton width="70%"></b-skeleton></a>
                        <small class="text-50 font-weight-bold mb-4pt"><b-skeleton width="50%"></b-skeleton></small>
                      </div>
                      <a href="#" class="ml-4pt material-icons text-20 card-course__icon-favorite" @click.prevent
                        >more_vert</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div class="row card-group-row mb-lg-12pt home-card">
            <div v-for="career in careers" :key="career.id" class="col-lg-3 col-md-4 col-sm-6 clickable-item">
              <div class="card card-sm card--elevated p-relative">
                <span
                  class="js-image d-flex justify-content-center clickable-item"
                  data-position="left"
                  :data-height="168"
                  @click.prevent="showCareerDetails(career.id)"
                >
                  <img :src="career.photo_url || DefaultCareerImg" alt="cna" class="career-card-img" />
                </span>

                <div class="card-body">
                  <div class="d-flex">
                    <a class="flex" href="#" @click.prevent="showCareerDetails(career.id)">
                      <div class="card-title">{{ career.name }}</div>
                      <small class="text-50 font-weight-bold mb-4pt d-flex align-items-center">
                        <!-- <i class="material-icons icon-16pt mr-1">{{ categoriesByKey[career.category].icon }}</i> -->
                        {{ $t(`careerPrefCategories.${career.category}`) }}
                        <i
                          class="material-icons icon-16pt ml-1"
                          v-b-popover.hover.top="categoriesByKey[career.category].description"
                          >info</i
                        >
                      </small>
                    </a>
                    <b-dropdown variant="flush" toggle-class="text-muted" no-caret right>
                      <template #button-content>
                        <i class="material-icons icon-24pt card-course__icon-favorite">more_vert</i
                        ><span class="sr-only">Menu</span>
                      </template>
                      <b-dropdown-item href="#" @click.prevent="showCareerDetails(career.id)"
                        ><i class="mr-1 material-icons">visibility</i>View</b-dropdown-item
                      >
                      <b-dropdown-item href="#" @click.prevent="updateCareerFavorite(career)"
                        ><i class="mr-1 material-icons" :class="{ 'text-danger': career.is_favorite }"
                          >favorite<template v-if="!career.is_favorite">_border</template></i
                        >{{ career.is_favorite ? 'Unfavorite' : 'Favorite' }}</b-dropdown-item
                      >
                    </b-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>

        <pagination
          v-if="totalCareers > careers.length"
          v-model="currentPage"
          :total-rows="totalCareers"
          :per-page="perPage"
          @change="onPageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';

import Page from '@/components/Page.vue';
import { CAREER_PREF_CATEGORIES } from '../../../common/constants';
import { mapActions } from 'vuex';
import { get, keyBy } from 'lodash';

import DefaultCareerImg from '@/assets/images/placeholders/cna_career.jpg';
import Pagination from '../../../components/Ui/Pagination.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  components: {
    Pagination,
    PageSeparator,
  },
  extends: Page,

  data() {
    return {
      DefaultCareerImg,
      title: 'Careers',

      categories: CAREER_PREF_CATEGORIES,
      categoriesByKey: {},
      ordering: 'category_order_no',

      careers: [],
      isLoading: true,
      perPage: 16,
      currentPage: 1,
      pageFromCount: 0,
      pageToCount: 0,
      totalCareers: 0,

      filters: {
        category: null,
      },

      categoryOptions: [{ value: null, text: 'All Categories' }],
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Careers', active: true },
      ];
    },
  },

  methods: {
    ...mapActions('careerPreference', ['getAllCareerPreferences', 'getCareerPreferencesWithFav']),
    ...mapActions('student', ['updateStudentCareerPreference']),
    get,

    async fetchCareers(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getCareerPreferencesWithFav({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...this.filters,
        ...params,
      });

      this.careers = response.data.results;
      this.currentPage = pageNum;
      this.totalCareers = response.data.count;
      this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
      this.pageToCount = this.pageFromCount + get(response.data, 'results.length', 0) - 1;
      this.isLoading = false;
    },

    showCareerDetails(id) {
      this.$router.push({ name: 'student-career-details', params: { id } });
    },

    async updateCareerFavorite(career) {
      const isFav = career.is_favorite;
      career.is_favorite = !career.is_favorite;
      await this.updateStudentCareerPreference({
        career_preference: career.id,
        remove_fav: isFav,
      });
    },

    onFilterApplied() {
      this.fetchCareers();
    },

    onPageChange(pageNum) {
      this.fetchCareers(pageNum);
    },
  },

  async mounted() {
    this.categoriesByKey = keyBy(CAREER_PREF_CATEGORIES, 'key');
    this.categoryOptions = [
      { value: null, text: 'All Categories' },
      ...CAREER_PREF_CATEGORIES.map((cat) => ({ value: cat.key, text: cat.name })),
    ];
    this.fetchCareers();
  },
};
</script>
