<template>
  <div>
    <div class="public-jobs-container">
      <div class="row mb-8pt">
        <div class="col-lg-12 d-flex">
          <div class="flex" style="max-width: 100%">
            <div class="mb-0">
              <h3>Candidate Spotlights</h3>
              <p class="text-90" style="font-size: 16px">
                Meet promising individuals eager to transform their skills and passions into meaningful careers. These
                spotlighted profiles feature candidates actively seeking opportunities to make a difference in
                healthcare.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-section">
      <div class="public-jobs-container">
        <div class="" v-if="!totalStories && !isLoading">
          <div class="d-flex justify-content-center" v-if="!totalEmployers && !isLoading">
            <div class="card card-block card-stretch card-height col-md-8">
              <div class="card-body text-center">
                <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
                <h4>No Spotlights Found</h4>

                <p class="text-muted">
                  Couldn't find any spotlights. We are constantly featuring potential candidates from our institution so
                  please check again later.
                </p>
              </div>
            </div>
          </div>
        </div>

        <b-skeleton-wrapper :loading="isLoading && !totalStories" v-else>
          <template #loading>
            <div class="row card-group-row mb-lg-12pt home-card">
              <div class="col-md-6 col-lg-6 card-group-row__col" v-for="item in [1, 2]" :key="item">
                <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card">
                  <div class="card-header d-flex align-items-center">
                    <a href="#" @click.prevent class="card-title flex"> <b-skeleton width="100%"></b-skeleton></a>
                  </div>
                  <div class="card-body d-flex flex-column">
                    <div class="d-flex align-items-center">
                      <div class="flex">
                        <div class="mb-16pt" :class="{ 'd-flex align-items-center': !isMobSmallScreen }">
                          <div class="mr-12pt" :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen }">
                            <a
                              href="#"
                              @click.prevent
                              class="avatar avatar-xl overlay js-overlay overlay--primary rounded-circle p-relative o-hidden mb-16pt align-self-center"
                              style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                            >
                              <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                            </a>
                          </div>
                          <div class="flex">
                            <div class="mb-0" style="text-transform: none">
                              <b-skeleton width="100%"></b-skeleton>
                              <b-skeleton width="55%"></b-skeleton>
                              <b-skeleton width="70%"></b-skeleton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div class="row card-group-row mb-lg-12pt home-card">
            <div class="col-lg-6 col-md-6 col-sm-6 clickable-item mb-3" v-for="story in stories" :key="story.id">
              <std-card :std="story" :isPublic="isPublic" @viewMore="openViewMoreModal" />
            </div>
          </div>
        </b-skeleton-wrapper>

        <infinite-loading @infinite="infiniteLoadHandler" v-if="!!totalStories">
          <div slot="spinner">
            <div class="text-center">
              <i class="fas fa-circle-notch fa-spin" />
            </div>
          </div>
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </div>
    </div>

    <!-- :login-path="modals.loginPath" -->
    <view-more-modal
      :show-modal.sync="modals.showViewMoreModal"
      :body-text="modals.viewMoreBodyText"
      :mainDomain="true"
      :mainPath="modals.mainPath"
      @close="hideViewMoreModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { get } from 'lodash';
import Page from '@/components/Page.vue';
// import { JOB_BENEFITS_OPTONS } from '../../../common/constants';
import { formatTimeAgo } from '../../../common/utils';
import ViewMoreModal from '../ViewMoreModal.vue';
// import Pagination from '../../../components/Ui/Pagination.vue';
import StdCard from '@/views/EmployerPortal/SponsorInterest/StdCard.vue';
import InfiniteLoading from 'vue-infinite-loading';
export default {
  components: { ViewMoreModal, StdCard, InfiniteLoading },
  extends: Page,
  name: 'PublicSpotlights',
  props: { showBreadcrumb: { Boolean, default: false } },

  data() {
    return {
      title: 'Home',
      completeHeadTitle: undefined,

      isLoading: false,
      totalStories: 0,
      perPage: 14,
      currentPage: 1,
      stories: [],

      pageNum: 1,
      pageSize: 20,

      windowWidth: window.innerWidth,

      modals: {
        loginPath: {},
        mainPath: '',
        viewMoreBodyText: '',
        showViewMoreModal: false,
      },
      isPublic: true,
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: { name: 'sch-public-home' } },
        { text: 'Spotlight', active: true },
      ];
    },
    ...mapGetters('school', ['getCurrentSchool']),

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },

    mainAppUrl() {
      return process.env.VUE_APP_MAIN_DOMAIN;
    },
  },

  methods: {
    ...mapActions('settings', ['setSettings']),
    ...mapActions('stories', ['getPublicStdStories']),
    get,
    formatTimeAgo,
    async loadMore() {
      this.isLoadingMore = true;
      this.pageNum += 1;
      await this.fetchStories();
      this.isLoadingMore = false;
    },
    async infiniteLoadHandler($state) {
      if (this.stories.length < this.totalStories) {
        // await this.loadMore();
        this.pageNum += 1;
        await this.fetchStories();
        $state.loaded();
      } else $state.complete();
    },
    getClickableLink(link) {
      return link.startsWith('http://') || link.startsWith('https://')
        ? link
        : `${process.env.VUE_APP_DOMAIN_PREFIX || 'https'}://${link}`;
    },

    openViewMoreModal(obj) {
      // this.modals.loginPath = { name: 'login' };
      if (obj.name === 'get-spotlight') {
        this.modals.mainPath = this.getClickableLink(`${this.mainAppUrl}/spotlights/${obj.params.id}`);
        this.modals.viewMoreBodyText = 'You must login to view more details about this story.';
      }
      // } else if (obj.name === 'redirect-employer-profile') {
      //   this.modals.viewMoreBodyText = 'You must login to view more details about this employer.';
      // } else {
      //   this.modals.viewMoreBodyText = 'You must login to view more jobs.';
      // }
      this.modals.showViewMoreModal = true;
    },

    hideViewMoreModal() {
      this.modals.showViewMoreModal = false;
      this.modals.viewMoreBodyText = '';
    },

    async fetchStories(pageNum = 1, params = {}) {
      this.isLoading = true;
      try {
        const response = await this.getPublicStdStories({
          limit: this.perPage,
          offset: (this.pageNum - 1) * this.perPage,
          school_slug: this.getCurrentSchool.slug,
          ...params,
        });

        if (response.data.results) {
          if (this.pageNum == 1) {
            this.stories = [];
            this.stories.push(...response.data.results);
          } else {
            this.stories = this.stories.concat(response.data.results);
          }
          this.totalStories = response.data.count;
        }
      } catch (_err) {
        // this.stories = [];
        // this.totalStories = 0;
      }

      this.currentPage = pageNum;
      this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
      this.pageToCount = this.pageFromCount + get(this.stories, 'length', 0) - 1;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchStories(pageNum);
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  watch: {
    getCurrentSchool: {
      immediate: true,

      handler(value) {
        if (get(value, 'id')) {
          this.stories = [];
          this.totalStories = 0;
          this.fetchStories();
          this.completeHeadTitle = `${get(this.getCurrentSchool, 'name', 'Home')} - ${get(
            this.getCurrentSchool,
            'pp_home_title',
            'Transition'
          )}`;
        }
      },
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);

    // Set page layout if used as a page (use if needed).
    // this.setSettings({ layout: { layout: 'fixed' } });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
