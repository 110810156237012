<template>
  <div v-click-outside="closeDropdown">
    <router-link :to="editable ? '' : { name: guide.redirectTo }" :style="editable ? 'cursor:grab !important' : ''">
      <div
        @click="() => (guide.redirectTo ? null : onClick())"
        class="card"
        :class="guide.redirectTo ? 'mb-2' : 'mb-0'"
        :style="editable ? 'height: 100%' : 'height: 92%'"
      >
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center mr-2">
              <div class="p-2 mr-2" style="border: 1px solid lightgray; border-radius: 15px">
                <md-icon :class="`text-${color}`" class="setup-guide-icon">{{ guide.icon }}</md-icon>
              </div>
              <div>
                <strong>{{ guide.title }}</strong>
                <p class="mb-1 text-muted">{{ guide.description }}</p>
                <span v-if="get(setupStatuses, guide.abbr) === false" class="d-flex align-items-center">
                  <md-icon class="text-warning mr-1" style="font-size: 18px">info</md-icon> Action Required
                </span>
              </div>
            </div>
            <b-btn
              v-if="!editable"
              size="sm"
              :variant="color"
              style="border-radius: 50px; aspect-ratio: 1/1"
              :to="{ name: guide.redirectTo }"
            >
              <md-icon v-if="guide.btnIcon" style="font-size: 15px">{{ guide.btnIcon }}</md-icon>
              <md-icon v-else style="font-size: 15px" :style="dropdown ? 'rotate: 90deg' : ''">chevron_right</md-icon>
            </b-btn>
            <div @click.stop v-else>
              <b-form-checkbox
                name="check-button"
                switch
                v-model="guide.enable"
                @change="() => $emit('enable', guide)"
              ></b-form-checkbox>
            </div>
          </div>
        </div>
      </div>
    </router-link>
    <div v-if="dropdown && !editable" class="card m-0" :class="{ opened: dropdown }">
      <div v-for="item in guide.options" :key="item.id">
        <router-link
          :to="{ name: item.redirectTo }"
          v-if="item.title === 'Universities Pathways' ? get(getLoggedInUser, 'linked_entity.std_portal_uni') : true"
        >
          <div class="card-body py-2">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <md-icon class="text-secondary mr-2">{{ item.icon }}</md-icon>
                <div>
                  <strong>{{ item.title }}</strong>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  props: {
    guide: {
      type: Object,
      default: () => {},
    },
    setupStatuses: {
      type: Object,
      default: () => {},
    },
    editable: {
      type: Boolean,
      default: () => false,
    },
    color: {
      type: String,
      default: () => 'secondary',
    },
  },
  data() {
    return {
      dropdown: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },
  methods: {
    get,
    onClick() {
      if (this.guide.handleTranscriptDownload) {
        this.guide.handleTranscriptDownload();
      } else {
        this.dropdown = !this.dropdown;
      }
    },
    closeDropdown() {
      this.dropdown = false;
    },
  },
  directives: {
    'click-outside': {
      bind: function(el, binding, vnode) {
        el.clickOutsideEvent = function(event) {
          // here I check that click was outside the el and his children
          if (!(el == event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind: function(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      },
    },
  },
};
</script>

<style lang="scss">
.opened {
  position: absolute;
  z-index: 1;
  width: 95%;
  top: 100px;
  box-shadow: 0px 0px 2px 2px lightgrey !important;
  animation: fadeIn 400ms;
  transform: translate(1px, 0px);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
