<template>
  <b-modal
    ref="documentViewerModal"
    hide-footer
    :title="documentTitle"
    centered
    lazy
    scrollable
    @hide="hideModal"
    size="lg"
  >
    <b-skeleton-img class="w-100" height="500px" v-if="isLoading" no-aspect></b-skeleton-img>
    <img :src="imageSrc" v-if="!isLoading && getFileType() === 'img'" class="w-100" />
    <div v-else-if="getFileType() === 'pdf'" v-for="page in numPages" :key="page">
      <pdf :src="pdfSrc" :page="page" class="w-100"></pdf>
      <hr v-if="page < numPages" />
    </div>
    <span v-else-if="!isLoading"
      >Uploaded file isn't supported for viewing.
      <a href="#" class="text-primary" @click.prevent="downloadGivenFile">Click to download.</a></span
    >
  </b-modal>
</template>

<script>
import mime from 'mime';
import pdf from '@teckel/vue-pdf';

import { parseFileNameFromUrl } from '../../../common/utils';
import { mapActions } from 'vuex';

export default {
  name: 'StudentDocumentViewer',

  components: { pdf },

  props: {
    showModal: { type: Boolean, default: false },
    documentTitle: { type: String, default: '' },
    fileSrc: { type: String, default: '' },
  },

  data() {
    return {
      isLoading: false,
      imageSrc: null,
      pdfSrc: null,
      numPages: 1,
    };
  },

  computed: {},

  watch: {
    showModal(value) {
      if (value) {
        if (['img', 'pdf'].includes(this.getFileType())) {
          this.isLoading = true;
          if (this.getFileType() === 'img') {
            this.loadImage();
          } else {
            this.pdfSrc = pdf.createLoadingTask(this.fileSrc);
            this.pdfSrc.promise.then((loadedPdf) => {
              this.numPages = loadedPdf.numPages;
              this.isLoading = false;
            });
          }
        }
        this.$refs.documentViewerModal.show();
      }
    },
  },

  methods: {
    ...mapActions('fileDownload', ['downloadFile']),

    hideModal() {
      this.$emit('close');
      this.imageSrc = this.pdfSrc = null;
      this.numPages = 1;
      this.isLoading = false;
    },

    hideModalManual() {
      this.$refs.documentViewerModal.hide();
      this.hideModal();
    },

    getFileType() {
      const type = mime.getType(parseFileNameFromUrl(this.fileSrc).split('.').pop());

      if (type && type.includes('image')) {
        return 'img';
      } else if (type && type.includes('pdf')) {
        return 'pdf';
      }

      return null;
    },

    loadImage() {
      const img = new Image();
      const vm = this;
      img.onload = () => {
        vm.imageSrc = vm.fileSrc;
        vm.isLoading = false;
      };
      img.src = this.fileSrc;
    },

    async downloadGivenFile() {
      const h = this.$createElement;

      this.makeToast({
        variant: 'primary',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
        toaster: 'b-toaster-bottom-left',
      });

      await this.downloadFile({ fileUrl: this.fileSrc, removeTimestamp: true });

      this.makeToast({
        variant: 'success',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
        toaster: 'b-toaster-bottom-left',
      });
    },
  },

  async mounted() {},
};
</script>
