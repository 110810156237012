<template>
  <div :class="containerClass">
    <div class="back-result-lg font-weight-bold">
      <a href="#" @click.prevent="goBack()" style="text-decoration: underline" class="mr-2">
        <span class="material-icons icon-32pt">navigate_before</span>
      </a>
    </div>
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <b-skeleton type="input" width="100%"></b-skeleton>
        <page-separator title="" class="mb-3" />

        <b-skeleton type="text" width="100%"></b-skeleton>
        <b-skeleton type="text" width="100%"></b-skeleton>
        <b-skeleton type="text" width="75%"></b-skeleton>
        <b-skeleton type="text" width="75%"></b-skeleton>
        <b-skeleton type="text" width="50%"></b-skeleton>
        <b-skeleton type="text" width="50%"></b-skeleton>
        <b-skeleton type="text" width="25%"></b-skeleton>
      </template>

      <span style="font-size: 2rem"
        ><b>{{ get(quesObj, 'title') }}</b></span
      >
      <div class="d-flex justify-content-between flex-wrap">
        <p class="text-muted mb-0">
          <span v-if="get(quesObj, 'is_edited')">Last Modified:</span> {{ formatDateTime(get(quesObj, 'updated_at')) }}
        </p>
        <p class="text-muted mb-0">Audience: {{ $t(`audienceTypes.${get(quesObj, 'audience')}`) }}</p>
      </div>
      <page-separator title="" />

      <div class="card card-body">
        <div class="d-flex">
          <user-avatar
            slot="aside"
            :user="get(quesObj, 'questioner')"
            circle="lg"
            size="4rem"
            :file-url="get(quesObj, 'questioner.avatar_url')"
            variant="dark"
          >
          </user-avatar>
          <div class="flex">
            <p class="d-flex align-items-center mb-2">
              <a class="text-body ml-2"
                ><strong
                  >{{ get(quesObj, 'questioner.first_name') }} {{ get(quesObj, 'questioner.last_name') }}
                  {{
                    get(quesObj, 'questioner.title')
                      ? quesObj.questioner.title === USER_TITLES.OTHER
                        ? `(${get(quesObj, 'questioner.other_title', 'Other')})`
                        : `(${userTitleOptions[get(quesObj, 'questioner.title', 'other')].text})`
                      : ''
                  }}</strong
                ></a
              >
            </p>
            <p class="d-flex align-items-center mb-2">
              <span class="text-body ml-2" v-safe-html="get(quesObj, 'description', '')"></span>
            </p>
          </div>
        </div>
      </div>
    </b-skeleton-wrapper>

    <div class="card-button" v-if="get(quesObj, 'topics')">
      <ul>
        <li v-for="(top, index) in topics" :key="`${top}-${index}`">
          <a href="#" @click.prevent><span class="material-icons icon-16pt text-100">check</span>{{ top }}</a>
        </li>
      </ul>
    </div>

    <div
      :class="{ 'card-body': !isTabSmallScreen }"
      v-if="[USER_ROLE_TYPES.SCHOOL, USER_ROLE_TYPES.STUDENT].includes(get(this.getLoggedInUser, 'role_type'))"
    >
      <comments
        :type="'studentquestion'"
        :obj="quesObj"
        title="Replies"
        :show-title="true"
        post-comment-placeholder="Write your reply here..."
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Page from '@/components/Page.vue';
import Comments from '../Comments/Comments.vue';
import { USER_ROLE_TYPES, USER_TITLES, USER_TITLE_OPTIONS } from '@/common/constants';
import { formatTimeAgo, formatDateTime } from '@/common/utils';
import { get, keyBy } from 'lodash';
import UserAvatar from '@/components/User/UserAvatar.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  extends: Page,
  components: { UserAvatar, Comments, PageSeparator },
  data() {
    return {
      USER_TITLES,

      title: 'Questions',
      quesObj: null,
      isLoading: false,
      USER_ROLE_TYPES,
      tabIndex: 0,
      windowWidth: window.innerWidth,
      dist: '',
      audienceOptions: [
        { value: null, text: 'Please select audience', disabled: true },
        { value: 'everyone', text: 'Everyone' },
        { value: 'past_students_and_instructors', text: 'Past students & instructors' },
        { value: 'current_students_and_instructors', text: 'Current students & instructors' },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['getAuthToken', 'getLoggedInUser']),

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },

    userTitleOptions() {
      return keyBy(USER_TITLE_OPTIONS, 'value');
    },
  },
  methods: {
    ...mapActions('questions', ['getQuestion']),

    get,
    formatDateTime,
    formatTimeAgo,
    goBack() {
      this.$router.push({ name: this.$route.params.back ? 'my-questions' : 'student-questions' });
    },
    async fetchQuestion() {
      this.isLoading = true;
      try {
        const res = await this.getQuestion(this.$route.params.id);
        res.data.description = res.data.description.replace(/(?:\r\n|\r|\n)/g, '<br>');
        this.quesObj = res.data;
      } catch (err) {
        if (err.response.status === 404) {
          setTimeout(() => this.$router.push({ name: 'student-questions' }), 250);
        }
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
    if (this.$route.params.id) {
      this.fetchQuestion();
    }
  },
};
</script>

<style>
</style>