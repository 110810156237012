<template>
  <div style="margin-top: -16px">
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div class="page-section bg-dark border-bottom-white">
          <div
            class="d-flex flex-column flex-md-row align-items-center text-center text-md-left"
            :class="containerClass"
          >
            <fmv-avatar :title="true" rounded size="xxl" no-link :class="!isMobSmallScreen ? 'mr-3' : ''">
              <b-skeleton-img height="113px" no-aspect></b-skeleton-img>
            </fmv-avatar>

            <div class="flex mb-32pt mb-md-0">
              <h2 class="text-white mb-0"><b-skeleton width="85%"></b-skeleton></h2>
              <p class="lead text-white-50">
                <b-skeleton width="60%"></b-skeleton>
              </p>
            </div>
            <b-skeleton type="button" v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL"></b-skeleton>
            <b-skeleton type="button" class="ml-2"></b-skeleton>
          </div>
        </div>

        <!-- <div class="page-section bg-alt border-bottom-2">
          <div :class="containerClass">
            <div class="row">
              <div class="col-md-6">
                <h4>Bio</h4>
                <b-skeleton width="60%"></b-skeleton>
                <b-skeleton width="85%"></b-skeleton>
                <b-skeleton width="75%"></b-skeleton>
              </div>
              <div class="col-md-6">
                <h4>Contact</h4>
                <b-skeleton width="60%"></b-skeleton>
                <b-skeleton width="85%"></b-skeleton>
                <b-skeleton width="75%"></b-skeleton>
              </div>
            </div>
          </div>
        </div> -->
      </template>

      <div
        class="bg-dark page-section employer-header-bg border-bottom-white mb-10pt img-fluid"
        :style="`background-image: url('${employer.profile_bg_img_url}');`"
      >
        <div class="overlay__content page-section employer-profile-cover-ol">
          <div
            class="d-flex flex-column flex-md-row align-items-center text-center text-md-left"
            :class="containerClass"
          >
            <fmv-avatar
              :title="true"
              rounded
              size="xxl"
              no-link
              :class="!isMobSmallScreen ? 'mr-3' : ''"
              title-class="bg-white"
            >
              <b-img
                :src="employer.logo_url || DefaultAvatar"
                class="img-fluid"
                width="104"
                alt="Logo"
                v-if="employer.logo_url"
              />
              <md-icon v-else>business</md-icon>
            </fmv-avatar>

            <div class="flex mb-32pt mb-md-0">
              <h2 class="text-white mb-0" v-text="employer.name" />
              <p class="lead text-white-50 d-flex align-items-center">
                {{ $t(`employerCompanyTypes.${employer.company_type}`) }}
                <span class="ml-16pt d-flex align-items-center">
                  <i class="material-icons icon-16pt mr-4pt">supervisor_account</i>
                  {{ employer.company_size }} Employees
                </span>
              </p>

              <div v-if="employer.hired_student_count">
                <md-icon style="font-size: 20px">work</md-icon>
                {{ employer.hired_student_count }} students from your school work here
              </div>
            </div>

            <partnership-request-button
              v-if="getLoggedInUser.role === USER_ROLES.SCHOOL_OWNER"
              :partnership="partnership"
              :entity="employer"
              :custom-class="'btn-white'"
              :container-class="isMobSmallScreen ? 'mb-2' : ''"
              :type="USER_ROLE_TYPES.EMPLOYER"
              :is-partnership-loading="isPartnershipLoading"
              :is-partnership-responding="isPartnershipResponding"
              @isResponding="updatePartnerRespLoader"
              @fetchPartnership="fetchPartnership"
            />
            <!-- <a
              :href="getClickableLink(employer.website_url)"
              class="btn btn-white"
              :class="{ 'ml-2': !isMobSmallScreen }"
              v-if="employer.website_url"
              target="_blank"
              ><md-icon class="mr-1">open_in_new</md-icon>Visit Website</a
            > -->
          </div>
        </div>
      </div>

      <!-- <div class="page-section bg-alt border-bottom-2">
        <div :class="containerClass">
          <div class="row">
            <div class="col-md-6">
              <h4>Bio</h4>
              <p class="text-70" v-text="employer.bio" v-if="employer.bio" />
              <i v-else>{{ employer.name }} has not added any bio.</i>
            </div>
            <div class="col-md-6">
              <h4>Contact</h4>
              <div class="text-70" v-text="employer.address" />
              <div class="text-70">{{ employer.zipcode }}, {{ employer.city }}, {{ employer.state }}</div>
              <div class="text-70">{{ employer.country }}</div>
              <div class="d-flex align-items-center mt-2" v-if="employer.phone">
                <md-icon class="font-size-16pt mr-2">phone</md-icon> {{ employer.phone }}
                
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- TABS -->
      <div :class="containerClass">
        <div style="display: center">
          <b-tabs
            nav-class="nav-tabs-card bg-white px-3"
            content-class=" "
            class="mt-0"
            id="homePageTab"
            @input="onTabsChanged"
            style="scroll-margin-top: 66px; font-size: 16.5px"
          >
            <b-tab title="Overview" class="tab-secondary">
              <template #title>
                <i class="fas fa-info align-self-center" :class="{ 'mx-4': isTabSmallScreen }"></i>
                <strong class="ml-2" v-if="!isTabSmallScreen">Overview</strong>
              </template>

              <div class="page-section">
                <div class="page-section student-card mt-0 pt-0 mb-32pt">
                  <div
                    class="d-flex justify-content-center mt-4"
                    v-if="get(partnership, 'status') === PARTNERSHIP_STATUS.SENT"
                  >
                    <div class="card card-block card-stretch card-height col-md-8">
                      <div class="card-body text-center">Partnership request has been sent.</div>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-center mt-4"
                    v-if="get(partnership, 'status') === PARTNERSHIP_STATUS.RECEIVED"
                  >
                    <div class="card card-block card-stretch card-height col-md-8">
                      <div class="card-body text-center">
                        <p class="text-muted">
                          {{
                            `${employer.name} has sent you a partnership request with the following message. Respond now to become partners.`
                          }}
                        </p>

                        <div class="d-flex align-items-start mb-2">
                          <h5 class="card-title fw600">Partnership Type</h5>
                        </div>

                        <div class="d-flex align-items-start mb-2">
                          <span
                            ><b>{{ $t(`partnershipTypes.${partnership.type}`) }}</b></span
                          >
                        </div>

                        <div class="d-flex align-items-start mb-2">
                          <h5 class="card-title fw600">Partnership Fees</h5>
                        </div>

                        <div class="d-flex align-items-start">
                          <span
                            ><b>Initiation Fee:</b> ${{ $n(partnership.initial_computed_fee) }} (${{
                              $n(partnership.partner_initial_fee)
                            }}
                            / Recently Active Student
                            <i
                              class="material-icons icon-16pt clickable-item text-black-50"
                              v-b-popover.hover.top="ACTIVE_STD_INFO_DESC"
                              >info</i
                            >)</span
                          >
                        </div>

                        <div class="d-flex align-items-start mb-2">
                          <span><b>Recurring Fee:</b> ${{ $n(partnership.partner_recurring_fee) }} / Month</span>
                        </div>
                        <div class="d-flex align-items-start mb-2">
                          <h5 class="card-title fw600">Requested Programs</h5>
                        </div>
                        <div
                          class="d-flex align-items-start"
                          v-for="(program, index) in partnership.programs"
                          :key="index"
                        >
                          <span><md-icon class="mr-2 text-50 icon-16pt">check_circle</md-icon>{{ program.title }}</span>
                        </div>

                        <div class="d-flex align-items-start mt-4">
                          <h5 class="card-title fw600">Message</h5>
                        </div>
                        <div class="card card-block card-stretch card-height mt-2">
                          <div class="card-body text-left">
                            <div v-html="partnership.message"></div>
                          </div>
                        </div>
                        <partnership-request-button
                          :partnership="partnership"
                          :entity="employer"
                          :customClass="'btn-primary'"
                          :container-class="'d-flex justify-content-center'"
                          :type="USER_ROLE_TYPES.EMPLOYER"
                          :is-partnership-loading="isPartnershipLoading"
                          :is-partnership-responding="isPartnershipResponding"
                          @isResponding="updatePartnerRespLoader"
                          @fetchPartnership="fetchPartnership"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="card border-left-0">
                    <div class="card-body">
                      <div class="d-flex mb-1">
                        <div class="flex">
                          <div class="d-flex align-items-center mb-1">
                            <h5 class="card-title fw600">About</h5>
                          </div>

                          <div class="d-flex">
                            <div class="flex">
                              <div
                                class="d-flex align-items-center mt-2"
                                v-if="
                                  get(employer, 'address') &&
                                  get(employer, 'city') &&
                                  get(employer, 'state') &&
                                  get(employer, 'zipcode') &&
                                  get(employer, 'country')
                                "
                              >
                                <md-icon class="font-size-16pt mr-2">location_on</md-icon>
                                {{ get(employer, 'address') }}, {{ get(employer, 'city') }},
                                {{ get(employer, 'state') }} {{ get(employer, 'zipcode') }},
                                {{ get(employer, 'country') }}
                              </div>
                              <!-- <div class="text-70" v-text="employer.address" />
                            <div class="text-70">{{ employer.zipcode }}, {{ employer.city }}, {{ employer.state }}</div>
                            <div class="text-70">{{ employer.country }}</div> -->
                              <div class="d-flex align-items-center mt-2" v-if="employer.phone">
                                <md-icon class="font-size-16pt mr-2">phone</md-icon>
                                <a :href="`tel:${employer.phone}`" class="text-black">{{ employer.phone }}</a>
                              </div>

                              <div class="d-flex align-items-center mt-2" v-if="employer.website_url">
                                <md-icon class="font-size-16pt mr-2">language</md-icon>
                                <a :href="getClickableLink(employer.website_url)" target="_blank" class="text-black">{{
                                  getClickableLink(employer.website_url)
                                }}</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card border-left-0" v-if="employer.bio">
                    <div class="card-body">
                      <div class="d-flex mb-1">
                        <div class="flex">
                          <div class="d-flex align-items-center mb-1">
                            <h5 class="card-title fw600">Bio</h5>
                          </div>

                          <div class="d-flex">
                            <div class="flex program-description">
                              <div v-safe-html="get(employer, 'bio', '')" v-if="employer.bio" />

                              <i v-else>{{ employer.name }} has not added any bio.</i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>

            <!-- Employer's Active Jobs -->
            <b-tab
              title="Jobs"
              class="tab-secondary"
              v-if="![USER_ROLE_TYPES.SCHOOL, USER_ROLE_TYPES.ORGANIZATION].includes(getLoggedInUser.role_type)"
            >
              <template #title>
                <i class="fas fa-briefcase align-self-center" :class="{ 'mx-4': isTabSmallScreen }"></i>
                <strong class="ml-2" v-if="!isTabSmallScreen">Jobs</strong>
              </template>

              <div class="page-section">
                <div class="page-section student-card mt-0 pt-0 mb-32pt">
                  <!-- <div
                    :class="containerClass"
                    class="page-section"
                    v-if="getLoggedInUser.role_type !== USER_ROLE_TYPES.SCHOOL"
                  > -->
                  <div class="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt" style="white-space: nowrap">
                    <!-- <h2 class="mb-0">Active Jobs</h2> -->
                    <div class="w-auto ml-sm-auto table d-flex align-items-center mb-sm-0">
                      <small class="text-muted text-headings text-uppercase d-sm-block">Sort by</small>
                      <b-dropdown variant="link text-black-70" right no-caret>
                        <template #button-content>
                          <span>{{ $t(`jobListingSortOptions.${jobOrdering}`) }}<i class="fas fa-sort ml-1"></i></span>
                        </template>
                        <b-dropdown-item :active="jobOrdering === '-id'" @click="onJobsSortChange({ sortBy: '-id' })"
                          >Most Recent</b-dropdown-item
                        >
                        <b-dropdown-item
                          :active="jobOrdering === 'title'"
                          @click="onJobsSortChange({ sortBy: 'title' })"
                          >Title</b-dropdown-item
                        >
                        <b-dropdown-item :active="jobOrdering === 'city'" @click="onJobsSortChange({ sortBy: 'city' })"
                          >Location</b-dropdown-item
                        >
                      </b-dropdown>

                      <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                        <b-form-input
                          class="w-lg-auto"
                          placeholder="Search..."
                          v-model="jobSearchTerm"
                          @input="onJobSearch"
                        />
                        <b-btn variant="flush" type="submit">
                          <md-icon v-text="'search'" />
                        </b-btn>
                      </form>
                    </div>
                  </div>

                  <page-separator
                    :title="
                      isLoading
                        ? 'Jobs'
                        : `Showing ${totalJobs ? `${$n(jobsPageFromCount)}-${$n(jobsPageToCount)}` : 0} of ${$n(
                            totalJobs
                          )} Jobs`
                    "
                  />

                  <div class="d-flex justify-content-center" v-if="!totalJobs && !areJobsLoading">
                    <div class="card card-block card-stretch card-height col-md-12">
                      <div class="card-body text-center">
                        <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
                        <h4>No Jobs Found</h4>

                        <p class="text-muted">
                          Couldn't find any open jobs right. However, there is a constant need for great candidates like
                          you, so please check again after a while.
                        </p>

                        <!-- <a href="#" @click.prevent="" class="btn btn-primary mt-2"> Sample Button </a> -->
                      </div>
                    </div>
                  </div>

                  <b-skeleton-wrapper :loading="areJobsLoading" v-else>
                    <template #loading>
                      <div class="row card-group-row mb-lg-12pt home-card">
                        <div class="col-sm-12 card-group-row__col" v-for="item in [1, 2]" :key="item">
                          <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card">
                            <div class="card-header d-flex align-items-center">
                              <a href="#" @click.prevent class="card-title flex">
                                <b-skeleton width="100%"></b-skeleton
                              ></a>
                            </div>
                            <div class="card-body d-flex flex-column">
                              <div class="d-flex align-items-center">
                                <div class="flex">
                                  <div class="d-flex align-items-center mb-16pt">
                                    <div class="mr-12pt">
                                      <a
                                        href="#"
                                        @click.prevent
                                        class="avatar avatar-xl overlay js-overlay overlay--primary rounded p-relative o-hidden mb-16pt align-self-center"
                                        style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl;object-fit: cover; height: inherit"
                                      >
                                        <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                                      </a>
                                    </div>
                                    <div class="flex">
                                      <div class="mb-0" style="text-transform: none">
                                        <b-skeleton width="100%"></b-skeleton>
                                        <b-skeleton width="55%"></b-skeleton>
                                        <b-skeleton width="70%"></b-skeleton>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>

                    <div class="row card-group-row mb-lg-12pt home-card">
                      <div class="col-sm-12 card-group-row__col" v-for="job in jobs" :key="job.id">
                        <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card">
                          <div class="card-header d-flex align-items-center">
                            <a href="#" @click.prevent="openJobProfile(job.id)" class="card-title flex mr-12pt">{{
                              job.title
                            }}</a>
                            <span>
                              <i class="material-icons icon-16pt mr-1 text-dark">business</i>
                              <router-link
                                :to="{ name: 'student-employer-profile', params: { id: job.employer.id } }"
                                class="text-primary"
                                style="text-decoration: underline"
                                >{{ job.employer.name }}</router-link
                              >
                            </span>
                          </div>

                          <div class="card-body d-flex flex-column" @click.prevent="openJobProfile(job.id)">
                            <div class="d-flex align-items-center">
                              <div class="flex">
                                <div class="d-flex align-items-center mb-16pt">
                                  <div class="mr-12pt">
                                    <a
                                      href="#"
                                      @click.prevent
                                      class="avatar avatar-xl overlay js-overlay overlay--primary rounded p-0 o-hidden d-flex justify-content-center align-items-center"
                                      style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl;object-fit: cover; height: inherit"
                                    >
                                      <img
                                        class="avatar-img m-0"
                                        :src="
                                          job.employer && job.employer.logo_url ? job.employer.logo_url : DefaultAvatar
                                        "
                                        width="160"
                                        height="auto"
                                      />
                                    </a>
                                  </div>
                                  <div class="flex">
                                    <div class="mb-0" style="text-transform: none">
                                      <div>
                                        <i class="material-icons icon-16pt">location_on</i
                                        ><strong style="font-weight: bold">{{ job.city }}, {{ job.state }}</strong>
                                      </div>
                                      <div>
                                        <i class="material-icons icon-16pt">timelapse</i> Seeking candidates for
                                        <strong style="font-weight: bold">{{
                                          $t(`jobWorkTypeOpts.${job.type}`)
                                        }}</strong>
                                        position
                                      </div>
                                      <div>
                                        <span>
                                          <i class="material-icons icon-16pt">favorite</i>
                                          <strong style="font-weight: bold">
                                            {{ job.caregiving_experience }} year(s)</strong
                                          >
                                          caregiving experience required
                                        </span>
                                      </div>
                                      <div>
                                        <span>
                                          <i class="material-icons icon-16pt">calendar_today</i> Posted
                                          <strong style="font-weight: bold">{{ formatTimeAgo(job.created_at) }}</strong>
                                        </span>
                                      </div>
                                    </div>
                                    <!-- <p class="flex mb-0 mt-1">
                            {{
                              truncate(trim(job.description), {
                                length: 240,
                                separator: /,? +/,
                              })
                            }}
                            <router-link
                              v-if="job.description.length > 240"
                              :to="{ name: 'student-job-details', params: { id: job.id } }"
                              class="text-primary"
                              >Read More</router-link
                            >
                          </p> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="card-button">
                              <ul>
                                <li v-for="(benefit, index) in job.benefitCarAdv" :key="`${benefit}-${index}`">
                                  <a href="#" @click.prevent
                                    ><span class="material-icons icon-16pt text-100">check</span>{{ benefit }}</a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-skeleton-wrapper>

                  <pagination
                    v-if="totalJobs > jobs.length"
                    v-model="currentPage"
                    :total-rows="totalJobs"
                    :per-page="perPage"
                    @change="onJobsPageChange"
                    aria-controls="jobs-table"
                  />
                  <!-- </div> -->
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
      <!-- <div class="d-flex justify-content-center mt-4" v-if="get(partnership, 'status') === PARTNERSHIP_STATUS.SENT">
        <div class="card card-block card-stretch card-height col-md-8">
          <div class="card-body text-center">Partnership request has been sent.</div>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4" v-if="get(partnership, 'status') === PARTNERSHIP_STATUS.RECEIVED">
        <div class="card card-block card-stretch card-height col-md-8">
          <div class="card-body text-center">
            <p class="text-muted">
              {{
                `${employer.name} has sent you a partnership request with the following message. Respond now to become partners.`
              }}
            </p>
            <div class="d-flex align-items-start mb-2">
              <h5 class="card-title fw600">Partnership Fees</h5>
            </div>

            <div class="d-flex align-items-start">
              <span
                ><b>Initiation Fee:</b> ${{ $n(partnership.initial_computed_fee) }} (${{
                  $n(partnership.partner_initial_fee)
                }}
                / Recently Active Student
                <i
                  class="material-icons icon-16pt clickable-item text-black-50"
                  v-b-popover.hover.top="ACTIVE_STD_INFO_DESC"
                  >info</i
                >)</span
              >
            </div>

            <div class="d-flex align-items-start mb-2">
              <span><b>Recurring Fee:</b> ${{ $n(partnership.partner_recurring_fee) }} / Month</span>
            </div>
            <div class="d-flex align-items-start mb-2">
              <h5 class="card-title fw600">Requested Programs</h5>
            </div>
            <div class="d-flex align-items-start" v-for="(program, index) in partnership.programs" :key="index">
              <span><md-icon class="mr-2 text-50 icon-16pt">check_circle</md-icon>{{ program.title }}</span>
            </div>

            <div class="d-flex align-items-start mt-4">
              <h5 class="card-title fw600">Message</h5>
            </div>
            <div class="card card-block card-stretch card-height mt-2">
              <div class="card-body text-left">
                <div v-html="partnership.message"></div>
              </div>
            </div>
            <partnership-request-button
              :partnership="partnership"
              :entity="employer"
              :customClass="'btn-primary'"
              :container-class="'d-flex justify-content-center'"
              :type="USER_ROLE_TYPES.EMPLOYER"
              :is-partnership-loading="isPartnershipLoading"
              :is-partnership-responding="isPartnershipResponding"
              @isResponding="updatePartnerRespLoader"
              @fetchPartnership="fetchPartnership"
            />
          </div>
        </div>
      </div> -->
    </b-skeleton-wrapper>
  </div>
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';

import Page from '@/components/Page.vue';
import { mapActions, mapGetters } from 'vuex';
import { formatFullDate, formatTimeAgo } from '../../common/utils';
import { FmvAvatar } from 'fmv-avatar';
import { debounce } from 'lodash';

import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import MdIcon from '../../components/Ui/MdIcon.vue';
import Pagination from '../../components/Ui/Pagination.vue';
import { JOB_BENEFITS_OPTONS, USER_ROLES, USER_ROLE_TYPES, ACTIVE_STD_INFO_DESC } from '../../common/constants';
import PartnershipRequestButton from '../Partnership/PartnershipRequestButton.vue';
import { PARTNERSHIP_STATUS } from '../../common/constants';
import { get, isEmpty } from 'lodash';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  components: { MdIcon, FmvAvatar, Pagination, PartnershipRequestButton, PageSeparator },
  extends: Page,

  data() {
    return {
      DefaultAvatar,
      USER_ROLE_TYPES,

      title: 'Employer Profile',

      isLoading: true,
      areJobsLoading: true,
      employer: {},

      totalJobs: 0,
      jobs: [],
      perPage: 10,
      currentPage: 1,

      jobsPageFromCount: 0,
      jobsPageToCount: 0,

      jobSearchTerm: '',
      jobOrdering: '-id',

      partnership: {},
      isPartnershipLoading: false,
      isPartnershipResponding: false,
      PARTNERSHIP_STATUS,
      windowWidth: window.innerWidth,
      ACTIVE_STD_INFO_DESC,
      firstLoad: true,
      USER_ROLES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Employer Profile', active: true },
      ];
    },

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
  },

  methods: {
    ...mapActions('employer', ['getEmployer']),
    ...mapActions('job', ['getAllJobs']),
    ...mapActions('fileDownload', ['downloadFile']),
    ...mapActions('partnership', ['getPartnership', 'getStatus']),
    formatFullDate,
    formatTimeAgo,
    get,
    isEmpty,
    onTabsChanged() {
      if (!this.firstLoad) {
        document.getElementById('homePageTab').scrollIntoView({ behavior: 'smooth' });
      }
      this.firstLoad = false;
    },
    async fetchPartnership() {
      this.isPartnershipLoading = true;
      const response = await this.getStatus({ target_request_id: this.employer.id, target_request_type: 'employer' });
      this.partnership = response.data;
      this.isPartnershipLoading = false;
    },

    updatePartnerRespLoader(val) {
      this.isPartnershipResponding = val;
    },

    async fetchEmployer(id = null) {
      this.isLoading = true;

      try {
        const empId = id || this.$route.params.id || this.getLoggedInUser.linked_entity.id;
        const resp = (await this.getEmployer(empId)).data;
        this.employer = resp;
      } catch (err) {
        this.$router.push({
          name:
            this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
              ? 'schl-employers-list'
              : 'student-employers-list',
        });
      }

      this.isLoading = false;
    },

    async downloadGivenFile(url) {
      const h = this.$createElement;

      this.makeToast({
        variant: 'primary',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
        toaster: 'b-toaster-bottom-left',
      });

      await this.downloadFile({ fileUrl: url, removeTimestamp: true });

      this.makeToast({
        variant: 'success',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
        toaster: 'b-toaster-bottom-left',
      });
    },

    getClickableLink(link) {
      return link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`;
    },

    async fetchJobs(pageNum = 1, params = {}) {
      this.areJobsLoading = true;

      const response = await this.getAllJobs({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        employer: this.$route.params.id || this.getLoggedInUser.linked_entity.id,
        ...(this.jobOrdering && { ordering: this.jobOrdering }),
        ...(this.jobSearchTerm && { search: this.jobSearchTerm }),
        is_active: true,
        ...params,
      });

      // Adding benefits and career advancements.
      response.data.results.forEach((job) => {
        job['benefitCarAdv'] = [];

        // Benefits
        JOB_BENEFITS_OPTONS.forEach((benefit) => {
          if (job.benefits.includes(benefit.value) && benefit.value !== 'other') {
            job['benefitCarAdv'].push(benefit.text);
          }
        });
        if (job.benefits.includes('other') && job.other_benefits) {
          job['benefitCarAdv'].push(job.other_benefits);
        }

        // Career Advancements
        if (job.offers_career_advancement) {
          job['benefitCarAdv'] = job['benefitCarAdv'].concat((job.career_offerings || []).map((career) => career.name));
          if (job.other_career_offering) {
            job['benefitCarAdv'].push(job.other_career_offering);
          }
        }
      });

      this.jobs = response.data.results;
      this.currentPage = pageNum;
      this.totalJobs = response.data.count;

      this.jobsPageFromCount = (this.currentPage - 1) * this.perPage + 1;
      this.jobsPageToCount = this.jobsPageFromCount + get(this.jobs, 'length', 0) - 1;

      this.areJobsLoading = false;
    },

    onJobsPageChange(pageNum) {
      this.fetchJobs(pageNum);
    },

    onJobsSortChange(context) {
      this.jobOrdering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchJobs();
    },

    onJobSearch() {
      this.debouncedSearchJobs(this);
    },

    debouncedSearchJobs: debounce((vm) => {
      vm.fetchJobs();
    }, 500),

    openJobProfile(id) {
      if (this.getLoggedInUser.role === USER_ROLES.STUDENT) {
        this.$router.push({ name: 'student-job-details', params: { id } });
      } else {
        this.$router.push({ name: 'manage-job-public-view', params: { id } });
      }
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async beforeRouteUpdate(to, from, next) {
    this.fetchEmployer(to.params.id);
    next();
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    await this.fetchEmployer();
    if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL) {
      this.fetchPartnership();
    }
    if (this.getLoggedInUser.role_type !== USER_ROLE_TYPES.SCHOOL) {
      this.fetchJobs();
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
