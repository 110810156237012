<template>
  <div>
    <div class="pt-32pt">
      <div :class="containerClass" class="d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
        <div class="flex d-flex flex-column flex-sm-row align-items-center">
          <div class="mb-24pt mb-sm-0 mr-sm-24pt">
            <page-heading class="mb-0">
              {{ title }}
              <i
                class="material-icons icon-16pt clickable-item"
                v-b-popover.hover.top="`Review your payment history through this portal.`"
                >info</i
              >
            </page-heading>
          </div>
        </div>
      </div>
    </div>

    <div class="page-section">
      <div :class="containerClass">
        <div v-if="getInAppPaymentStatus">
          Haven't paid fully for your class yet?
          <router-link class="text-primary" :to="{ name: 'manage-plans' }"> Click here </router-link>
          to start your payment plan now!
        </div>

        <page-separator title="" />

        <div class="d-flex justify-content-center" v-if="!payments.length && !isLoading">
          <div class="card card-block card-stretch card-height col-md-12">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Payments Found</h4>

              <p class="text-muted">No payments has been found.</p>
            </div>
          </div>
        </div>

        <div v-else-if="isLoading">
          <div class="card">
            <b-skeleton-table :rows="5" :columns="6"></b-skeleton-table>
          </div>
        </div>

        <div v-else class="card mb-0">
          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="payments"
            :busy="isLoading"
            head-variant="light"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(status)="data">
              <b-badge
                :class="`border border-${TRANSACTION_STATUSES_COLORS[data.item.status]} text-${
                  TRANSACTION_STATUSES_COLORS[data.item.status]
                }`"
                variant="none"
                >{{ $t(`transactionStatuses.${data.item.status}`) }}
              </b-badge>
            </template>

            <template #cell(type)="data"> {{ $t(`paymentTypes.${data.item.type}`) }} </template>

            <template #cell(description)="data">
              <span v-if="data.item.type === PAYMENT_TYPES.STRIPE_SUBSCRIPTION">
                You subscribed to {{ data.item.extra.subscription_product }}
              </span>
              <span v-else-if="data.item.type === PAYMENT_TYPES.REGISTRATION">
                You registered for {{ data.item.extra.program }} in class #{{ data.item.extra.class_id }}
              </span>
              <span v-else-if="data.item.type === PAYMENT_TYPES.PRODUCT_PURCHASE">
                You bought product/s against invoice #{{ data.item.extra.invoice_id }}
              </span>
              <span v-else-if="data.item.type === PAYMENT_TYPES.PROCESSING_FEE"> Processing fee deduction </span>
              <span v-else-if="data.item.type === PAYMENT_TYPES.CLASS_PLAN">
                You paid your payment plan dues for {{ data.item.extra.program }} class #{{ data.item.extra.class_id }}
              </span>
              <span v-else>{{ data.item.description }}</span>
            </template>

            <template #cell(plan)="data">
              <span
                v-if="
                  [PAYMENT_PLANS.FREE_PLAN, PAYMENT_PLANS.PAY_NOW, PAYMENT_PLANS.PAY_LATER].includes(
                    data.item.extra.plan
                  )
                "
              >
                {{ $t(`paymentPlans.${data.item.extra.plan}`) }}
              </span>
              <span class="d-flex justify-content-center" v-else> - </span>
            </template>

            <template #cell(amount)="data">
              {{ $n(data.item.amount, 'currency', 'en-US') }}
            </template>

            <template #cell(created_at)="data"> {{ formatFullDate(data.item.created_at) }} </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalPayments"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="payments-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';

import PageHeading from '@/components/Ui/PageHeading.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { get, debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';

import {
  DEFAULT_PAGE_SIZE,
  TRANSACTION_STATUSES_COLORS,
  PAYMENT_TYPES,
  PAYMENT_PLANS,
  CUSTOMER_PORTAL_TYPES,
} from '@/common/constants';
import { formatFullDate, getClickableLinkUsSanitize } from '@/common/utils';

export default {
  components: { PageHeading, Pagination, PageSeparator },
  extends: Page,

  name: 'MyPayments',

  data() {
    return {
      title: 'My Payments',
      TRANSACTION_STATUSES_COLORS,
      PAYMENT_TYPES,
      isLoading: false,
      PAYMENT_PLANS,
      perPage: DEFAULT_PAGE_SIZE,
      CUSTOMER_PORTAL_TYPES,
      currentPage: 1,
      isLinkLoading: false,
      totalPayments: 0,
      payments: [],
      searchTerm: '',
      customerPortalLink: null,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('school', ['getInAppPaymentStatus']),

    tableColumns() {
      return [
        { key: 'status', label: 'Status' },
        { key: 'type', label: 'Type' },
        { key: 'description', label: 'Description' },
        { key: 'plan', label: 'Plan' },
        { key: 'amount', label: 'Amount' },
        { key: 'created_at', label: 'Date' },
      ];
    },
  },

  methods: {
    get,
    ...mapActions('payments', ['getStdPayments', 'getCustomerPortalLink']),

    formatFullDate,
    getClickableLinkUsSanitize,
    async fetchCustomerPortalLink() {
      this.isLinkLoading = true;
      try {
        const response = await this.getCustomerPortalLink();

        this.customerPortalLink = response.data;
      } catch (e) {
        // TODO use if needed
      }
      this.isLinkLoading = false;
    },
    async fetchPayments(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();
      let response;

      try {
        response = await this.getStdPayments({
          // student_id: this.getLoggedInUser.id,
          limit: this.perPage,
          offset: (pageNum - 1) * this.perPage,
          ...(this.ordering && { ordering: this.ordering }),
          ...(this.searchTerm && { search: this.searchTerm }),
          ...params,
        });
        this.payments = response.data.results;
        this.currentPage = pageNum;
        this.totalPayments = response.data.count;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchPayments(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchPayments();
    },

    onSearch() {
      this.debouncedSearchPayments(this);
    },

    debouncedSearchPayments: debounce((vm) => {
      vm.fetchPayments();
    }, 500),
  },

  async mounted() {
    this.fetchPayments();
    this.fetchCustomerPortalLink();
  },
};
</script>
