<template>
  <div>
    <div class="">
      <!-- <div class="row mb-8pt">
        <div class="col-lg-12 d-flex">
          <div class="flex" style="max-width: 100%">
            <div class="mb-0">
              <p class="text-90" style="font-size: 16px">
                The following are partnered companies who offer job and career opportunities to our students.<b>
                  Note: Students must be logged in to view all employers details.</b
                >
              </p>
            </div>
          </div>
        </div>
      </div> -->
      <page-separator
        :title="
          isLoading
            ? 'Companies'
            : `Showing ${totalEmployers ? `${$n(pageFromCount)}-${$n(pageToCount)}` : 0} of ${$n(
                totalEmployers
              )} companies`
        "
      />
    </div>

    <div class="page-section">
      <div class="">
        <div class="d-flex justify-content-center" v-if="!totalEmployers && !isLoading">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Companies Found</h4>

              <p class="text-muted">
                Couldn't find any companies. We are constantly onboarding new partnered companies so please check again
                later.
              </p>
            </div>
          </div>
        </div>

        <b-skeleton-wrapper :loading="isLoading" v-else>
          <template #loading>
            <div class="row card-group-row mb-lg-12pt mb-16pt home-card">
              <div class="col-md-6 col-lg-6 card-group-row__col" v-for="item in [1, 2]" :key="item">
                <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card myBox">
                  <div class="card-header d-flex align-items-center">
                    <a href="#" @click.prevent class="card-title flex mr-12pt">
                      <b-skeleton width="100%"></b-skeleton
                    ></a>
                    <!-- <a href="#" @click.prevent class="" data-placement="bottom">
                      <b-skeleton width="100%"></b-skeleton
                    ></a> -->
                  </div>
                  <div class="card-body flex text-center d-flex flex-column justify-content-center">
                    <a
                      href="#"
                      @click.prevent
                      class="avatar avatar-xl overlay js-overlay overlay--primary rounded p-relative o-hidden mb-16pt align-self-center"
                      style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                    >
                      <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                    </a>
                    <div class="flex">
                      <p class="text-70 measure-paragraph">
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="55%"></b-skeleton>
                        <b-skeleton width="70%"></b-skeleton>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="row card-group-row mb-lg-12pt mb-16pt home-card">
            <div class="col-md-6 col-lg-6 card-group-row__col" v-for="employer in employers" :key="employer.id">
              <div
                class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card myBox clickable-item"
                @click.prevent="openEmployerProfile(employer.slug)"
              >
                <div class="card-header d-flex align-items-center">
                  <a href="#" @click.prevent class="card-title flex mr-12pt">{{ employer.name }}</a>
                  <a href="#" @click.prevent class="" data-placement="bottom"
                    >{{ employer.city }}, {{ employer.state }}</a
                  >
                </div>
                <div class="card-body flex text-center d-flex flex-column align-items-center justify-content-center">
                  <a
                    href="#"
                    @click.prevent
                    class="avatar avatar-xl overlay js-overlay overlay--primary rounded p-relative o-hidden mb-16pt"
                    style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                  >
                    <img
                      class="avatar-img rounded"
                      :src="employer.logo_url || DefaultLogo"
                      width="160"
                      height="auto"
                      style="object-fit: contain !important"
                    />
                  </a>
                  <div class="flex">
                    <p class="text-70 measure-paragraph">
                      <span v-if="trim(employer.short_bio)">{{
                        truncate(trim(employer.short_bio), {
                          length: 240,
                          separator: /,? +/,
                        })
                      }}</span>
                      <i v-else>{{ employer.name }} has not added any bio yet.</i>
                    </p>
                  </div>

                  <div class="card-button">
                    <ul>
                      <li v-for="career in employer.offered_careers" :key="career.id">
                        <a href="#" @click.prevent
                          ><span class="material-icons icon-16pt text-100">check</span>{{ career.name }}</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>

        <pagination
          v-if="totalEmployers > employers.length"
          v-model="currentPage"
          :total-rows="totalEmployers"
          :per-page="perPage"
          @change="onPageChange"
          class="mb-4"
        />

        <div class="row mb-32pt">
          <div class="col-lg-12 d-flex">
            <div class="flex" style="max-width: 100%">
              <div class="alert alert-light mb-2 border border-primary" role="alert" style="border: ">
                <div class="d-flex flex-wrap text-center align-items-start">
                  <div class="flex" style="min-width: 180px">
                    <small class="text-black-100">
                      <p class="mr-1" style="font-size: 16px">
                        Are you interested in connecting with our students? Learn how to partner with
                        {{ get(getCurrentSchool, 'name', 'Transition') }}.
                      </p>
                      <a class="btn btn-primary">
                        <router-link class="text-white" :to="{ name: 'sch-public-parnerships' }"
                          >For Employers</router-link
                        >
                      </a>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <view-more-modal
      :show-modal.sync="modals.showViewMoreModal"
      :body-text="modals.viewMoreBodyText"
      :login-path="modals.loginPath"
      @close="hideViewMoreModal"
    /> -->
  </div>
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';
import { trim, truncate, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
//import ViewMoreModal from '../ViewMoreModal.vue';
import Page from '@/components/Page.vue';
import DefaultLogo from '@/assets/images/logos/logo_icon.png';
import { USER_ROLE_TYPES } from '../../../common/constants';
import Pagination from '../../../components/Ui/Pagination.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  components: {
    // ViewMoreModal,
    Pagination,
    PageSeparator,
  },
  extends: Page,

  data() {
    return {
      DefaultLogo,
      USER_ROLE_TYPES,

      title: 'Home',
      completeHeadTitle: undefined,

      isLoading: true,
      totalEmployers: 0,
      perPage: 14,
      currentPage: 1,
      employers: [],

      pageFromCount: 0,
      pageToCount: 0,

      modals: {
        loginPath: {},
        viewMoreBodyText: '',
        showViewMoreModal: false,
      },
      windowWidth: window.innerWidth,
    };
  },

  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
  },

  methods: {
    ...mapActions('employer', ['getPublicEmployers']),
    trim,
    truncate,
    get,

    openEmployerProfile(empSlug) {
      this.$router.push({ name: 'emp-public-profile', params: { slug: empSlug } });
    },

    // openViewMoreModal(obj) {
    //   this.modals.loginPath = obj;
    //   if (obj.name === 'redirect-employer-profile') {
    //     this.modals.viewMoreBodyText = 'You must login to view more details about this employer.';
    //   } else {
    //     this.modals.viewMoreBodyText = 'You must login to view more employers.';
    //   }
    //   this.modals.showViewMoreModal = true;
    // },

    hideViewMoreModal() {
      this.modals.showViewMoreModal = false;
      this.modals.viewMoreBodyText = '';
    },

    async fetchEmployers(pageNum = 1) {
      this.isLoading = true;
      const response = await this.getPublicEmployers({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        school: this.getCurrentSchool.id,
      });

      this.employers = response.data.results;
      this.currentPage = pageNum;
      this.totalEmployers = response.data.count;

      this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
      this.pageToCount = this.pageFromCount + get(response.data, 'results.length', 0) - 1;

      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchEmployers(pageNum);
    },

    openEmpProfile(id) {
      this.$router.push({
        name: 'schl-employer-profile',
        params: { id },
      });
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  watch: {
    getCurrentSchool: {
      immediate: true,
      deep: true,
      handler(value) {
        if (get(value, 'id')) {
          this.fetchEmployers();
          this.completeHeadTitle = `${get(this.getCurrentSchool, 'name', 'Home')} - ${get(
            this.getCurrentSchool,
            'pp_home_title',
            'Transition'
          )}`;
        }
      },
    },
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
  },
};
</script>
