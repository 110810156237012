<template>
  <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
    <h3 class="text-center mb-3">
      Choose a skill that you will be demonstrating.
    </h3>
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-6" v-for="item in [1, 2, 3]" :key="item">
            <div class="card card-sm card--elevated p-relative">
              <span class="js-image">
                <b-skeleton-img width="100%" class="pb-0 mb-0"></b-skeleton-img>
              </span>

              <div class="card-body">
                <div class="card-title"><b-skeleton width="100%" /></div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <div class="row card-group-row mb-lg-12pt home-card">
        <div v-for="skill in skillData" :key="skill.id" class="col-lg-4 col-md-4 col-sm-12 clickable-item mb-3">
          <div
            class="card card-sm card--elevated p-relative h-100 mb-0 clickable-item-hov"
            @click.prevent="showSkillDetails(skill)"
          >
            <span
              class="js-image d-flex justify-content-center clickable-item overlay"
              style="width: 100%"
              data-position="left"
              :data-height="168"
            >
              <img
                :src="skill.sample_video_thumbnail_url"
                style="border-top-left-radius: 0.5rem; border-top-right-radius: 0.5rem"
                alt="cna"
                class="career-card-img"
                v-if="skill.sample_video_thumbnail_url"
              />
              <video
                :src="skill.sample_video_url"
                style="border-top-left-radius: 0.5rem; border-top-right-radius: 0.5rem"
                class="career-card-img"
                v-else
              ></video>
              <div class="overlay__content align-items-start justify-content-between p-2">
                <div v-if="selectedSkill.id === skill.id">
                  <md-icon class="text-success">check_circle</md-icon>
                </div>
              </div>
            </span>

            <div class="card-body pb-0 mb-2">
              <div class="d-flex justify-content-center">
                <div class="card-title">{{ skill.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-skeleton-wrapper>
  </b-form>
</template>

<script>
export default {
  props: {
    skillData: { type: Array, required: true },
    isLoading: { type: Boolean, default: false },
    selectedSkill: { type: Object, default: () => ({}) },
  },

  data() {
    return {};
  },

  validations() {},

  computed: {},

  watch: {},

  methods: {
    showSkillDetails(skill) {
      this.$emit('skillSelected', skill);
    },
  },

  mounted() {},
};
</script>
