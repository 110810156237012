<template>
  <div class="col-sm-12 card-group-row__col" :id="`testimonial-${testimonial.id}`" style="scroll-margin-top: 90px">
    <div class="card card-lg card-group-row__card" :id="`testimonial-card-${testimonial.id}`">
      <div class="card-body d-flex flex-column">
        <div class="d-flex align-items-center">
          <div class="flex">
            <div :class="{ 'd-flex ': !isMobSmallScreen }">
              <div :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen, 'mr-16pt': !isMobSmallScreen }">
                <a
                  href="#"
                  @click.prevent
                  class="avatar avatar-xxl overlay js-overlay overlay--primary rounded-circle p-0 o-hidden"
                  style="border: 5px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                >
                  <img
                    class="avatar-img m-0"
                    :src="
                      testimonial.created_by && testimonial.created_by.avatar_url
                        ? testimonial.created_by.avatar_url
                        : DefaultAvatar
                    "
                    width="160"
                    height="auto"
                  />
                </a>
              </div>
              <div class="flex">
                <div class="mb-0" style="text-transform: none">
                  <h3>{{ testimonial.created_by.first_name }} {{ testimonial.created_by.last_name[0] }}.</h3>
                  <p v-if="testimonial.curr_employer">
                    <strong class="text-primary mr-1">Current Employer: </strong> {{ testimonial.curr_employer }}
                  </p>
                  <p>
                    <strong class="text-primary mr-1">Year Completed: </strong
                    >{{ getYearCompleted(testimonial.created_by.grad_date) }}
                  </p>
                  <p class="text-break">
                    <strong class="text-primary mr-1">{{ `Why I came to ${get(getCurrentSchool, 'name')}?` }}</strong>
                    {{ testimonial.school_joining_reason }}
                  </p>
                  <p class="text-break">
                    <strong class="text-primary mr-1">{{
                      `What career successes have happened with you, since completing your CNA training at ${get(
                        getCurrentSchool,
                        'name'
                      )}?`
                    }}</strong>
                    {{ testimonial.career_success_details }}
                  </p>
                  <p class="text-break mb-0">
                    <strong class="text-primary mr-1">Tips to pass on to the readers:</strong>
                    {{ testimonial.readers_tips }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';
import { get } from 'lodash';
import { mapGetters } from 'vuex';
//import Pagination from '../../../components/Ui/Pagination.vue';
import moment from 'moment';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
export default {
  components: {},
  props: {
    testimonial: Object,
  },
  name: 'PublicTestimonialCard',

  data() {
    return {
      DefaultAvatar,

      title: 'Testimonials',

      windowWidth: window.innerWidth,
      firstLoad: true,
    };
  },

  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
  },

  methods: {
    get,
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    getYearCompleted(gradDate) {
      const oneDate = moment(gradDate, 'YYYY-MM-DD');
      return oneDate.format('YYYY');
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
