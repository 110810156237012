<template>
  <b-modal ref="programModal" hide-footer title="Offered Programs" centered lazy scrollable @hide="hideModal" size="lg">
    <div class="row px-2 ma-0">
      <div class="col-md-4 col-sm-4 mb-2" v-for="program in programs" :key="program.id">
        <div class="card" style="height: 90%">
          <div style="position: relative">
            <img
              :src="program.image_url"
              :alt="program.title"
              style="object-fit: cover; border-radius: 0.5rem 0.5rem 0rem 0rem"
              width="100%"
              height="100px"
              class="mb-2"
            />

            <div class="p-2">
              {{ program.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ProgramModal',

  props: {
    showModal: { type: Boolean, default: false },
    programs: { type: Array, default: () => [] },
  },

  data() {
    return {};
  },

  computed: {},

  watch: {
    showModal(value) {
      if (value) {
        this.$refs.programModal.show();
      }
    },
  },

  methods: {
    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.programModal.hide();
      this.hideModal();
    },
  },
};
</script>
