<template>
  <div :class="comment.parent_comment ? 'ml-auto w-75' : ''">
    <div class="card card-block card-stretch card-height mb-3" @mouseover="hover = true" @mouseleave="hover = false">
      <div class="card-body d-flex">
        <b-avatar
          v-if="comment.commenter.first_name && comment.commenter.last_name"
          variant="primary"
          size="40px"
          :text="`${comment.commenter.first_name[0].toUpperCase()}${comment.commenter.last_name[0].toUpperCase()}`"
        />
        <b-avatar variant="primary" size="50px" v-else />
        <div class="w-100 ml-3">
          <div class="d-flex flex-wrap">
            <strong
              >{{ comment.commenter.first_name }} {{ comment.commenter.last_name }}
              {{
                get(comment, 'commenter.title')
                  ? comment.commenter.title === USER_TITLES.OTHER
                    ? `(${get(comment, 'commenter.other_title', 'Other')})`
                    : `(${userTitleOptions[get(comment, 'commenter.title', 'other')].text})`
                  : ''
              }}</strong
            >

            <small class="ml-auto">
              {{ formatDateTime(comment.created_at) }}
            </small>
          </div>
          <p>{{ comment.text }}</p>
          <div class="mt-2 d-flex justify-content-end">
            <a href="#" @click.prevent="onReply(comment)" class="d-flex align-items-center"
              ><i class="fas fa-reply mr-2"></i>Reply</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="mb-2" v-if="isReplying">
      <post-comment
        :skill="skill"
        :is-parent="!!comment.parent_comment"
        :parent-comment-id="comment.parent_comment || comment.id"
        :reply-to-comment="comment.id"
        :reply-to-mention-name="replyToMentionName"
        @posted="onReplyPosted"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatDateTime } from '../../../../common/utils';
import { USER_TITLES, USER_TITLE_OPTIONS } from '@/common/constants';
import { get, keyBy } from 'lodash';

import PostComment from './PostComment.vue';
export default {
  name: 'CommentListItem',

  props: {
    comment: Object,
    skill: Object,
  },

  components: { PostComment },

  data() {
    return {
      USER_TITLES,

      isReplying: false,
      replyToMentionName: '',
    };
  },

  methods: {
    get,
    formatDateTime,
    onReply(comment) {
      this.replyToMentionName = comment.commenter.first_name;
      this.isReplying = !this.isReplying;
    },
    onReplyPosted() {
      this.isReplying = false;
      this.replyToMentionName = null;
    },
  },

  mounted() {},

  updated() {},

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    userTitleOptions() {
      return keyBy(USER_TITLE_OPTIONS, 'value');
    },
  },
};
</script>
