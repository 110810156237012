<template>
  <div>
    <!-- <page-header :title="title" :container-class="containerClass" /> -->
    <div class="pt-32pt">
      <div class="d-flex flex-column align-items-center text-center text-sm-left container page__container">
        <b-img :src="getNavbarBrandLogo" height="100" alt="Transition" />
        <!-- <div class="flex d-flex flex-column flex-sm-row align-items-center">
          <div class="mb-24pt mb-sm-0 mr-sm-24pt">
            <h1 class="mb-0 h2">Welcome</h1>
          </div>
        </div>
        <span class="breadcrumb mb-0"
          >We’re excited to help you start and grow your career as CNA. First, let's setup your profile.</span
        > -->
      </div>
    </div>
    <div class="page-section border-bottom-2">
      <div :class="containerClass">
        <student-invite-onboarding />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Page from '@/components/Page.vue';
import StudentInviteOnboarding from '../StudentPortal/Onboarding/StudentInviteOnboarding.vue';

export default {
  name: 'AcceptInvite',
  components: { StudentInviteOnboarding },
  extends: Page,

  computed: {
    ...mapGetters('navbarConfig', ['getNavbarBrandLogo']),
  },

  data() {
    return {
      title: 'Accept Invite',
    };
  },
};
</script>

<style>
</style>