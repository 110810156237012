<template>
  <div class="page-section mt-3 pt-0 mb-32pt">
    <h3 v-if="showTitle">
      {{ title }}
      <span v-if="commentsCount"> ({{ $n(commentsCount) }})</span>
    </h3>
    <post-comment :obj="obj" :type="type" is-parent :comment-placeholder="postCommentPlaceholder" />
    <div class="w-100 mt-2">
      <div v-for="(comment, index) in allComments" :key="index">
        <comment-list-item
          :id="`comment-${comment.id}`"
          :comment="comment"
          :obj="obj"
          style="scroll-margin-top: 70px"
          :type="type"
        />
        <!-- Replies -->
        <div v-if="get(comment, 'replies.length')">
          <comment-list-item
            v-for="reply in comment.replies"
            :key="reply.id"
            :id="`comment-${reply.id}`"
            :comment="reply"
            :obj="obj"
            style="scroll-margin-top: 70px"
            :type="type"
          />
        </div>
      </div>
    </div>
    <infinite-loading @infinite="infiniteLoadHandler">
      <div slot="spinner">
        <div class="text-center">
          <i class="fas fa-circle-notch fa-spin" />
        </div>
      </div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import PostComment from './PostComment.vue';
import CommentListItem from './CommentListItem.vue';
import { USER_ROLE_TYPES } from '@/common/constants';
import { get } from 'lodash';

export default {
  components: { PostComment, CommentListItem, InfiniteLoading },
  props: {
    obj: Object,
    type: String,
    showTitle: Boolean,
    title: String,
    postCommentPlaceholder: { type: String, default: 'Write your comment here...' },
  },
  data() {
    return {
      connection: null,
      allComments: [],
      areCommentsLoading: false,
      commentsCount: 0,
      pageNum: 1,
      pageSize: 20,
      totalComments: 0,
    };
  },
  computed: {
    ...mapGetters('auth', ['getAuthToken', 'getLoggedInUser']),

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
  },
  watch: {
    obj: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.fetchComments();
          this.fetchCommentCount();
          this.hookCommentsWS();
        }
      },
    },
  },
  methods: {
    ...mapActions('comments', ['getComments', 'getCommentCount']),
    get,
    async fetchCommentCount() {
      this.commentCountLoading = true;
      const resp = await this.getCommentCount({
        commented_on_type: this.type,
        commented_on_object_ids: [this.obj.id],
      });
      this.commentsCount = get(resp.data, 'count.0.comments_count', 0);
      this.commentCountLoading = false;
    },
    async fetchComments(params = {}) {
      this.areCommentsLoading = true;
      const response = await this.getComments({
        limit: this.pageSize,
        offset: (this.pageNum - 1) * this.pageSize,
        comment_on_id: this.obj.id,
        comment_on_type: this.type,
        ...params,
      });
      if (response.data.results) {
        this.allComments.push(...response.data.results);
        this.totalComments = response.data.count;
      }
      if (this.$route.params.commentId) {
        this.$nextTick(() => {
          const cm = document.getElementById(`comment-${this.$route.params.commentId}`);
          cm.scrollIntoView({ behavior: 'smooth' });
        });
      }
      if (this.$route.params.replyId) {
        this.$nextTick(() => {
          const cm = document.getElementById(`comment-${this.$route.params.replyId}`);
          cm.scrollIntoView({ behavior: 'smooth' });
        });
      }
      this.areCommentsLoading = false;
    },
    async loadMore() {
      this.isLoadingMore = true;
      this.pageNum++;
      await this.fetchComments();
      this.isLoadingMore = false;
    },
    async infiniteLoadHandler($state) {
      if (this.allComments.length < this.totalComments) {
        await this.loadMore();
        $state.loaded();
      } else $state.complete();
    },
    hookCommentsWS() {
      if ([USER_ROLE_TYPES.SCHOOL, USER_ROLE_TYPES.STUDENT].includes(get(this.getLoggedInUser, 'role_type'))) {
        this.connection = new WebSocket(
          `${process.env.VUE_APP_BACKEND_WS_URL}/ws/comments/${this.type}/${this.obj.id}/?token=${this.getAuthToken}`
        );
        this.connection.onmessage = (event) => {
          const data = JSON.parse(event.data).message;

          if (data.action === 'Create') {
            if (!data.comment.parent_comment) {
              if (!data.comment.replies) data.comment['replies'] = [];

              this.allComments.unshift(data.comment);
            } else {
              const comment = this.allComments.find((com) => com.id === data.comment.parent_comment);
              if (comment) {
                comment.replies.push(data.comment);
              }
            }
            this.commentsCount += 1;
          } else this.allComments = this.allComments.filter((comment) => comment.id !== data.comment.id);
        };
        this.connection.onerror = () => {};
      }
    },
  },
  async mounted() {},
};
</script>

<style>
</style>