<template>
  <div class="bg-light">
    <div :class="containerClass" v-if="!isSubmit">
      <div class="d-flex justify-content-center mt-2 pt-2">
        <b-skeleton-wrapper :loading="isLoading">
          <template #loading>
            <b-skeleton-img width="160px" height="120px" no-aspect></b-skeleton-img>
          </template>
          <b-img :src="get(scholarship, 'image_url')" height="110" />
        </b-skeleton-wrapper>
      </div>

      <b-skeleton-wrapper :loading="isLoading">
        <template #loading>
          <div class="d-flex justify-content-center mt-3">
            <b-skeleton width="60%"></b-skeleton>
          </div>
        </template>
        <div class="d-flex justify-content-center mt-2 text-center">
          <h3 class="mb-0 font-weight-bolder" v-text="`${get(scholarship, 'title')}`" />
        </div>
      </b-skeleton-wrapper>

      <div class="mx-3 mb-4 mt-4 text-center">
        <b-skeleton-wrapper :loading="isLoading">
          <template #loading>
            <p class="d-flex justify-content-center">
              <b-skeleton width="90%"></b-skeleton>
            </p>
          </template>

          <span style="font-size: 0.9125rem">
            <span v-if="get(scholarship, 'summary')">
              {{ get(scholarship, 'summary') }}
            </span>
          </span>
        </b-skeleton-wrapper>
      </div>

      <b-form @submit.prevent="onSubmit">
        <div class="row mb-2">
          <div class="col-md-6">
            <div class="card" style="height: 95%">
              <div class="card-header bg-light">
                <h5 class="my-0">Sponsorship Information</h5>
              </div>
              <div class="card-body d-flex flex-column justify-content-center">
                <div>
                  <b-skeleton-wrapper :loading="isLoading">
                    <template #loading>
                      <p class="lead text-white-50">
                        <b-skeleton width="70%"></b-skeleton>
                      </p>
                      <p class="lead text-white-50">
                        <b-skeleton width="60%"></b-skeleton>
                      </p>
                      <p class="lead text-white-50">
                        <b-skeleton width="50%"></b-skeleton>
                      </p>
                      <p class="lead text-white-50">
                        <b-skeleton width="40%"></b-skeleton>
                      </p>
                    </template>

                    <div class="icon-align mb-2" v-if="get(scholarship, 'deadline')">
                      <i class="fas fa-calendar-alt icon-16pt mr-1"></i><b class="mr-1 form-label">Deadline:</b>
                      {{ formatDateSimple(get(scholarship, 'deadline')) }}
                    </div>
                    <div
                      v-if="
                        get(scholarship, 'address') ||
                        get(scholarship, 'region') ||
                        get(scholarship, 'city') ||
                        get(scholarship, 'state') ||
                        get(scholarship, 'zipcode') ||
                        get(scholarship, 'country')
                      "
                      class="icon-align mb-2"
                    >
                      <i class="fas fa-map-marker-alt icon-16pt mr-1"></i> <b class="mr-1 form-label">Address:</b>
                      {{
                        computeAddress(
                          scholarship,
                          ['address', 'region', 'city', 'state', 'zipcode', 'country'],
                          (stateKey = 'state'),
                          (zipcodeKey = 'zipcode')
                        )
                      }}
                    </div>
                    <div
                      class="icon-align mb-2"
                      v-if="get(scholarship, 'min_amount') && get(scholarship, 'max_amount')"
                    >
                      <i class="fas fa-dollar-sign icon-16pt mr-1"></i>
                      <b class="mr-1 form-label"> Amount:</b>
                      ${{ $n(get(scholarship, 'min_amount')) }} - ${{ $n(get(scholarship, 'max_amount')) }}
                    </div>

                    <div
                      class="icon-align mb-2"
                      v-if="get(scholarship, 'minimum_pay') && get(scholarship, 'maximum_pay')"
                    >
                      <i class="fas fa-dollar-sign icon-16pt mr-1"></i>
                      <b class="mr-1 form-label"> Pay:</b>
                      ${{ $n(get(scholarship, 'minimum_pay')) }} - ${{ $n(get(scholarship, 'maximum_pay')) }}
                    </div>
                    <div class="icon-align mb-2" v-if="get(scholarship, 'work_commitment')">
                      <i class="fas fa-calendar-week icon-16pt mr-1"></i> <b class="mr-1 form-label">Work Commitment:</b
                      >{{ get(scholarship, 'work_commitment') }} Months
                    </div>
                    <div v-if="get(scholarship, 'pay_rate')" class="icon-align mb-2">
                      <i class="fas fa-clock icon-16pt mr-1"></i> <b class="mr-1 form-label">Pay Rate:</b
                      >{{ $t(`jobPayRateOpts.${get(scholarship, 'pay_rate')}`) }}
                    </div>

                    <div v-if="get(scholarship, 'schedule')" class="icon-align mb-2">
                      <i class="fas fa-clipboard-list icon-16pt mr-1"></i>
                      <b class="mr-1 form-label"> Schedule:</b
                      >{{ $t(`jobScheduleOpts.${get(scholarship, 'schedule')}`) }}
                    </div>
                  </b-skeleton-wrapper>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card" style="height: 95%">
              <div class="card-header bg-light d-flex">
                <div>
                  <h5 class="my-0">Student Information</h5>
                </div>
                <div class="ml-auto">
                  <i class="fas fa-user-edit icon-16pt mr-1"></i>
                  <router-link target="_blank" to="/student/my-profile">Edit Profile</router-link>
                </div>
              </div>
              <div class="card-body d-flex flex-column justify-content-center">
                <div>
                  <b-skeleton-wrapper :loading="isLoading">
                    <template #loading>
                      <p class="lead text-white-50">
                        <b-skeleton width="70%"></b-skeleton>
                      </p>
                      <p class="lead text-white-50">
                        <b-skeleton width="60%"></b-skeleton>
                      </p>
                      <p class="lead text-white-50">
                        <b-skeleton width="50%"></b-skeleton>
                      </p>
                      <p class="lead text-white-50">
                        <b-skeleton width="40%"></b-skeleton>
                      </p>
                    </template>

                    <div class="icon-align mb-2">
                      <i class="fas fa-user icon-16pt mr-1"></i><b class="mr-1 form-label">Name:</b
                      >{{ get(getLoggedInUser, 'first_name') }}
                      {{ get(getLoggedInUser, 'last_name') }}
                    </div>

                    <div class="icon-align mb-2">
                      <i class="fas fa-envelope icon-16pt mr-1"></i><b class="mr-1 form-label">Email:</b
                      >{{ get(getLoggedInUser, 'email') }}
                    </div>

                    <div class="icon-align">
                      <i class="fas fa-phone icon-16pt mr-1"></i><b class="mr-1 form-label">Phone:</b
                      >{{ get(getLoggedInUser, 'phone') }}
                    </div>
                  </b-skeleton-wrapper>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card" v-for="section in sections" :key="`sec-${section.id}`">
          <div class="card-header bg-light">
            <h5 class="my-0">{{ section.title }}</h5>
          </div>
          <div class="card-body">
            <b-form-group
              :label="ques.is_required ? `${get(ques, 'question.title')}*` : get(ques, 'question.title')"
              :label-for="`section-${section.id}-question-${ques.question.id}-${ques.id}`"
              label-class="form-label"
              v-slot="{ ariaDescribedby }"
              v-for="ques in section.questions"
              :key="`sec-que-${ques.id}`"
            >
              <b-form-radio-group
                v-if="ques.question.type === QUES_TYPES.BOOLEAN"
                :id="`section-${section.id}-question-${ques.question.id}-${ques.id}`"
                v-model="customSectionData[`section-${section.id}`][`question-${ques.question.id}-${ques.id}`]"
                :options="get(ques.question, 'extra.options', [])"
                :aria-describedby="ariaDescribedby"
                :state="
                  !get(
                    $v,
                    `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.required`,
                    true
                  ) &&
                  get(
                    $v,
                    `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.$dirty`,
                    false
                  )
                    ? false
                    : null
                "
                :required="ques.is_required"
              ></b-form-radio-group>

              <b-form-input
                :id="`section-${section.id}-question-${ques.question.id}-${ques.id}`"
                v-if="ques.question.type === QUES_TYPES.SHORT_TEXT"
                :placeholder="get(ques.question, 'placeholder', 'Please write your answer here.')"
                v-model="customSectionData[`section-${section.id}`][`question-${ques.question.id}-${ques.id}`]"
                :state="
                  !get(
                    $v,
                    `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.required`,
                    true
                  ) &&
                  get(
                    $v,
                    `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.$dirty`,
                    false
                  )
                    ? false
                    : null
                "
                :required="ques.is_required"
              />
              <b-form-textarea
                v-if="ques.question.type === QUES_TYPES.LONG_TEXT"
                :id="`section-${section.id}-question-${ques.question.id}-${ques.id}`"
                class="form-control"
                rows="10"
                :placeholder="get(ques.question, 'placeholder', 'Please write your answer here.')"
                v-model="customSectionData[`section-${section.id}`][`question-${ques.question.id}-${ques.id}`]"
                :state="
                  !get(
                    $v,
                    `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.required`,
                    true
                  ) &&
                  get(
                    $v,
                    `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.$dirty`,
                    false
                  )
                    ? false
                    : null
                "
                :required="ques.is_required"
              ></b-form-textarea>

              <b-media
                class="align-items-center"
                vertical-align="center"
                v-if="ques.question.type === QUES_TYPES.FILE_TYPE"
              >
                <b-form-file
                  :id="`section-${section.id}-question-${ques.question.id}-${ques.id}`"
                  :placeholder="get(ques.question, 'placeholder', 'Select File')"
                  :browse-text="$t('generalMsgs.browse')"
                  v-model="selectedQueUploadFile[`sec-${section.id}-que-${ques.question.id}`]"
                  @input="uploadFile('file_type', 'documents', '', section.id, ques.question.id)"
                  :disabled="isQueFileUploading[`sec-${section.id}-que-${ques.question.id}`]"
                  :required="ques.is_required && !selectedQueUploadFile[`sec-${section.id}-que-${ques.question.id}`]"
                />
              </b-media>

              <v-select
                v-if="ques.question.type === QUES_TYPES.SINGLE_SELECT"
                :id="`section-${section.id}-question-${ques.question.id}-${ques.id}`"
                class="form-control v-select-custom"
                label="text"
                item-text="text"
                v-model="customSectionData[`section-${section.id}`][`question-${ques.question.id}-${ques.id}`]"
                :reduce="(ins) => ins.value"
                :placeholder="get(ques.question, 'placeholder', 'Please write your answer here.')"
                :options="get(ques.question, 'extra.options', [])"
                :class="
                  !get(
                    $v,
                    `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.required`,
                    true
                  ) &&
                  get(
                    $v,
                    `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.$dirty`,
                    false
                  )
                    ? 'form-control is-invalid'
                    : ''
                "
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="
                      ques.is_required &&
                      !customSectionData[`section-${section.id}`][`question-${ques.question.id}-${ques.id}`]
                    "
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
                <template slot="option" slot-scope="option"> {{ option.text }} </template>
                <template slot="selected-option" slot-scope="option">
                  {{ option.text }}
                </template>
              </v-select>
              <v-select
                v-if="ques.question.type === QUES_TYPES.MULTI_SELECT"
                :id="`section-${section.id}question-${ques.question.id}`"
                class="form-control v-select-custom"
                label="text"
                item-text="text"
                v-model="customSectionData[`section-${section.id}`][`question-${ques.question.id}-${ques.id}`]"
                :reduce="(ins) => ins.value"
                :placeholder="get(ques.question, 'placeholder', 'Please write your answer here.')"
                :options="get(ques.question, 'extra.options', [])"
                :class="
                  !get(
                    $v,
                    `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.required`,
                    true
                  ) &&
                  get(
                    $v,
                    `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.$dirty`,
                    false
                  )
                    ? 'form-control is-invalid'
                    : ''
                "
                multiple
              >
                <template #search="{ attributes, events }">
                  <input
                    class="vs__search"
                    :required="
                      ques.is_required &&
                      !get(
                        customSectionData[`section-${section.id}`][`question-${ques.question.id}-${ques.id}`],
                        'length'
                      )
                    "
                    v-bind="attributes"
                    v-on="events"
                  />
                </template>
                <template slot="option" slot-scope="option"> {{ option.text }} </template>
                <template slot="selected-option" slot-scope="option">
                  {{ option.text }}
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="
                  !get(
                    $v,
                    `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.required`,
                    true
                  ) &&
                  get(
                    $v,
                    `customSectionData.section-${section.id}.question-${ques.question.id}-${ques.id}.$dirty`,
                    false
                  )
                    ? false
                    : null
                "
                >This field is required.</b-form-invalid-feedback
              >
            </b-form-group>
          </div>
        </div>

        <!-- Terms & Conditions Card -->
        <div class="card">
          <div class="card-header bg-light">
            <h5 class="my-0">Terms and Conditions</h5>
          </div>
          <div class="card-body">
            <b-form-group label-class="form-label">
              <b-form-checkbox v-model="termsAndConditions" name="check-button">
                <span style="font-weight: initial" class="text-black">
                  I understand and accept the
                  <a href="#" @click.prevent="showTermsModal = true" class="text-primary">terms and conditions</a>.
                </span>
              </b-form-checkbox>
            </b-form-group>

            <b-form-group label="Signature*" label-for="signature" label-class="form-label">
              <b-form-input
                id="signature"
                placeholder="Type Full Name"
                v-model="signature"
                :state="!$v.signature.required && $v.signature.$dirty ? false : null"
                maxlength="60"
              />

              <b-form-invalid-feedback v-if="!$v.signature.required">This field is required.</b-form-invalid-feedback>
            </b-form-group>

            <div class="text-dark" v-if="signature">Preview</div>
            <h1 class="signature-font">{{ signature }}</h1>
          </div>
        </div>

        <b-btn
          variant="primary"
          :disabled="isFormLoading || !termsAndConditions"
          style="width: 150px"
          type="submit"
          class="btn-normal float-right"
        >
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>Submit</span>
        </b-btn>
      </b-form>
    </div>
    <div v-if="isSubmit">
      <success-component :isSubmit="isSuccess" :sponsorship="scholarship.title" :isLoading="false" />
    </div>

    <app-terms-modal
      :show-modal="showTermsModal"
      @close="showTermsModal = false"
      :termsAndConditions="get(scholarship, 'terms_conditions')"
    ></app-terms-modal>
  </div>
</template>

<script>
import axios from 'axios';

import { truncate, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Page from '@/components/Page.vue';
import { required } from 'vuelidate/lib/validators';

import { QUES_TYPES } from '@/common/constants';
import { formatDateSimple, formatFullDate, computeAddress } from '@/common/utils';
import AppTermsModal from './AppTermsModal.vue';

export default {
  components: {
    SuccessComponent: () => import('./SuccessComponent'),
    AppTermsModal,
  },
  extends: Page,
  layout: 'blank',

  data() {
    return {
      isSubmit: false,
      isSuccess: false,
      isLoading: false,
      termsAndConditions: false,
      showTermsModal: false,
      school: null,
      signature: '',
      scholarship: {},
      isUploading: {},
      isQueFileUploading: {},
      selectedUploadFile: {},
      selectedQueUploadFile: {},
      uploadPercent: { avatar: 0, resume: 0, file_type: {} },
      uploadCancelTokenSource: {
        file_type: {},
      },

      sponsorship: {},
      file_type: null,
      areSectionsLoading: false,
      QUES_TYPES,
      sections: [],
      customSectionData: {},
    };
  },
  validations() {
    const val = {
      signature: { required },
      customSectionData: {},
    };

    return val;
  },
  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    ...mapGetters('auth', ['getLoggedInUser']),

    isFormLoading() {
      return (
        this.isLoading ||
        Object.values(this.isUploading).some((val) => val) ||
        Object.values(this.isQueFileUploading).some((val) => val)
      );
    },
  },

  methods: {
    ...mapActions('student', ['getStudentUploadPresignedUrl']),
    ...mapActions('applicationForm', ['getPublicRegistrationForm', 'CreateApplicationForm']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),

    ...mapActions('sections', ['getPublicSections']),

    ...mapActions('scholarships', ['getScholarship']),
    formatDateSimple,
    formatFullDate,
    computeAddress,
    get,
    truncate,
    goToHome() {
      this.$router.push({ name: 'home' });
    },

    async fetchSections(params = {}) {
      this.areSectionsLoading = true;
      try {
        const res = await this.getPublicSections({
          scholarship: this.$route.params.id,
          ...params,
        });

        const secData = {};
        const queSecUploadFile = {};
        const queFileUploading = {};

        for (const sectionObj of res.data) {
          const quesObj = sectionObj.questions.reduce((obj, item) => {
            if (item.question.type === QUES_TYPES.FILE_TYPE) {
              queSecUploadFile[`sec-${sectionObj.id}-que-${item.question.id}`] = null;
              queFileUploading[`sec-${sectionObj.id}-que-${item.question.id}`] = false;
            }

            return Object.assign(obj, {
              [`question-${item.question.id}-${item.id}`]: null,
            });
          }, {});
          secData[`section-${sectionObj.id}`] = quesObj;
        }

        this.selectedQueUploadFile = queSecUploadFile;
        this.isQueFileUploading = queFileUploading;

        this.customSectionData = secData;

        this.sections = res.data;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areSectionsLoading = false;
    },

    async fetchScholarship() {
      this.isLoading = true;
      try {
        const res = await this.getScholarship(this.$route.params.id);
        this.scholarship = res.data;

        this.title = this.scholarship.title;
      } catch (e) {
        this.$router.go(-1);
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },

    async onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isLoading = true;
        try {
          const sectionsArr = [];
          Object.entries(this.customSectionData).map((section) => {
            Object.entries(section[1]).map((question) => {
              sectionsArr.push({
                section_question: parseInt(question[0].split('-')[2]),

                response: { data: question[1] },
              });
            });
          });

          const data = {
            scholarship: this.scholarship.id,

            application_responses: sectionsArr,
            signature: this.signature,
          };

          await this.CreateApplicationForm({ ...data });

          this.isSubmit = true;
          this.isSuccess = true;
        } catch (err) {
          this.isSubmit = true;
          this.isSuccess = false;
        }
      } else {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },

    async uploadFile(
      fileType,
      uploadType = 'documents',
      profileUrlField = 'resume_url',
      section = null,
      question = null
    ) {
      let file;

      if (fileType === QUES_TYPES.FILE_TYPE) {
        this.uploadCancelTokenSource[fileType][`sec-${section}-que-${question}`] = axios.CancelToken.source();
        this.isQueFileUploading[`sec-${section}-que-${question}`] = true;
        file = this.selectedQueUploadFile[`sec-${section}-que-${question}`];
      } else {
        this.uploadCancelTokenSource[fileType] = axios.CancelToken.source();
        this.isUploading[fileType] = true;
        file = this.selectedUploadFile[fileType];
      }

      try {
        const urlResp = await this.getStudentUploadPresignedUrl({
          file_name: file.name,
          content_type: file.type,
          upload_type: uploadType,
        });

        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: file,
          config: {
            onUploadProgress: function (progressEvent) {
              const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              if (fileType === QUES_TYPES.FILE_TYPE) {
                this.uploadPercent[fileType][`sec-${section}-que-${question}`] = percentage;
              } else {
                this.uploadPercent[fileType] = percentage;
              }
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[fileType].token,
          },
        });
        if (fileType === 'avatar') {
          this.user.avatarUrl = urlResp.upload_url.split('?')[0];
        } else if (fileType === 'file_type') {
          this.customSectionData[`section-${section}`][`question-${question}`] = urlResp.upload_url.split('?')[0];
          this.file_type = urlResp.upload_url.split('?')[0];
        } else this.student[profileUrlField] = urlResp.upload_url.split('?')[0];
      } catch (error) {
        if (fileType === QUES_TYPES.FILE_TYPE) {
          this.selectedQueUploadFile[`sec-${section}-que-${question}`] = null;
        } else {
          this.selectedUploadFile[fileType] = null;
        }
      }
      if (fileType === QUES_TYPES.FILE_TYPE) {
        this.uploadCancelTokenSource[fileType][`sec-${section}-que-${question}`] = null;
        this.uploadPercent[fileType][`sec-${section}-que-${question}`] = 0;
        this.isQueFileUploading[`sec-${section}-que-${question}`] = false;
      } else {
        this.uploadCancelTokenSource[fileType] = null;
        this.uploadPercent[fileType] = 0;
        this.isUploading[fileType] = false;
      }
    },
  },
  async mounted() {
    this.fetchScholarship();

    if (this.$route.params.id) {
      this.fetchSections();
    }
  },
};
</script>

<style>
</style>
