<template>
  <div>
    <div style="padding-top: 5px; min-height: calc(100vh - 400px) !important" id="page-container">
      <div class="page-section border-bottom-2 jobs_container">
        <div :class="containerClass">
          <h2 class="text-center">
            Resource Hub

            <i
              class="material-icons icon-16pt clickable-item"
              v-b-popover.hover.top="`Access a list of resources provided by your school.`"
              >info</i
            >
          </h2>
          <page-separator title="" />
          <div class="row">
            <resource
              :class="!isMobileScreen ? 'mb-3' : ''"
              v-for="resource in featuredResources"
              :key="resource.id"
              :resource="resource"
            />
            <resource
              :class="!isMobileScreen ? 'mb-3' : ''"
              v-for="resource in staticResources"
              :key="resource.id"
              :resource="resource"
            />
            <resource
              :class="!isMobileScreen ? 'mb-3' : ''"
              v-for="resource in resources"
              :key="resource.id"
              :resource="resource"
            />
            <!-- <div class="col-lg-12">
              <div class="card border-1 border-left-3 border-left-primary text-center mb-lg-0">
                <div class="card-body">
                  <b-row no-gutters>
                    <b-col
                      md="5 mb-4"
                      class="rounded bg-secondary"
                      style="box-shadow: 8px 8px 2px 1px rgba(0, 0, 0, 0.2); opacity: 0.85"
                    >
                      <img
                        class="img-responsive"
                        src="@/assets/images/placeholders/1.png"
                        height="125"
                        style="marging-bottom: 100px !important"
                      />
                    </b-col>
                    <b-col md="7">
                      <h3>Sponsorships</h3>
                      <div>Interested in receiving additional funding? Find sponsorships.</div>
                      <router-link
                        class="btn btn-primary setup-guide-btn btn-normal mt-3"
                        style="width: 150px"
                        :to="{ name: 'student-scholarships-listing' }"
                        >Explore</router-link
                      >
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>

            <div
              v-if="get(getLoggedInUser, 'linked_entity.sch_payment_plan.title') === SCH_PAYMENT_PLANS.PAID_PLAN"
              class="col-lg-12 mt-3"
            >
              <div class="card border-1 border-left-3 border-left-primary text-center mb-lg-0">
                <div class="card-body">
                  <b-row no-gutters>
                    <b-col
                      md="5 mb-4"
                      class="rounded bg-secondary"
                      style="box-shadow: 8px 8px 2px 1px rgba(0, 0, 0, 0.2); opacity: 0.85"
                    >
                      <img class="img-responsive" src="@/assets/images/placeholders/2.png" height="125" />
                    </b-col>
                    <b-col md="7">
                      <h3>Discussion Forum</h3>
                      <div>Have questions? Get answers from our discussion forum.</div>
                      <router-link
                        class="btn btn-primary setup-guide-btn btn-normal mt-3"
                        style="width: 150px"
                        :to="{ name: 'student-questions' }"
                        >Ask Question</router-link
                      >
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>

            <div
              v-if="get(getLoggedInUser, 'linked_entity.sch_payment_plan.title') === SCH_PAYMENT_PLANS.PAID_PLAN"
              class="col-lg-12 mt-3"
            >
              <div class="card border-1 border-left-3 border-left-primary text-center mb-lg-0">
                <div class="card-body">
                  <b-row no-gutters>
                    <b-col
                      md="5 mb-4"
                      class="rounded bg-secondary"
                      style="box-shadow: 8px 8px 2px 1px rgba(0, 0, 0, 0.2); opacity: 0.85"
                    >
                      <img class="img-responsive" src="@/assets/images/placeholders/3.png" height="125" />
                    </b-col>
                    <b-col md="7">
                      <h3>Skills Simulation</h3>
                      <div>Ready to show off your skills? Practice skills using video simulation.</div>
                      <router-link
                        class="btn btn-primary setup-guide-btn btn-normal mt-3"
                        style="width: 150px"
                        :to="{ name: 'student-skill' }"
                        >Practice</router-link
                      >
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import PageHeader from '@/components/Ui/PageHeader.vue';
//import { mapActions } from 'vuex';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { get } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import Page from '@/components/Page.vue';
import { hexToRgb } from '../../../common/utils';
import { SCH_PAYMENT_PLANS } from '../../../common/constants';

export default {
  components: {
    Resource: () => import('./Resource.vue'),
    PageSeparator,
  },
  extends: Page,
  name: 'Community',
  data() {
    return {
      title: 'Community',

      totalSchools: 0,
      totalStudents: 0,
      totalEmployers: 0,

      isLoading: {
        totalSchools: false,
        totalStudents: false,
        totalEmployers: false,
      },
      resources: [],
      featuredResources: [],
      windowWidth: window.innerWidth,
      SCH_PAYMENT_PLANS,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Dashboard', active: true },
      ];
    },
    staticResources() {
      return [
        // {
        //   id: 'static-1',
        //   title: 'Sponsorships',
        //   image_url: '1.png',
        //   short_description: 'Interested in receiving additional funding? Find sponsorships.',
        //   description: '',
        //   is_featured: false,
        //   enbl_detail_pg: true,
        //   external_link: 'string',
        //   cta_btn_text: 'Explore',
        //   pageRoute: 'student-scholarships-listing',
        //   static: true,
        // },

        ...(get(this.getLoggedInUser, 'linked_entity.sch_payment_plan.title') === SCH_PAYMENT_PLANS.PAID_PLAN
          ? [
              {
                id: 'static-2',
                title: 'Discussion Forum',
                image_url: '2.png',
                short_description: 'Have questions? Get answers from our discussion forum.',
                description: '',
                is_featured: false,
                enbl_detail_pg: true,
                external_link: 'string',
                cta_btn_text: 'Ask Question',
                pageRoute: 'student-questions',
                static: true,
              },
              {
                id: 'static-3',
                title: 'Skills Simulation',
                image_url: '3.png',
                short_description: 'Ready to show off your skills? Practice skills using video simulation.',
                description: '',
                is_featured: false,
                enbl_detail_pg: true,
                external_link: 'string',
                cta_btn_text: 'Practice',
                pageRoute: 'student-skill',
                static: true,
              },
            ]
          : []),
      ];
    },
    isMobileScreen() {
      return this.windowWidth <= 600;
    },
    rgbPrimaryColor() {
      return hexToRgb('red', '#214e6f');
    },
  },

  methods: {
    ...mapActions('school', ['getAllResources']),

    get,
    async fetchResources() {
      try {
        const response = await this.getAllResources({});

        this.resources = response.data.filter((resource) => !resource.is_featured);
        this.featuredResources = response.data.filter((resource) => resource.is_featured);
      } catch {
        // TODO: Use if needed
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    this.fetchResources();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
