<template>
  <div :class="feedback.parent_feedback ? 'ml-auto w-75' : ''">
    <div class="card card-block card-stretch card-height mb-3" @mouseover="hover = true" @mouseleave="hover = false">
      <div class="card-body d-flex">
        <b-avatar
          v-if="feedback.feedback_by.first_name && feedback.feedback_by.last_name"
          variant="primary"
          size="40px"
          :text="`${feedback.feedback_by.first_name[0].toUpperCase()}${feedback.feedback_by.last_name[0].toUpperCase()}`"
        />
        <b-avatar variant="primary" size="50px" v-else />
        <div class="w-100 ml-3">
          <div class="d-flex flex-wrap">
            <strong
              >{{ feedback.feedback_by.first_name }} {{ feedback.feedback_by.last_name }}
              {{
                get(feedback, 'feedback_by.title')
                  ? feedback.feedback_by.title === USER_TITLES.OTHER
                    ? `(${get(feedback, 'feedback_by.other_title', 'Other')})`
                    : `(${userTitleOptions[get(feedback, 'feedback_by.title', 'other')].text})`
                  : ''
              }}
            </strong>

            <small class="ml-auto">
              {{ formatDateTime(feedback.created_at) }}
            </small>
          </div>
          <p>{{ feedback.text }}</p>
          <div class="mt-2 d-flex justify-content-end">
            <a href="#" @click.prevent="onReply(feedback)" class="d-flex align-items-center"
              ><i class="fas fa-reply mr-2"></i>Reply</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="mb-2" v-if="isReplying">
      <post-feedback
        :skill="skill"
        :is-parent="!!feedback.parent_feedback"
        :parent-feedback-id="feedback.parent_feedback || feedback.id"
        :reply-to-feedback="feedback.id"
        :reply-to-mention-name="replyToMentionName"
        @posted="onReplyPosted"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatDateTime } from '../../../../common/utils';
import { USER_ROLE_TYPES, USER_TITLES, USER_TITLE_OPTIONS } from '../../../../common/constants';
import { get, keyBy } from 'lodash';
import PostFeedback from './PostFeedback.vue';
export default {
  name: 'FeedbackListItem',
  components: {
    PostFeedback,
  },
  props: {
    feedback: Object,
    skill: Object,
  },

  data() {
    return {
      USER_TITLES,
      USER_ROLE_TYPES,

      isReplying: false,
      replyToMentionName: '',
    };
  },

  methods: {
    get,
    formatDateTime,
    onReply(feedback) {
      this.replyToMentionName = feedback.feedback_by.first_name;
      this.isReplying = !this.isReplying;
    },
    onReplyPosted() {
      this.isReplying = false;
      this.replyToMentionName = null;
    },
  },

  mounted() {},

  updated() {},

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    userTitleOptions() {
      return keyBy(USER_TITLE_OPTIONS, 'value');
    },
  },
};
</script>
