<template>
  <div id="page-container">
    <!-- <page-header :title="title" :container-class="containerClass" /> -->
    <div
      class="page-section"
      :class="getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL ? 'jobs_container_low_pad' : 'jobs_container'"
    >
      <div :class="containerClass">
        <div class="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt flex-wrap">
          <h2>Career Paths in Organizations</h2>

          <!-- <small class="text-muted text-headings text-uppercase mr-1 d-sm-block" v-if="!isMobSmallScreen"
              >Sort by</small
            > -->
          <!-- <b-dropdown variant="link text-black-70" right no-caret>
              <template #button-content>
                <span>{{ $t(`studentEmpSortOptions.${ordering}`) }}<i class="fas fa-sort ml-1"></i></span>
              </template>

              <b-dropdown-item :active="ordering === 'name'" @click="onSortChange({ sortBy: 'name' })"
                >Name</b-dropdown-item
              >
              <b-dropdown-item :active="ordering === 'company_type'" @click="onSortChange({ sortBy: 'company_type' })"
                >Industry</b-dropdown-item
              >
              <b-dropdown-item :active="ordering === 'city'" @click="onSortChange({ sortBy: 'city' })"
                >Location</b-dropdown-item
              >
            </b-dropdown> -->

          <!-- <b-form-group> -->
          <div class="col-lg d-flex flex-wrap justify-content-end">
            <div class="col-lg-4" :class="{ 'pl-0': isMobSmallScreen }">
              <v-select
                id="offered_programs"
                class="form-control v-select-custom"
                label="name"
                v-model="career"
                :reduce="(cr) => cr.id"
                placeholder="Search Careers..."
                :options="allCareers"
                :loading="areCareersLoading"
                @input="onSearch"
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" v-bind="attributes" v-on="events" />
                </template>
                <template slot="option" slot-scope="option">
                  <span>{{ option.name }}</span>
                </template>
              </v-select>
            </div>
            <!-- </b-form-group> -->
          </div>
        </div>

        <page-separator
          :title="
            !isLoading
              ? `showing ${totalOrgs ? `${pageFromCount}-${pageToCount}` : 0} of ${totalOrgs} organizations`
              : ''
          "
        />

        <div class="d-flex justify-content-center" v-if="!totalOrgs && !isLoading">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Organizations Found</h4>

              <p class="text-muted">Couldn't find any Organizations.</p>

              <!-- <a href="#" @click.prevent="" class="btn btn-primary mt-2"> Sample Button </a> -->
            </div>
          </div>
        </div>

        <b-skeleton-wrapper :loading="isLoading" v-else>
          <template #loading>
            <div class="row card-group-row mb-lg-12pt home-card">
              <div class="col-md-6 col-lg-6 card-group-row__col" v-for="item in [1, 2]" :key="item">
                <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card myBox">
                  <div class="card-header d-flex align-items-center">
                    <a href="#" @click.prevent class="card-title flex mr-12pt">
                      <b-skeleton width="100%"></b-skeleton
                    ></a>
                    <!-- <a href="#" @click.prevent class="" data-placement="bottom">
                      <b-skeleton width="100%"></b-skeleton
                    ></a> -->
                  </div>
                  <div class="card-body flex text-center d-flex flex-column justify-content-center">
                    <a
                      href="#"
                      @click.prevent
                      class="avatar avatar-xl overlay js-overlay overlay--primary rounded p-relative o-hidden mb-16pt align-self-center"
                      style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                    >
                      <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                    </a>
                    <div class="flex">
                      <p class="text-70 measure-paragraph">
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="55%"></b-skeleton>
                        <b-skeleton width="70%"></b-skeleton>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div class="row card-group-row mb-lg-12pt home-card">
            <div class="col-md-6 col-lg-6 card-group-row__col" v-for="org in organizations" :key="org.id">
              <div
                class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card myBox clickable-item"
                @click.prevent="openSite(org)"
              >
                <div class="card-header d-flex align-items-center">
                  <a href="#" @click.prevent class="card-title flex mr-12pt">
                    {{ org.name }}
                  </a>
                  <a href="#" @click.prevent class="" data-placement="bottom">{{ org.city }}, {{ org.state }}</a>
                </div>
                <div class="card-body flex text-center d-flex flex-column align-items-center justify-content-center">
                  <a
                    href="#"
                    @click.prevent
                    class="avatar avatar-xl overlay js-overlay overlay--primary rounded p-relative o-hidden mb-16pt"
                    style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                  >
                    <img
                      class="avatar-img rounded"
                      :src="org.logo_url || DefaultLogo"
                      width="160"
                      height="auto"
                      style="object-fit: contain !important"
                    />
                  </a>

                  <div class="flex">
                    <p class="text-70 measure-paragraph">
                      <span v-if="trim(org.short_bio)">{{
                        truncate(trim(org.short_bio), {
                          length: 240,
                          separator: /,? +/,
                        })
                      }}</span>
                      <i v-else>{{ org.name }} has not added any bio yet.</i>
                    </p>
                  </div>

                  <div class="card-button">
                    <ul>
                      <li v-for="career in org.offered_careers" :key="career.id">
                        <a href="#" @click.prevent
                          ><span class="material-icons icon-16pt text-100">check</span>{{ career.name }}</a
                        >
                      </li>
                    </ul>
                  </div>

                  <a
                    :href="getRedirectUrl(org)"
                    target="blank"
                    class="btn btn-dark"
                    :class="isMobSmallScreen ? 'w-100' : 'w-75'"
                    ><md-icon class="mr-1">open_in_new</md-icon> Take Assessment</a
                  >
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>

        <pagination
          v-if="totalOrgs > organizations.length"
          v-model="currentPage"
          :total-rows="totalOrgs"
          :per-page="perPage"
          @change="onPageChange"
          aria-controls="orgs-table"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, get, trim, truncate } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import DefaultLogo from '@/assets/images/logos/logo_icon.png';
import { USER_ROLE_TYPES } from '@/common/constants';
import { getClickableLink } from '@/common/utils';

export default {
  components: { Pagination, PageSeparator },
  extends: Page,

  data() {
    return {
      DefaultLogo,
      USER_ROLE_TYPES,

      title: 'Organizations',

      isLoading: true,
      partnerStatusesLoading: true,

      perPage: 14,
      currentPage: 1,
      totalOrgs: 0,
      allCareers: [],
      organizations: [],
      areCareersLoading: false,
      pageFromCount: 0,
      pageToCount: 0,
      searchTerm: '',
      career: '',
      ordering: 'name',

      windowWidth: window.innerWidth,
    };
  },

  computed: {
    ...mapGetters('employer', ['getEmployerFilters']),
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Organizations', active: true },
      ];
    },

    isMobSmallScreen() {
      return this.windowWidth <= 500;
    },
  },

  methods: {
    ...mapActions('organization', ['getAllOrganizations']),
    ...mapActions('careerPreference', ['getAllCareerPreferences']),
    get,
    trim,
    truncate,

    async fetchCareerPreferences() {
      this.areCareersLoading = true;

      const response = await this.getAllCareerPreferences({});
      this.allCareers = response.data;

      this.areCareersLoading = false;
    },
    async fetchOrgs(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('page-container').scrollIntoView();
      try {
        const response = await this.getAllOrganizations({
          limit: this.perPage,
          offset: (pageNum - 1) * this.perPage,
          ...(this.ordering && { ordering: this.ordering }),
          ...(this.searchTerm && { search: this.searchTerm }),
          ...(this.career && { offered_careers: this.career }),
          ...params,
        });
        this.organizations = response.data.results;
        this.currentPage = pageNum;
        this.totalOrgs = response.data.count;

        this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
        this.pageToCount = this.pageFromCount + get(response.data, 'results.length', 0) - 1;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchOrgs(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchOrgs();
    },

    onSearch() {
      this.debouncedSearchOrgs(this);
    },

    debouncedSearchOrgs: debounce((vm) => {
      vm.fetchOrgs();
    }, 500),

    openSite(org) {
      window.open(this.getRedirectUrl(org), '_blank');
    },

    getRedirectUrl(org) {
      return getClickableLink(`${org.organization_site.wbst_domain}/career-assessment`);
       
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    this.fetchCareerPreferences();
    this.fetchOrgs();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.card-button ul li a {
  padding: 5px 7px !important;
}
</style>
