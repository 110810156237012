var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"col-md-12 px-0 page-section pt-0",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('h3',{staticClass:"text-center mb-1"},[_vm._v("Nice pic! Now, let's learn more about your career preferences.")]),_c('p',{staticClass:"text-center mb-5"},[_vm._v(" Your preferences will help linking you to the career opportunities that are most relevant to you. ")]),_c('b-form-group',{attrs:{"label":"Desired Work Type","label-for":"desired_work_type","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",class:!_vm.$v.profile.desired_work_type.required && _vm.$v.profile.desired_work_type.$dirty ? 'form-control is-invalid' : '',attrs:{"id":"desired_work_type","label":"text","reduce":function (item) { return item.value; },"placeholder":"Select the type(s) of work you are looking for.","options":_vm.workTypeOptions,"multiple":""},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.profile.desired_work_type}},'input',attributes,false),events))]}}]),model:{value:(_vm.profile.desired_work_type),callback:function ($$v) {_vm.$set(_vm.profile, "desired_work_type", $$v)},expression:"profile.desired_work_type"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.profile.desired_work_type.required && _vm.$v.profile.desired_work_type.$dirty ? false : null}},[_vm._v("This field is required.")])],1),_c('b-form-group',{attrs:{"label":"Desired Work Time","label-for":"desired_work_time","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",class:!_vm.$v.profile.desired_work_time.required && _vm.$v.profile.desired_work_time.$dirty ? 'form-control is-invalid' : '',attrs:{"id":"desired_work_time","label":"text","reduce":function (item) { return item.value; },"placeholder":"Select the time(s) would you be able to work.","options":_vm.workTimeOptions,"multiple":""},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.profile.desired_work_time}},'input',attributes,false),events))]}}]),model:{value:(_vm.profile.desired_work_time),callback:function ($$v) {_vm.$set(_vm.profile, "desired_work_time", $$v)},expression:"profile.desired_work_time"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.profile.desired_work_time.required && _vm.$v.profile.desired_work_time.$dirty ? false : null}},[_vm._v("This field is required.")])],1),_c('b-form-group',{attrs:{"label":"Desired Work Days","label-for":"desired_work_day","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",class:!_vm.$v.profile.desired_work_day.required && _vm.$v.profile.desired_work_day.$dirty ? 'form-control is-invalid' : '',attrs:{"id":"desired_work_day","label":"text","reduce":function (item) { return item.value; },"placeholder":"Select the days(s) you would be able to work.","options":_vm.workDayOptions,"multiple":""},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.profile.desired_work_day}},'input',attributes,false),events))]}}]),model:{value:(_vm.profile.desired_work_day),callback:function ($$v) {_vm.$set(_vm.profile, "desired_work_day", $$v)},expression:"profile.desired_work_day"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.profile.desired_work_day.required && _vm.$v.profile.desired_work_day.$dirty ? false : null}},[_vm._v("This field is required.")])],1),_c('b-form-group',{attrs:{"label":"Preferable Work Travel","label-for":"work_travel","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",class:!_vm.$v.profile.work_travel.required && _vm.$v.profile.work_travel.$dirty ? 'form-control is-invalid' : '',attrs:{"id":"work_travel","label":"text","reduce":function (item) { return item.value; },"placeholder":"How much are you willing to travel for work?","options":_vm.workTravelOptions},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.profile.work_travel}},'input',attributes,false),events))]}}]),model:{value:(_vm.profile.work_travel),callback:function ($$v) {_vm.$set(_vm.profile, "work_travel", $$v)},expression:"profile.work_travel"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.profile.work_travel.required && _vm.$v.profile.work_travel.$dirty ? false : null}},[_vm._v("This field is required.")])],1),_c('b-form-group',{attrs:{"label":"Interested Facility Type(s)","label-for":"interested_facility_types","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",class:!_vm.$v.profile.interested_facility_types.required && _vm.$v.profile.interested_facility_types.$dirty
          ? 'form-control is-invalid'
          : '',attrs:{"id":"interested_facility_types","label":"text","reduce":function (item) { return item.value; },"placeholder":"Select the facility types you are interested in working at","options":_vm.facilityTypeOptions,"multiple":""},scopedSlots:_vm._u([{key:"search",fn:function(ref){
          var attributes = ref.attributes;
          var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.profile.interested_facility_types}},'input',attributes,false),events))]}}]),model:{value:(_vm.profile.interested_facility_types),callback:function ($$v) {_vm.$set(_vm.profile, "interested_facility_types", $$v)},expression:"profile.interested_facility_types"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.profile.interested_facility_types.required && _vm.$v.profile.interested_facility_types.$dirty ? false : null}},[_vm._v("This field is required.")])],1),_c('b-form-group',{attrs:{"label":"Language Preference(s)","label-for":"lang_preferences","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",class:!_vm.$v.profile.lang_preferences.required && _vm.$v.profile.lang_preferences.$dirty ? 'form-control is-invalid' : '',attrs:{"id":"lang_preferences","label":"name","reduce":function (item) { return item.value; },"placeholder":"Select Your Preferred Language(s)","options":_vm.langOptions,"multiple":""},scopedSlots:_vm._u([{key:"search",fn:function(ref){
          var attributes = ref.attributes;
          var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.profile.lang_preferences}},'input',attributes,false),events))]}}]),model:{value:(_vm.profile.lang_preferences),callback:function ($$v) {_vm.$set(_vm.profile, "lang_preferences", $$v)},expression:"profile.lang_preferences"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.profile.lang_preferences.required && _vm.$v.profile.lang_preferences.$dirty ? false : null}},[_vm._v("This field is required.")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }