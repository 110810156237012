<template>
  <b-modal
    content-class="simulation-modal-content-class"
    dialog-class="simulation-modal-dialog-class"
    modal-class="simulation-modal-class"
    body-class="d-flex flex-column align-items-center justify-content-center"
    ref="SimulationModal"
    hide-footer
    hide-header
    no-close-on-backdrop
    centered
    lazy
    @hide="hideModal"
    size="md"
  >
    <div class="d-flex flex-column align-items-center mt-2">
      <div class="text-center">
        <h4>{{ truncate(simulation.title) }}</h4>
      </div>
      <b-alert v-if="callError" class="my-2" show variant="danger">
        <div class="d-flex align-items-center">
          <div class="mr-2">
            <i class="material-icons " style="font-size: 25px">warning</i>
          </div>
          <div>
            Can't start simulation without mic access. Please allow microphone permission in browser settings.
          </div>
        </div>
      </b-alert>
      <div v-else-if="!isCalling">
        Connecting...
      </div>
      <div v-else-if="callEnded">
        Call Ended
      </div>
      <div v-else style="font-size: 17px;" :class="{ 'text-danger': callEnding }">
        <strong>
          {{ formatTime(time) }}
        </strong>
      </div>
      <div class="content-center">
        <div :class="{ pulse: pulse }" class="default-pulse">
          <img :src="getImage(get(simulation, 'agent.voice_id'))" alt="" />
        </div>
      </div>
      <b-btn class="mt-2" @click="hideModalManual" style="border-radius: 10px;" variant="danger" aspect-ratio="1/1">
        End Simulation<i class="ml-2 fas fa-phone-slash"></i>
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { get, truncate } from 'lodash';
import { RetellWebClient } from 'retell-client-js-sdk';
import { SIMULATION_VOICES_OPTIONS } from '@/common/constants';

export default {
  name: 'SimulationModal',

  props: {
    showModal: { type: Boolean, default: false },
    simulation: { type: Object, default: () => {} }
  },

  data() {
    return {
      isLoading: false,
      time: 0,
      isRunning: false,
      interval: null,
      callEnding: false,
      callEnded: false,
      pulse: false,
      webClient: new RetellWebClient(),
      isCalling: false,
      SIMULATION_VOICES_OPTIONS,
      callError: false
    };
  },
  validations() {
    return {};
  },
  methods: {
    ...mapActions('simulations', ['registerSimulation']),
    get,
    truncate,
    getImage(voice) {
      if (voice) {
        return require(`@/assets/images/avatars/${
          this.SIMULATION_VOICES_OPTIONS.find(item => item.value === voice).image
        }`);
      }
    },
    async hideModal() {
      this.$emit('close');
    },
    formatTime(seconds) {
      if (seconds > 300) {
        this.callEnded = true;
        setTimeout(() => this.hideModalManual(), 1000);
      }
      if (seconds > 270) {
        this.callEnding = true;
      }
      const time = new Date(seconds * 1000);
      return time
        .toTimeString()
        .split(' ')[0]
        .slice(3, time.length);
    },
    async hideModalManual() {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(`Are you sure you want to end simulation?`, {
        title: 'Are you sure?',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      });
      if (isConfirmed) {
        this.toggleConversation();
        this.$refs.SimulationModal.hide();
        this.hideModal();
      }
    },
    toggleTimer() {
      if (this.isRunning) {
        clearInterval(this.interval);
        this.time = 0;
      } else {
        this.interval = setInterval(this.incrementTime, 1000);
      }
      this.isRunning = !this.isRunning;
    },
    incrementTime() {
      this.time = parseInt(this.time) + 1;
    },
    async toggleConversation() {
      if (this.isCalling) {
        this.isCalling = false;
        this.webClient.stopConversation();
      } else {
        const response = await this.registerSimulation({ simulation_id: this.simulation.id });

        if (response.data.data.call_id) {
          this.webClient.startConversation({
            callId: response.data.data.call_id,
            sampleRate: response.data.data.sample_rate,
            enableUpdate: true
          });

          this.webClient.on('conversationStarted', () => {
            this.isCalling = true;
          });
        }
      }
      this.toggleTimer();
    }
  },
  watch: {
    showModal(value) {
      if (value) {
        this.$refs.SimulationModal.show();
        this.callEnding = false;
        this.callEnded = false;

        this.toggleConversation();
      }
    }
  },
  mounted() {
    this.webClient.on('audio', () => {
      this.pulse = true;
    });

    this.webClient.on('conversationEnded', ({ code }) => {
      // console.log('Closed with code:', code, ', reason:', reason);

      if (code == 1002 || code == 1000) {
        this.toggleConversation();
        this.$refs.SimulationModal.hide();
        this.hideModal();
      }
    });

    this.webClient.on('error', error => {
      if (error === `User didn't give microphone permission`) {
        this.callError = true;
      }
    });

    this.webClient.on('update', update => {
      if (update.transcript.slice(-1)[0].role === 'user') {
        this.pulse = false;
      } else {
        this.pulse = true;
      }
    });
  },
  created() {
    if (this.isCalling) {
      window.addEventListener('beforeunload', event => {
        event.preventDefault();
        event.returnValue = true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.content-center {
  padding: 100px;
}

.pulse i {
  color: #fff;
}

.default-pulse i {
  color: #fff;
}

.default-pulse {
  height: 100px;
  width: 100px;
  background-color: #214e6f;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pulse {
  height: 100px;
  width: 100px;
  background-color: #214e6f;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pulse::before {
  content: '';
  position: absolute;
  border: 1px solid #214e6f;
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  border-radius: 50%;
  animation: pulse 1s linear infinite;
}

.pulse::after {
  content: '';
  position: absolute;
  border: 1px solid #214e6f;
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  border-radius: 50%;
  animation: pulse 1s linear infinite;
  animation-delay: 0.3s;
  opacity: 0;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}
</style>
