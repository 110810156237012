<template>
  <div>
    <div :class="containerClass">
      <div class="back-result-lg mb-3 font-weight-bold">
        <a href="#" @click.prevent="$router.go(-1)" style="text-decoration: underline" class="mr-2">
          <span class="material-icons icon-32pt">navigate_before</span>
        </a>
      </div>
      <b-skeleton-wrapper :loading="skillsLoading" v-if="skillsLoading">
        <template #loading>
          <div class="d-flex justify-content-center">
            <b-skeleton-img width="500px" height="300px"></b-skeleton-img>
          </div>
        </template>
      </b-skeleton-wrapper>

      <div class="page-section d-flex justify-content-center" v-else-if="!allSkills.length">
        <div class="card card-block card-stretch card-height col-md-8">
          <div class="card-body text-center">
            <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
            <h4>No Skills Available</h4>

            <p class="text-muted">Please contact the school administrator for assistance.</p>
          </div>
        </div>
      </div>
      <div v-else class="card mb-0">
        <form-wizard
          ref="wizard"
          title=""
          subtitle=""
          shape="tab"
          :color="primaryColor"
          class="onboarding-form"
          @on-change="handleTabChange"
          @on-complete="onComplete"
        >
          <tab-content title="Select Skill" icon="fas fa-check" class="practice-skill-tab">
            <SkillSelectionForm
              ref="selection"
              @skillSelected="skillselection"
              :skillData="allSkills"
              :isLoading="skillsLoading"
              :selectedSkill="selectedSkill"
            />
          </tab-content>

          <tab-content title="Watch Demo" icon="fas fa-video" class="practice-skill-tab">
            <h3 class="text-center">
              Watch the video below to see how the skill is performed.
            </h3>
            <p class="text-center mb-5">
              After watching the video, select Next to record yourself performing the skill.
            </p>
            <div v-if="selectedSkill.sample_video_url" class="h-100 w-100">
              <video class="w-100" style="max-height: 500px;" controls :src="selectedSkill.sample_video_url" />
            </div>
          </tab-content>

          <tab-content title="Upload Your Video" icon="fas fa-upload" class="practice-skill-tab">
            <UploadStudentSkillForm ref="uploadForm" @videoUploading="loadStatus => (isVideoUploading = loadStatus)" />
          </tab-content>

          <template v-slot:next>
            <b-button
              type="submit"
              variant="dark"
              :disabled="isFormLoading"
              style="min-width: 150px; "
              :style="activeTabIndex === 0 ? 'display: none' : ''"
            >
              <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
              <span v-else>Next</span>
            </b-button>
          </template>
          <template v-slot:finish>
            <button
              class="btn btn-primary"
              type="primary"
              :disabled="isFormLoading"
              style="min-width: 100px; height: 2.3rem"
            >
              <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
              <span v-else>Post</span>
            </button>
          </template>

          <template #step="props" v-if="isFormLoading">
            <wizard-step :tab="props.tab" :transition="props.transition" :index="props.index" />
          </template>
        </form-wizard>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Page from '@/components/Page.vue';
import SkillSelectionForm from './SkillSelectionForm.vue';
import UploadStudentSkillForm from './UploadStudentSkillForm.vue';

export default {
  components: {
    UploadStudentSkillForm,
    SkillSelectionForm,
  },
  extends: Page,

  data() {
    return {
      title: 'Practice Skill',
      skill: {},
      isLoading: false,
      isVideoUploading: false,
      skillsLoading: true,
      allSkills: [],
      primaryColor: '#214e6f',
      selectedSkill: {},
      activeTabIndex: 0,
    };
  },

  validations() {
    return {
      skill: {},
    };
  },
  computed: {
    ...mapGetters('navbarConfig', ['getNavbarBrandLogo']),

    isFormLoading() {
      return this.isLoading || this.isVideoUploading;
    },
  },

  methods: {
    ...mapActions('skills', ['getSchSkillsConfigs', 'uploadStudentSkill']),

    async fetchSkills() {
      const response = await this.getSchSkillsConfigs();
      this.allSkills = response.data;
      this.skillsLoading = false;
    },
    resetForm() {
      this.skill = {};
      this.selectedSkill = {};
      if (this.$refs.uploadForm) {
        this.$refs.uploadForm.$v.$reset();
      }
    },

    setActiveTab() {
      if (this.$refs.wizard.activeTabIndex === 0) {
        this.resetForm();
      }
    },

    async onComplete() {
      const formData = this.$refs.uploadForm.validateFormData();

      if (!formData.isValid) {
        this.makeToast({ variant: 'danger', msg: 'Please fill the form correctly.' });
        return;
      } else {
        this.isLoading = true;
        const payload = {
          ...formData.skill,
          skill: this.selectedSkill.id,
        };

        try {
          await this.uploadStudentSkill(payload);
          this.$router.push({ name: 'my-skills' });
        } catch (error) {
          this.makeToast({ variant: 'danger', msg: 'Error in uploading skill' });
        }
        this.isLoading = false;
      }
    },
    skillselection(skill) {
      this.selectedSkill = skill;
      this.$refs.wizard.nextTab();
    },

    handleTabChange(prevTab, nextTab) {
      this.activeTabIndex = nextTab;
      if (nextTab === 0) {
        this.resetForm();
      }
    },
  },

  async mounted() {
    await this.fetchSkills();
  },
};
</script>

<style lang="scss" scoped>
.practice-skill-tab {
  margin-top: -30px;
}
</style>
