<template>
  <div>
    <page-header :title="title" :breadcrumb="breadcrumb" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <!-- TODO: Add page design here -->
        <page-separator title="" />
        <div class="row mb-32pt">
          <div class="col-lg-12 d-flex">
            <div class="flex" style="max-width: 100%">
              <div class="mb-0">
                <p class="text-90" style="font-size: 15px">
                  The following is a guide to help you build a resume that adequately communicates your skills and
                  experience as a Certified Nursing Assistant.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-32pt">
          <div class="col-lg-12 d-flex">
            <div class="flex" style="max-width: 100%">
              <div class="">
                <h5>1. resume format</h5>
                <p class="text-90" style="font-size: 15px">
                  There are three standard resume formats:
                  <a
                    href="https://resume.io/how-to-write-a-resume/chronological-resume-format"
                    target="_blank"
                    class="text-dark font-weight-bold text-underline"
                    >chronological</a
                  >,
                  <a
                    target="_blank"
                    href="https://resume.io/how-to-write-a-resume/functional-resume"
                    class="text-dark font-weight-bold text-underline"
                    >functional</a
                  >, and
                  <a
                    target="_blank"
                    href="https://www.easyresume.io/templates/combination-hybrid"
                    class="text-dark font-weight-bold text-underline"
                    >hybrid</a
                  >
                  (sometimes called a combination resume). For most job seekers, a hybrid resume format, which puts
                  equal emphasis on skills and work experience, is the best choice.
                  <a class="text-primary font-weight-bold" href="/resume_example.png" download>Download example</a> of a
                  hybrid resume.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-32pt">
          <div class="col-lg-12 d-flex">
            <div class="flex" style="max-width: 100%">
              <div class="">
                <h5>2. name and contact info</h5>
                <p class="text-90 mb-3" style="font-size: 15px">
                  The top of your resume should include the following information:
                </p>
                <ul>
                  <li>Name</li>
                  <li>Location (City, State, Zip Code)</li>
                  <li>Phone number</li>
                  <li>Email Address</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-8 d-flex justify-content-center">
            <div class="text-center d-flex justify-content-center">
              <img
                class="img-fluid border border-primary"
                src="@/assets/images/placeholders/name_contact.png"
                alt="image"
                width="70px"
              />
            </div>
          </div>
        </div>
        <div class="row mb-32pt">
          <div class="col-lg-12 d-flex">
            <div class="flex" style="max-width: 100%">
              <div class="">
                <h5>3. objective statement</h5>
                <p class="text-90" style="font-size: 15px">
                  A resume objective statement includes a title and 2-3 sentence career intent summary placed at the top
                  of your resume. Your statement should communicate to recruiters and hiring managers why you are a good
                  fit for the job.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-8 d-flex justify-content-center">
            <div class="text-center d-flex justify-content-center">
              <img
                class="img-fluid border border-primary"
                src="@/assets/images/placeholders/objective_statement.png"
                alt="image"
                width="70px"
              />
            </div>
          </div>
        </div>
        <div class="row mb-32pt">
          <div class="col-lg-12 d-flex">
            <div class="flex" style="max-width: 100%">
              <div class="">
                <h5>4. relevant skills</h5>
                <p class="text-90" style="font-size: 15px">
                  The skills section of your resume shows employers you have the abilities required to succeed in the
                  role. Often, employers pay special attention to the skills section of your resume to determine if you
                  should move on to the next step of the hiring process.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-8 d-flex justify-content-center">
            <div class="text-center d-flex justify-content-center">
              <img
                class="img-fluid border border-primary"
                src="@/assets/images/placeholders/relevant_skills.png"
                alt="image"
                width="70px"
              />
            </div>
          </div>
        </div>
        <div class="row mb-32pt">
          <div class="col-lg-12 d-flex">
            <div class="flex" style="max-width: 100%">
              <div class="">
                <h5>5. experiences</h5>
                <p class="text-90" style="font-size: 15px">
                  The work experience section is the heart of your resume. Employers look at this section closely to
                  determine whether your job history and prior accomplishments make you a promising candidate.
                </p>
                <p class="text-90" style="font-size: 15px">
                  List each job in reverse-chronological order. Each job should have its own subheading that includes
                  the following information:
                </p>
                <ul>
                  <li>Company Name</li>
                  <li>Company Location</li>
                  <li>Job title</li>
                  <li>Start and end dates</li>
                  <li>Job tasks</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-8 d-flex justify-content-center">
            <div class="text-center d-flex justify-content-center">
              <img
                class="img-fluid border border-primary"
                src="@/assets/images/placeholders/professional_experiences.png"
                alt="image"
                width="70px"
              />
            </div>
          </div>
        </div>
        <div class="row mb-32pt">
          <div class="col-lg-12 d-flex">
            <div class="flex" style="max-width: 100%">
              <div class="">
                <h5>6. education</h5>
                <p class="text-90" style="font-size: 14px">
                  As a <b>CNA candidate</b>, it’s common to include your education on your resume, especially since you
                  will be applying to a job that requires a certification.
                </p>
                <p class="text-90" style="font-size: 14px">
                  List each instutution in reverse-chronological order. Each institution should have its own subheading
                  that includes the following information:
                </p>
                <ul>
                  <li>Name of Institution</li>
                  <li>Degree or Certification Name</li>
                  <li>School Location</li>
                  <li>Start and end dates</li>
                  <li>Type of Institution</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-8 d-flex justify-content-center">
            <div class="text-center d-flex justify-content-center">
              <img
                class="img-fluid border border-primary"
                src="@/assets/images/placeholders/education.png"
                alt="image"
                width="70px"
              />
            </div>
          </div>
        </div>
        <div class="row mb-32pt">
          <div class="col-lg-12 d-flex">
            <div class="flex" style="max-width: 100%">
              <div class="">
                <h5>7. other</h5>
                <p class="text-90" style="font-size: 14px">
                  While not represented here, study abroad and volunteer experience, certifications, and relevant
                  coursework are other options to present on a resume.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-32pt">
          <div class="col-lg-12 d-flex">
            <div class="flex" style="max-width: 100%">
              <div class="alert alert-light mb-2 border border-primary" role="alert" style="border: ">
                <div class="d-flex flex-wrap text-center align-items-start">
                  <div class="flex" style="min-width: 180px">
                    <small class="text-black-100">
                      <p class="mr-1">
                        Are you a {{ get(getCurrentSchool, 'name', 'Transition') }} student? Begin crafting your
                        professional resume within minutes using our custom Resume Builder.
                      </p>
                      <b-btn :to="{ name: 'std-manage-resume' }" variant="primary">Build New Resume</b-btn>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { get } from 'lodash';
import PageHeader from '@/components/Ui/PageHeader.vue';
import Page from '@/components/Page.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  components: { PageHeader, PageSeparator },
  extends: Page,
  name: 'PublicResume',

  data() {
    return {
      title: 'Resume Guide',
    };
  },

  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: { name: 'sch-public-home' } },
        { text: 'Resume', active: true },
      ];
    },
  },

  methods: {
    ...mapActions('settings', ['setSettings']),
    get,
  },

  async mounted() {
    this.setSettings({ layout: { layout: 'fixed' } });
  },
};
</script>
