<template>
  <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
    <!-- <div> -->
    <b-media class="align-items-center" vertical-align="center">
      <h3 class="text-center">
        Record yourself performing the skill you selected.
      </h3>
      <p class="text-center mb-5">
        Please make sure to verbally explain each step in detail as you perform.
      </p>

      <div class="mt-3" v-if="!skill.skill_video_url">
        <div class="d-flex justify-content-center">
          <b-avatar size="8rem" rounded variant="dark" v-if="!isVideoUploading">
            <md-icon style="font-size: 60px;" >cloud_upload</md-icon>
          

          </b-avatar>
          <b-spinner style="width: 5rem; height: 5rem;color: #214E6F" label="Large Spinner" v-else></b-spinner>
        </div>

        <b-form-group label="" label-for="privacy" label-class="form-label" class="mt-3">
          <b-form-file
            id="video"
            placeholder="Upload a video file (max size: 25MB)"
            v-model="uploadedVidFile"
            @input="uploadVideo"
            :disabled="isVideoUploading"
            accept="video/mp4, video/webm"
            :state="!$v.skill.skill_video_url.required && $v.skill.skill_video_url.$dirty ? false : null"
          />
          <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
        </b-form-group>
      </div>

      <div v-else>
        <div class="d-flex justify-content-end mb-3">
          <b-btn style="width: 80px" variant="danger" @click.prevent="removeVideo()"> Remove </b-btn>
        </div>
        <video id="video" class="w-100" style="height: 500px;" :src="skill.skill_video_url" controls></video>
      </div>

      <b-form-group label="Privacy" label-for="privacy" label-class="form-label mt-2">
        <div class="custom-select-icon">
          <b-form-select
            class="custom-select-icon__select"
            v-model="skill.skill_video_access"
            :options="options"
            required
            :state="!$v.skill.skill_video_access.required && $v.skill.skill_video_access.$dirty ? false : null"
          >
          </b-form-select>
          <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
          <span class="material-icons custom-select-icon__icon">sort</span>
        </div>
      </b-form-group>
    </b-media>
    <!-- </div> -->
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

export default {
  components: {},

  data() {
    return {
      selectedSkill: '',
      isUploading: false,
      skill: {
        skill_video_access: null,
        skill_video_url: null,
      },
      uploadedVidFile: null,
      isVideoUploading: false,
      options: [
        { value: null, text: 'Please Select' },
        { value: 'public', text: 'Public' },
        { value: 'private', text: 'Private' },
      ],
    };
  },

  validations() {
    return {
      skill: {
        skill_video_url: { required },
        skill_video_access: { required },
      },
    };
  },

  computed: {},

  watch: {},

  methods: {
    ...mapActions('school', ['getSchoolImageUploadPresignedUrl']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    showSkillDetails(skill) {
      this.selectedSkill = skill;
      this.$emit('selected-skill', this.selectedSkill);
    },

    removeVideo() {
      this.skill.skill_video_url = '';
      this.uploadedVidFile = null;
    },

    async uploadVideo() {
      this.isVideoUploading = true;
      this.$emit('videoUploading', true);

      // check file size and type from this.uploadedVidFile
      if (this.uploadedVidFile.size > 25000000) {
        this.makeToast({ variant: 'danger', msg: 'File size should not exceed 25MB' });
        this.removeVideo();
        this.isVideoUploading = false;
        this.$emit('videoUploading', false);
        return;
      }
      if (!['video/mp4', 'video/webm'].includes(this.uploadedVidFile.type)) {
        this.makeToast({ variant: 'danger', msg: 'File type should be mp4 or webm' });
        this.removeVideo();
        this.isVideoUploading = false;
        this.$emit('videoUploading', false);
        return;
      }

      try {
        const urlResp = await this.getSchoolImageUploadPresignedUrl({
          file_name: this.uploadedVidFile.name,
          content_type: this.uploadedVidFile.type,
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadedVidFile,
        });
        this.skill.skill_video_url = urlResp.upload_url.split('?')[0];
        this.isThumbUploading = true;
      } catch (error) {
        this.uploadedVidFile = null;
      }
      this.isVideoUploading = false;
      this.$emit('videoUploading', false);
    },

    validateFormData() {
      this.$v.$touch();
      return {
        isValid: !this.$v.$invalid,
        skill: this.skill,
      };
    },
  },

  mounted() {},
};
</script>
