<template>
  <div>
    <!-- <page-header :title="title" :container-class="containerClass" /> -->
    <div class="pt-32pt">
      <div class="d-flex flex-column align-items-center text-center text-sm-left container page__container">
        <b-img :src="getNavbarBrandLogo || '/images/logos/logo_blue.png'" height="40" alt="Transition" />
        <!-- <div class="flex d-flex flex-column flex-sm-row align-items-center">
          <div class="mb-24pt mb-sm-0 mr-sm-24pt">
            <h1 class="mb-0 h2">Welcome</h1>
          </div>
        </div>
        <span class="breadcrumb mb-0"
          >Welcome! We’re excited to help you navigate your career. Let's setup your account details!</span
        > -->
      </div>
    </div>
    <div class="page-section border-bottom-2">
      <div :class="containerClass">
        <!-- <div class="card mb-0"></div> -->

        <div class="card mb-0">
          <!-- <div class="card-header"></div> -->
          <form-wizard
            title=""
            subtitle=""
            @on-complete="onComplete"
            shape="tab"
            :color="primaryColor"
            ref="invoiceForm"
            class="onboarding-form"
          >
            <tab-content title="" icon="fas fa-info-circle" :before-change="() => onSubmit('aboutForm')">
              <about-std-ob-form
                :user-data="user"
                :profile-data="profile"
                :school-name="school.name"
                :allPrograms="allPrograms"
                :allTags="allTags"
                :areTagsLoading="areTagsLoading"
                :areProgramsLoading="areProgramsLoading"
                @isUploading="onUploading"
                @fetchTags="fetchTags"
                ref="aboutForm"
              ></about-std-ob-form>
              <!-- <basic-user-info-form :user-data="user" @isUploading="onUploading" ref="aboutForm" /> -->
            </tab-content>

            <tab-content title="" icon="fas fa-calendar-day" :before-change="() => onSubmit('gradDateForm')">
              <grad-date-form
                :profile-data="profile"
                :school-name="school.name"
                :program-name="selectedProgramName"
                ref="gradDateForm"
              />
            </tab-content>

            <tab-content title="" icon="fas fa-calendar-day" :before-change="() => onSubmit('highSchoolDiplomaForm')">
              <HighSchoolDiplomaForm :profile-data="profile" :school-name="school.name" ref="highSchoolDiplomaForm" />
              <!-- :program-name="selectedProgramName" -->
            </tab-content>

            <tab-content title="" :before-change="() => onSubmit('avatarForm')">
              <avatar-form :user-data="user" @isUploading="onUploading" ref="avatarForm" />
            </tab-content>

            <!-- PIIs removed from onboarding for easier flow. -->
            <!-- <tab-content title="" icon="fas fa-user" :before-change="() => onSubmit('personalForm')">
              <personal-id-form
                :pii-data="piiTypes"
                :expires-data="expiresAt"
                :types-config="piiTypesConfig"
                @isUploading="onUploading"
                ref="personalForm"
              ></personal-id-form>
            </tab-content> -->

            <tab-content title="" icon="fas fa-sliders-h" :before-change="() => onSubmit('preferencesForm')">
              <preferences-std-ob-form
                :profile-data="profile"
                ref="preferencesForm"
                @areCareerPrefsLoading="val => (areCareerPrefsLoading = val)"
              ></preferences-std-ob-form>
            </tab-content>

            <tab-content title="" icon="fas fa-hand-holding-medical" :before-change="() => onSubmit('experienceForm')">
              <experience-std-ob-form
                :profile-data="profile"
                @isUploading="onUploading"
                ref="experienceForm"
              ></experience-std-ob-form>
            </tab-content>

            <template v-slot:prev>
              <button class="btn btn-light" :disabled="isFormLoading">Back</button>
            </template>

            <template v-slot:next>
              <b-button type="submit" variant="dark" :disabled="isFormLoading" style="min-width: 150px">
                <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
                <span v-else>Next</span>
              </b-button>
            </template>

            <!-- <template v-slot:custom-buttons-right="props">
              <button
                class="btn btn-secondary mr-3"
                :disabled="isFormLoading"
                style="min-width: 130px"
                v-if="!$route.params.id || props.activeTabIndex === 0"
                @click.prevent="onSubmit(null, true)"
              >
                <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
                <span v-else> Extra Btn </span>
              </button>
            </template> -->

            <template v-slot:finish>
              <button
                class="btn btn-primary"
                type="primary"
                :disabled="isFormLoading"
                style="min-width: 100px; height: 2.3rem"
              >
                <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
                <span v-else>Finish</span>
              </button>
            </template>

            <template #step="props" v-if="isFormLoading">
              <wizard-step :tab="props.tab" :transition="props.transition" :index="props.index" />
            </template>
          </form-wizard>
          <!-- <div class="card-footer"></div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';
import { mapActions, mapGetters } from 'vuex';
import { find, pick, get } from 'lodash';
import moment from 'moment';
import Page from '@/components/Page.vue';
import AboutStdObForm from './components/AboutStdObForm.vue';
import PreferencesStdObForm from './components/PreferencesStdObForm.vue';
import ExperienceStdObForm from './components/ExperienceStdObForm.vue';
import GradDateForm from './components/GradDateForm.vue';
import HighSchoolDiplomaForm from './components/HighSchoolDiplomaForm.vue';
import AvatarForm from './components/AvatarForm.vue';
import { formatToAPIDate } from '../../../common/utils';
import { DASHBOARD_TYPES } from '@/common/constants';

export default {
  components: {
    AboutStdObForm,
    AvatarForm,
    GradDateForm,
    PreferencesStdObForm,
    ExperienceStdObForm,
    HighSchoolDiplomaForm,
  },
  extends: Page,

  data() {
    return {
      title: 'Welcome',

      profile: {
        grad_date: null,
        cna_license_no: '',
        work_experience: '',
        curr_employer: '',
        quote: '',
        start_date: null,
        cma_license_no: '',
        desired_work_type: [],
        desired_work_time: [],
        desired_work_day: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
        work_travel: '',
        career_preferences: [],
        lang_preferences: [],

        resume_url: '',
        program_type: '',
        address: '',
        zipcode: '',
        city: '',
        state: '',
        country: 'United States',
        selected_tag: null,
        ged_or_diploma: null,
        diploma_date: '',
      },
      student_piis: [],
      piiTypes: { tb_test: '', covid_vacc: '', social_sec_card: '', state_id: '', cpr: '' },
      expiresAt: { tb_test: null, covid_vacc: null, social_sec_card: null, state_id: null, cpr: null },
      user: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        repeatPassword: '',
        phone: '',
        bio: '',
        avatar_url: '',
        gender: '',
      },
      school: {
        name: '',
      },
      primaryColor: '#214e6f',
      piiTypesConfig: { covid_vacc: null, cpr: null, tb_test: null, social_sec_card: null, state_id: null },

      isConfigPiiLoading: false,
      isLoading: false,
      areCareerPrefsLoading: false,
      isUploading: { avatar: false },
      selectedUploadFile: { avatar: null },
      uploadPercent: { avatar: 0 },
      uploadCancelTokenSource: { avatar: null },
      fileSizeExceed: { avatar: false },
      invalidFileType: { avatar: false },
      errorStates: { password: null },

      fieldTypes: { password: 'text', repeatPassword: 'text' },
      areProgramsLoading: false,
      allPrograms: [],
      allTags: [],
      areTagsLoading: false,
      selectedProgramName: '',
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser', 'getLoggedInUserDashboard']),
    ...mapGetters('navbarConfig', ['getNavbarBrandLogo']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Welcome', active: true },
      ];
    },
    isFormLoading() {
      return this.isLoading || this.areCareerPrefsLoading || Object.values(this.isUploading).some(val => val);
    },
  },

  methods: {
    ...mapActions('student', ['getMyStudentProfile', 'updateMyStudentProfile']),
    ...mapActions('school', ['getSchoolConfigPiis']),
    ...mapActions('attendance', ['getTags']),
    ...mapActions('program', ['getAllPublicPrograms']),

    async fetchSclConfigPiis() {
      this.isConfigPiiLoading = true;
      try {
        const res = await this.getSchoolConfigPiis();
        res.data.forEach(pii => {
          this.piiTypesConfig[pii.type] = pii.status;
        });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isConfigPiiLoading = false;
    },
    async fetchPrograms() {
      this.areProgramsLoading = true;
      const response = await this.getAllPublicPrograms({
        ordering: 'id',
        ...(this.getLoggedInUserDashboard === DASHBOARD_TYPES.SCHOOL && {
          school: get(this.getLoggedInUser, 'linked_entity.id'),
        }),
      });
      this.allPrograms = response.data;
      this.areProgramsLoading = false;
    },
    async fetchTags(program) {
      this.areTagsLoading = true;
      const response = await this.getTags({
        program__id: program,
      });
      this.allTags = response.data.data;
      this.areTagsLoading = false;
    },
    async fetchStudentProfile() {
      this.isLoading = true;
      const resp = await this.getMyStudentProfile();
      this.profile = {
        ...pick(resp, [
          'cna_license_no',
          'work_experience',
          'curr_employer',
          'quote',
          'desired_work_type',
          'desired_work_time',
          'desired_work_day',
          'work_travel',
          'interested_facility_types',
          'career_preferences',
          'lang_preferences',
          'resume_url',
          'covid_url',
          'address',
          'zipcode',
          'city',
          'state',
          // 'country',
          'start_date',
          'cma_license_no',
          'prog_weeks',
          'program_type',
          'selected_tag',
        ]),
        country: 'United States',
        enrolled_program: get(resp, 'enrolled_program.id', null),
        selected_tag: get(resp, 'selected_tag.id', null),
        grad_date: resp.grad_date ? new Date(resp.grad_date) : null,
        ged_or_diploma: resp.ged_or_diploma,
        diploma_date: resp.diploma_date ? new Date(resp.diploma_date) : null,
      };
      this.student_piis = resp.student_piis;
      this.student_piis.forEach(pii => {
        this.piiTypes[pii.type] = pii.document_url;
        this.expiresAt[pii.type] = pii.expire_at;
      });

      this.user = pick(resp.user, ['first_name', 'last_name', 'phone', 'bio', 'avatar_url', 'gender']);

      if (this.getLoggedInUserDashboard === DASHBOARD_TYPES.SCHOOL) {
        this.fetchTags(get(resp, 'enrolled_program.id', null));
        this.school = resp.school;
      }
      this.isLoading = false;
    },

    onUploading(uploadingStatus) {
      this.isUploading = { ...this.isUploading, ...uploadingStatus };
    },

    onSubmit(formRef) {
      let dataValidation = { isValid: false, formData: {} };
      if (this.$refs[formRef]) {
        dataValidation = this.$refs[formRef].validateFormData();

        if (formRef === 'aboutForm') {
          this.user = { ...this.user, ...dataValidation.formData.user };
          this.profile = { ...this.profile, ...dataValidation.formData.profile };

          const program = find(this.allPrograms, prg => this.profile.enrolled_program == prg.id);
          this.selectedProgramName = get(program, 'title', '');
        } else if (formRef === 'gradDateForm') {
          this.profile = { ...this.profile, ...dataValidation.formData.profile };
        } else if (formRef === 'highSchoolDiplomaForm') {
          this.profile = { ...this.profile, ...dataValidation.formData.profile };
        } else if (formRef === 'personalForm') {
          this.piiTypes = { ...this.piiTypes, ...dataValidation.formData.pii };
          this.expiresAt = { ...this.expiresAt, ...dataValidation.formData.expire };
        } else if (formRef === 'avatarForm') {
          this.user = { ...this.user, ...dataValidation.formData.user };
        } else {
          this.profile = { ...this.profile, ...dataValidation.formData };
        }
      }
      if (!dataValidation.isValid) {
        this.makeToast({ variant: 'danger', msg: 'Please fill the form correctly.' });
      }
      return dataValidation.isValid;
    },

    async onComplete() {
      this.isLoading = true;
      try {
        let piisArr = [];

        // PIIs removed from onboarding for easier flow.
        // for (const item in this.piiTypes) {
        //   if (this.piiTypes[item]) {
        //     piisArr.push({ type: item, document_url: this.piiTypes[item], expire_at: this.expiresAt[item] });
        //   }
        // }

        this.profile.grad_date = this.profile.grad_date ? formatToAPIDate(moment(this.profile.grad_date)) : null;
        this.profile.diploma_date = this.profile.diploma_date
          ? formatToAPIDate(moment(this.profile.diploma_date))
          : null;
        this.profile.start_date = this.profile.start_date ? formatToAPIDate(moment(this.profile.start_date)) : null;

        await this.updateMyStudentProfile({
          data: { ...this.profile, student_piis: piisArr, user: this.user },
        });
        this.makeToast({ variant: 'success', msg: 'Profile Successfully Updated' });

        setTimeout(() => this.$router.push({ name: 'student-home' }), 250);
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    this.fetchStudentProfile();
    if (this.getLoggedInUserDashboard === DASHBOARD_TYPES.SCHOOL) {
      this.fetchSclConfigPiis();
    }
    this.fetchPrograms();
  },
};
</script>
