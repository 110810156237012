<template>
  <b-modal ref="resumeEducationModal" hide-footer :title="modalTitle" centered lazy size="lg" @hide="hideModal">
    <b-form @submit.prevent="onSave">
      <b-form-group label="Type / Degree" label-for="type" label-class="form-label">
        <div class="custom-select-icon">
          <b-form-select
            class="custom-select-icon__select"
            v-model="educationData.type"
            :options="typeOptions"
            required
          >
          </b-form-select>
          <span class="material-icons custom-select-icon__icon">sort</span>
        </div>
      </b-form-group>

      <b-form-group label="Study" label-for="study" label-class="form-label">
        <b-form-input id="study" placeholder="Enter the study name." v-model="educationData.study" required />
      </b-form-group>

      <b-form-group label="Institution" label-for="institution" label-class="form-label">
        <b-form-input
          id="institution"
          placeholder="Enter institution's name."
          v-model="educationData.institution"
          required
        />
      </b-form-group>

      <div class="row">
        <b-form-group label="City" label-for="city" label-class="form-label" class="col-md-6">
          <b-form-input id="city" placeholder="Enter city." v-model="educationData.city" required />
        </b-form-group>

        <b-form-group label="State" label-for="state" label-class="form-label" class="col-md-6">
          <b-form-input id="state" placeholder="Enter state." v-model="educationData.state" required />
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group label="Start Date" label-for="start_date" label-class="form-label" class="col-md-6">
          <b-form-datepicker
            v-model="educationData.start_date"
            :max="maxDateAllowed"
            required
            :state="!$v.educationData.start_date.required && $v.educationData.start_date.$dirty ? false : null"
          ></b-form-datepicker>
          <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="End Date" label-for="end_date" label-class="form-label" class="col-md-6">
          <b-form-datepicker
            v-model="educationData.end_date"
            :disabled="educationData.still_attending"
            :max="maxDateAllowed"
            required
            :state="
              (!$v.educationData.end_date.required || !$v.educationData.end_date.isCorrectFormat) &&
              $v.educationData.end_date.$dirty
                ? false
                : null
            "
          ></b-form-datepicker>
          <b-form-checkbox
            id="still_attending"
            v-model="educationData.still_attending"
            name="still_attending"
            class="mt-1"
          >
            I'm currently attending.
          </b-form-checkbox>
          <b-form-invalid-feedback v-if="!$v.educationData.end_date.required"
            >This field is required.</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-else>End date must be greater than the start date.</b-form-invalid-feedback>
        </b-form-group>
      </div>

      <b-btn variant="primary" style="width: 200px" type="submit" class="btn-normal">
        {{ education ? 'Update' : 'Add' }} Education
      </b-btn>
    </b-form>
  </b-modal>
</template>

<script>
import { get, pick } from 'lodash';
import moment from 'moment';
import { required, requiredIf } from 'vuelidate/lib/validators';

import DefaultImg from '@/assets/images/placeholders/cna_career.jpg';
import { RESUME_EDUCATION_TYPE_OPTIONS } from '../../../../common/constants';

export default {
  name: 'ResumeEducationModal',

  components: {},

  props: {
    showModal: { type: Boolean, default: false },
    education: { type: Object || null },
    itemIndex: { type: Number || null },
  },

  data() {
    return {
      DefaultImg,

      educationData: {
        type: null,
        study: '',
        institution: '',
        city: '',
        state: '',
        start_date: '',
        end_date: '',
        still_attending: false,
      },

      modalTitle: '',
      maxDateAllowed: undefined,
      typeOptions: [{ value: null, text: 'Select education type', disabled: true }, ...RESUME_EDUCATION_TYPE_OPTIONS],
    };
  },

  validations() {
    return {
      educationData: {
        type: { required },
        study: { required },
        institution: { required },
        city: { required },
        state: { required },
        start_date: { required },
        end_date: {
          required: requiredIf(() => !this.educationData.still_attending),
          isCorrectFormat(value) {
            return (
              this.educationData.still_attending ||
              !this.educationData.start_date ||
              !this.educationData.end_date ||
              moment(this.educationData.start_date).isBefore(moment(value))
            );
          },
        },
        still_attending: {},
      },
    };
  },

  computed: {},

  watch: {
    showModal(value) {
      if (value) {
        this.modalTitle = this.education ? 'Edit Education' : 'Add Education';
        this.maxDateAllowed = new Date();
        if (this.education) {
          this.educationData = pick(this.education, [
            'type',
            'study',
            'institution',
            'city',
            'state',
            'start_date',
            'end_date',
            'still_attending',
          ]);
        } else {
          this.educationData = {
            type: null,
            study: '',
            institution: '',
            city: '',
            state: '',
            start_date: '',
            end_date: '',
            still_attending: false,
          };
        }
        this.$v.$reset();
        this.$refs.resumeEducationModal.show();
      }
    },
  },

  methods: {
    get,

    hideModal() {
      this.$emit('close');
    },

    hideUpdateModal(data) {
      this.$refs.resumeEducationModal.hide();
      this.$emit('closeUpdate', data);
    },

    hideModalManual() {
      this.$refs.resumeEducationModal.hide();
      this.hideModal();
    },

    onSave() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.hideUpdateModal({
          education: {
            ...this.educationData,
            end_date: this.educationData.still_attending ? null : this.educationData.end_date,
          },
          index: this.itemIndex,
          isUpdate: !!this.education,
        });
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
    },
  },

  async mounted() {},
};
</script>
