<template>
  <div id="page-container">
    <!-- <page-header :title="title" :container-class="containerClass" /> -->
    <div
      class="page-section"
      :class="getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL ? 'jobs_container_low_pad' : 'jobs_container'"
    >
      <div :class="containerClass">
        <div class="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt" style="white-space: nowrap">
          <h2 class="mb-0 d-sm-block" v-if="!isMobSmallScreen">
            Companies

            <i
              class="material-icons icon-16pt clickable-item"
              v-b-popover.hover.top="`Access a directory of employers.`"
              >info</i
            >
          </h2>
          <div class="w-auto ml-sm-auto table d-flex align-items-center mb-sm-0">
            <small class="text-muted text-headings text-uppercase mr-1 d-sm-block" v-if="!isMobSmallScreen"
              >Sort by</small
            >
            <b-dropdown variant="link text-black-70" right no-caret>
              <template #button-content>
                <span>{{ $t(`studentEmpSortOptions.${ordering}`) }}<i class="fas fa-sort ml-1"></i></span>
              </template>

              <b-dropdown-item :active="ordering === 'name'" @click="onSortChange({ sortBy: 'name' })"
                >Name</b-dropdown-item
              >
              <b-dropdown-item :active="ordering === 'company_type'" @click="onSortChange({ sortBy: 'company_type' })"
                >Industry</b-dropdown-item
              >
              <b-dropdown-item :active="ordering === 'city'" @click="onSortChange({ sortBy: 'city' })"
                >Location</b-dropdown-item
              >
              <!-- <b-dropdown-item :active="ordering === 'best_match'">Best Match</b-dropdown-item> -->
            </b-dropdown>

            <b-btn
              v-if="enableSidebarToggle !== false"
              v-fmv-toggle.employer-filters
              size="sm"
              variant="white"
              class="mr-2"
            >
              <md-icon left>tune</md-icon>
              <span>Filters</span>
            </b-btn>

            <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
              <b-form-input class="w-lg-auto" placeholder="Search..." v-model="searchTerm" @input="onSearch" />
              <b-btn variant="flush" type="submit">
                <md-icon v-text="'search'" />
              </b-btn>
            </form>
          </div>
        </div>

        <page-separator
          :title="
            !isLoading
              ? `showing ${totalEmployers ? `${pageFromCount}-${pageToCount}` : 0} of ${totalEmployers} companies`
              : ''
          "
        />

        <div class="d-flex justify-content-center" v-if="!totalEmployers && !isLoading">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Employers Found</h4>

              <p class="text-muted">
                Couldn't find any employers. We are constantly onboarding new employers so please check again after a
                while.
              </p>

              <!-- <a href="#" @click.prevent="" class="btn btn-primary mt-2"> Sample Button </a> -->
            </div>
          </div>
        </div>

        <b-skeleton-wrapper :loading="isLoading" v-else>
          <template #loading>
            <div class="row card-group-row mb-lg-12pt home-card">
              <div class="col-md-6 col-lg-6 card-group-row__col" v-for="item in [1, 2]" :key="item">
                <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card myBox">
                  <div class="card-header d-flex align-items-center">
                    <a href="#" @click.prevent class="card-title flex mr-12pt">
                      <b-skeleton width="100%"></b-skeleton
                    ></a>
                    <!-- <a href="#" @click.prevent class="" data-placement="bottom">
                      <b-skeleton width="100%"></b-skeleton
                    ></a> -->
                  </div>
                  <div class="card-body flex text-center d-flex flex-column justify-content-center">
                    <a
                      href="#"
                      @click.prevent
                      class="avatar avatar-xl overlay js-overlay overlay--primary rounded p-relative o-hidden mb-16pt align-self-center"
                      style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                    >
                      <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                    </a>
                    <div class="flex">
                      <p class="text-70 measure-paragraph">
                        <b-skeleton width="100%"></b-skeleton>
                        <b-skeleton width="55%"></b-skeleton>
                        <b-skeleton width="70%"></b-skeleton>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="row card-group-row mb-lg-12pt home-card">
            <div class="col-md-6 col-lg-6 card-group-row__col" v-for="employer in employers" :key="employer.id">
              <div
                class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card myBox clickable-item"
                @click.prevent="openEmpProfile(employer.id)"
              >
                <div class="card-header d-flex align-items-center">
                  <a href="#" @click.prevent class="card-title flex mr-12pt">
                    {{ employer.name }}
                    <i
                      class="fas fa-handshake ml-2 d-flex align-self-center text-primary"
                      v-if="get(partnerStatusesById, `${employer.id}.status`)"
                      v-b-popover.hover.top="'This employer is a partner'"
                    ></i>
                  </a>
                  <a href="#" @click.prevent class="" data-placement="bottom"
                    >{{ employer.city }}, {{ employer.state }}</a
                  >
                </div>
                <div class="card-body flex text-center d-flex flex-column align-items-center justify-content-center">
                  <a
                    href="#"
                    @click.prevent
                    class="avatar avatar-xl overlay js-overlay overlay--primary rounded p-relative o-hidden mb-16pt"
                    style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                  >
                    <img
                      class="avatar-img rounded"
                      :src="employer.logo_url || DefaultLogo"
                      width="160"
                      height="auto"
                      style="object-fit: contain !important"
                    />
                  </a>
                  <span class="text-primary mb-2" v-if="employer.hired_student_count"
                    >{{ employer.hired_student_count }} students from your school work here</span
                  >
                  <div class="flex">
                    <p class="text-70 measure-paragraph">
                      <span v-if="trim(employer.short_bio)">{{
                        truncate(trim(employer.short_bio), {
                          length: 240,
                          separator: /,? +/,
                        })
                      }}</span>
                      <i v-else>{{ employer.name }} has not added any bio yet.</i>
                    </p>
                  </div>

                  <div class="card-button">
                    <ul>
                      <li v-for="career in employer.offered_careers" :key="career.id">
                        <a href="#" @click.prevent
                          ><span class="material-icons icon-16pt text-100">check</span>{{ career.name }}</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>

        <pagination
          v-if="totalEmployers > employers.length"
          v-model="currentPage"
          :total-rows="totalEmployers"
          :per-page="perPage"
          @change="onPageChange"
          aria-controls="employers-table"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, get, keyBy, map, trim, truncate } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '../../components/Ui/Pagination.vue';
import DefaultLogo from '@/assets/images/logos/logo_icon.png';
import EmployerFilters from './EmployerFilters/EmployerFilters.vue';
import EmployerFiltersSidebar from './EmployerFilters/EmployerFiltersSidebar.vue';
import { USER_ROLE_TYPES } from '../../common/constants';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  components: { Pagination, PageSeparator },
  extends: Page,

  data() {
    return {
      DefaultLogo,
      USER_ROLE_TYPES,

      title: 'Employers',

      isLoading: true,
      partnerStatusesLoading: true,

      perPage: 14,
      currentPage: 1,
      totalEmployers: 0,
      employers: [],
      partnerStatusesById: {},

      pageFromCount: 0,
      pageToCount: 0,
      searchTerm: '',

      ordering: 'name',

      windowWidth: window.innerWidth,

      filters: {
        zipcode: null,
        company_type: null,
        company_size: null,
      },
    };
  },

  computed: {
    ...mapGetters('employer', ['getEmployerFilters']),
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Employers', active: true },
      ];
    },

    subLayout() {
      return this.windowWidth > 992;
    },
    subLayoutDrawer() {
      return this.windowWidth > 992 ? EmployerFiltersSidebar : EmployerFilters;
    },
    subLayoutDrawerId() {
      return 'employer-filters';
    },

    enableSidebarToggle() {
      return this.windowWidth <= 992;
    },

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
  },

  watch: {
    getEmployerFilters: {
      deep: true,
      handler(filterValues) {
        this.filters.zipcode = filterValues.zipcode;
        this.filters.company_type = filterValues.companyType.length ? filterValues.companyType.join(',') : null;
        this.filters.company_size = filterValues.companySize.length ? filterValues.companySize.join(',') : null;
        this.debouncedSearchEmployers(this);
      },
    },
  },

  methods: {
    ...mapActions('employer', ['getAllEmployers']),
    ...mapActions('partnership', ['getPartnersStatuses']),
    get,
    trim,
    truncate,

    async fetchEmployers(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('page-container').scrollIntoView();

      const response = await this.getAllEmployers({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...this.filters,
        ...params,
      });
      this.employers = response.data.results;
      this.currentPage = pageNum;
      this.totalEmployers = response.data.count;

      if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL) {
        this.getEmployersPartnerStatuses();
      }
      this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
      this.pageToCount = this.pageFromCount + get(response.data, 'results.length', 0) - 1;

      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchEmployers(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchEmployers();
    },

    onSearch() {
      this.debouncedSearchEmployers(this);
    },

    debouncedSearchEmployers: debounce((vm) => {
      vm.fetchEmployers();
    }, 500),

    openEmpProfile(id) {
      this.$router.push({
        name:
          this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
            ? 'schl-employer-profile'
            : 'student-employer-profile',
        params: { id },
      });
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },

    async getEmployersPartnerStatuses() {
      this.partnerStatusesLoading = true;

      const statuses = (
        await this.getPartnersStatuses({
          partner_type: 'employer',
          partner_entity_ids: map(this.employers, 'id'),
        })
      ).data;
      this.partnerStatusesById = keyBy(statuses, 'id');

      this.partnerStatusesLoading = false;
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    this.fetchEmployers();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
