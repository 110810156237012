<template>
  <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
    <h3 class="text-center mb-1">
      Great! Do you have a High School Diploma / GED?
    </h3>

    <b-form-group class="text-center mt-4" label-for="ged_or_diploma" label-class="form-label">
      <b-form-radio-group
        id="ged_or_diploma"
        v-model="profile.ged_or_diploma"
        :options="YES_NO_OPTIONS"
        required
        buttons
        button-variant="outline-dark"
        style="width: 350px"
        :state="!$v.profile.ged_or_diploma.required && $v.profile.ged_or_diploma.$dirty ? false : null"
      ></b-form-radio-group>
      <b-form-invalid-feedback
        :state="!$v.profile.ged_or_diploma.required && $v.profile.ged_or_diploma.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group :label="`Graduation Year`" label-for="gradDate" label-class="form-label">
      <date-picker
        v-model="profile.diploma_date"
        type="year"
        format="YYYY"
        style="width: 100%"
        value-type="date"
        id="diploma_date"
        lang="en"
        placeholder="No Date Selected"
      ></date-picker>
    </b-form-group>
  </b-form>
</template>

<script>
import { pick } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
export default {
  components: { DatePicker },
  props: {
    profileData: { type: Object, required: true },
    schoolName: { type: String, required: true }
  },
  data() {
    return {
      profile: {
        ged_or_diploma: null,
        diploma_date: ''
      },
      YES_NO_OPTIONS: [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' }
      ]
    };
  },
  validations() {
    return {
      profile: {
        ged_or_diploma: { required }
      }
    };
  },

  watch: {
    profileData: {
      handler() {
        this.profile = pick(this.profileData, ['ged_or_diploma', 'diploma_date']);
      },

      deep: true
    }
  },
  methods: {
    validateFormData() {
      this.$v.$touch();
      return {
        isValid: !this.$v.$invalid,
        formData: { profile: { ...this.profile } }
      };
    }
  }
};
</script>

<style></style>
