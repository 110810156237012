<template>
  <b-form class="col-md-12 px-0 page-section pt-0">
    <b-form-group label-for="avatar" label-class="form-label" class="row-align-items-center">
      <b-media class="align-items-center" vertical-align="center">
        <h3 class="text-center mb-1">
          Excellent {{ user.first_name }}! Now let's start building your career profile. Upload a professional head
          shot.
        </h3>
        <p class="text-center mb-5">
          Think of how you would want to appear in a face-to-face interview or professional networking event .
        </p>
        <div class="d-flex justify-content-center mb-4 mt-4">
          <user-avatar
            slot="aside"
            size="8rem"
            :isLoading="isUploading.avatar"
            :user="user"
            url-field="avatar_url"
            first-name-field="first_name"
            last-name-field="last_name"
            variant="dark"
            rounded
            avatar-class="border-4 border-dark"
          >
          </user-avatar>
          <!-- <fmv-avatar :src="user.avatar_url" title-class="bg-light" square class="mr-2" size="xxl"> </fmv-avatar> -->
        </div>
        <div class="d-flex justify-content-center mb-3">
          <image-uploader
            class="col-md-6"
            @image="(e) => setImage(e, 'avatar', 'images')"
            :isUploading="isUploading.avatar"
            :isRequired="false"
            placeholder="Select professional looking headshot photo (max size: 4MB)."
          />
        </div>
      </b-media>
    </b-form-group>
  </b-form>
</template>

<script>
import { pick } from 'lodash';
import axios from 'axios';
import { mapActions } from 'vuex';
import UserAvatar from '@/components/User/UserAvatar.vue';
// import { FmvAvatar } from 'fmv-avatar';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  components: { UserAvatar, ImageUploader },
  props: {
    userData: { type: Object, required: true },
  },
  data() {
    return {
      user: {
        avatar_url: null,
        first_name: '',
      },
      isUploading: { avatar: false },
      selectedUploadFile: { avatar: null },
      uploadPercent: { avatar: 0 },
      uploadCancelTokenSource: { avatar: null },
      fileSizeExceed: { avatar: false },
      invalidFileType: { avatar: false },
    };
  },
  validations() {
    return {
      user: {
        avatar_url: {
          validFile() {
            return !this.fileSizeExceed.avatar && !this.invalidFileType.avatar;
          },
        },
      },
    };
  },

  watch: {
    userData: {
      handler() {
        this.user = pick(this.userData, ['avatar_url', 'first_name']);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('student', ['getMyStudentProfile', 'updateMyStudentProfile', 'getStudentUploadPresignedUrl']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    setImage(file, fileType, uploadType = 'images') {
      this.selectedUploadFile[fileType] = file;
      this.uploadFile(fileType, uploadType);
    },
    validateFormData() {
      this.$v.$touch();
      return {
        isValid: !this.$v.$invalid,
        formData: { user: { ...this.user } },
      };
    },
    async uploadFile(fileType, uploadType = 'images', profileUrlField = 'resume_url', allowedType = 'image') {
      this.fileSizeExceed[fileType] = this.invalidFileType[fileType] = false;
      if (this.selectedUploadFile[fileType].size > 10 * 1024 * 1024) {
        this.fileSizeExceed[fileType] = true;
        return;
      } else if (!this.selectedUploadFile[fileType].type.includes(allowedType)) {
        this.invalidFileType[fileType] = true;
        return;
      }

      this.isUploading[fileType] = true;
      this.$emit('isUploading', this.isUploading);
      this.uploadCancelTokenSource[fileType] = axios.CancelToken.source();

      try {
        const urlResp = await this.getStudentUploadPresignedUrl({
          file_name: this.selectedUploadFile[fileType].name,
          content_type: this.selectedUploadFile[fileType].type,
          upload_type: uploadType,
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.selectedUploadFile[fileType],
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent[fileType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[fileType].token,
          },
        });
        if (fileType === 'avatar') this.user.avatar_url = urlResp.upload_url.split('?')[0];
        else this.profile[profileUrlField] = urlResp.upload_url.split('?')[0];
      } catch (error) {
        this.selectedUploadFile[fileType] = null;
      }

      this.uploadCancelTokenSource[fileType] = null;
      this.uploadPercent[fileType] = 0;

      this.isUploading[fileType] = false;
      this.$emit('isUploading', this.isUploading);
    },
  },
};
</script>

<style>
</style>