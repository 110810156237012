<template>
  <div class="mb-32pt mt-32pt">
    <div style="position: relative">
      <video id="partnershipVid" class="" v-if="partnershipOverviewVid" style="width: 100%" :controls="playVideo">
        <source :src="partnershipOverviewVid" type="video/mp4" />
      </video>
      <div
        v-if="!playVideo"
        @click.prevent="handlerVideo"
        class="d-flex justify-content-center align-items-center clickable-item"
        style="position: absolute; height: 100%; width: 100%; background: transparent; top: 0"
      >
        <md-icon style="font-size: 60px">play_circle_filled</md-icon>
      </div>
    </div>

    <div class="mb-0" v-if="get(getCurrentSchool, 'emp_pship_desc')">
      <div style="font-size: 16px" v-html="get(getCurrentSchool, 'emp_pship_desc')"></div>
    </div>
    <div class="mb-0" v-else>
      <h3>Partnership Overview</h3>
      <div style="font-size: 16px">
        Our partnerships are a collaborative effort to help students explore career choices, make informed career
        decisions, and begin applying their skills into the workplace. If you are seeking to connect with our students,
        offer sponorships, and participate in hiring/career events; please view the following steps on how to request to
        become a partner.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash';
export default {
  name: 'DiscoverTrain',
  data() {
    return {
      playVideo: false,
    };
  },
  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    mainAppUrl() {
      return process.env.VUE_APP_MAIN_DOMAIN;
    },
    partnershipOverviewVid() {
      return (
        get(this.getCurrentSchool, 'pship_overview_video') ||
        'https://transition-prod.s3.amazonaws.com/resources/public_site_video+(1).mp4'
      );
    },
  },
  methods: {
    get,
    handlerVideo() {
      document.getElementById('partnershipVid').play();
      this.playVideo = true;
    },
    getClickableLink(link) {
      return link.startsWith('http://') || link.startsWith('https://')
        ? link
        : `${process.env.VUE_APP_DOMAIN_PREFIX || 'https'}://${link}`;
    },
  },
};
</script>

<style></style>
