<template>
  <b-modal ref="workExpModal" hide-footer :title="modalTitle" centered lazy size="lg" @hide="hideModal">
    <b-form @submit.prevent="onSave">
      <b-form-group label="Title" label-for="title" label-class="form-label">
        <b-form-input
          id="title"
          placeholder="Enter the job title."
          v-model="workExpData.title"
          required
          :disabled="isLoading"
        />
      </b-form-group>

      <b-form-group label="Employer" label-for="employer" label-class="form-label">
        <b-form-input
          id="employer"
          placeholder="Enter employer's name."
          v-model="workExpData.employer"
          required
          :disabled="isLoading"
        />
      </b-form-group>

      <div class="row">
        <b-form-group label="City" label-for="city" label-class="form-label" class="col-md-6">
          <b-form-input id="city" placeholder="Enter city." v-model="workExpData.city" required :disabled="isLoading" />
        </b-form-group>

        <b-form-group label="State" label-for="state" label-class="form-label" class="col-md-6">
          <b-form-input
            id="state"
            placeholder="Enter state."
            v-model="workExpData.state"
            required
            :disabled="isLoading"
          />
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group label="Start Date" label-for="start_date" label-class="form-label" class="col-md-6">
          <b-form-datepicker
            v-model="workExpData.start_date"
            :max="maxDateAllowed"
            required
            :state="!$v.workExpData.start_date.required && $v.workExpData.start_date.$dirty ? false : null"
            :disabled="isLoading"
          ></b-form-datepicker>
          <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="End Date" label-for="end_date" label-class="form-label" class="col-md-6">
          <b-form-datepicker
            v-model="workExpData.end_date"
            :disabled="workExpData.still_working || isLoading"
            :max="maxDateAllowed"
            required
            :state="
              (!$v.workExpData.end_date.required || !$v.workExpData.end_date.isCorrectFormat) &&
              $v.workExpData.end_date.$dirty
                ? false
                : null
            "
          ></b-form-datepicker>
          <b-form-checkbox
            id="still_working"
            v-model="workExpData.still_working"
            name="still_working"
            class="mt-1"
            :disabled="isLoading"
          >
            I currently work here.
          </b-form-checkbox>
          <b-form-invalid-feedback v-if="!$v.workExpData.end_date.required"
            >This field is required.</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-else>End date must be greater than the start date.</b-form-invalid-feedback>
        </b-form-group>
      </div>

      <div class="mb-2">
        <page-separator title="Work Tasks" />
        <div class="d-flex justify-content-end">
          <b-btn variant="primary" @click.prevent="addWorkTask" size="sm" :disabled="isLoading">
            <span>Add Task</span>
          </b-btn>
        </div>

        <i v-if="!get(workExpData, 'work_tasks.length')">No work tasks added.</i>

        <div v-for="(task, index) in workExpData.work_tasks" :key="`task-${index}`">
          <div class="d-flex justify-content-between">
            <div class="w-100">
              <b-form-group label="Task" :label-for="`task-${index}`" label-class="form-label">
                <b-form-input
                  :id="`task-${index}`"
                  placeholder="Enter task info."
                  v-model="workExpData.work_tasks[index]"
                  required
                  :disabled="isLoading"
                />
              </b-form-group>
            </div>

            <div class="d-flex align-items-center ml-2">
              <b-btn variant="danger" :disabled="isLoading" @click.prevent="removeTask(index)" size="sm">
                <span>Remove</span>
              </b-btn>
            </div>
          </div>
        </div>
      </div>

      <b-btn
        variant="primary"
        style="width: 200px"
        type="submit"
        class="btn-normal d-flex ml-auto"
        :disabled="isLoading"
      >
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else> {{ workExp ? 'Update' : 'Add' }} Experience</span>
      </b-btn>
    </b-form>
  </b-modal>
</template>

<script>
import { get, pick } from 'lodash';
import moment from 'moment';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

import { formatToAPIDate } from '../../../../common/utils';
import DefaultImg from '@/assets/images/placeholders/cna_career.jpg';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  name: 'WorkExpModal',

  components: { PageSeparator },

  props: {
    showModal: { type: Boolean, default: false },
    workExp: { type: Object || null },
    expIndex: { type: Number || null },
  },

  data() {
    return {
      DefaultImg,

      workExpData: {
        title: '',
        employer: '',
        city: '',
        state: '',
        start_date: '',
        end_date: '',
        still_working: false,
        work_tasks: [],
      },

      modalTitle: '',
      maxDateAllowed: undefined,

      isLoading: false,
    };
  },

  validations() {
    return {
      workExpData: {
        title: { required },
        employer: { required },
        city: { required },
        state: { required },
        start_date: { required },
        end_date: {
          required: requiredIf(() => !this.workExpData.still_working),
          isCorrectFormat(value) {
            return (
              this.workExpData.still_working ||
              !this.workExpData.start_date ||
              !this.workExpData.end_date ||
              moment(this.workExpData.start_date).isBefore(moment(value))
            );
          },
        },
        still_working: {},
        work_tasks: {},
      },
    };
  },

  computed: {},

  watch: {
    showModal(value) {
      if (this.$route.params.hireId) {
        this.fetchHireData();
      }

      if (value) {
        this.modalTitle = this.workExp ? 'Edit Work Experience' : 'Add Work Experience';
        this.maxDateAllowed = new Date();
        if (this.workExp) {
          this.workExpData = pick(this.workExp, [
            'title',
            'employer',
            'city',
            'state',
            'start_date',
            'end_date',
            'still_working',
            'work_tasks',
          ]);
        } else {
          this.workExpData = {
            title: '',
            employer: '',
            city: '',
            state: '',
            start_date: '',
            end_date: '',
            still_working: false,
            work_tasks: [''],
          };
        }
        this.$v.$reset();
        this.$refs.workExpModal.show();
      }
    },
  },

  methods: {
    get,
    ...mapActions('hire', ['getStudentHireDetails']),

    hideModal() {
      this.$emit('close');
    },

    hideUpdateModal(data) {
      this.$refs.workExpModal.hide();
      this.$emit('closeUpdate', data);
    },

    hideModalManual() {
      this.$refs.workExpModal.hide();
      this.hideModal();
    },

    onSave() {
      this.isLoading = true;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.hideUpdateModal({
          workExp: { ...this.workExpData, end_date: this.workExpData.still_working ? null : this.workExpData.end_date },
          index: this.expIndex,
          isUpdate: !!this.workExp,
        });
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }

      this.isLoading = false;
    },

    addWorkTask() {
      this.workExpData.work_tasks.push('');
    },

    removeTask(index) {
      this.workExpData.work_tasks.splice(index, 1);
    },

    async fetchHireData() {
      this.isLoading = true;
      try {
        const hireData = (await this.getStudentHireDetails({ hire_id: this.$route.params.hireId })).data;
        this.workExpData.title = hireData.job.title;
        this.workExpData.employer = hireData.employer.name;
        this.workExpData.city = hireData.job.city;
        this.workExpData.state = hireData.job.state;
        this.workExpData.start_date = formatToAPIDate(hireData.hiring_date);
        this.workExpData.still_working = true;
        this.workExpData.work_tasks = [];
      } catch (_err) {
        this.hideModalManual();
      }
      this.isLoading = false;
    },
  },

  async mounted() {},
};
</script>
