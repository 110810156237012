<template>
  <div class="bg-light">
    <div
      :class="containerClass"
      class="page-section bg-white border shadowed"
      style="
        min-height: 100vh !important;
        -webkit-box-shadow: 0px 0px 19px -6px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 19px -6px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 19px -6px rgba(0, 0, 0, 0.75);
      "
    >
      <sch-detail is-public />
    </div>
  </div>
</template>

<script>
import Page from '@/components/Page';
import SchDetail from '../Scholarships/SchDetail.vue';
export default {
  name: 'Scholarship',
  layout: 'blank',
  extends: Page,
  components: {
    SchDetail,
  },
};
</script>

<style>
</style>