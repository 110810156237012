<template>
  <b-modal ref="resumeSkillModal" hide-footer :title="modalTitle" centered lazy size="lg" @hide="hideModal">
    <b-form @submit.prevent="onSave">
      <b-form-group label="Skill Name" label-for="name" label-class="form-label">
        <div class="custom-select-icon">
          <b-form-select class="custom-select-icon__select" v-model="skillData.name" :options="nameOptions" required>
          </b-form-select>
          <span class="material-icons custom-select-icon__icon">sort</span>
        </div>
        <b-form-input
          id="other_skill"
          class="mt-2"
          placeholder="Please specify."
          v-model="skillData.other_skill"
          required
          v-if="skillData.name === 'other'"
        />
      </b-form-group>

      <b-btn variant="primary" style="width: 200px" type="submit" class="btn-normal">
        {{ skill ? 'Update' : 'Add' }} Skill
      </b-btn>
    </b-form>
  </b-modal>
</template>

<script>
import { get, pick } from 'lodash';
import { required, requiredIf } from 'vuelidate/lib/validators';

import DefaultImg from '@/assets/images/placeholders/cna_career.jpg';

export default {
  name: 'ResumeSkillModal',

  components: {},

  props: {
    showModal: { type: Boolean, default: false },
    skill: { type: Object || null },
    itemIndex: { type: Number || null },
    addedSkills: { type: Array || null },
    allSkills: { type: Array || null },
  },

  data() {
    return {
      DefaultImg,

      skillData: {
        name: null,
        other_skill: null,
      },

      modalTitle: '',
      nameOptions: [{ value: null, text: 'Select skill name', disabled: true }, ...this.allSkills],
    };
  },

  validations() {
    return {
      skillData: {
        name: { required },
        other_skill: { required: requiredIf(() => this.skillData.name === 'other') },
      },
    };
  },

  computed: {},

  watch: {
    showModal(value) {
      if (value) {
        this.modalTitle = this.skill ? 'Edit Skill' : 'Add Skill';
        this.maxDateAllowed = new Date();
        if (this.skill) {
          this.skillData = pick(this.skill, ['name', 'other_skill']);
        } else {
          this.skillData = { name: null, other_skill: null };
        }

        const selectedSkills = this.addedSkills
          .filter((skill) => skill.name !== 'other' && skill.name !== get(this.skill, 'name'))
          .map((skill) => skill.name);
        this.nameOptions = [
          { value: null, text: 'Select skill name', disabled: true },
          ...this.allSkills.filter((skill) => !selectedSkills.includes(skill.value)),
        ];

        this.$v.$reset();
        this.$refs.resumeSkillModal.show();
      }
    },
  },

  methods: {
    get,

    hideModal() {
      this.$emit('close');
    },

    hideUpdateModal(data) {
      this.$refs.resumeSkillModal.hide();
      this.$emit('closeUpdate', data);
    },

    hideModalManual() {
      this.$refs.resumeSkillModal.hide();
      this.hideModal();
    },

    onSave() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.hideUpdateModal({
          skill: {
            ...this.skillData,
            end_date: this.skillData.still_attending ? null : this.skillData.end_date,
          },
          index: this.itemIndex,
          isUpdate: !!this.skill,
        });
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
    },
  },

  async mounted() {},
};
</script>
