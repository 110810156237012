<template>
  <div data-perfect-scrollbar>
    <b-skeleton-wrapper :loading="isLoading" v-if="isLoading">
      <template #loading>
        <div class="page-section pb10 student-page">
          <div class="container page__container d-flex align-items-center">
            <p class="backResult text-50">
              <router-link
                :to="{ name: 'student-careers' }"
                style="text-decoration: underline"
                v-if="getLoggedInUser.role === USER_ROLES.STUDENT"
              >
                <span class="material-icons icon-18pt text-80">navigate_before</span>Back to Careers
              </router-link>
              <router-link
                :to="{ name: 'edit-job', params: { id: $route.params.id } }"
                style="text-decoration: underline"
                v-else
              >
                <span class="material-icons icon-18pt text-80">navigate_before</span>Back to Careers
              </router-link>
            </p>
          </div>

          <div
            class="container page__container d-flex flex-column flex-md-row align-items-center text-center text-md-left"
          >
            <b-col md="2">
              <b-skeleton-img height="113px" no-aspect></b-skeleton-img>
            </b-col>
            <div class="flex mb-32pt mb-md-0 mobilemb0">
              <h4 class="mb-0"><b-skeleton width="60%" height="1.5rem"></b-skeleton></h4>
              <p class="d-flex mt-2 mb-3">
                <b-skeleton width="85%"></b-skeleton>
              </p>

              <b-row>
                <b-col md="2" sm="12">
                  <b-skeleton type="button" width="100%"></b-skeleton>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>

        <div class="container page__container page-section student-card mt-0 pt-0 mb-32pt">
          <div class="card">
            <div class="card-body">
              <div class="d-flex mb-1">
                <div class="flex">
                  <div class="d-flex">
                    <div class="flex">
                      <span class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">local_hospital</i
                        ><b-skeleton width="75%"></b-skeleton>
                      </span>
                      <span class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">school</i><b-skeleton width="50%"></b-skeleton>
                      </span>
                      <span class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">attach_money</i><b-skeleton width="50%"></b-skeleton>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <div class="d-flex mb-1">
                <div class="flex">
                  <div class="mb-1">
                    <h5 class="card-title fw600"><b-skeleton width="50%"></b-skeleton></h5>
                  </div>

                  <span>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="60%"></b-skeleton>
                  </span>

                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-skeleton-wrapper>

    <div v-else>
      <div class="page-section pb10 student-page">
        <div class="container page__container d-flex align-items-center">
          <p class="backResult text-50">
            <router-link
              :to="{ name: 'student-careers' }"
              style="text-decoration: underline"
              v-if="getLoggedInUser.role === USER_ROLES.STUDENT"
            >
              <span class="material-icons icon-18pt text-80">navigate_before</span>Back to Careers
            </router-link>
            <router-link
              :to="{ name: 'edit-job', params: { id: $route.params.id } }"
              style="text-decoration: underline"
              v-else
            >
              <span class="material-icons icon-18pt text-80">navigate_before</span>Back to Careers
            </router-link>
          </p>
        </div>

        <div
          class="container page__container d-flex flex-column flex-md-row align-items-center text-center text-md-left"
        >
          <img
            :src="career.photo_url || DefaultCareerImg"
            width="115"
            class="mr-md-32pt mb-32pt mb-md-0 rounded mobilemb10"
            alt="Career Logo"
          />
          <div class="flex mb-32pt mb-md-0 mobilemb0">
            <h4 class="mb-0">{{ career.name }}</h4>

            <p class="lh-1 mb-4">
              <span class="text-black-50 small font-weight-bold">{{
                $t(`careerPrefCategories.${career.category}`)
              }}</span>
              <i
                class="material-icons icon-16pt ml-1 text-black-50 clickable-item"
                v-b-popover.hover.top="get(categoriesByKey, `${career.category}.description`)"
                >info</i
              >
            </p>

            <b-button variant="primary" @click.prevent="updateCareerFavorite"
              ><span class="material-icons icon-16pt mr-1"
                >favorite<template v-if="!career.is_favorite">_border</template></span
              >{{ career.is_favorite ? 'Unfavorite' : 'Favorite' }}</b-button
            >
          </div>
        </div>
      </div>

      <div class="container page__container page-section mt-0 pt-0 mb-32pt">
        <div class="student-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex mb-1">
                <div class="flex">
                  <div class="d-flex align-items-center mb-1">
                    <h5 class="card-title fw600">Basic Info</h5>
                  </div>

                  <div class="d-flex">
                    <div class="flex">
                      <span class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">local_hospital</i>
                        <p class="flex text-50 lh-1 mb-0">
                          <strong>Required Skills:</strong><span class="ml-1">{{ career.required_skills }}</span>
                        </p>
                      </span>
                      <span class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">school</i>
                        <p class="flex text-50 lh-1 mb-0">
                          <strong>Required Education:</strong><span class="ml-1">{{ career.required_education }}</span>
                        </p>
                      </span>
                      <span class="icon-align">
                        <i class="material-icons icon-18pt mr-8pt">attach_money</i>
                        <p class="flex text-50 lh-1 mb-0">
                          <strong>Salary Range:</strong>
                          <span class="ml-1"
                            >${{ $n(career.salary_range_from) }} - ${{ $n(career.salary_range_to) }}</span
                          >
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="event-card">
          <div class="card">
            <div class="card-body">
              <div class="d-flex mb-1">
                <div class="flex">
                  <div class="d-flex align-items-center mb-1">
                    <h5 class="card-title fw600">Description</h5>
                  </div>

                  <div v-safe-html="career.description" />

                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Page from '@/components/Page.vue';
import { mapActions, mapGetters } from 'vuex';
import { get, keyBy } from 'lodash';
import { formatFullDate, getClickableLink } from '../../../common/utils';
import { USER_ROLES, CAREER_PREF_CATEGORIES } from '../../../common/constants';
import DefaultCareerImg from '@/assets/images/placeholders/cna_career.jpg';

export default {
  name: 'CareerShowPage',
  components: {},
  extends: Page,

  data() {
    return {
      DefaultCareerImg,
      USER_ROLES,
      title: 'Career Details',

      isLoading: true,
      career: {},

      categoriesByKey: {},
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },

  methods: {
    ...mapActions('careerPreference', ['getCareerPreference']),
    ...mapActions('student', ['updateStudentCareerPreference']),
    ...mapActions('fileDownload', ['downloadFile']),
    get,
    formatFullDate,
    getClickableLink,
    async updateCareerFavorite() {
      const isFav = this.career.is_favorite;
      this.career.is_favorite = !this.career.is_favorite;
      await this.updateStudentCareerPreference({
        career_preference: this.career.id,
        remove_fav: isFav,
      });
    },

    async fetchCareer() {
      this.isLoading = true;
      try {
        const resp = (await this.getCareerPreference(this.$route.params.id)).data;
        this.career = resp;
      } catch (_err) {
        this.$router.push({ name: 'student-careers' });
      }
      this.isLoading = false;
    },

    async downloadGivenFile(url) {
      const h = this.$createElement;

      this.makeToast({
        variant: 'primary',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
        toaster: 'b-toaster-bottom-left',
      });

      await this.downloadFile({ fileUrl: url, removeTimestamp: true });

      this.makeToast({
        variant: 'success',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
        toaster: 'b-toaster-bottom-left',
      });
    },
  },

  async mounted() {
    this.categoriesByKey = keyBy(CAREER_PREF_CATEGORIES, 'key');
    this.fetchCareer();
  },
};
</script>
