<template>
  <div>
    <page-header
      :title="title"
      :container-class="containerClass"
      infoText="Check the status of your scholarship applications."
    />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    :placeholder="$t('studentMsgs.searchStudents')"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div> -->
            </div>
          </div>

          <!-- Students Table -->
          <b-table
            :fields="tableColumns"
            :items="allScholarships"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @row-clicked="onRowClicked"
            @sort-changed="onSortChange"
          >
            <template #cell(title)="data"> {{ data.item.scholarship.title }} </template>

            <template #cell(posted)="data">
              {{
                data.item.scholarship.posted_by_org
                  ? data.item.scholarship.posted_by_org.name
                  : data.item.scholarship.employers[0].name
              }}
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalStudents"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="students-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, truncate } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';
// import MdIcon from '@/components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '@/common/constants';
import { formatFullDate } from '@/common/utils';
export default {
  components: { PageHeader, Pagination },
  extends: Page,

  data() {
    return {
      title: 'My Applications',
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalStudents: 0,
      students: [],
      searchTerm: '',
      areSchLoading: false,
      allScholarships: [],
      schFilter: '',
      windowWidth: window.innerWidth,
    };
  },

  computed: {
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.$t('studentMsgs.students'), active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'title', label: 'Title', tdClass: 'clickable-item' },

        {
          key: 'created_at',
          label: 'Applied On',
          tdClass: 'clickable-item',
          formatter: (value) => (value ? formatFullDate(value) : value),
        },

        {
          key: 'posted',
          label: 'Sponsored By',
          tdClass: 'clickable-item',
        },
      ];
    },
  },

  methods: {
    ...mapActions('applicationForm', ['getAllSchApps']),
    ...mapActions('scholarships', ['getAllAppliedScholarships']),
    truncate,
    onRowClicked(data) {
      this.$router.push({
        name: 'std-application',
        params: {
          id: data.id,
        },
      });
    },

    async fetchScholarships() {
      this.areSchLoading = true;

      try {
        const response = await this.getAllAppliedScholarships({});

        this.allScholarships = response.data;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areSchLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchScholarships(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchScholarships();
    },

    onSearch() {
      this.debouncedSearchStudents(this);
    },

    debouncedSearchStudents: debounce((vm) => {
      vm.fetchScholarships();
    }, 500),
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    this.fetchScholarships();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
