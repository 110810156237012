<template>
  <div style="margin-top: -20px">
    <!-- <div class="page-section"> -->

    <!-- TODO: Add page design here -->
    <!-- <h2 class="text-center" style="font-weight: bold">{{get(getCurrentSchool, 'banner_title', 'Transition') }}</h2> -->
    <div
      class="bg-dark page-section employer-header-bg border-bottom-white mb-10pt img-fluid"
      :style="`background-image: url('${get(getCurrentSchool, 'emp_pship_banner_img')}');`"
    >
      <div
        class="page__container mb-0"
        :style="`background:(rgba(${rgbDarkColor.r}, ${rgbDarkColor.g}, ${rgbDarkColor.b}, 0.95) !important;`"
      >
        <div class="text-center">
          <h1 class="text-white mb-8pt employer-header-h1" style="">
            {{ get(getCurrentSchool, 'emp_pship_banner_title') }}
          </h1>
          <p class="measure-lead mx-auto text-white mb0 employer-header-text">
            {{ get(getCurrentSchool, 'emp_pship_short_desc') }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="isTabSmallScreen" class="text-center">
      <h3 class="mt-4">Ways to Partner</h3>
    </div>
    <div v-if="isTabSmallScreen" class="d-flex" style="max-width: 1200px; overflow-x: scroll">
      <router-link
        to="/"
        :href="`${getClickableLink(mainAppUrl)}/employer-signup/?referral_id=${get(getCurrentSchool, 'referral_id')}`"
        target="_blank"
        style="text-decoration: none; color: black"
        v-for="way in waysToPartner"
        :key="way.id"
      >
        <div
          class="card p-4 mx-3 text-center clickable-item-hover"
          style="margin-top: 10px; background: #fafafa; height: 90%"
          :style="isSmallScreen ? 'width: 90vw' : 'width: 50vw'"
        >
          <md-icon class="partnership-icon mb-2">{{ way.icon }}</md-icon>
          <div class="mb-2" style="font-size: 20px; font-weight: 600">{{ way.heading }}</div>
          <p style="font-size: 15px">{{ way.desc }}</p>
        </div>
      </router-link>
    </div>
    <div class="row mx-auto" :class="isTabSmallScreen ? '' : 'container'">
      <div :class="isTabSmallScreen ? 'col-md-12' : 'col-md-8'">
        <b-tabs
          nav-class="nav-tabs-card bg-white"
          content-class=" "
          class="mt-0"
          id="homePageTab"
          @input="onTabsChanged"
          style="scroll-margin-top: 66px; font-size: 16.5px"
          lazy
        >
          <b-tab title="Overview">
            <div class="page-section" :class="isTabSmallScreen ? 'container' : ''">
              <div class=""><discover-n-train /></div>
            </div>
          </b-tab>

          <b-tab title="Background">
            <div class="page-section" :class="isTabSmallScreen ? 'container' : ''">
              <div class="card p-3">
                <h3>Facts</h3>
                <div class="row">
                  <div class="d-flex align-items-center mb-3 col-md-6" style="font-size: 15px">
                    <md-icon class="mr-1">calendar_today</md-icon>
                    <b class="mr-1"> Avg Weeks: </b>{{ get(getCurrentSchool, 'avg_program_weeks') || '-' }}
                  </div>
                  <div class="d-flex align-items-center mb-3 col-md-6" style="font-size: 15px">
                    <md-icon class="mr-1">school</md-icon>
                    <b class="mr-1"> Pass Rate: </b>{{ get(getCurrentSchool, 'pass_rate') || '-' }}%
                  </div>
                  <div class="d-flex align-items-center mb-3 col-md-6" style="font-size: 15px">
                    <md-icon class="mr-1">school</md-icon>
                    <b class="mr-1">
                      {{ get(getCurrentSchool, 'active_students') > 1 ? 'Active Students' : 'Active Student' }}: </b
                    >{{ get(getCurrentSchool, 'active_students') || '-' }}
                  </div>
                  <div class="d-flex align-items-center mb-3 col-md-6" style="font-size: 15px">
                    <md-icon class="mr-1">local_library</md-icon>
                    <b class="mr-1"> Offered Programs: </b>
                    <span class="clickable-item text-primary" @click.prevent="openProgramModal">
                      {{ get(getCurrentSchool, 'offered_programs.length') || '-' }}
                    </span>
                  </div>
                </div>

                <hr />

                <h3>Bio</h3>

                <div style="font-size: 15px" v-safe-html="get(getCurrentSchool, 'bio')"></div>
              </div>
            </div>
          </b-tab>

          <b-tab title="Spotlights">
            <div class="page-section" :class="isTabSmallScreen ? 'container' : ''">
              <div class=""><public-spotlights /></div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <div v-if="!isTabSmallScreen" class="col-md-4 text-center">
        <h3 class="mt-4">Ways to Partner</h3>
        <router-link
          to="/"
          :href="`${getClickableLink(mainAppUrl)}/employer-signup/?referral_id=${get(getCurrentSchool, 'referral_id')}`"
          target="_blank"
          style="text-decoration: none; color: black"
          v-for="way in waysToPartner"
          :key="way.id"
        >
          <div class="card p-4 mx-3 text-center clickable-item-hover" style="margin-top: 40px; background: #fafafa">
            <md-icon class="partnership-icon mb-2">{{ way.icon }}</md-icon>
            <div class="mb-2" style="font-size: 20px; font-weight: 600">{{ way.heading }}</div>
            <p style="font-size: 15px">
              {{ way.desc }}
            </p>
          </div>
        </router-link>
      </div>
    </div>
    <program-modal
      :showModal="showProgramModal"
      :programs="get(getCurrentSchool, 'offered_programs')"
      @close="hideProgramModal"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { get } from 'lodash';
import Page from '@/components/Page.vue';
import { hexToRgb } from '@/common/utils';
import PublicSpotlights from './PublicSpotlights.vue';
import DiscoverNTrain from './DiscoverNTrain.vue';
import ProgramModal from './ProgramModal.vue';

export default {
  components: { PublicSpotlights, DiscoverNTrain, ProgramModal },
  extends: Page,
  name: 'PublicPartnerships',

  data() {
    return {
      title: 'Employer Partners',
      completeHeadTitle: undefined,
      showProgramModal: false,
      windowWidth: window.innerWidth,
      waysToPartner: [
        {
          id: 1,
          icon: 'school',
          heading: 'Hire Our Students',
          desc: `Find trained individuals who are ready to work, saving you time and money on recruiting.`,
        },
        {
          id: 2,
          icon: 'stars',
          heading: 'Back New Learners',
          desc: `Support future healthcare workers by sponsoring their training. We take care of the details, so you can focus on growth.`,
        },
        {
          id: 3,
          icon: 'volunteer_activism',
          heading: 'Train Your Team',
          desc: `Help your staff learn more with our accredited healthcare courses, providing them the chance to grow.`,
        },
      ],
    };
  },

  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: { name: 'sch-public-home' } },
        { text: 'Partners', active: true },
      ];
    },
    rgbDarkColor() {
      return hexToRgb(get(this.getCurrentSchool, 'dark_color', '#214e6f'));
    },

    mainAppUrl() {
      return process.env.VUE_APP_MAIN_DOMAIN;
    },

    isTabSmallScreen() {
      return this.windowWidth <= 992;
    },

    isSmallScreen() {
      return this.windowWidth <= 576;
    },
  },

  watch: {
    getCurrentSchool: {
      immediate: true,
      deep: true,
      handler(value) {
        if (get(value, 'id')) {
          this.completeHeadTitle = `${this.title} - ${get(this.getCurrentSchool, 'name', 'Transition')}`;
        }
      },
    },
  },

  methods: {
    ...mapActions('settings', ['setSettings']),
    get,
    onTabsChanged() {
      if (!this.firstLoad) {
        document.getElementById('homePageTab').scrollIntoView({ behavior: 'smooth' });
      }
      this.firstLoad = false;
    },
    getClickableLink(link) {
      return link.startsWith('http://') || link.startsWith('https://')
        ? link
        : `${process.env.VUE_APP_DOMAIN_PREFIX || 'https'}://${link}`;
    },
    openProgramModal() {
      this.showProgramModal = true;
    },
    hideProgramModal() {
      this.showProgramModal = false;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);

    this.setSettings({ layout: { layout: 'fixed' } });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
