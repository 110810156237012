<template>
  <div :class="containerClass">
    <div class="back-result-lg font-weight-bold">
      <a href="#" @click.prevent="goBack()" style="text-decoration: underline">
        <span class="material-icons icon-32pt">navigate_before</span>
      </a>
    </div>

    <h2>Add a Offer</h2>
    
    <b-form-group label="Position Title*" label-for="position" label-class="form-label">
        <b-form-input
          id="position"
          placeholder="Enter position"
          v-model="position"
          :state="!$v.position.required && $v.position.$dirty ? false : null"
        />
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group label="Offer Type*" label-for="offer_type" label-class="form-label">
        <v-select
          id="offer_type"
          class="form-control v-select-custom"
          v-model="offer_type"
           placeholder="Enter question title"
          :options="allOfferTypes"
          :state="!$v.offer_type.required && $v.offer_type.$dirty ? 'form-control is-invalid' : ''"
        >
     
        </v-select>
        <b-form-invalid-feedback :state="!$v.offer_type.required && $v.offer_type.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="Organization Name*" label-for="organization" label-class="form-label">
        <b-form-input
          id="organization"
          placeholder="Enter question title"
          v-model="organization"
          :state="!$v.organization.required && $v.organization.$dirty ? false : null"
        />
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Status*" label-for="status" label-class="form-label">
        <!-- <b-form-input
          id="status"
          placeholder="Enter status"
          v-model="status"
          :state="!$v.status.required && $v.status.$dirty ? false : null"
        /> -->
        <v-select
          id="status"
          class="form-control v-select-custom"
          v-model="status"
          placeholder="Select Enrolled Program"
          :options="allStatuses"
          :class="!$v.status.required && $v.status.$dirty ? 'form-control is-invalid' : ''"
        >
          <!-- label="title" -->
          <!-- :reduce="status => status.id" -->
          <!-- :loading="areProgramsLoading" -->
          <!-- <template #search="{ attributes, events }">
            <input class="vs__search" :required="!status" v-bind="attributes" v-on="events" />
          </template>
          <template slot="option" slot-scope="option">
            <span>{{ option.title }}</span>
          </template> -->
        </v-select>
        <b-form-invalid-feedback :state="!$v.status.required && $v.status.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
        <!-- <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback> -->
      </b-form-group>

      <b-form-group label="Location*" label-for="location" label-class="form-label">
        <b-form-input
          id="location"
          placeholder="Enter question title"
          v-model="location"
          :state="!$v.location.required && $v.location.$dirty ? false : null"
        />
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Offer Date*" label-for="offer_date" class="form-label">
        <date-picker
          id="offer_date"
          v-model="offer_date"
          type="date"
          format="MM/DD/YYYY"
          value-type="YYYY-MM-DD"
          style="width: 100%"
          lang="en"
          placeholder="MM/DD/YYYY"
          :state="!$v.offer_date.required && $v.offer_date.$dirty ? false : null"
        >
        </date-picker>
        <b-form-invalid-feedback :state="!$v.offer_date.required && $v.offer_date.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="Start Date*" label-for="start_date" class="form-label">
        <date-picker
          id="start_date"
          v-model="start_date"
          type="date"
          format="MM/DD/YYYY"
          value-type="YYYY-MM-DD"
          style="width: 100%"
          lang="en"
          placeholder="MM/DD/YYYY"
          :state="!$v.start_date.required && $v.start_date.$dirty ? false : null"
        >
        </date-picker>
        <b-form-invalid-feedback :state="!$v.start_date.required && $v.start_date.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-btn variant="primary" :disabled="isLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('update') : 'Add' }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Page from '@/components/Page.vue';
import { required } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/en';

export default {
  extends: Page,
  components: { DatePicker },

  data() {
    return {
      title: 'Add Certificate',
      offer_type: 'Job',
      organization: '',
      position: '',
      status: 'Pending',
      location: '',
      offer_date: null,
      start_date: null,
      allStatuses: ['Pending', 'Accepted', 'Declined'],
      allOfferTypes: ['Job', 'Internship'],
    };
  },

  validations() {
    return {
      offer_type: { required },
      organization: { required },
      position: { required },
      status: { required },
      location: { required },
      offer_date: { required },
      start_date: { required },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
  },

  methods: {
    ...mapActions('student', ['createOffer', 'getOffer', 'updateOffer', 'updateShowEarnedPointToast']),
    get,
    goBack() {
      this.$router.go(-1);
    },
    async fetchQuestion() {
      try {
        const res = await this.getOffer(this.$route.params.id);
        this.offer_type = res.data.offer_type;
        this.organization = res.data.organization;
        this.position = res.data.position;
        this.status = res.data.status;
        this.location = res.data.location;
        this.offer_date = res.data.offer_date;
        this.start_date = res.data.start_date;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    async onSubmit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isLoading = true;
        try {
          const data = {
            offer_type: this.offer_type,
            organization: this.organization,
            position: this.position,
            status: this.status,
            location: this.location,
            offer_date: this.offer_date,
            start_date: this.start_date,
          };

          // let response;
          if (this.$route.params.id) {
            await this.updateOffer({ id: this.$route.params.id, data });
            this.makeToast({ variant: 'success', msg: 'Offer updated!' });
          } else {
            const offerResp=await this.createOffer(data);
            this.makeToast({ variant: 'success', msg: 'Offer asked!' });
            if (offerResp.data.data.points_earned > 0) {
              this.updateShowEarnedPointToast(offerResp.data.data.points_earned);
            }
          }
          setTimeout(() => {
          this.$router.push({ name: 'my-offers' });}, 1000);
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
        this.isLoading = false;
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields.' });
      }
    },
  },
  async mounted() {
    if (this.$route.params.id) {
      this.fetchQuestion();
    }
  },
};
</script>

<style></style>
