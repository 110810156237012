<template>
  <fmv-sidebar type="light" variant="white" class="py-16pt" wheel-propagation>
    <div class="sidebar-heading mb-4" style="font-size: 18px; font-weight: bolder">Filter</div>

    <div class="sidebar-heading">Programs</div>
    <div class="sidebar-block">
      <b-form-group>
        <v-select
          id="offered_programs"
          class="form-control v-select-custom"
          label="title"
          v-model="filters.program_id"
          :reduce="offered_programs => offered_programs.id"
          placeholder="Select Offered Program"
          :options="allPrograms"
          :loading="areProgramsLoading"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events" />
          </template>
          <template slot="option" slot-scope="option">
            <span>{{ option.title }}</span>
          </template>
        </v-select>
      </b-form-group>
    </div>

    <div class="sidebar-heading">Offerings</div>

    <div class="sidebar-block">
      <v-select
        id="offering"
        class="form-control v-select-custom"
        label="text"
        v-model="filters.offering"
        :reduce="item => item.value"
        placeholder="Select offering"
        :options="offeringsOptions"
        multiple
      >
      </v-select>
    </div>

    <div class="sidebar-heading">State</div>
    <div class="sidebar-block">
      <b-form-group>
        <v-select
          id="offered_programs"
          class="form-control v-select-custom"
          label="text"
          v-model="filters.state"
          :reduce="status => status.value"
          placeholder="Select state"
          :options="stateOptions"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events" />
          </template>
          <template slot="option" slot-scope="option">
            <span>{{ option.text }}</span>
          </template>
        </v-select>
      </b-form-group>
    </div>

    <div class="sidebar-heading mb-2" style="z-index: 1;">Tuition Fee Range</div>
    <div class="sidebar-block" style="z-index: 100">
    <vue-slider
      v-if="get(filters, 'tuition.length')"
      v-model="filters.tuition" 
      :min="tuition ? tuition.min_tuition_fee : 0" 
      :max="tuition ? tuition.max_tuition_fee : 100000" 
      :interval="1"
      :tooltip-formatter="formatter1"
    ></vue-slider>
    </div>
  </fmv-sidebar>
</template>

<script>
import { FmvSidebar } from 'fmv-layout';
import { SCHOOL_OFFERINGS } from '@/common/constants';
import { truncate, get, map } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
var UsaStates = require('usa-states').UsaStates;

export default {
  name: 'FiltersSidebar',
  components: { FmvSidebar },

  data() {
    return {
      filters: {
        offering: null,
        tuition: [],
        tuition_to: null,
        program_id: null,
        state: null,
      },
      allPrograms: [],
      areProgramsLoading: false,
      stateOptions: [],
      tuition: {},
      offeringsOptions: SCHOOL_OFFERINGS,
      formatter1: '${value}',
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('uni', ['getUniFilters']),
    breadcrumb() {
      return [{ text: this.$t('studentMsgs.students'), active: true }];
    },
  },

  methods: {
    get,
    truncate,
    ...mapActions('program', ['getAllPublicPrograms']),
    ...mapActions('uni', ['updateUniFilters', 'getMinMaxTuition']),

    async fetchPrograms() {
      this.areProgramsLoading = true;

      const response = await this.getAllPublicPrograms({
        type: 'degree',
      });
      this.allPrograms = response.data;
      this.areProgramsLoading = false;
    },

    async fetchMinMaxTuition() {
      try {
        const response = await this.getMinMaxTuition();
        this.tuition = response.data || {};

        // Set the tuition filter values to either fetched data or default to [0, 10000]
        this.filters.tuition = [
          this.tuition.min_tuition_fee ?? 0,
          this.tuition.max_tuition_fee ?? 100000
        ];
      } catch (error) {
        console.error("Failed to fetch min/max tuition:", error);
        // Optionally set default values in case of an error
        this.filters.tuition = [0, 100000];
  }
},
  },

  watch: {
    filters: {
      deep: true,
      handler(filters) {
        this.updateUniFilters({ filters });
      },
    },
  },
  async mounted() {
    var usStates = new UsaStates();
    this.stateOptions = this.stateOptions.concat(
      map(usStates.states, state => ({
        value: state.name,
        text: state.name,
      })),
    );
    this.fetchPrograms();
    this.fetchMinMaxTuition();
  },
};
</script>
