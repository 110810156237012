<template>
  <b-modal
    ref="partnershipDetailsModal"
    hide-footer
    title="Partnership Details"
    centered
    lazy
    scrollable
    @hide="hideModal"
    size="md"
  >
    <div class="mb-2" v-if="partnership.partner_revoked_at">
      <div>
        <b>Status: </b>
        <b-badge
          :class="'border border-' + (partnership.partner_revoked_at ? `danger text-danger` : `success text-success`)"
          variant="none"
          >{{ partnership.partner_revoked_at ? 'To be dissolved' : 'Active' }}</b-badge
        >
      </div>
      <div><b>Will be revoked on:</b> {{ formatFullDate(partnership.partner_revoked_at) }}</div>
    </div>

    <div class="d-flex align-items-start mb-2">
      <span class="card-title fw600">Partnership Fees</span>
    </div>

    <div class="d-flex align-items-start">
      <span
        ><b>Initiation Fee:</b> ${{ $n(partnership.initial_computed_fee) }} (${{ $n(partnership.partner_initial_fee) }}
        / Recently Active Student
        <i class="material-icons icon-16pt clickable-item text-black-50" v-b-popover.hover.top="ACTIVE_STD_INFO_DESC"
          >info</i
        >)
      </span>
    </div>

    <div class="d-flex align-items-start">
      <span><b>Reference Fee:</b> ${{ $n(partnership.partner_reference_fee) }} / Hire</span>
    </div>

    <div class="d-flex align-items-start" :class="partnership.partner_revoked_at ? 'mb-2' : null">
      <span><b>Recurring Fee:</b> ${{ $n(partnership.partner_recurring_fee) }} / Month</span>
    </div>
    <div class="d-flex align-items-start mb-2" v-if="!partnership.partner_revoked_at">
      <span><b>Next Payment Date:</b> {{ formatFullDate(partnership.partner_next_cycle) }}</span>
    </div>

    <div class="d-flex align-items-start mb-2">
      <span class="card-title fw600">Programs</span>
      <span v-if="!get(partnership, 'programs.length')">: None</span>
    </div>
    <div v-if="get(partnership, 'programs.length')">
      <div class="d-flex align-items-start" v-for="(program, index) in partnership.programs" :key="index">
        <span><md-icon class="mr-2 text-50 icon-16pt">check_circle</md-icon>{{ program.title }}</span>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <a
        class="btn btn-danger"
        v-if="!partnership.partner_revoked_at"
        @click.prevent="viewDissolveModal"
        href="#"
        data-toggle="modal"
        >Dissolve</a
      >
      <a
        class="btn btn-primary"
        v-else-if="get(getLoggedInUser, 'role_type') !== USER_ROLE_TYPES.SCHOOL"
        @click.prevent="viewReactivateModal"
        href="#"
        data-toggle="modal"
        >Reactivate</a
      >
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';
import { formatFullDate, formatDateTime } from '../../common/utils';
import { ACTIVE_STD_INFO_DESC, USER_ROLE_TYPES } from '../../common/constants';
export default {
  name: 'PartnershipDetailsModal',

  props: {
    partnership: Object,
    showModal: { type: Boolean, default: false },
  },

  data() {
    return {
      ACTIVE_STD_INFO_DESC,
      USER_ROLE_TYPES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },

  watch: {
    showModal(value) {
      if (value) {
        this.$refs.partnershipDetailsModal.show();
      }
    },
  },
  methods: {
    ...mapActions('partnership', ['deletePartnership', 'unrevokePartnership']),
    formatFullDate,
    formatDateTime,
    get,
    async viewReactivateModal() {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        'Please ensure that you want to reactivate the partnership. You will be able to access all of the partnership benefits after reactivation.',
        {
          title: 'Reactivate Partnership',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (isConfirmed) {
        await this.unrevokePartnership(this.partnership.id);
        this.makeToast({ variant: 'success', msg: 'Partnership Reactivated!' });
        this.$emit('fetch');
        this.hideModalManual();
      }
    },
    async viewDissolveModal() {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        'Please ensure that you want to dissolve the partnership. You will not be able to access any of the partnership benefits after dissolution. Note: Partnership will be dissolved when current cycle ends.',
        {
          title: 'Dissolve Partnership',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (isConfirmed) {
        await this.deletePartnership(this.partnership.id);
        this.makeToast({ variant: 'success', msg: 'Partnership marked for dissolution!' });
        this.$emit('fetch');
        this.hideModalManual();
      }
    },

    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.partnershipDetailsModal.hide();
      this.hideModal();
    },
  },

  async mounted() {},
};
</script>
