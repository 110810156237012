<template>
  <div>
    <page-header :title="title" :breadcrumb="breadcrumb" :container-class="containerClass" />
    <div class="page-section jobs_container">
      <div :class="containerClass">
        <page-separator title="" />
        <div class="row mb-32pt">
          <div class="col-lg-12 d-flex">
            <div class="flex" style="max-width: 100%">
              <div class="mb-0">
                <p class="text-90" style="font-size: 15px">
                  The following are various career paths that are available as a Certified Nursing Assistant.
                  <b>Note: Students must be logged in to view career details.</b>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="page-section d-flex justify-content-center" v-if="!careers.length && !isLoading">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Careers Found</h4>

              <p class="text-muted">
                Couldn't find any careers. We are constantly adding new career options so please check again after a
                while.
              </p>
            </div>
          </div>
        </div>
        <b-skeleton-wrapper :loading="isLoading" v-else>
          <template #loading>
            <div class="row">
              <div class="col-lg-3 col-md-4 col-sm-6" v-for="item in [1, 2, 3, 4]" :key="item">
                <div class="card card-sm card--elevated p-relative">
                  <span class="js-image">
                    <b-skeleton-img width="100%" class="pb-0 mb-0"></b-skeleton-img>
                  </span>

                  <div class="card-body">
                    <div class="d-flex">
                      <div class="flex">
                        <a class="card-title" href="#" @click.prevent><b-skeleton width="70%"></b-skeleton></a>
                        <small class="text-50 font-weight-bold mb-4pt"><b-skeleton width="50%"></b-skeleton></small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div class="row card-group-row mb-lg-12pt home-card">
            <div v-for="career in careers" :key="career.id" class="col-lg-3 col-md-4 col-sm-6 clickable-item">
              <router-link :to="{ name: 'student-career-details', params: { id: career.id } }">
                <div class="card card-sm card--elevated p-relative">
                  <span
                    class="js-image d-flex justify-content-center clickable-item"
                    data-position="left"
                    :data-height="168"
                  >
                    <img :src="career.photo_url || DefaultCareerImg" alt="cna" class="career-card-img" />
                  </span>

                  <div class="card-body">
                    <div class="d-flex">
                      <router-link :to="{ name: 'student-career-details', params: { id: career.id } }">
                        <div class="card-title">{{ career.name }}</div>
                        <small class="text-50 font-weight-bold mb-4pt d-flex align-items-center">
                          <!-- <i class="material-icons icon-16pt mr-1">{{ categoriesByKey[career.category].icon }}</i> -->
                          {{ $t(`careerPrefCategories.${career.category}`) }}
                          <i
                            class="material-icons icon-16pt ml-1"
                            v-b-popover.hover.top="categoriesByKey[career.category].description"
                            >info</i
                          >
                        </small>
                      </router-link>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6 clickable-item" v-if="showViewMore">
              <router-link :to="{ name: 'student-careers' }" class="text-dark">
                <div class="card card-sm card--elevated p-relative careers-view-more-bg" style="height: 14.5rem">
                  <div class="card-body d-flex align-items-center justify-content-center">
                    <h5 class="card-title">View More</h5>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { mapActions } from 'vuex';
import { CAREER_PREF_CATEGORIES } from '../../../common/constants';
import Page from '@/components/Page.vue';
import DefaultCareerImg from '@/assets/images/placeholders/cna_career.jpg';
import { keyBy } from 'lodash';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  components: { PageHeader, PageSeparator },
  extends: Page,
  name: 'PublicCareers',

  data() {
    return {
      title: 'Career Guide',
      DefaultCareerImg,

      showViewMore: false,
      perPage: 7,
      currentPage: 0,
      careers: [],
      isLoading: false,
      categories: CAREER_PREF_CATEGORIES,
      categoriesByKey: {},
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: { name: 'sch-public-home' } },
        { text: 'Careers', active: true },
      ];
    },
  },

  methods: {
    ...mapActions('settings', ['setSettings']),
    ...mapActions('careerPreference', ['getPublicCareers']),
    async fetchCareers(params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();
      try {
        const response = await this.getPublicCareers({
          limit: this.perPage,
          offset: this.currentPage,
          ...params,
        });

        this.careers = response.data.careers;
        this.showViewMore = response.data.view_more;
      } catch (_err) {
        this.careers = [];
        this.showViewMore = false;
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    this.setSettings({ layout: { layout: 'fixed' } });
    this.categoriesByKey = keyBy(CAREER_PREF_CATEGORIES, 'key');
    this.categoryOptions = [
      { value: null, text: 'All Categories' },
      ...CAREER_PREF_CATEGORIES.map((cat) => ({ value: cat.key, text: cat.name })),
    ];
    this.fetchCareers();
  },
};
</script>
