<template>
  <b-modal ref="secureDocModal" hide-footer :title="documentTitle" centered lazy scrollable @hide="hideModal" size="lg">
    <b-skeleton-img class="w-100" height="500px" v-if="isUrlLoading || isLoading" no-aspect></b-skeleton-img>

    <div v-else-if="!isUrlLoading && fileSrc">
      <div class="alert alert-warning w-100 mb-2" role="alert" v-if="expireAt && isExpired">
        This document has been expired.
      </div>

      <div v-if="expireAt" class="d-flex justify-content-between align-items-center">
        <span> {{ isExpired ? 'Expired' : 'Expires' }} on: {{ formatDateSimple(expireAt) }}</span>
        <b-btn variant="primary" v-if="!isLoading && ['img', 'pdf'].includes(getFileType())" @click="downloadGivenFile"
          >Download</b-btn
        >
      </div>
      <div v-else class="d-flex justify-content-end">
        <b-btn variant="primary" v-if="!isLoading && ['img', 'pdf'].includes(getFileType())" @click="downloadGivenFile"
          >Download</b-btn
        >
      </div>

      <img :src="imageSrc" v-if="(!isLoading && getFileType()) === 'img'" class="w-100 mt-2" />
      <div v-else-if="getFileType() === 'pdf'" v-for="page in numPages" :key="page">
        <pdf :src="pdfSrc" :page="page" class="w-100 mt-2"></pdf>
        <hr v-if="page < numPages" />
      </div>
    </div>
    <span v-else-if="!isLoading && fileSrc"
      >Uploaded file isn't supported for viewing.Click download button to download the file.
      <!-- <a href="#" class="text-primary" @click.prevent="downloadGivenFile">Click to download.</a> -->
    </span>

    <div class="card p-3 mt-3">
      <b-form-group
        :label="question.question"
        label-class="form-label"
        v-for="(question, index) in questions"
        :key="index"
      >
        <b-form-textarea
          class="form-control"
          rows="5"
          :placeholder="question.question"
          :value="question.answer"
          disabled
        ></b-form-textarea>
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
import mime from 'mime';
import pdf from '@teckel/vue-pdf';
import { get } from 'lodash';
import { parseFileNameFromUrl, formatDateSimple, isDateExpired } from '@/common/utils';
import { mapActions } from 'vuex';

export default {
  name: 'SecureDocModal',

  components: { pdf },

  props: {
    showModal: { type: Boolean, default: false },
    documentTitle: { type: String, default: '' },
    id: { type: Number, default: null },
    documentType: { type: String, default: '' },
  },

  data() {
    return {
      isLoading: false,
      imageSrc: null,
      pdfSrc: null,
      numPages: 1,
      showSecurityModal: false,
      isUrlLoading: false,
      fileSrc: null,
      expireAt: null,
      isExpired: false,
      questions: [],
    };
  },

  computed: {},

  watch: {
    async showModal(value) {
      if (value) {
        await this.fetchStudentDocUrl();

        this.$refs.secureDocModal.show();
      }
    },
    expireAt(value) {
      if (value) {
        this.isExpired = isDateExpired(this.expireAt);
      }
    },
  },

  methods: {
    get,
    ...mapActions('fileDownload', ['downloadFile']),
    ...mapActions('student', ['getStudentDocUrl']),
    formatDateSimple,
    async fetchStudentDocUrl() {
      this.isUrlLoading = true;
      try {
        const res = await this.getStudentDocUrl({ student_id: this.id, type: this.documentType });
        this.fileSrc = res.data.url;
        this.expireAt = res.data.expire_at;
        this.questions = get(res, 'data.questions_answers');
        if (['img', 'pdf'].includes(this.getFileType())) {
          this.isLoading = true;
          if (this.getFileType() === 'img') {
            this.loadImage();
          } else {
            this.pdfSrc = pdf.createLoadingTask(this.fileSrc);
            this.pdfSrc.promise.then((loadedPdf) => {
              this.numPages = loadedPdf.numPages;
              this.isLoading = false;
            });
          }
        }
      } catch (err) {
        if (get(err.response, 'status') === 403) {
          this.$emit('verification', this.documentTitle, this.id, this.documentType);
          this.hideModal();
        } else this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isUrlLoading = false;
    },
    hideModal() {
      this.$emit('close');
      setTimeout(() => this.$refs.secureDocModal.hide());

      this.imageSrc = this.pdfSrc = null;
      this.numPages = 1;
      this.isLoading = false;
    },

    hideModalManual() {
      this.$refs.secureDocModal.hide();
      this.hideModal();
    },

    getFileType() {
      if (!this.fileSrc) return;
      const type = mime.getType(parseFileNameFromUrl(this.fileSrc).split('.').pop());

      if (type && type.includes('image')) {
        return 'img';
      } else if (type && type.includes('pdf')) {
        return 'pdf';
      }

      return null;
    },

    loadImage() {
      const img = new Image();
      const vm = this;
      img.onload = () => {
        vm.imageSrc = vm.fileSrc;
        vm.isLoading = false;
      };
      img.src = this.fileSrc;
    },

    async downloadGivenFile() {
      const h = this.$createElement;

      this.makeToast({
        variant: 'primary',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
        toaster: 'b-toaster-bottom-left',
      });

      await this.downloadFile({ fileUrl: this.fileSrc, removeTimestamp: true });

      this.makeToast({
        variant: 'success',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
        toaster: 'b-toaster-bottom-left',
      });
    },
  },

  async mounted() {},
};
</script>




