<template>
  <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
    <h3 class="text-center mb-1">Nice pic! Now, let's learn more about your career preferences.</h3>
    <p class="text-center mb-5">
      Your preferences will help linking you to the career opportunities that are most relevant to you.
    </p>
    <b-form-group label="Desired Work Type" label-for="desired_work_type" label-class="form-label">
      <v-select
        id="desired_work_type"
        class="form-control v-select-custom"
        :class="
          !$v.profile.desired_work_type.required && $v.profile.desired_work_type.$dirty ? 'form-control is-invalid' : ''
        "
        label="text"
        v-model="profile.desired_work_type"
        :reduce="(item) => item.value"
        placeholder="Select the type(s) of work you are looking for."
        :options="workTypeOptions"
        multiple
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!profile.desired_work_type" v-bind="attributes" v-on="events" />
        </template>
      </v-select>
      <b-form-invalid-feedback
        :state="!$v.profile.desired_work_type.required && $v.profile.desired_work_type.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Desired Work Time" label-for="desired_work_time" label-class="form-label">
      <v-select
        id="desired_work_time"
        class="form-control v-select-custom"
        :class="
          !$v.profile.desired_work_time.required && $v.profile.desired_work_time.$dirty ? 'form-control is-invalid' : ''
        "
        label="text"
        v-model="profile.desired_work_time"
        :reduce="(item) => item.value"
        placeholder="Select the time(s) would you be able to work."
        :options="workTimeOptions"
        multiple
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!profile.desired_work_time" v-bind="attributes" v-on="events" />
        </template>
      </v-select>
      <b-form-invalid-feedback
        :state="!$v.profile.desired_work_time.required && $v.profile.desired_work_time.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Desired Work Days" label-for="desired_work_day" label-class="form-label">
      <v-select
        id="desired_work_day"
        class="form-control v-select-custom"
        :class="
          !$v.profile.desired_work_day.required && $v.profile.desired_work_day.$dirty ? 'form-control is-invalid' : ''
        "
        label="text"
        v-model="profile.desired_work_day"
        :reduce="(item) => item.value"
        placeholder="Select the days(s) you would be able to work."
        :options="workDayOptions"
        multiple
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!profile.desired_work_day" v-bind="attributes" v-on="events" />
        </template>
      </v-select>
      <b-form-invalid-feedback
        :state="!$v.profile.desired_work_day.required && $v.profile.desired_work_day.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Preferable Work Travel" label-for="work_travel" label-class="form-label">
      <v-select
        id="work_travel"
        class="form-control v-select-custom"
        :class="!$v.profile.work_travel.required && $v.profile.work_travel.$dirty ? 'form-control is-invalid' : ''"
        label="text"
        v-model="profile.work_travel"
        :reduce="(item) => item.value"
        placeholder="How much are you willing to travel for work?"
        :options="workTravelOptions"
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!profile.work_travel" v-bind="attributes" v-on="events" />
        </template>
      </v-select>
      <b-form-invalid-feedback :state="!$v.profile.work_travel.required && $v.profile.work_travel.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Interested Facility Type(s)" label-for="interested_facility_types" label-class="form-label">
      <v-select
        id="interested_facility_types"
        class="form-control v-select-custom"
        :class="
          !$v.profile.interested_facility_types.required && $v.profile.interested_facility_types.$dirty
            ? 'form-control is-invalid'
            : ''
        "
        label="text"
        v-model="profile.interested_facility_types"
        :reduce="(item) => item.value"
        placeholder="Select the facility types you are interested in working at"
        :options="facilityTypeOptions"
        multiple
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!profile.interested_facility_types" v-bind="attributes" v-on="events" />
        </template>
      </v-select>
      <b-form-invalid-feedback
        :state="
          !$v.profile.interested_facility_types.required && $v.profile.interested_facility_types.$dirty ? false : null
        "
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Language Preference(s)" label-for="lang_preferences" label-class="form-label">
      <v-select
        id="lang_preferences"
        class="form-control v-select-custom"
        :class="
          !$v.profile.lang_preferences.required && $v.profile.lang_preferences.$dirty ? 'form-control is-invalid' : ''
        "
        label="name"
        v-model="profile.lang_preferences"
        :reduce="(item) => item.value"
        placeholder="Select Your Preferred Language(s)"
        :options="langOptions"
        multiple
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!profile.lang_preferences" v-bind="attributes" v-on="events" />
        </template>
      </v-select>
      <b-form-invalid-feedback
        :state="!$v.profile.lang_preferences.required && $v.profile.lang_preferences.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <!-- <b-form-group label="Career Preference(s)" label-for="career_preferences" label-class="form-label">
      <v-select
        id="career_preferences"
        class="form-control v-select-custom"
        label="name"
        v-model="profile.career_preferences"
        :reduce="(item) => item.id"
        placeholder="Select the careers you prefer"
        :options="careerPreferences"
        :loading="areCareerPrefsLoading"
        multiple
      >
        <template slot="option" slot-scope="option">
          <div class="d-flex align-items-center">
            <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
              <b-img :src="option.photo_url" class="img-fluid" width="20" alt="Logo" v-if="option.photo_url" />
              <md-icon class="text-dark" v-else>local_hospital</md-icon>
            </fmv-avatar>
            <i>{{ option.name }}</i
            ><span class="ml-1">- (${{ $n(option.salary_range_from) }} - ${{ $n(option.salary_range_to) }})</span
            ><span class="ml-1">- {{ option.description }}</span>
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          {{ option.name }}
        </template>
      </v-select>
    </b-form-group> -->
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import { map, pick } from 'lodash';
import { mapActions } from 'vuex';
import { getLangOptions } from '../../../../common/utils';

export default {
  components: {},

  props: {
    profileData: { type: Object, required: true },
  },

  data() {
    return {
      profile: {
        desired_work_type: [],
        desired_work_time: [],
        desired_work_day: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
        work_travel: '',
        interested_facility_types: [],
        career_preferences: [],
        lang_preferences: [],
      },

      workDayOptions: [],
      workTypeOptions: [
        { value: 'part_time', text: 'Part-time' },
        { value: 'full_time', text: 'Full-time' },
        { value: 'not_sure', text: 'Not sure' },
      ],
      workTimeOptions: [
        { value: 'day', text: 'Day' },
        { value: 'evening', text: 'Evening' },
        { value: 'overnight', text: 'Overnight' },
      ],
      workTravelOptions: [],
      facilityTypeOptions: [
        { value: 'adult_day_care', text: 'Adult day care' },
        { value: 'assisted_living', text: 'Assisted living' },
        { value: 'hospital', text: 'Hospital' },
        { value: 'hospice_care', text: 'Hospice care' },
        { value: 'home_care', text: 'Home care' },
        { value: 'longterm_care', text: 'Long-term care' },
        { value: 'other', text: 'Other' },
      ],
      careerPreferences: [],
      langOptions: [],
      areCareerPrefsLoading: false,
    };
  },

  validations() {
    return {
      profile: {
        desired_work_type: { required },
        desired_work_time: { required },
        desired_work_day: { required },
        work_travel: { required },
        interested_facility_types: { required },
        career_preferences: {},
        lang_preferences: { required },
      },
    };
  },

  computed: {},

  watch: {
    profileData: {
      handler() {
        this.profile = pick(this.profileData, [
          'desired_work_type',
          'desired_work_time',
          'desired_work_day',
          'work_travel',
          'interested_facility_types',
          'career_preferences',
          'lang_preferences',
        ]);
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('careerPreference', ['getAllCareerPreferences']),
    validateFormData() {
      this.$v.$touch();
      return {
        isValid: !this.$v.$invalid,
        formData: { ...this.profile },
      };
    },

    fillInOptions() {
      this.workDayOptions = map(moment.weekdays(), (day) => ({ value: day.toLowerCase(), text: day }));

      this.workTravelOptions = map([...Array(9).keys()], (num) => ({
        value: `${(num + 1) * 10}`,
        text: `${(num + 1) * 10} miles`,
      }));
      this.workTravelOptions.push({ value: '100+', text: '100+ miles' });

      this.langOptions = getLangOptions();
    },

    async fetchCareerPreferences() {
      this.areCareerPrefsLoading = true;
      this.$emit('areCareerPrefsLoading', this.areCareerPrefsLoading);

      const response = await this.getAllCareerPreferences({});
      this.careerPreferences = response.data;

      this.areCareerPrefsLoading = false;
      this.$emit('areCareerPrefsLoading', this.areCareerPrefsLoading);
    },
  },

  async mounted() {
    this.fillInOptions();
    // this.fetchCareerPreferences();
  },
};
</script>
