<template>
  <div>
    <div :class="containerClass" class="public-jobs-container">
      <page-separator
        :title="
          isLoading
            ? get(getCurrentSchool, 'pp_testimonial_title', 'Testimonials')
            : `Showing ${$n(totalTestimonials)} ${get(getCurrentSchool, 'pp_testimonial_title', 'Testimonials')}`
        "
      />
    </div>

    <div class="page-section jobs_container">
      <div :class="containerClass" class="public-jobs-container">
        <div class="row mb-8pt">
          <div class="col-lg-12 d-flex">
            <div class="flex" style="max-width: 100%">
              <div class="mb-0">
                <p class="text-90" style="font-size: 16px">
                  {{ get(getCurrentSchool, 'pp_testimonial_description') }}
                  <b>If you are a student and would like to share your story, </b>
                  <a>
                    <router-link
                      :to="{ name: 'manage-student-testimonial' }"
                      style="text-decoration: underline"
                      class="text-primary"
                      >click here</router-link
                    ></a
                  >.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center" v-if="!totalTestimonials && !isLoading">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Testimonials Written</h4>

              <p class="text-muted">
                No testimonial has been posted yet. If you are a student, be the first to
                <router-link
                  :to="{ name: 'manage-student-testimonial' }"
                  class="text-primary"
                  style="text-decoration: underline"
                  >write a testimonial</router-link
                >.
              </p>
            </div>
          </div>
        </div>

        <b-skeleton-wrapper :loading="isLoading">
          <template #loading>
            <div class="row card-group-row mb-lg-12pt home-card">
              <div class="col-sm-12 card-group-row__col" v-for="item in [1, 2]" :key="item">
                <div class="card card-lg card-group-row__card">
                  <div class="card-body d-flex flex-column">
                    <div class="d-flex align-items-center">
                      <div class="flex">
                        <div :class="{ 'd-flex align-items-center': !isMobSmallScreen }">
                          <div
                            :class="{
                              'd-flex justify-content-center mb-3': isMobSmallScreen,
                              'mr-16pt': !isMobSmallScreen,
                            }"
                          >
                            <a
                              href="#"
                              @click.prevent
                              class="avatar avatar-xxl overlay js-overlay overlay--primary rounded-circle p-relative o-hidden mb-16pt align-self-center"
                              style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                            >
                              <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                            </a>
                          </div>
                          <div class="flex">
                            <h3><b-skeleton width="100%"></b-skeleton></h3>
                            <div class="mb-0" style="text-transform: none">
                              <b-skeleton width="100%"></b-skeleton>
                              <b-skeleton width="55%"></b-skeleton>
                              <b-skeleton width="70%"></b-skeleton>
                              <b-skeleton width="65%"></b-skeleton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div class="row card-group-row mb-lg-12pt home-card">
            <public-testimonial-card
              v-for="(testimonial, index) in allTestimonials"
              :key="index"
              :testimonial="testimonial"
            />
          </div>
        </b-skeleton-wrapper>

        <!-- <pagination
          v-if="totalTestimonials > allTestimonials.length"
          v-model="currentPage"
          :total-rows="totalTestimonials"
          :per-page="perPage"
          @change="onPageChange"
          aria-controls="jobs-table"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { get, trim, truncate } from 'lodash';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Page from '@/components/Page.vue';
import { mapActions, mapGetters } from 'vuex';
import PublicTestimonialCard from './PublicTestimonialCard.vue';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
export default {
  components: {
    PublicTestimonialCard,
    PageSeparator,
  },
  extends: Page,
  name: 'PublicTestimonialList',
  props: {
    showBreadcrumb: { Boolean, default: false },
  },

  data() {
    return {
      DefaultAvatar,

      title: 'Home',
      completeHeadTitle: undefined,

      isLoading: false,
      totalTestimonials: 0,
      perPage: 20,
      currentPage: 0,
      allTestimonials: [],
      windowWidth: window.innerWidth,
    };
  },

  computed: {
    ...mapGetters('school', ['getCurrentSchool']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: { name: 'sch-public-home' } },
        { text: 'Testimonials', active: true },
      ];
    },

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
  },

  methods: {
    ...mapActions('settings', ['setSettings']),
    ...mapActions('testimonial', ['getPublicTestimonials']),
    get,
    trim,
    truncate,

    async fetchTestimonials(pageNum = 1, params = {}) {
      this.isLoading = true;

      const response = await this.getPublicTestimonials({
        school: this.getCurrentSchool.id,
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...params,
      });
      this.allTestimonials = get(response.data, 'results', []);
      this.totalTestimonials = get(response.data, 'count', 0);
      this.currentPage = pageNum;

      this.isLoading = false;

      if (this.$route.query) {
        this.$nextTick(() => {
          const testimonial = document.getElementById(`testimonial-${this.$route.query.id}`);
          if (testimonial) {
            testimonial.scrollIntoView({ behavior: 'smooth' });

            // Highlighting selected testimonial.
            const testimonialCard = document.getElementById(`testimonial-card-${this.$route.query.id}`);
            const origBorder = testimonialCard.style.border;
            testimonialCard.style.border = '5px solid #e4e6e7';
            setTimeout(() => {
              testimonialCard.style.border = origBorder;
            }, 2000);
          }
        });
      }
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  watch: {
    getCurrentSchool: {
      immediate: true,
      deep: true,
      handler(value) {
        if (get(value, 'id')) {
          this.fetchTestimonials();
          this.completeHeadTitle = `${get(this.getCurrentSchool, 'name', 'Home')} - ${get(
            this.getCurrentSchool,
            'pp_home_title',
            'Transition'
          )}`;
        }
      },
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    // Set page layout if used as a page (use if needed).
    // this.setSettings({ layout: { layout: 'fixed' } });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
