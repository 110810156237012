<template>
  <div id="page-container">
    <div class="page-section jobs_container">
      <div class="container">
        <div class="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt" style="white-space: nowrap">
          <h2 class="mb-0 d-sm-block">External Jobs</h2>
          <div class="w-auto ml-sm-auto table d-flex align-items-center mb-sm-0">
            <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
              <div class="custom-select-icon" style="min-width: 150px">
                <b-form-select
                  class="custom-select-icon__select w-lg-auto"
                  placeholder="Search..."
                  v-model="searchTerm"
                  :options="stateOptions"
                  @input="onSearch"
                />
                <span class="material-icons custom-select-icon__icon">sort</span>
              </div>
              <!-- <b-btn variant="flush" type="submit">
                <md-icon v-text="'search'" />
              </b-btn> -->
            </form>
          </div>
        </div>

        <page-separator title="" />
        <ins
          class="talrooads"
          style="display: inline-block; width: 300px; height: 600px"
          data-ad-id="40467b75-0af0-4939-b933-e0f908e74762"
          data-ad-cid="Y3VzdG9tZXJfaWQ6NjA5NA=="
          data-input-keyword="cna"
          :data-input-loc="getLoggedInUser.linked_entity.state"
        ></ins>
        <!-- <script>
          (TalrooAds = window.TalrooAds || []).push({});
        </script> -->
        <!--   <div class="d-flex justify-content-center" v-if="!totalScholarships && !isLoading">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Scholarships Found</h4>
              <p class="text-muted">No scholarship currently available. Please check again after a while.</p>
            </div>
          </div>
        </div>

        <b-skeleton-wrapper :loading="isLoading" v-else>
          <template #loading>
            <div class="row card-group-row mb-lg-12pt home-card">
              <div class="col-sm-12 card-group-row__col" v-for="item in [1, 2]" :key="item">
                <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card">
                  <div class="card-header d-flex align-items-center">
                    <a href="#" @click.prevent class="card-title flex"> <b-skeleton width="100%"></b-skeleton></a>
                  </div>
                  <div class="card-body d-flex flex-column">
                    <div class="d-flex align-items-center">
                      <div class="flex">
                        <div class="mb-16pt" :class="{ 'd-flex align-items-center': !isMobSmallScreen }">
                          <div class="mr-12pt" :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen }">
                            <a
                              href="#"
                              @click.prevent
                              class="
                                avatar avatar-xl
                                overlay
                                js-overlay
                                overlay--primary
                                rounded-circle
                                p-relative
                                o-hidden
                                mb-16pt
                                align-self-center
                              "
                              style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                            >
                              <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                            </a>
                          </div>
                          <div class="flex">
                            <div class="mb-0" style="text-transform: none">
                              <b-skeleton width="100%"></b-skeleton>
                              <b-skeleton width="55%"></b-skeleton>
                              <b-skeleton width="70%"></b-skeleton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="row card-group-row mb-lg-12pt home-card">
            <div
              class="col-sm-12 card-group-row__col clickable-item"
              v-for="scholarship in scholarships"
              :key="scholarship.id"
              @click.prevent="openScholarshipDetail(scholarship.id)"
            >
              <div class="card card-lg overlay--primary-dodger-blue card-group-row__card">
                <div class="card-header d-flex align-items-center">
                  <span class="card-title flex mr-12pt">{{ scholarship.title }}</span>
                  <span>
                    <i class="material-icons icon-16pt mr-1 text-dark">business</i>
                    <router-link
                      :to="{
                        name:
                          getLoggedInUser && getLoggedInUser.role_type === USER_ROLES.STUDENT
                            ? 'student-employer-profile'
                            : 'redirect-employer-profile',
                        params: { id: scholarship.employer.id },
                      }"
                      class="text-primary"
                      style="text-decoration: underline"
                      >{{ scholarship.employer.name }}</router-link
                    >
                  </span>
                </div>

                <div class="card-body d-flex flex-column">
                  <div class="d-flex align-items-center">
                    <div class="flex">
                      <div class="mb-16pt" :class="{ 'd-flex align-items-center': !isMobSmallScreen }">
                        <div class="mr-12pt" :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen }">
                          <a
                            href="#"
                            @click.prevent
                            class="
                              avatar avatar-xl
                              overlay
                              js-overlay
                              overlay--primary
                              rounded
                              p-0
                              o-hidden
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                            style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                          >
                            <img
                              class="avatar-img m-0 rounded"
                              :src="scholarship.image_url ? scholarship.image_url : DefaultAvatar"
                              width="160"
                              height="auto"
                            />
                          </a>
                        </div>
                        <div class="flex">
                          <div class="mb-0" style="text-transform: none">
                            <div>
                              <i class="material-icons icon-16pt">attach_money</i> Amount:
                              <strong style="font-weight: bold"
                                >${{ scholarship.min_amount }} - ${{ scholarship.max_amount }}</strong
                              >
                            </div>
                            <div>
                              <i class="material-icons icon-16pt">calendar_today</i> Deadline:
                              <strong style="font-weight: bold">{{ formatFullDate(scholarship.deadline) }}</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-button">
                    <div class="mb-3">
                      {{ scholarship.summary }}
                    </div>
                    <ul>
                      <li v-for="(program, index) in scholarship.programs" :key="`schlr-prgrm-${index}`">
                        <a href="#" @click.prevent
                          ><span class="material-icons icon-16pt text-100">check</span>{{ program.title }}</a
                        >
                      </li>
                    </ul>
                    <b-btn
                      style="width: 100%"
                      href="#"
                      variant="light"
                      @click.prevent="openScholarshipDetail(scholarship.id)"
                    >
                      <i class="material-icons icon-16pt mr-1">launch</i>

                      Apply Now</b-btn
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper> -->

        <!-- <pagination
          v-if="totalScholarships > scholarships.length"
          v-model="currentPage"
          :total-rows="totalScholarships"
          :per-page="perPage"
          @change="onPageChange"
          aria-controls="scholarships-table"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { debounce, get, map } from 'lodash';
// import Pagination from '../Ui/Pagination.vue';
import { mapActions, mapGetters } from 'vuex';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import { USER_ROLES } from '@/common/constants';
import Page from '@/components/Page.vue';
import { formatFullDate } from '@/common/utils';
var UsaStates = require('usa-states').UsaStates;
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  name: 'ExternalJobs',
  components: { PageSeparator },
  extends: Page,

  data() {
    return {
      DefaultAvatar,
      title: 'External Jobs',
      isLoading: true,
      perPage: 14,
      currentPage: 1,
      totalScholarships: 0,
      scholarships: [],
      searchTerm: '',
      ordering: '-id',
      pageFromCount: 0,
      pageToCount: 0,
      windowWidth: window.innerWidth,
      firstLoad: true,
      USER_ROLES,
      stateOptions: [],
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('school', ['getCurrentSchool']),

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
  },

  watch: {},

  methods: {
    ...mapActions('scholarships', ['getAllScholarship', 'getAllScholarshipList']),
    get,
    formatFullDate,

    onPageChange(pageNum) {
      this.fetchScholarships(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchScholarships();
    },

    onSearch() {
      // var ad = document.getElementById('talrooads')[0];
      // var ad = document.getElementsByClassName('talrooads')[0];
      // ad.dataset.inputLoc = this.searchTerm;
      // ad.refresh();
    },

    debouncedSearchScholarships: debounce((vm) => {
      vm.fetchScholarships();
    }, 500),

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    this.searchTerm = this.getLoggedInUser.linked_entity.state;
    var usStates = new UsaStates();
    this.stateOptions = this.stateOptions.concat(
      map(usStates.states, (state) => ({
        value: state.name,
        text: state.name,
      }))
    );
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
