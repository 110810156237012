<template>
  <div style="margin-top: -18px">
    <div
      class="bg-dark page-section employer-header-bg border-bottom-white mb-10pt img-fluid"
      :style="`background-image: url('${get(getCurrentSchool, 'profile_bg_img_url')}');`"
    >
      <div
        class="page__container mb-0"
        :style="`background:(rgba(${rgbDarkColor.r}, ${rgbDarkColor.g}, ${rgbDarkColor.b}, 0.95) !important;`"
      >
        <div class="text-center">
          <h1 class="text-white mb-8pt employer-header-h1" style="">
            {{ get(getCurrentSchool, 'pp_home_title') }}
          </h1>
          <p class="measure-lead mx-auto text-white mb0 employer-header-text">
            {{ get(getCurrentSchool, 'pp_home_description') }}
          </p>
          <a>
            <router-link :to="{ name: 'student-signup' }" class="btn btn-primary mb-3">{{
              get(getCurrentSchool, 'pp_cta_link_text')
            }}</router-link></a
          >
          <!-- <p class="measure-lead mx-auto text-white mb0" style="font-size: 16px">
            Already joined?
            <a>
              <router-link
                :to="{ name: 'login' }"
                style="text-decoration: underline; font-size: 18px"
                class="text-secondary"
                >Login here</router-link
              ></a
            >
          </p> -->
        </div>
      </div>
    </div>

    <div class="border-bottom-2 container mx-auto" style="display: center">
      <b-tabs
        nav-class="nav-tabs-card bg-white"
        content-class=" "
        class="mt-0"
        id="homePageTab"
        @input="onTabsChanged"
        style="scroll-margin-top: 66px; font-size: 16.5px"
        lazy
      >
        <b-tab title="Jobs">
          <div class="page-section">
            <div class=""><public-jobs /></div>
          </div>
        </b-tab>
        <!-- <b-tab :title="get(getCurrentSchool, 'pp_testimonial_title', 'Testimonials')">
          <div class="page-section">
            <div class="container"><public-testimonial-list /></div>
          </div>
        </b-tab> -->
        <b-tab title="Sponsorships">
          <scholarships-listing />
        </b-tab>
        <b-tab title="Companies" class="tab-secondary">
          <div class="page-section">
            <div class=""><public-employers /></div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { get } from 'lodash';
import PublicJobs from '../Jobs/PublicJobs.vue';
// import PublicTestimonialList from '../Testimonials/PublicTestimonialList.vue';
import PublicEmployers from '../Employers/PublicEmployers.vue';
import Page from '@/components/Page.vue';
import { hexToRgb } from '../../../common/utils';
import ScholarshipsListing from '../../../components/Scholarships/ScholarshipsListing.vue';

export default {
  extends: Page,
  name: 'SchlPublicHome',

  components: {
    PublicJobs,
    PublicEmployers,
    ScholarshipsListing,
    //  PublicTestimonialList
  },

  data() {
    return {
      title: 'Home',
      completeHeadTitle: 'Home - Transition',

      firstLoad: true,
    };
  },

  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    breadcrumb() {
      return [{ text: this.$t('home'), active: true }];
    },
    rgbDarkColor() {
      return hexToRgb(get(this.getCurrentSchool, 'dark_color', '#214e6f'));
    },
  },

  watch: {
    getCurrentSchool: {
      immediate: true,
      deep: true,
      handler(value) {
        if (get(value, 'id')) {
          this.completeHeadTitle = `${get(this.getCurrentSchool, 'name', 'Home')} - ${get(
            this.getCurrentSchool,
            'pp_home_title',
            'Transition',
          )}`;
        }
      },
    },
  },

  methods: {
    ...mapActions('settings', ['setSettings']),
    ...mapActions('auth', ['verifyTemporaryToken']),

    get,
    onTabsChanged() {
      if (!this.firstLoad) {
        document.getElementById('homePageTab').scrollIntoView({ behavior: 'smooth' });
      }
      this.firstLoad = false;
    },
  },

  async mounted() {
    this.setSettings({ layout: { layout: 'fixed' } });
  },
};
</script>
<style scoped lang="scss">
@media (max-width: 1200px) {
  .image-container {
    padding: 0 !important;
  }
}
</style>
