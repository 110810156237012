<template>
  <div>
    <div class="d-flex" :class="!isParent ? 'w-75 ml-auto' : ''">
      <b-avatar
        v-if="getLoggedInUser.first_name && getLoggedInUser.last_name"
        variant="primary"
        size="40px"
        class="ml-2"
        :text="`${getLoggedInUser.first_name[0].toUpperCase()}${getLoggedInUser.last_name[0].toUpperCase()}`"
      />
      <b-avatar variant="primary" size="40px" v-else />
      <div class="w-100 ml-4">
        <form @submit.prevent="onSubmit">
          <textarea
            id="commentText"
            class="form-control mb-3"
            rows="3"
            :placeholder="commentPlaceholder"
            v-model="comment"
            :disabled="isPosting"
            required
          />
          <div class="text-right mt-2">
            <b-button type="submit" variant="primary" :disabled="isPosting" style="min-width: 100px" class="btn-normal">
              <i v-if="isPosting" class="fas fa-circle-notch fa-spin" />
              <span v-else> Post </span>
            </b-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PostComment',

  props: {
    obj: { type: Object, default: () => {} },
    isParent: { type: Boolean, default: false },
    parentCommentId: { type: Number, default: null },
    replyToComment: { type: Number, default: null },
    replyToMentionName: { type: String, default: null },
    type: { type: String, default: null },
    commentPlaceholder: { type: String, default: null },
  },

  data() {
    return {
      comment: null,
      isPosting: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },

  methods: {
    ...mapActions('comments', ['createComment']),

    async onSubmit() {
      this.isPosting = true;

      try {
        const data = {
          text: this.comment,
          comment_on_type: this.type,
          comment_on_id: this.obj.id, //(skill id)

          ...(this.parentCommentId && { parent_comment: this.parentCommentId }),
          ...(this.replyToComment && { reply_to_comment: this.replyToComment }),
        };

        await this.createComment(data);
        this.comment = null;
        this.makeToast('success', 'Comment Posted!');
      } catch (error) {
        this.makeToast('danger', this.$t('errorMsgs.genErrorMsg'));
      }
      this.isPosting = false;
      this.$emit('posted');
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },
  },
  async mounted() {
    if (this.replyToMentionName) {
      this.comment = `@${this.replyToMentionName} `;
    }
  },
};
</script>
