<template>
  <div>
    <div :class="containerClass">
      <div class="back-result-lg font-weight-bold">
        <a href="#" @click.prevent="goBack()" style="text-decoration: underline" class="mr-2">
          <span class="material-icons icon-32pt">navigate_before</span>
        </a>
      </div>
      <sch-detail />
    </div>
  </div>
</template>


<script>
import Page from '@/components/Page.vue';

import SchDetail from './SchDetail.vue';
export default {
  components: {
    SchDetail,
  },
  extends: Page,

  data() {
    return {
      title: 'Sponsorship Details',
    };
  },

  computed: {},

  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },

  async mounted() {},
};
</script>
