<template>
  <div :class="containerClass" class="page-section">
    <h2>
      My Uploads

      <i class="material-icons icon-16pt clickable-item" v-b-popover.hover.top="`Manage and upload your documents.`"
        >info</i
      >
    </h2>
    <div class="card">
      <div class="card-header">
        <h5 class="my-0">Upload Guidelines</h5>
      </div>
      <div style="font-size: 16px" class="px-3 my-4">
        Follow the guidelines mentioned below while uploading your documents. If you have any query, contact support for
        assistance.
        <br />
        <br />
        <b>What are the acceptable file formats and file sizes for uploads?</b>
        <ul>
          <li>Acceptable file formats: PDF, PNG, JPG</li>
        </ul>
        <b> What are the page image guidelines? </b>
        <ul>
          <li>Choose a well-lit area</li>
          <li>Lay the document flat and have nothing in the background</li>
          <li>Ensure clear legibility and name visibility</li>
          <li>Frame the entire document, no cut corners</li>
          <li>Focus and stabilize the camera</li>
        </ul>
      </div>

      <div class="row" :style="isMobileScreen ? '' : 'margin-left: 140px; margin-right: 140px'">
        <div class="col-md-6 text-center mb-2">
          <img src="/images/docs/good-example.png" alt="" />
          <div class="my-2">
            <md-icon class="text-success" style="font-size: 19px">check_circle</md-icon>
            <b class="text-success"> Good ID </b>
          </div>
        </div>
        <div class="col-md-6 text-center mb-2">
          <img src="/images/docs/bad-example1.png" alt="" />
          <div class="my-2">
            <md-icon class="text-danger" style="font-size: 19px">block</md-icon>
            <b class="text-danger"> Bad: </b>
            The ID is blurry
          </div>
        </div>
      </div>
      <div
        v-if="!isShowMore"
        class="text-center text-primary clickable-item mb-3"
        @click.prevent="() => (isShowMore = true)"
      >
        show more
      </div>
      <div v-if="isShowMore">
        <div class="row" :style="isMobileScreen ? '' : 'margin-left: 140px; margin-right: 140px'">
          <div class="col-md-6 text-center mb-2" v-for="(doc, index) in badDocImages" :key="index">
            <img :src="doc.img" alt="" />
            <div class="my-2">
              <md-icon class="text-danger" style="font-size: 19px">block</md-icon>
              <b class="text-danger"> Bad: </b>
              {{ doc.text }}
            </div>
          </div>
        </div>
        <div class="text-center text-primary clickable-item mb-3" @click.prevent="() => (isShowMore = false)">
          show less
        </div>
      </div>
    </div>

    <b-skeleton-wrapper :loading="isPIITypeConfigLoading || isPIIConfigLoading || isLoading">
      <template #loading>
        <div class="card">
          <div class="card-body">
            <div class="d-flex mb-1">
              <div class="flex">
                <b-skeleton width="100%"></b-skeleton>
                <b-skeleton width="55%"></b-skeleton>
                <b-skeleton width="70%"></b-skeleton>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="card" v-if="isObjectEmpty">
        <div class="card-body">
          <div class="d-flex mb-1">
            <div class="flex">
              <i>No Enrolled program found to upload documents.</i>
            </div>
          </div>
        </div>
      </div>

      <b-form
        class="col-md-12 px-0 pt-0"
        @submit.prevent="onSubmit"
        v-else-if="!isObjectEmpty && hasValueOtherThanDisabled"
      >
        <div class="card" id="piiCard" style="scroll-margin-top: 68px">
          <div class="card-header">
            <h5 class="my-0">Records</h5>
          </div>
          <div class="card-body">
            <div v-if="isPIITypeConfigLoading || isPIIConfigLoading">
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </div>
            <div v-for="doc in piis" :key="doc.id">
              <b-form-group
                :label="`${get(doc, 'title')}${
                  get(piiTypesConfig, get(doc, 'type')) === PII_CONFIG_TYPE.REQUIRED ? '*' : ''
                }`"
                :label-for="get(doc, 'type')"
                label-class="form-label"
                class="row-align-items-center"
                v-if="
                  get(piiTypesConfig, get(doc, 'type')) !== PII_CONFIG_TYPE.DISABLED &&
                  recordsKeys.includes(get(doc, 'type'))
                "
              >
                <span v-if="!get(piiTypes, get(doc, 'type'))">
                  <a
                    href="#"
                    class="text-primary"
                    @click.prevent="
                      openAddModal(doc, get(piiTypesConfig, get(doc, 'type')) === PII_CONFIG_TYPE.REQUIRED)
                    "
                    ><i class="fas fa-upload mr-2"></i>Upload File
                  </a>
                </span>
                <span v-else-if="get(piiTypes, get(doc, 'type'))">
                  <div class="row">
                    <div class="col-md-10">
                      <a
                        href="#"
                        @click.prevent="
                          downloadFile({
                            fileUrl: get(piiTypes, get(doc, 'type')),
                            removeTimestamp: true,
                          })
                        "
                        v-b-popover.hover.top="'Download'"
                        ><i class="fas fa-file text-primary"></i>
                        {{ parseFileNameFromUrl(get(piiTypes, get(doc, 'type')), true) }}
                      </a>
                      <span
                        v-if="get(expiresAt, get(doc, 'type'))"
                        :class="{ 'text-danger': isPIIExpired(get(expiresAt, get(doc, 'type'))) }"
                      >
                        ({{ isPIIExpired(get(expiresAt, get(doc, 'type'))) ? 'Expired' : 'Expires' }} on:
                        {{ formatDateSimple(get(expiresAt, get(doc, 'type'))) }})</span
                      >
                    </div>
                    <div class="text-right col-md-2">
                      <a href="#" class="text-danger" @click.prevent="removePiiFile(get(doc, 'type'), get(doc, 'type'))"
                        ><i class="fas fa-times-circle"></i> Remove
                      </a>
                    </div>
                  </div>
                </span>

                <b-form-invalid-feedback
                  :state="
                    !get($v.piiTypes, get(doc, 'type')).required && get($v.piiTypes, get(doc, 'type')).$dirty
                      ? false
                      : null
                  "
                  >This field is required.</b-form-invalid-feedback
                >
              </b-form-group>
            </div>
          </div>
        </div>

        <!-- Credentials -->

        <div class="card" id="piiCard" style="scroll-margin-top: 68px">
          <div class="card-header">
            <h5 class="my-0">Credentials</h5>
          </div>
          <div class="card-body">
            <div v-if="isPIIConfigLoading">
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </div>
            <div v-for="doc in piis" :key="doc.id">
              <b-form-group
                :label="`${get(doc, 'title')}${
                  get(piiTypesConfig, get(doc, 'type')) === PII_CONFIG_TYPE.REQUIRED ? '*' : ''
                }`"
                :label-for="get(doc, 'type')"
                label-class="form-label"
                class="row-align-items-center"
                v-if="
                  get(piiTypesConfig, get(doc, 'type')) !== PII_CONFIG_TYPE.DISABLED &&
                  credentialsKeys.includes(get(doc, 'type'))
                "
              >
                <span v-if="!get(piiTypes, get(doc, 'type'))">
                  <a
                    href="#"
                    class="text-primary"
                    @click.prevent="
                      openAddModal(doc, get(piiTypesConfig, get(doc, 'type')) === PII_CONFIG_TYPE.REQUIRED)
                    "
                    ><i class="fas fa-upload mr-2"></i>Upload File
                  </a>
                </span>
                <span v-else-if="get(piiTypes, get(doc, 'type'))">
                  <div class="row">
                    <div class="col-md-10">
                      <a
                        href="#"
                        @click.prevent="
                          downloadFile({
                            fileUrl: get(piiTypes, get(doc, 'type')),
                            removeTimestamp: true,
                          })
                        "
                        v-b-popover.hover.top="'Download'"
                        ><i class="fas fa-file text-primary"></i>
                        {{ parseFileNameFromUrl(get(piiTypes, get(doc, 'type')), true) }}
                      </a>
                      <span
                        v-if="get(expiresAt, get(doc, 'type'))"
                        :class="{ 'text-danger': isPIIExpired(get(expiresAt, get(doc, 'type'))) }"
                      >
                        ({{ isPIIExpired(get(expiresAt, get(doc, 'type'))) ? 'Expired' : 'Expires' }} on:
                        {{ formatDateSimple(get(expiresAt, get(doc, 'type'))) }})</span
                      >
                    </div>
                    <div class="text-right col-md-2">
                      <a href="#" class="text-danger" @click.prevent="removePiiFile(get(doc, 'type'), get(doc, 'type'))"
                        ><i class="fas fa-times-circle"></i> Remove
                      </a>
                    </div>
                  </div>
                </span>

                <b-form-invalid-feedback
                  :state="
                    !get($v.piiTypes, get(doc, 'type')).required && get($v.piiTypes, get(doc, 'type')).$dirty
                      ? false
                      : null
                  "
                  >This field is required.</b-form-invalid-feedback
                >
              </b-form-group>
            </div>
          </div>
        </div>

        <!-- <b-btn variant="primary" :disabled="isFormLoading" style="width: 200px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>Save</span>
      </b-btn> -->
      </b-form>
    </b-skeleton-wrapper>
    <add-pii-modal
      :show-modal="showDocModal"
      :title="addDocTitle"
      :type="addDocType"
      :isRequired="isRequired"
      :isDocDateReq="isDocDateReq"
      :questions="questions"
      @add="piiDocAdded"
      @close="hideModal"
    />
  </div>
</template>

<script>
import { forEach, get, keyBy } from 'lodash';
import { mapActions } from 'vuex';
import AddPiiModal from '../../SuperAdminPortal/Students/AddPiiModal.vue';
import Page from '../../../components/Page.vue';
import { PII_TYPES, PII_CONFIG_TYPE } from '../../../common/constants';

import {
  parseFileNameFromUrl,
  formatToAPIDate,
  formatDateSimple,
  isDateExpired as isPIIExpired,
} from '../../../common/utils';
import { requiredIf } from 'vuelidate/lib/validators';

export default {
  components: { AddPiiModal },
  extends: Page,

  data() {
    return {
      title: 'Manage Records & Credentials',
      PII_TYPES,
      PII_CONFIG_TYPE,
      showDocModal: false,
      addDocType: '',
      addDocTitle: '',
      hasValueOtherThanDisabled: false,
      isPIITypeConfigLoading: false,
      recordsKeys: [
        'state_id',
        'social_sec_card',
        'green_card',
        'passport',
        'birth_cert',
        'immunization',
        'tb_test',
        'covid_vacc',
        'vehicle_reg',
        'background_check',
        'student_review',
        'rec_misc_doc_1',
        'rec_misc_doc_2',
        'rec_misc_doc_3',
        'rec_misc_doc_4',
        'rec_misc_doc_5',
        'rec_misc_doc_6',
        'rec_misc_doc_7',
      ],
      credentialsKeys: [
        'cpr',
        'bls',
        'first_aid',
        'completion_cert',
        'school_waiver',
        'ged',
        'cred_misc_doc_1',
        'cred_misc_doc_2',
        'cred_misc_doc_3',
        'cred_misc_doc_4',
        'cred_misc_doc_5',
        'cred_misc_doc_6',
        'cred_misc_doc_7',
      ],
      piiTypes: {
        tb_test: '',
        covid_vacc: '',
        social_sec_card: '',
        state_id: '',
        cpr: '',
        bls: '',
        first_aid: '',
        background_check: '',
        student_review: '',
        rec_misc_doc_1: '',
        rec_misc_doc_2: '',
        rec_misc_doc_3: '',
        rec_misc_doc_4: '',
        rec_misc_doc_5: '',
        rec_misc_doc_6: '',
        rec_misc_doc_7: '',
        cred_misc_doc_1: '',
        cred_misc_doc_2: '',
        cred_misc_doc_3: '',
        cred_misc_doc_4: '',
        cred_misc_doc_5: '',
        cred_misc_doc_6: '',
        cred_misc_doc_7: '',
        vehicle_reg: '',
        green_card: '',
        passport: '',
        completion_cert: '',
        birth_cert: '',
        school_waiver: '',
        ged: '',
        immunization: '',
      },
      expiresAt: {
        tb_test: null,
        covid_vacc: null,
        social_sec_card: null,
        state_id: null,
        cpr: null,
        bls: null,
        first_aid: null,
        background_check: null,
        student_review: null,
        rec_misc_doc_1: null,
        rec_misc_doc_2: null,
        rec_misc_doc_3: null,
        rec_misc_doc_4: null,
        rec_misc_doc_5: null,
        rec_misc_doc_6: null,
        rec_misc_doc_7: null,
        cred_misc_doc_1: null,
        cred_misc_doc_2: null,
        cred_misc_doc_3: null,
        cred_misc_doc_4: null,
        cred_misc_doc_5: null,
        cred_misc_doc_6: null,
        cred_misc_doc_7: null,
        vehicle_reg: null,
        green_card: null,
        passport: null,
        completion_cert: null,
        birth_cert: null,
        school_waiver: null,
        ged: null,
        immunization: null,
      },
      piis: {},
      isLoading: false,
      isPIIConfigLoading: false,
      isObjectEmpty: false,
      student_piis: [],

      piiTypesConfig: {},
      windowWidth: window.innerWidth,
      isRequired: false,
      isDocDateReq: false,
      questions: [],
      isShowMore: false,
      badDocImages: [
        {
          img: '/images/docs/bad-example2.png',
          text: 'The name is cropped',
        },
        {
          img: '/images/docs/bad-example3.png',
          text: 'The photo is cropped',
        },
        {
          img: '/images/docs/bad-example4.png',
          text: 'Zoomed in too much',
        },
        {
          img: '/images/docs/bad-example5.png',
          text: 'Photocopy of an ID',
        },
      ],
    };
  },

  validations() {
    return {
      piiTypes: {
        state_id: {
          required: requiredIf(() => get(this.piiTypesConfig, 'state_id') === PII_CONFIG_TYPE.REQUIRED),
        },
        social_sec_card: {
          required: requiredIf(() => get(this.piiTypesConfig, 'social_sec_card') === PII_CONFIG_TYPE.REQUIRED),
        },
        tb_test: { required: requiredIf(() => get(this.piiTypesConfig, 'tb_test') === PII_CONFIG_TYPE.REQUIRED) },
        covid_vacc: { required: requiredIf(() => get(this.piiTypesConfig, 'covid_vacc') === PII_CONFIG_TYPE.REQUIRED) },
        cpr: { required: requiredIf(() => get(this.piiTypesConfig, 'cpr') === PII_CONFIG_TYPE.REQUIRED) },
        bls: { required: requiredIf(() => get(this.piiTypesConfig, 'bls') === PII_CONFIG_TYPE.REQUIRED) },
        first_aid: { required: requiredIf(() => get(this.piiTypesConfig, 'first_aid') === PII_CONFIG_TYPE.REQUIRED) },
        background_check: {
          required: requiredIf(() => get(this.piiTypesConfig, 'background_check') === PII_CONFIG_TYPE.REQUIRED),
        },
        student_review: {
          required: requiredIf(() => get(this.piiTypesConfig, 'student_review') === PII_CONFIG_TYPE.REQUIRED),
        },
        rec_misc_doc_1: {
          required: requiredIf(() => get(this.piiTypesConfig, 'rec_misc_doc_1') === PII_CONFIG_TYPE.REQUIRED),
        },
        rec_misc_doc_2: {
          required: requiredIf(() => get(this.piiTypesConfig, 'rec_misc_doc_2') === PII_CONFIG_TYPE.REQUIRED),
        },
        rec_misc_doc_3: {
          required: requiredIf(() => get(this.piiTypesConfig, 'rec_misc_doc_3') === PII_CONFIG_TYPE.REQUIRED),
        },
        rec_misc_doc_4: {
          required: requiredIf(() => get(this.piiTypesConfig, 'rec_misc_doc_4') === PII_CONFIG_TYPE.REQUIRED),
        },
        rec_misc_doc_5: {
          required: requiredIf(() => get(this.piiTypesConfig, 'rec_misc_doc_5') === PII_CONFIG_TYPE.REQUIRED),
        },
        rec_misc_doc_6: {
          required: requiredIf(() => get(this.piiTypesConfig, 'rec_misc_doc_6') === PII_CONFIG_TYPE.REQUIRED),
        },
        rec_misc_doc_7: {
          required: requiredIf(() => get(this.piiTypesConfig, 'rec_misc_doc_7') === PII_CONFIG_TYPE.REQUIRED),
        },
        cred_misc_doc_1: {
          required: requiredIf(() => get(this.piiTypesConfig, 'cred_misc_doc_1') === PII_CONFIG_TYPE.REQUIRED),
        },
        cred_misc_doc_2: {
          required: requiredIf(() => get(this.piiTypesConfig, 'cred_misc_doc_2') === PII_CONFIG_TYPE.REQUIRED),
        },
        cred_misc_doc_3: {
          required: requiredIf(() => get(this.piiTypesConfig, 'cred_misc_doc_3') === PII_CONFIG_TYPE.REQUIRED),
        },
        cred_misc_doc_4: {
          required: requiredIf(() => get(this.piiTypesConfig, 'cred_misc_doc_4') === PII_CONFIG_TYPE.REQUIRED),
        },
        cred_misc_doc_5: {
          required: requiredIf(() => get(this.piiTypesConfig, 'cred_misc_doc_5') === PII_CONFIG_TYPE.REQUIRED),
        },
        cred_misc_doc_6: {
          required: requiredIf(() => get(this.piiTypesConfig, 'cred_misc_doc_6') === PII_CONFIG_TYPE.REQUIRED),
        },
        cred_misc_doc_7: {
          required: requiredIf(() => get(this.piiTypesConfig, 'cred_misc_doc_7') === PII_CONFIG_TYPE.REQUIRED),
        },
        vehicle_reg: {
          required: requiredIf(() => get(this.piiTypesConfig, 'vehicle_reg') === PII_CONFIG_TYPE.REQUIRED),
        },
        green_card: {
          required: requiredIf(() => get(this.piiTypesConfig, 'green_card') === PII_CONFIG_TYPE.REQUIRED),
        },
        passport: {
          required: requiredIf(() => get(this.piiTypesConfig, 'passport') === PII_CONFIG_TYPE.REQUIRED),
        },
        completion_cert: {
          required: requiredIf(() => get(this.piiTypesConfig, 'completion_cert') === PII_CONFIG_TYPE.REQUIRED),
        },
        birth_cert: {
          required: requiredIf(() => get(this.piiTypesConfig, 'birth_cert') === PII_CONFIG_TYPE.REQUIRED),
        },
        school_waiver: {
          required: requiredIf(() => get(this.piiTypesConfig, 'school_waiver') === PII_CONFIG_TYPE.REQUIRED),
        },
        ged: {
          required: requiredIf(() => get(this.piiTypesConfig, 'ged') === PII_CONFIG_TYPE.REQUIRED),
        },
        immunization: {
          required: requiredIf(() => get(this.piiTypesConfig, 'immunization') === PII_CONFIG_TYPE.REQUIRED),
        },
      },
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Manage Records & Credentials', active: true },
      ];
    },
    isFormLoading() {
      return this.isLoading || this.isPIIConfigLoading;
    },
    isMobileScreen() {
      return this.windowWidth <= 1200;
    },
  },

  methods: {
    ...mapActions('student', ['getMyStudentProfile', 'updateMyPIIDocs']),
    ...mapActions('fileDownload', ['downloadFile']),
    ...mapActions('school', ['getSchoolConfigPiis', 'getStudentPiiConfig']),
    parseFileNameFromUrl,
    formatDateSimple,
    get,
    isPIIExpired,

    handleResize() {
      this.windowWidth = window.innerWidth;
    },

    openAddModal(doc, isRequired) {
      if (this.isFormLoading) return;

      this.showDocModal = true;
      this.addDocType = doc.type;
      this.addDocTitle = doc.title;
      this.questions = doc.custom_questions;
      this.isDocDateReq = doc.req_expiry_date;
      this.isRequired = isRequired;
    },
    piiDocAdded(file, url, type, date, questions) {
      this.showDocModal = false;
      this.documentAdded = true;
      this.piiTypes[type] = url;
      this.expiresAt[type] = date;
      this.addDocType = '';
      this.addDocTitle = '';
      this.questions = questions;
      this.onSubmit();
    },
    hideModal() {
      this.showDocModal = false;
      this.addDocType = '';
      this.addDocTitle = '';
    },

    async removePiiFile(fileType) {
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        'Please confirm that you want to remove the selected document. You will need to reupload the document if required.',
        {
          title: 'Are you sure?',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (isConfirmed) {
        this.piiTypes[fileType] = null;
        this.expiresAt[fileType] = null;
        this.onSubmit();
      }
    },

    async onSubmit() {
      this.isLoading = true;

      // this.$v.$touch();

      // if (this.$v.$invalid) {
      //   this.makeToast({ variant: 'danger', msg: 'Please fill the form correctly.' });
      // } else {
      try {
        let piiArr = [];
        for (const item in this.piiTypes) {
          if (this.piiTypes[item]) {
            piiArr.push({
              questions_answers: this.questions,
              type: item,
              document_url: this.piiTypes[item],
              expire_at: this.expiresAt[item] ? formatToAPIDate(this.expiresAt[item]) : null,
            });
          }
        }
        await this.updateMyPIIDocs({
          data: { student_piis: piiArr },
        });
        this.makeToast({ variant: 'success', msg: 'Documents Updated Successfully' });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      // }

      this.isLoading = false;
    },

    async downloadGivenFile({ fileUrl, removeTimestamp = true }) {
      const h = this.$createElement;

      this.makeToast({
        variant: 'primary',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
        toaster: 'b-toaster-bottom-left',
      });

      await this.downloadFile({ fileUrl, removeTimestamp });

      this.makeToast({
        variant: 'success',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
        toaster: 'b-toaster-bottom-left',
      });
    },

    async fetchStudentProfile() {
      this.isLoading = true;

      const resp = await this.getMyStudentProfile(true);

      this.student_piis = resp.student_piis;

      this.student_piis.forEach((pii) => {
        this.piiTypes[pii.type] = pii.document_url;
        this.expiresAt[pii.type] = pii.expire_at;
      });
      forEach(this.piiTypes, (type, key) => {
        if (!type) {
          this.piiTypes[key] = null;
        }
      });

      this.isLoading = false;
    },

    async fetchSclConfigPiis() {
      this.isPIIConfigLoading = true;
      try {
        const res = await this.getSchoolConfigPiis();
        // res.data.forEach((pii) => {
        //   this.piiTypesConfig[pii.type] = pii.status;
        // });

        this.piis = keyBy(res.data, 'type');
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isPIIConfigLoading = false;
    },
    async fetchStdConfigPiis() {
      this.isPIITypeConfigLoading = true;
      try {
        const res = await this.getStudentPiiConfig();
        res.data.forEach((pii) => {
          this.piiTypesConfig[pii.config.type] = pii.status;
        });
        this.isObjectEmpty = Object.keys(this.piiTypesConfig).length === 0;
        this.hasValueOtherThanDisabled = Object.keys(this.piiTypesConfig).some(
          (key) => this.piiTypesConfig[key] !== 'disabled'
        );
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isPIITypeConfigLoading = false;
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);

    await this.fetchSclConfigPiis();
    await this.fetchStdConfigPiis();
    this.fetchStudentProfile();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
