<template>
  <div class="bg-light">
    <div :class="containerClass">
      <div class="d-flex justify-content-center mt-2 pt-2">
        <b-skeleton-wrapper :loading="isLoading">
          <template #loading>
            <b-skeleton-img width="160px" height="120px" no-aspect></b-skeleton-img>
          </template>
          <b-img :src="get(scholarship, 'image_url')" height="110" />
        </b-skeleton-wrapper>
      </div>

      <b-skeleton-wrapper :loading="isLoading">
        <template #loading>
          <div class="d-flex justify-content-center mt-3">
            <b-skeleton width="60%"></b-skeleton>
          </div>
        </template>
        <div class="d-flex justify-content-center mt-2 text-center">
          <h3 class="mb-0 font-weight-bolder" v-text="`${get(scholarship, 'title')}`" />
        </div>
      </b-skeleton-wrapper>

      <div class="mx-3 mb-4 mt-4 text-center">
        <b-skeleton-wrapper :loading="isLoading">
          <template #loading>
            <p class="d-flex justify-content-center">
              <b-skeleton width="90%"></b-skeleton>
            </p>
          </template>

          <span style="font-size: 0.9125rem">
            <span v-if="get(scholarship, 'summary')">
              {{ get(scholarship, 'summary') }}
            </span>
          </span>
        </b-skeleton-wrapper>
      </div>

      <b-form>
        <div class="row mb-2">
          <div class="col-md-6">
            <div class="card" style="height: 95%">
              <div class="card-header bg-light">
                <h5 class="my-0">Sponsorship Information</h5>
              </div>
              <div class="card-body d-flex flex-column justify-content-center">
                <div>
                  <b-skeleton-wrapper :loading="isLoading">
                    <template #loading>
                      <p class="lead text-white-50">
                        <b-skeleton width="70%"></b-skeleton>
                      </p>
                      <p class="lead text-white-50">
                        <b-skeleton width="60%"></b-skeleton>
                      </p>
                      <p class="lead text-white-50">
                        <b-skeleton width="50%"></b-skeleton>
                      </p>
                      <p class="lead text-white-50">
                        <b-skeleton width="40%"></b-skeleton>
                      </p>
                    </template>

                    <div class="icon-align mb-2">
                      <i class="fas fa-calendar-alt icon-16pt mr-1"></i><b class="mr-1 form-label">Deadline:</b>
                      {{ formatDateSimple(get(scholarship, 'deadline')) }}
                    </div>
                    <div
                      v-if="
                        get(scholarship, 'address') &&
                        get(scholarship, 'city') &&
                        get(scholarship, 'state') &&
                        get(scholarship, 'zipcode') &&
                        get(scholarship, 'country')
                      "
                      class="icon-align mb-2"
                    >
                      <i class="fas fa-map-marker-alt icon-16pt mr-1"></i> <b class="mr-1 form-label">Address:</b>
                      {{ get(scholarship, 'address') }}, {{ get(scholarship, 'city') }},
                      {{ get(scholarship, 'state') }}
                      {{ get(scholarship, 'zipcode') }},
                      {{ get(scholarship, 'country') }}
                    </div>
                    <div class="icon-align mb-2">
                      <i class="fas fa-dollar-sign icon-16pt mr-1"></i>
                      <b class="mr-1 form-label"> Amount:</b>
                      ${{ $n(get(scholarship, 'min_amount')) }} - ${{ $n(get(scholarship, 'max_amount')) }}
                    </div>

                    <div class="icon-align mb-2">
                      <i class="fas fa-dollar-sign icon-16pt mr-1"></i>
                      <b class="mr-1 form-label"> Pay:</b>
                      ${{ $n(get(scholarship, 'minimum_pay')) }} - ${{ $n(get(scholarship, 'maximum_pay')) }}
                    </div>
                    <div class="icon-align mb-2">
                      <i class="fas fa-calendar-week icon-16pt mr-1"></i> <b class="mr-1 form-label">Work Commitment:</b
                      >{{ get(scholarship, 'work_commitment') }} Months
                    </div>
                    <div v-if="get(scholarship, 'pay_rate')" class="icon-align mb-2">
                      <i class="fas fa-clock icon-16pt mr-1"></i> <b class="mr-1 form-label">Pay Rate:</b
                      >{{ $t(`jobPayRateOpts.${get(scholarship, 'pay_rate')}`) }}
                    </div>

                    <div v-if="get(scholarship, 'schedule')" class="icon-align mb-2">
                      <i class="fas fa-clipboard-list icon-16pt mr-1"></i>
                      <b class="mr-1 form-label"> Schedule:</b
                      >{{ $t(`jobScheduleOpts.${get(scholarship, 'schedule')}`) }}
                    </div>
                  </b-skeleton-wrapper>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card" style="height: 95%">
              <div class="card-header bg-light d-flex">
                <div>
                  <h5 class="my-0">Student Information</h5>
                </div>
              </div>
              <div class="card-body d-flex flex-column justify-content-center">
                <div>
                  <b-skeleton-wrapper :loading="isLoading">
                    <template #loading>
                      <p class="lead text-white-50">
                        <b-skeleton width="70%"></b-skeleton>
                      </p>
                      <p class="lead text-white-50">
                        <b-skeleton width="60%"></b-skeleton>
                      </p>
                      <p class="lead text-white-50">
                        <b-skeleton width="50%"></b-skeleton>
                      </p>
                      <p class="lead text-white-50">
                        <b-skeleton width="40%"></b-skeleton>
                      </p>
                    </template>

                    <div class="icon-align mb-2">
                      <i class="fas fa-user icon-16pt mr-1"></i><b class="mr-1 form-label">Name:</b
                      >{{ get(getLoggedInUser, 'first_name') }}
                      {{ get(getLoggedInUser, 'last_name') }}
                    </div>

                    <div class="icon-align mb-2">
                      <i class="fas fa-envelope icon-16pt mr-1"></i><b class="mr-1 form-label">Email:</b
                      >{{ get(getLoggedInUser, 'email') }}
                    </div>

                    <div class="icon-align">
                      <i class="fas fa-phone icon-16pt mr-1"></i><b class="mr-1 form-label">Phone:</b
                      >{{ get(getLoggedInUser, 'phone') }}
                    </div>
                  </b-skeleton-wrapper>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card" v-for="sec in sections" :key="`sec-${sec.id}`">
          <div class="card-body">
            <div class="d-flex">
              <div class="flex">
                <div class="d-flex align-items-center mb-1">
                  <h5 class="card-title mb-2">{{ sec.section.title }}</h5>
                </div>

                <i v-if="!sec.data.length">No questions in the section.</i>

                <b-form-group
                  :label="ques.title"
                  label-class="form-label"
                  v-for="(ques, index) in sec.data"
                  :key="index"
                  v-else
                >
                  <b-form-input
                    :placeholder="ques.placeholder"
                    v-if="ques.type === QUES_TYPES.SHORT_TEXT"
                    :value="ques.response.data"
                    disabled
                  />
                  <b-form-textarea
                    v-else-if="ques.type === QUES_TYPES.LONG_TEXT"
                    class="form-control"
                    rows="10"
                    :placeholder="ques.placeholder"
                    :value="ques.response.data"
                    disabled
                  ></b-form-textarea>
                  <v-select
                    v-else-if="ques.type === QUES_TYPES.SINGLE_SELECT"
                    class="form-control v-select-custom"
                    label="text"
                    disabled
                    :value="ques.response.data"
                    :reduce="(ins) => ins.value"
                    :options="get(ques, 'extra.options', [])"
                    :placeholder="ques.placeholder"
                    :clearable="false"
                  >
                  </v-select>
                  <v-select
                    v-if="ques.type === QUES_TYPES.MULTI_SELECT"
                    class="form-control v-select-custom"
                    label="text"
                    :value="ques.response.data"
                    :reduce="(ins) => ins.value"
                    :options="get(ques, 'extra.options', [])"
                    :placeholder="ques.placeholder"
                    multiple
                    disabled
                    :clearable="false"
                  >
                  </v-select>
                  <div v-else-if="ques.type === QUES_TYPES.FILE_TYPE">
                    <a
                      v-if="ques.response.data"
                      href="#"
                      @click.prevent="
                        downloadGivenFile({
                          fileUrl: ques.response.data,
                          removeTimestamp: true,
                        })
                      "
                      v-b-popover.hover.top="'Download'"
                      ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(ques.response.data, true) }}
                    </a>
                    <i v-else>No file uploaded.</i>
                  </div>

                  <b-form-radio-group
                    v-else-if="ques.type === QUES_TYPES.BOOLEAN"
                    v-model="ques.response.data"
                    :options="get(ques, 'extra.options', YES_NO_OPTIONS)"
                    disabled
                  ></b-form-radio-group>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>

        <!-- Terms & Conditions Card -->
        <div class="card">
          <div class="card-header bg-light">
            <h5 class="my-0">Terms and Conditions</h5>
          </div>
          <div class="card-body">
            <b-form-group label-class="form-label">
              <b-form-checkbox v-model="termsAndConditions" name="check-button" disabled>
                <span style="font-weight: initial" class="text-black">
                  I understand and accept the
                  <a href="#" class="text-primary">terms and conditions</a>.
                </span>
              </b-form-checkbox>
            </b-form-group>

            <!-- <b-form-group label="Signature*" label-for="signature" label-class="form-label">
              <b-form-input disabled id="signature" placeholder="Type Full Name" :value="signature" />
            </b-form-group> -->

            <!-- <div class="text-dark" v-if="signature">Preview</div> -->
            <h1 class="signature-font">{{ signature }}</h1>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import { truncate, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Page from '@/components/Page.vue';

import { QUES_TYPES, YES_NO_OPTIONS } from '@/common/constants';
import { formatDateSimple, formatFullDate } from '@/common/utils';

export default {
  components: {},
  extends: Page,
  layout: 'blank',

  data() {
    return {
      isSubmit: false,
      isSuccess: false,
      isLoading: false,
      termsAndConditions: false,
      showTermsModal: false,
      school: null,
      signature: '',
      scholarship: {},
      isUploading: {},
      isQueFileUploading: {},
      selectedUploadFile: {},
      selectedQueUploadFile: {},
      uploadPercent: { avatar: 0, resume: 0, file_type: {} },
      uploadCancelTokenSource: {
        file_type: {},
      },
      sponsorship: {},
      file_type: null,
      areSectionsLoading: false,
      QUES_TYPES,
      sections: [],
      customSectionData: {},
      YES_NO_OPTIONS,
    };
  },
  validations() {
    const val = {};

    return val;
  },
  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    ...mapGetters('auth', ['getLoggedInUser']),

    isFormLoading() {
      return (
        this.isLoading ||
        Object.values(this.isUploading).some((val) => val) ||
        Object.values(this.isQueFileUploading).some((val) => val)
      );
    },
  },

  methods: {
    ...mapActions('student', ['getStudentUploadPresignedUrl']),
    ...mapActions('applicationForm', ['getApplicationResponse']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),

    ...mapActions('scholarships', ['getScholarship']),
    formatDateSimple,
    formatFullDate,

    get,
    truncate,
    goToHome() {
      this.$router.push({ name: 'home' });
    },

    async fetchSections(params = {}) {
      this.areSectionsLoading = true;
      try {
        const res = await this.getApplicationResponse({
          applicant: this.$route.params.id,
          ...params,
        });

        this.sections = res.data.app_question_response;
        this.scholarship = res.data.scholarship;
        this.signature = res.data.signature;

        this.termsAndConditions = this.signature ? true : false;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areSectionsLoading = false;
    },

    async fetchScholarship() {
      this.isLoading = true;
      try {
        const res = await this.getScholarship(this.$route.params.id);
        this.scholarship = res.data;

        this.title = this.scholarship.title;
      } catch (e) {
        this.$router.go(-1);
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },

    async uploadFile(
      fileType,
      uploadType = 'documents',
      profileUrlField = 'resume_url',
      section = null,
      question = null
    ) {
      let file;

      if (fileType === QUES_TYPES.FILE_TYPE) {
        this.uploadCancelTokenSource[fileType][`sec-${section}-que-${question}`] = axios.CancelToken.source();
        this.isQueFileUploading[`sec-${section}-que-${question}`] = true;
        file = this.selectedQueUploadFile[`sec-${section}-que-${question}`];
      } else {
        this.uploadCancelTokenSource[fileType] = axios.CancelToken.source();
        this.isUploading[fileType] = true;
        file = this.selectedUploadFile[fileType];
      }

      try {
        const urlResp = await this.getStudentUploadPresignedUrl({
          file_name: file.name,
          content_type: file.type,
          upload_type: uploadType,
        });

        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: file,
          config: {
            onUploadProgress: function (progressEvent) {
              const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              if (fileType === QUES_TYPES.FILE_TYPE) {
                this.uploadPercent[fileType][`sec-${section}-que-${question}`] = percentage;
              } else {
                this.uploadPercent[fileType] = percentage;
              }
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[fileType].token,
          },
        });
        if (fileType === 'avatar') {
          this.user.avatarUrl = urlResp.upload_url.split('?')[0];
        } else if (fileType === 'file_type') {
          this.customSectionData[`section-${section}`][`question-${question}`] = urlResp.upload_url.split('?')[0];
          this.file_type = urlResp.upload_url.split('?')[0];
        } else this.student[profileUrlField] = urlResp.upload_url.split('?')[0];
      } catch (error) {
        if (fileType === QUES_TYPES.FILE_TYPE) {
          this.selectedQueUploadFile[`sec-${section}-que-${question}`] = null;
        } else {
          this.selectedUploadFile[fileType] = null;
        }
      }
      if (fileType === QUES_TYPES.FILE_TYPE) {
        this.uploadCancelTokenSource[fileType][`sec-${section}-que-${question}`] = null;
        this.uploadPercent[fileType][`sec-${section}-que-${question}`] = 0;
        this.isQueFileUploading[`sec-${section}-que-${question}`] = false;
      } else {
        this.uploadCancelTokenSource[fileType] = null;
        this.uploadPercent[fileType] = 0;
        this.isUploading[fileType] = false;
      }
    },
  },
  async mounted() {
    // this.fetchScholarship();

    if (this.$route.params.id) {
      this.fetchSections();
    }
  },
};
</script>

<style>
</style>
