<template>
  <div style="padding-top: 16px" id="page-container">
    <!-- <page-header :title="title" :container-class="containerClass" /> -->

    <div class="page-section jobs_container">
      <div :class="containerClass">
        <div class="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt" style="white-space: nowrap">
          <h2 class="mb-0 d-sm-block" v-if="!isMobSmallScreen">
            {{ isLocalJobsPage ? 'Featured Jobs' : 'Jobs' }}
            <i
              class="material-icons icon-16pt clickable-item"
              v-b-popover.hover.top="
                isLocalJobsPage
                  ? `Discover jobs that are specifically featured for you.`
                  : `Browse a comprehensive list of available jobs on Transition.`
              "
              >info</i
            >
          </h2>
          <div class="w-auto ml-sm-auto table d-flex align-items-center mb-sm-0">
            <small class="text-muted text-headings text-uppercase d-sm-block" v-if="!isMobSmallScreen">Sort by</small>
            <b-dropdown variant="link text-black-70" right no-caret>
              <template #button-content>
                <span>{{ $t(`jobListingSortOptions.${ordering}`) }}<i class="fas fa-sort ml-1"></i></span>
              </template>

              <b-dropdown-item
                :active="ordering === '-job_type,-created_at'"
                @click="onSortChange({ sortBy: '-job_type,-created_at' })"
                >Most Relevant
              </b-dropdown-item>

              <b-dropdown-item :active="ordering === '-id'" @click="onSortChange({ sortBy: '-id' })"
                >Most Recent
              </b-dropdown-item>

              <b-dropdown-item :active="ordering === 'title'" @click="onSortChange({ sortBy: 'title' })"
                >Title
              </b-dropdown-item>

              <b-dropdown-item :active="ordering === 'city'" @click="onSortChange({ sortBy: 'city' })"
                >Location
              </b-dropdown-item>

              <b-dropdown-item
                :active="ordering === 'employer__name'"
                @click="onSortChange({ sortBy: 'employer__name' })"
                >Employer
              </b-dropdown-item>

              <!-- <b-dropdown-item :active="ordering === 'best_match'">Best Match</b-dropdown-item> -->
            </b-dropdown>
            <b-btn v-if="enableSidebarToggle !== false" v-fmv-toggle.job-filters size="sm" variant="white" class="mr-2">
              <md-icon left>tune</md-icon>
              <span>Filters</span>
            </b-btn>
            <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
              <b-form-input class="w-lg-auto" placeholder="Search..." v-model="searchTerm" @input="onSearch" />
              <b-btn variant="flush" type="submit">
                <md-icon v-text="'search'" />
              </b-btn>
            </form>
          </div>
        </div>

        <page-separator
          :title="!isLoading ? `showing ${totalJobs ? `${pageFromCount}-${pageToCount}` : 0} of ${totalJobs} Jobs` : ''"
        />

        <div class="d-flex justify-content-center" v-if="!totalJobs && !isLoading">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Jobs Found</h4>

              <p class="text-muted">
                Couldn't find any open jobs right. However, there is a constant need for great candidates like you, so
                please check again later.
              </p>

              <!-- <a href="#" @click.prevent="" class="btn btn-primary mt-2"> Sample Button </a> -->
            </div>
          </div>
        </div>

        <b-skeleton-wrapper :loading="isLoading" v-else>
          <template #loading>
            <div class="row card-group-row mb-lg-12pt home-card">
              <div class="col-sm-12 card-group-row__col" v-for="item in [1, 2]" :key="item">
                <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card">
                  <div class="card-header d-flex align-items-center">
                    <a href="#" @click.prevent class="card-title flex"> <b-skeleton width="100%"></b-skeleton></a>
                  </div>
                  <div class="card-body d-flex flex-column">
                    <div class="d-flex align-items-center">
                      <div class="flex">
                        <div class="mb-16pt" :class="{ 'd-flex align-items-center': !isMobSmallScreen }">
                          <div class="mr-12pt" :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen }">
                            <a
                              href="#"
                              @click.prevent
                              class="avatar avatar-xl overlay js-overlay overlay--primary rounded p-relative o-hidden mb-16pt align-self-center"
                              style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                            >
                              <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                            </a>
                          </div>
                          <div class="flex">
                            <div class="mb-0" style="text-transform: none">
                              <b-skeleton width="100%"></b-skeleton>
                              <b-skeleton width="55%"></b-skeleton>
                              <b-skeleton width="70%"></b-skeleton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div class="row card-group-row mb-lg-12pt home-card">
            <job-card :jobs="jobs" />
          </div>
        </b-skeleton-wrapper>

        <pagination
          v-if="totalJobs > jobs.length"
          v-model="currentPage"
          :total-rows="totalJobs"
          :per-page="perPage"
          @change="onPageChange"
          aria-controls="jobs-table"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, get, trim, truncate } from 'lodash';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import { mapActions, mapGetters } from 'vuex';

import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import { JOB_BENEFITS_OPTONS } from '../../../common/constants';
import { formatTimeAgo } from '../../../common/utils';
import JobFilters from './JobFilters/JobFilters.vue';
import JobFiltersSidebar from './JobFilters/JobFiltersSidebar.vue';
import JobCard from '../../Jobs/JobCard.vue';

export default {
  components: { Pagination, JobCard, PageSeparator },
  extends: Page,

  data() {
    return {
      DefaultAvatar,

      title: 'Jobs',

      isLoading: true,
      perPage: 14,
      currentPage: 1,
      totalJobs: 0,
      jobs: [],

      searchTerm: '',
      ordering: '-job_type,-created_at',

      pageFromCount: 0,
      pageToCount: 0,

      filters: {
        zipcode: null,
        caregiving_experience: null,
        required_certs: null,
        required_records: null,
        type: null,
        schedule: null,
      },
      job_type: null,
      benefits: [
        { key: 'cna', name: 'CNA' },
        { key: 'cpr', name: 'CPR Certification' },
        { key: 'bls', name: 'BLS Certification (Life Support)' },
        { key: 'first_aid', name: 'First Aid Certification' },
        { key: 'covid_vac', name: 'COVID-19 Vaccination' },
        { key: 'vehicle_url', name: 'Vehicle Registration' },
        { key: 'tb_test', name: 'TB Test' },
      ],

      windowWidth: window.innerWidth,
      firstLoad: true,
    };
  },

  computed: {
    ...mapGetters('job', ['getJobFilters']),
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.isLocalJobsPage ? 'Featured Jobs' : 'Jobs', active: true },
      ];
    },

    subLayout() {
      return this.windowWidth > 992;
    },
    subLayoutDrawer() {
      return this.windowWidth > 992 ? JobFiltersSidebar : JobFilters;
    },
    subLayoutDrawerId() {
      return 'jobs-filters';
    },

    enableSidebarToggle() {
      return this.windowWidth <= 992;
    },

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },

    isLocalJobsPage() {
      return this.$route.name === 'student-local-jobs-listing';
    },
  },

  watch: {
    getJobFilters: {
      deep: true,
      handler(filterValues) {
        this.filters.zipcode = filterValues.zipCode;
        this.filters.caregiving_experience = filterValues.experience;
        this.filters.type = filterValues.type.length ? filterValues.type.join(',') : null;
        this.filters.schedule = filterValues.schedule.length ? filterValues.schedule.join(',') : null;
        this.filters.required_certs = filterValues.certifications.length ? filterValues.certifications.join(',') : null;
        this.filters.required_records = filterValues.records.length ? filterValues.records.join(',') : null;
        this.job_type = filterValues.job_type;
        this.debouncedSearchJobs(this);
      },
    },
  },

  methods: {
    ...mapActions('job', ['getAllJobs', 'setResetJobFilters']),
    get,
    trim,
    truncate,
    formatTimeAgo,

    async fetchJobs(pageNum = 1, params = {}) {
      this.isLoading = true;
      if (document.getElementById('page-container')) {
        document.getElementById('page-container').scrollIntoView();
      }
      const response = await this.getAllJobs({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...(this.$route.name === 'student-local-jobs-listing' && {
          exclude_external: 1,
        }),
        is_active: true,
        ...this.filters,
        ...params,
      });

      // Adding benefits and career advancements.
      response.data.results.forEach((job) => {
        job['benefitCarAdv'] = [];

        // Benefits
        JOB_BENEFITS_OPTONS.forEach((benefit) => {
          if (job.benefits.includes(benefit.value) && benefit.value !== 'other') {
            job['benefitCarAdv'].push(benefit.text);
          }
        });
        if (job.benefits.includes('other') && job.other_benefits) {
          job['benefitCarAdv'].push(job.other_benefits);
        }

        // Career Advancements
        if (job.offers_career_advancement) {
          job['benefitCarAdv'] = job['benefitCarAdv'].concat((job.career_offerings || []).map((career) => career.name));
          if (job.other_career_offering) {
            job['benefitCarAdv'].push(job.other_career_offering);
          }
        }
      });

      this.jobs = response.data.results;
      this.currentPage = pageNum;
      this.totalJobs = response.data.count;
      this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
      this.pageToCount = this.pageFromCount + get(response.data, 'results.length', 0) - 1;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchJobs(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchJobs();
    },

    onSearch() {
      this.debouncedSearchJobs(this);
    },

    debouncedSearchJobs: debounce((vm) => {
      vm.fetchJobs();
    }, 500),

    openJobProfile(id) {
      if (this.$route.name === 'student-local-jobs-listing') {
        this.$router.push({ name: 'student-local-job-details', params: { id } });
      } else {
        this.$router.push({ name: 'student-job-details', params: { id } });
      }
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.firstLoad) {
        vm.setResetJobFilters(true);
      }
    });
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    this.fetchJobs();
    this.firstLoad = false;
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
