<template>
  <div :class="containerClass">
    <div class="back-result-lg font-weight-bold">
      <a href="#" @click.prevent="goBack()" style="text-decoration: underline">
        <span class="material-icons icon-32pt">navigate_before</span>
      </a>
    </div>

    <h2>Add a Certification</h2>
    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group label="Certification Type*" label-for="certificationType" label-class="form-label">
        <v-select
          id="certificationType"
          class="form-control v-select-custom"
          :class="!$v.certificationType.required && $v.certificationType.$dirty ? 'form-control is-invalid' : ''"
          label="title"
          v-model="certificationType"
          :reduce="certificationType => certificationType.title"
          placeholder="Select Enrolled Program"
          :loading="areProgramsLoading"
          :options="allPrograms"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!certificationType" v-bind="attributes" v-on="events" />
          </template>
          <template slot="option" slot-scope="option">
            <span>{{ option.title }}</span>
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.certificationType.required && $v.certificationType.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group
        v-if="certificationType === 'other'"
        label="Other"
        label-for="certificationOther"
        label-class="form-label"
      >
        <b-form-input
          id="certificationOther"
          placeholder="Enter question title"
          v-model="certificationOther"
          :state="!$v.certificationOther.required && $v.certificationOther.$dirty ? false : null"
        />
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        label="Certifying Institution/Organization*"
        label-for="certifyingInstitution"
        label-class="form-label"
      >
        <b-form-input
          id="certifyingInstitution"
          placeholder="Enter question title"
          v-model="certifyingInstitution"
          :state="!$v.certifyingInstitution.required && $v.certifyingInstitution.$dirty ? false : null"
        />
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Certification Number" label-for="certificationNumber" label-class="form-label">
        <b-form-input id="certificationNumber" placeholder="Enter question title" v-model="certificationNumber" />
      </b-form-group>

      <b-form-group label="Year Received*" label-for="yearOfCertification" class="form-label">
        <date-picker
          id="yearOfCertification"
          v-model="yearOfCertification"
          type="date"
          format="MM/DD/YYYY"
          value-type="YYYY-MM-DD"
          style="width: 100%"
          lang="en"
          placeholder="MM/DD/YYYY"
          :state="!$v.yearOfCertification.required && $v.yearOfCertification.$dirty ? false : null"
        >
        </date-picker>
        <b-form-invalid-feedback
          :state="!$v.yearOfCertification.required && $v.yearOfCertification.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="Expiration Date" label-for="expiryDate" class="form-label">
        <date-picker
          v-model="expiryDate"
          type="date"
          format="MM/DD/YYYY"
          value-type="YYYY-MM-DD"
          style="width: 100%"
          lang="en"
          placeholder="MM/DD/YYYY"
          id="expiryDate"
        ></date-picker>
      </b-form-group>

      <b-form-group
        label="Upload Certification Document"
        label-for="document"
        label-class="form-label"
        class="row-align-items-center"
      >
        <!-- Upload progress while uploading -->
        <b-progress :max="100" animated v-if="isUploading">
          <b-progress-bar :value="uploadPercent">
            <span>
              Uploading (<strong>{{ uploadPercent }} %</strong>)
            </span>
          </b-progress-bar>
        </b-progress>
        <span v-else-if="docUrl">
          <div class="row">
            <div class="col-md-10">
              <a
                href="#"
                @click.prevent="
                  downloadFile({
                    fileUrl: docUrl,
                    removeTimestamp: true,
                  })
                "
                v-b-popover.hover.top="'Download'"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(docUrl, true) }}
              </a>
            </div>
            <div class="text-right col-md-2">
              <a href="#" class="text-danger" @click.prevent="removeFile()"
                ><i class="fas fa-times-circle"></i> Remove
              </a>
            </div>
          </div>
        </span>
        <b-media class="align-items-center" vertical-align="center" v-else>
          <b-form-file
            placeholder="Select Document (max size: 10MB, only .pdf and image files allowed)"
            :browse-text="$t('generalMsgs.browse')"
            v-model="selectedUploadFile"
            @input="uploadFile()"
            :disabled="isUploading"
            accept=".pdf, image/*"
          />
        </b-media>
      </b-form-group>

      <b-btn variant="primary" :disabled="isLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('update') : 'Add' }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios';
import { get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Page from '@/components/Page.vue';
import { parseFileNameFromUrl } from '../../../common/utils';
import { required, requiredIf } from 'vuelidate/lib/validators';
import {} from '@/common/constants';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/en';

export default {
  extends: Page,
  components: { DatePicker },

  data() {
    return {
      title: 'Add Certificate',
      isLoading: false,
      certificationType: null,
      certificationNumber: '',
      certificationOther: '',
      certifyingInstitution: '',
      docUrl: '',
      isUploading: false,
      selectedUploadFile: null,
      uploadPercent: 0,
      allPrograms: [],
      areProgramsLoading: false,
      expiryDate: null,
      yearOfCertification: '',
    };
  },

  validations() {
    return {
      certifyingInstitution: { required },
      yearOfCertification: { required },
      certificationType: { required },
      certificationOther: { required: requiredIf(() => this.certificationType === 'other') },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
  },

  methods: {
    ...mapActions('program', ['getAllPublicPrograms']),
    ...mapActions('fileDownload', ['downloadFile']),
    ...mapActions('student', ['createCert', 'getCert', 'updateCert', 'addStudentPii', 'getStudentUploadPresignedUrl', 'updateShowEarnedPointToast']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    parseFileNameFromUrl,
    get,
    goBack() {
      this.$router.go(-1);
    },
    async fetchPrograms() {
      this.areProgramsLoading = true;
      const response = await this.getAllPublicPrograms({
        prg_type: 'certificate',
      });
      this.allPrograms = response.data;
      this.allPrograms.push({ title: 'Other', id: 'other' });
      this.areProgramsLoading = false;
    },
    async uploadFile(uploadType = 'documents') {
      this.uploadCancelTokenSource = axios.CancelToken.source();
      this.isUploading = true;

      try {
        const urlResp = await this.getStudentUploadPresignedUrl({
          file_name: this.selectedUploadFile.name,
          content_type: this.selectedUploadFile.type,
          upload_type: uploadType,
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.selectedUploadFile,
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource.token,
          },
        });
        this.docUrl = urlResp.upload_url.split('?')[0];
      } catch (error) {
        this.selectedUploadFile = null;
      }

      this.uploadCancelTokenSource = null;
      this.uploadPercent = 0;

      this.isUploading = false;
    },
    removeFile() {
      this.selectedUploadFile = null;
      this.docUrl = null;
    },
    async fetchQuestion() {
      try {
        const res = await this.getCert(this.$route.params.id);
        this.certificationType = res.data.certification_type;
        this.certificationOther = res.data.certification_other;
        this.certifyingInstitution = res.data.certifying_institution;
        this.yearOfCertification = res.data.year_of_certification;
        this.expiryDate = res.data.expiry_date;
        this.certificationNumber = res.data.certification_number;
        this.docUrl = res.data.certificate_url;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    async onSubmit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isLoading = true;
        try {
          const data = {
            certification_type: this.certificationType,
            certification_other: this.certificationOther,
            certifying_institution: this.certifyingInstitution,
            year_of_certification: this.yearOfCertification,
            expiry_date: this.expiryDate,
            certification_number: this.certificationNumber,
            certificate_url: this.docUrl,
          };

          if (this.$route.params.id) {
            await this.updateCert({ id: this.$route.params.id, data });
            this.makeToast({ variant: 'success', msg: 'Certification updated!' });
          } else {
            const certResp = await this.createCert(data);
            this.makeToast({ variant: 'success', msg: 'Certification asked!' });
            if (certResp.data.data.points_earned > 0) {
              this.updateShowEarnedPointToast(certResp.data.data.points_earned);
            }
          }
          setTimeout(() => {
            this.$router.push({ name: 'my-certifications' });
          }, 1000);
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
        this.isLoading = false;
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields.' });
      }
    },
  },
  async mounted() {
    this.fetchPrograms();
    if (this.$route.params.id) {
      this.fetchQuestion();
    }
  },
};
</script>

<style></style>
