<template>
  <div style="margin-top: -16px">
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div class="page-section bg-dark border-bottom-white">
          <div
            class="d-flex flex-column flex-md-row align-items-center text-center text-md-left"
            :class="containerClass"
          >
            <fmv-avatar :title="true" rounded size="xxl" no-link :class="!isMobSmallScreen ? 'mr-3' : ''">
              <b-skeleton-img height="113px" no-aspect></b-skeleton-img>
            </fmv-avatar>

            <div class="flex mb-32pt mb-md-0">
              <h2 class="text-white mb-0"><b-skeleton width="85%"></b-skeleton></h2>
              <p class="lead text-white-50">
                <b-skeleton width="60%"></b-skeleton>
              </p>
            </div>
            <b-skeleton type="button" v-if="get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL"></b-skeleton>
            <b-skeleton type="button" class="ml-2"></b-skeleton>
          </div>
        </div>
      </template>

      <div
        class="bg-dark employer-profile-bg border-bottom-white overlay overlay--primary-pickled-bluewood"
        :style="`background-image: url('${employer.profile_bg_img_url}');`"
      >
        <div class="overlay__content page-section employer-profile-cover-ol">
          <div
            class="d-flex flex-column flex-md-row align-items-center text-center text-md-left"
            :class="containerClass"
          >
            <fmv-avatar
              :title="true"
              rounded
              size="xxl"
              no-link
              :class="!isMobSmallScreen ? 'mr-3' : ''"
              title-class="bg-white"
            >
              <b-img
                :src="employer.logo_url || DefaultAvatar"
                class="img-fluid"
                width="104"
                alt="Logo"
                v-if="employer.logo_url"
              />
              <md-icon v-else>business</md-icon>
            </fmv-avatar>

            <div class="flex mb-32pt mb-md-0">
              <h2 class="text-white mb-0" v-text="employer.name" />
              <p class="lead text-white-50 d-flex align-items-center">
                {{ $t(`employerCompanyTypes.${employer.company_type}`) }}
                <span class="ml-16pt d-flex align-items-center">
                  <i class="material-icons icon-16pt mr-4pt">supervisor_account</i>
                  {{ employer.company_size }} Employees
                </span>
              </p>
            </div>

            <a
              :href="getClickableLink(employer.website_url)"
              class="btn btn-white"
              :class="{ 'ml-2': !isMobSmallScreen }"
              v-if="employer.website_url"
              target="_blank"
              ><md-icon class="mr-1">open_in_new</md-icon>Visit Website</a
            >
          </div>
        </div>
      </div>

      <!-- TABS -->
      <div :class="containerClass">
        <div style="display: center">
          <b-tabs
            nav-class="nav-tabs-card bg-white px-3"
            content-class=" "
            class="mt-0"
            id="homePageTab"
            @input="onTabsChanged"
            style="scroll-margin-top: 66px; font-size: 16.5px"
          >
            <b-tab title="Overview" class="tab-secondary">
              <template #title>
                <i class="fas fa-info align-self-center" :class="{ 'mx-4': isTabSmallScreen }"></i>
                <strong class="ml-2" v-if="!isTabSmallScreen">Overview</strong>
              </template>

              <div class="page-section">
                <div class="page-section student-card mt-0 pt-0 mb-32pt">
                  <div class="card border-left-0">
                    <div class="card-body">
                      <div class="d-flex mb-1">
                        <div class="flex">
                          <div class="d-flex align-items-center mb-1">
                            <h5 class="card-title fw600">Contact</h5>
                          </div>

                          <div class="d-flex">
                            <div class="flex">
                              <div class="d-flex align-items-center mt-2">
                                <md-icon class="font-size-16pt mr-2">location_on</md-icon>{{ employer.zipcode }},
                                {{ employer.city }}, {{ employer.state }}
                              </div>
                              <div class="d-flex align-items-center mt-2" v-if="employer.phone">
                                <md-icon class="font-size-16pt mr-2">phone</md-icon>
                                <a :href="`tel:${employer.phone}`" class="text-black">{{ employer.phone }}</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card border-left-0" v-if="employer.bio">
                    <div class="card-body">
                      <div class="d-flex mb-1">
                        <div class="flex">
                          <div class="d-flex align-items-center mb-1">
                            <h5 class="card-title fw600">Bio</h5>
                          </div>

                          <div class="d-flex">
                            <div class="flex program-description">
                              <div v-safe-html="get(employer, 'bio', '')" v-if="employer.bio" />

                              <i v-else>{{ employer.name }} has not added any bio.</i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>

            <!-- Employer's Active Jobs -->
            <b-tab title="Jobs" class="tab-secondary">
              <template #title>
                <i class="fas fa-briefcase align-self-center" :class="{ 'mx-4': isTabSmallScreen }"></i>
                <strong class="ml-2" v-if="!isTabSmallScreen">Jobs</strong>
              </template>

              <div class="page-section">
                <div class="page-section student-card mt-0 pt-0 mb-32pt">
                  <!-- <div
                    :class="containerClass"
                    class="page-section"
                    v-if="getLoggedInUser.role_type !== USER_ROLE_TYPES.SCHOOL"
                  > -->
                  <div class="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt" style="white-space: nowrap">
                    <!-- <h2 class="mb-0">Active Jobs</h2> -->
                    <div class="w-auto ml-sm-auto table d-flex align-items-center mb-sm-0">
                      <small class="text-muted text-headings text-uppercase d-sm-block">Sort by</small>
                      <b-dropdown variant="link text-black-70" right no-caret>
                        <template #button-content>
                          <span>{{ $t(`jobListingSortOptions.${jobOrdering}`) }}<i class="fas fa-sort ml-1"></i></span>
                        </template>
                        <b-dropdown-item :active="jobOrdering === '-id'" @click="onJobsSortChange({ sortBy: '-id' })"
                          >Most Recent</b-dropdown-item
                        >
                        <b-dropdown-item
                          :active="jobOrdering === 'title'"
                          @click="onJobsSortChange({ sortBy: 'title' })"
                          >Title</b-dropdown-item
                        >
                        <b-dropdown-item :active="jobOrdering === 'city'" @click="onJobsSortChange({ sortBy: 'city' })"
                          >Location</b-dropdown-item
                        >
                      </b-dropdown>

                      <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                        <b-form-input
                          class="w-lg-auto"
                          placeholder="Search..."
                          v-model="jobSearchTerm"
                          @input="onJobSearch"
                        />
                        <b-btn variant="flush" type="submit">
                          <md-icon v-text="'search'" />
                        </b-btn>
                      </form>
                    </div>
                  </div>

                  <page-separator
                    :title="
                      isLoading
                        ? 'Jobs'
                        : `Showing ${totalJobs ? `${$n(jobsPageFromCount)}-${$n(jobsPageToCount)}` : 0} of ${$n(
                            totalJobs
                          )} Jobs`
                    "
                  />

                  <div class="d-flex justify-content-center" v-if="!totalJobs && !areJobsLoading">
                    <div class="card card-block card-stretch card-height col-md-12">
                      <div class="card-body text-center">
                        <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
                        <h4>No Jobs Found</h4>

                        <p class="text-muted">
                          Couldn't find any open jobs right. However, there is a constant need for great candidates like
                          you, so please check again after a while.
                        </p>
                      </div>
                    </div>
                  </div>

                  <b-skeleton-wrapper :loading="areJobsLoading" v-else>
                    <template #loading>
                      <div class="row card-group-row mb-lg-12pt home-card">
                        <div class="col-sm-12 card-group-row__col" v-for="item in [1, 2]" :key="item">
                          <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card">
                            <div class="card-header d-flex align-items-center">
                              <a href="#" @click.prevent class="card-title flex">
                                <b-skeleton width="100%"></b-skeleton
                              ></a>
                            </div>
                            <div class="card-body d-flex flex-column">
                              <div class="d-flex align-items-center">
                                <div class="flex">
                                  <div class="d-flex align-items-center mb-16pt">
                                    <div class="mr-12pt">
                                      <a
                                        href="#"
                                        @click.prevent
                                        class="avatar avatar-xl overlay js-overlay overlay--primary rounded-circle p-relative o-hidden mb-16pt align-self-center"
                                        style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                                      >
                                        <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                                      </a>
                                    </div>
                                    <div class="flex">
                                      <div class="mb-0" style="text-transform: none">
                                        <b-skeleton width="100%"></b-skeleton>
                                        <b-skeleton width="55%"></b-skeleton>
                                        <b-skeleton width="70%"></b-skeleton>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>

                    <div class="row card-group-row mb-lg-12pt home-card">
                      <div class="col-sm-12 card-group-row__col" v-for="job in jobs" :key="job.id">
                        <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card">
                          <div class="card-header d-flex align-items-center">
                            <a
                              href="#"
                              @click.prevent="
                                openViewMoreModal({ name: 'student-job-details', params: { id: job.id } })
                              "
                              class="card-title flex mr-12pt"
                              >{{ job.title }}</a
                            >
                            <span>
                              <i class="material-icons icon-16pt mr-1 text-dark">business</i>
                              <router-link
                                :to="{ name: 'student-employer-profile', params: { id: job.employer.id } }"
                                class="text-primary"
                                style="text-decoration: underline"
                                >{{ job.employer.name }}</router-link
                              >
                            </span>
                          </div>

                          <div
                            class="card-body d-flex flex-column"
                            @click.prevent="openViewMoreModal({ name: 'student-job-details', params: { id: job.id } })"
                          >
                            <div class="d-flex align-items-center">
                              <div class="flex">
                                <div class="d-flex align-items-center mb-16pt">
                                  <div class="mr-12pt">
                                    <a
                                      href="#"
                                      @click.prevent
                                      class="avatar avatar-xl overlay js-overlay overlay--primary rounded-circle p-0 o-hidden d-flex justify-content-center align-items-center"
                                      style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                                    >
                                      <img
                                        class="avatar-img m-0"
                                        :src="
                                          job.employer && job.employer.logo_url ? job.employer.logo_url : DefaultAvatar
                                        "
                                        width="160"
                                        height="auto"
                                      />
                                    </a>
                                  </div>
                                  <div class="flex">
                                    <div class="mb-0" style="text-transform: none">
                                      <div>
                                        <i class="material-icons icon-16pt">location_on</i
                                        ><strong style="font-weight: bold">{{ job.city }}, {{ job.state }}</strong>
                                      </div>
                                      <div>
                                        <i class="material-icons icon-16pt">timelapse</i> Seeking candidates for
                                        <strong style="font-weight: bold">{{
                                          $t(`jobWorkTypeOpts.${job.type}`)
                                        }}</strong>
                                        position
                                      </div>
                                      <div>
                                        <span>
                                          <i class="material-icons icon-16pt">favorite</i>
                                          <strong style="font-weight: bold">
                                            {{ job.caregiving_experience }} year(s)</strong
                                          >
                                          caregiving experience required
                                        </span>
                                      </div>
                                      <div>
                                        <span>
                                          <i class="material-icons icon-16pt">calendar_today</i> Posted
                                          <strong style="font-weight: bold">{{ formatTimeAgo(job.created_at) }}</strong>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="card-button">
                              <ul>
                                <li v-for="(benefit, index) in job.benefitCarAdv" :key="`${benefit}-${index}`">
                                  <a href="#" @click.prevent
                                    ><span class="material-icons icon-16pt text-100">check</span>{{ benefit }}</a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-skeleton-wrapper>

                  <pagination
                    v-if="totalJobs > jobs.length"
                    v-model="currentPage"
                    :total-rows="totalJobs"
                    :per-page="perPage"
                    @change="onJobsPageChange"
                    aria-controls="jobs-table"
                  />
                  <!-- </div> -->
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-skeleton-wrapper>

    <view-more-modal
      :show-modal.sync="modals.showViewMoreModal"
      :login-path="modals.loginPath"
      :body-text="modals.viewMoreBodyText"
      @close="hideViewMoreModal"
    />
  </div>
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';

import Page from '@/components/Page.vue';
import { mapActions, mapGetters } from 'vuex';
import { formatFullDate, formatTimeAgo } from '@/common/utils';
import { FmvAvatar } from 'fmv-avatar';
import { debounce } from 'lodash';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import MdIcon from '@/components/Ui/MdIcon.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import { JOB_BENEFITS_OPTONS, USER_ROLES, USER_ROLE_TYPES, ACTIVE_STD_INFO_DESC } from '@/common/constants';
//import PartnershipRequestButton from '@/Partnership/PartnershipRequestButton.vue';
import { PARTNERSHIP_STATUS } from '@/common/constants';
import { get, isEmpty } from 'lodash';
import ViewMoreModal from '../ViewMoreModal.vue';

export default {
  components: { MdIcon, FmvAvatar, Pagination, ViewMoreModal, PageSeparator },
  extends: Page,

  data() {
    return {
      DefaultAvatar,
      USER_ROLE_TYPES,

      title: 'Employer Profile',

      isLoading: true,
      areJobsLoading: true,
      employer: {},

      totalJobs: 0,
      jobs: [],
      perPage: 10,
      currentPage: 1,

      jobsPageFromCount: 0,
      jobsPageToCount: 0,

      jobSearchTerm: '',
      jobOrdering: '-id',

      partnership: {},
      isPartnershipLoading: false,
      isPartnershipResponding: false,
      PARTNERSHIP_STATUS,
      windowWidth: window.innerWidth,
      ACTIVE_STD_INFO_DESC,
      firstLoad: true,
      publicSchool: null,
      modals: {
        loginPath: {},
        viewMoreBodyText: '',
        showViewMoreModal: false,
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('school', ['getCurrentSchool']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Employer Profile', active: true },
      ];
    },

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
  },

  methods: {
    ...mapActions('employer', ['getEmployer', 'getPublicEmployer']),
    ...mapActions('job', ['getAllJobs']),
    ...mapActions('fileDownload', ['downloadFile']),
    ...mapActions('partnership', ['getPartnership', 'getStatus']),
    ...mapActions('school', ['getLocalJobsForCurrentSchool']),
    ...mapActions('settings', ['setSettings']),
    formatFullDate,
    formatTimeAgo,
    get,
    isEmpty,

    onTabsChanged() {
      if (!this.firstLoad) {
        document.getElementById('homePageTab').scrollIntoView({ behavior: 'smooth' });
      }
      this.firstLoad = false;
    },

    openViewMoreModal(obj) {
      this.modals.loginPath = obj;
      if (obj.name === 'student-job-details') {
        this.modals.viewMoreBodyText = 'You must login to view more details about this job.';
      } else if (obj.name === 'redirect-employer-profile') {
        this.modals.viewMoreBodyText = 'You must login to view more details about this employer.';
      } else {
        this.modals.viewMoreBodyText = 'You must login to view more jobs.';
      }
      this.modals.showViewMoreModal = true;
    },

    hideViewMoreModal() {
      this.modals.showViewMoreModal = false;
      this.modals.viewMoreBodyText = '';
    },

    async fetchPublicEmployer() {
      this.isLoading = true;

      try {
        const resp = (await this.getPublicEmployer({ slug: this.$route.params.slug })).data;
        this.employer = resp;
        if (!this.employer.id) {
          this.$router.push({ name: 'sch-public-home' });
        }
      } catch (err) {
        this.$router.push({ name: 'sch-public-home' });
      }

      this.isLoading = false;
    },

    async downloadGivenFile(url) {
      const h = this.$createElement;

      this.makeToast({
        variant: 'primary',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
        toaster: 'b-toaster-bottom-left',
      });

      await this.downloadFile({ fileUrl: url, removeTimestamp: true });

      this.makeToast({
        variant: 'success',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
        toaster: 'b-toaster-bottom-left',
      });
    },

    getClickableLink(link) {
      return link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`;
    },

    async fetchJobs(pageNum = 1, params = {}) {
      this.areJobsLoading = true;

      const response = await this.getLocalJobsForCurrentSchool({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        employer: this.employer.id,
        school: this.getCurrentSchool.id,

        ...params,
      });

      // Adding benefits and career advancements.
      response.data.results.forEach((job) => {
        job['benefitCarAdv'] = [];

        // Benefits
        JOB_BENEFITS_OPTONS.forEach((benefit) => {
          if (job.benefits.includes(benefit.value) && benefit.value !== 'other') {
            job['benefitCarAdv'].push(benefit.text);
          }
        });
        if (job.benefits.includes('other') && job.other_benefits) {
          job['benefitCarAdv'].push(job.other_benefits);
        }

        // Career Advancements
        if (job.offers_career_advancement) {
          job['benefitCarAdv'] = job['benefitCarAdv'].concat((job.career_offerings || []).map((career) => career.name));
          if (job.other_career_offering) {
            job['benefitCarAdv'].push(job.other_career_offering);
          }
        }
      });

      this.jobs = response.data.results;
      this.currentPage = pageNum;
      this.totalJobs = response.data.count;

      this.jobsPageFromCount = (this.currentPage - 1) * this.perPage + 1;
      this.jobsPageToCount = this.jobsPageFromCount + get(this.jobs, 'length', 0) - 1;

      this.areJobsLoading = false;
    },

    onJobsPageChange(pageNum) {
      this.fetchJobs(pageNum);
    },

    onJobsSortChange(context) {
      this.jobOrdering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchJobs();
    },

    onJobSearch() {
      this.debouncedSearchJobs(this);
    },

    debouncedSearchJobs: debounce((vm) => {
      vm.fetchJobs();
    }, 500),

    openJobProfile(id) {
      if (get(this.getLoggedInUser, 'role') === USER_ROLES.STUDENT) {
        this.$router.push({ name: 'student-job-details', params: { id } });
      } else {
        this.$router.push({ name: 'manage-job-public-view', params: { id } });
      }
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    this.setSettings({ layout: { layout: 'fixed' } });
    window.addEventListener('resize', this.handleResize);

    await this.fetchPublicEmployer();

    this.fetchJobs();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
