<template>
  <div>
    <div class="pt-32pt">
      <div :class="containerClass" class="d-flex flex-column flex-md-row align-items-center text-center text-sm-left">
        <div class="flex d-flex flex-column flex-sm-row align-items-center">
          <div class="mb-24pt mb-sm-0 mr-sm-24pt">
            <page-heading class="mb-0">
              {{ title }}
              <i
                class="material-icons icon-16pt clickable-item"
                v-b-popover.hover.top="`Review your payment history through this portal.`"
                >info</i
              >
            </page-heading>
          </div>
        </div>
      </div>
    </div>

    <div class="page-section">
      <div :class="{ container: !isMobileScreen }">
        <vue-cal
          :hideViewSelector="true"
          :time="false"
          :disable-views="['years', 'year', 'week', 'day']"
          :dblclick-to-navigate="false"
          active-view="month"
          style="height: 500px; border-radius: 12px"
        >
          <template #arrow-prev>
            <i
              :style="`color: ${textColorChanged(get(getLoggedInUser.linked_entity, 'primary_color'))}`"
              class="icon material-icons"
              >arrow_back</i
            >
          </template>
          <template #arrow-next>
            <i
              :style="`color: ${textColorChanged(get(getLoggedInUser.linked_entity, 'primary_color'))}`"
              class="icon material-icons"
              >arrow_forward</i
            >
          </template>
          <template #title="{ title }">
            <div :style="`color: ${textColorChanged(get(getLoggedInUser.linked_entity, 'primary_color'))}`">
              {{ title }}
            </div>
          </template>
          <template #cell-content="{ cell }">
            <div
              v-if="formatAttendance[cell.formattedDate]"
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <div>
                {{ cell.content }}
              </div>
              <div
                v-if="formatAttendance[cell.formattedDate].status"
                class="d-flex align-items-center justify-content-center pill"
                :class="formatAttendance[cell.formattedDate].status === 'P' ? 'bg-success' : 'bg-danger'"
                :style="isMobileScreen ? 'font-size: 8px; height: 20px;' : 'font-size: 10px; height: 22px;'"
              >
                {{ formatAttendance[cell.formattedDate].status === 'P' ? 'Present' : 'Absent' }}
              </div>
            </div>
            <div v-else>
              {{ cell.content }}
            </div>
          </template>
        </vue-cal>
      </div>
    </div>
  </div>
</template>

<script>
import { get, keyBy } from 'lodash';
import Page from '@/components/Page.vue';
import { mapGetters, mapActions } from 'vuex';
import PageHeading from '@/components/Ui/PageHeading.vue';
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';

export default {
  components: { PageHeading, VueCal },
  extends: Page,

  data() {
    return {
      title: 'My Attendance',
      windowWidth: window.innerWidth,
      attendance: [],
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    formatAttendance() {
      return keyBy(this.attendance, 'date');
    },
    isMobileScreen() {
      return this.windowWidth <= 576;
    },
  },

  methods: {
    ...mapActions('attendance', ['getAttendance']),
    get,
    keyBy,
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    textColorChanged(color) {
      if (color) {
        const r = parseInt(color.slice(1, 3), 16);
        const g = parseInt(color.slice(3, 5), 16);
        const b = parseInt(color.slice(5, 7), 16);

        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance > 0.5 ? 'black' : 'white';
      }
    },

    async fetchAttendance() {
      const res = await this.getAttendance();
      this.attendance = res.data.data;
    },
  },

  async mounted() {
    this.fetchAttendance();

    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="css">
.pill {
  width: 70%;
  border-radius: 5px;
  color: white;
}

.vuecal__title-bar {
  background-color: var(--primary-color);
  border-radius: 12px 12px 0 0;
}

.vuecal__flex .vuecal__cell {
  border-radius: 12px;
}

.vuecal__flex .vuecal__flex {
  border-radius: 12px;
}
</style>
