<template>
  <div>
    <div :class="containerClass">
      <!-- <page-header title="Skill" :breadcrumb="breadcrumb" :container-class="null" class="mb-32pt" /> -->

      <div class="back-result-lg mb-3 font-weight-bold">
        <a href="#" @click.prevent="goBack()" style="text-decoration: underline" class="mr-2">
          <span class="material-icons icon-32pt">navigate_before</span>
        </a>
      </div>
      <div class="d-flex justify-content-center">
        <b-skeleton-wrapper :loading="isSkillLoading" style="width: 100%">
          <template #loading>
            <div class="card card-block card-stretch card-height col-md-12">
              <div class="card-body">
                <b-skeleton-img no-aspect height="350px"></b-skeleton-img>
                <div class="d-flex align-items-start mt-3">
                  <b-skeleton width="70%" />
                </div>

                <page-separator title="" class="mt-1" />
                <div class="d-flex">
                  <div class="avatar avatar-sm rounded-circle o-hidden" style="border: 1px solid #dfe2e6">
                    <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                  </div>

                  <b-skeleton width="50%" class="mt-3 ml-3" />
                  <!-- </span> -->
                </div>
              </div>
            </div>
          </template>
          <div class="card card-block card-stretch card-height col-md-12">
            <div class="card-body">
              <video id="video-preview" class="skill-video" controls :src="skill.skill_video_url" />
              <div class="d-flex justify-content-between my-0 flex-wrap">
                <span class="mt-3" style="font-size: 1.5rem"
                  ><b>{{ skill.skill ? skill.skill.title : skill.title }} </b>
                  <md-icon
                    class="text-success"
                    v-if="skill.approval_status === APPROVAL_STATUS.APPROVED"
                    v-b-popover.hover.click.top
                    title="Approved Skill"
                    >check_circle</md-icon
                  >
                </span>

                <div>
                  <b-btn
                    class="mt-3 btn-normal"
                    @click.prevent="get(likesCount, 'mylike') ? unlikeSkill() : likeSkill()"
                    :variant="get(likesCount, 'mylike') ? 'primary' : 'light'"
                  >
                    <md-icon class="mr-1">thumb_up</md-icon>
                    {{ $n(get(likesCount, 'like_count', 0)) }} Likes</b-btn
                  >

                  <b-btn
                    class="mt-3 ml-2 btn-normal"
                    @click.prevent="
                      skill.approval_status === APPROVAL_STATUS.PENDING ? approveSkill() : unapproveSkill()
                    "
                    v-if="get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL"
                    :variant="skill.approval_status === APPROVAL_STATUS.PENDING ? 'success' : 'danger'"
                  >
                    {{ skill.approval_status === APPROVAL_STATUS.PENDING ? 'Approve' : 'Undo Approval' }}</b-btn
                  >
                </div>
              </div>

              <page-separator title="" class="my-0" />

              <div class="d-flex align-items-center">
                <user-avatar
                  slot="aside"
                  :user="get(skill, 'student.user')"
                  circle="lg"
                  size="lg"
                  :file-url="get(skill, 'student.user.avatar_url')"
                  variant="dark"
                >
                </user-avatar>
                <span class="ml-2">
                  <div class="card-title mt-3">
                    {{ get(skill, 'student.user.first_name') }} {{ get(skill, 'student.user.last_name') }}
                  </div>
                  <p class="text-muted">{{ formatDateTime(skill.created_at) }}</p>
                </span>
              </div>
              <!-- <page-separator title="" class="my-0" /> -->
            </div>

            <div
              :class="{ 'card-body': !isTabSmallScreen }"
              v-if="[USER_ROLE_TYPES.SCHOOL, USER_ROLE_TYPES.STUDENT].includes(get(this.getLoggedInUser, 'role_type'))"
            >
              <b-tabs
                nav-class="nav-tabs-card bg-white px-3"
                content-class=" "
                class="mt-0"
                id="stdSkillTabs"
                v-model="tabIndex"
                style="font-size: 16.5px"
              >
                <b-tab title="Comments" class="tab-secondary">
                  <template #title>
                    <i class="fas fa-comment align-self-center" :class="{ 'mx-4': isTabSmallScreen }"></i>
                    <strong class="ml-2" v-if="!isTabSmallScreen"
                      >Comments <span v-if="commentsCount">({{ $n(commentsCount) }})</span></strong
                    >
                  </template>

                  <div class="page-section mt-3 pt-0 mb-32pt">
                    <post-comment :skill="skill" is-parent />
                    <div class="w-100 mt-2">
                      <div v-for="(comment, index) in allComments" :key="index">
                        <comment-list-item
                          :id="`comment-${comment.id}`"
                          :comment="comment"
                          :skill="skill"
                          style="scroll-margin-top: 70px"
                        />
                        <!-- Replies -->
                        <div v-if="get(comment, 'replies.length')">
                          <comment-list-item
                            v-for="reply in comment.replies"
                            :key="reply.id"
                            :id="`comment-${reply.id}`"
                            :comment="reply"
                            :skill="skill"
                            style="scroll-margin-top: 70px"
                          />
                        </div>
                      </div>
                    </div>
                    <infinite-loading @infinite="infiniteLoadHandler">
                      <div slot="spinner">
                        <div class="text-center">
                          <i class="fas fa-circle-notch fa-spin" />
                        </div>
                      </div>
                      <div slot="no-more"></div>
                      <div slot="no-results"></div>
                    </infinite-loading>
                  </div>
                </b-tab>
                <b-tab
                  title="Feedback"
                  class="tab-secondary"
                  v-if="
                    [USER_ROLE_TYPES.SCHOOL].includes(get(this.getLoggedInUser, 'role_type')) ||
                      get(skill, 'student.user.id') === get(getLoggedInUser, 'id')
                  "
                >
                  <template #title>
                    <i class="fas fa-comment-alt align-self-center" :class="{ 'mx-4': isTabSmallScreen }"></i>
                    <strong class="ml-2" v-if="!isTabSmallScreen"
                      >Feedback <span v-if="feedbackCount">({{ $n(feedbackCount) }})</span></strong
                    >
                  </template>
                  <div class="page-section mt-3 pt-0 mb-32pt">
                    <post-feedback
                      :skill="skill"
                      is-parent
                      v-if="[USER_ROLE_TYPES.SCHOOL].includes(get(this.getLoggedInUser, 'role_type'))"
                    />
                    <div class="w-100 mt-2" v-if="allFeedbacks.length">
                      <div v-for="(feedback, index) in allFeedbacks" :key="index">
                        <feedback-list-item
                          :id="`feedback-${feedback.id}`"
                          :feedback="feedback"
                          :skill="skill"
                          style="scroll-margin-top: 70px"
                        />
                        <!-- Replies -->
                        <div v-if="get(feedback, 'replies.length')">
                          <feedback-list-item
                            v-for="reply in feedback.replies"
                            :key="reply.id"
                            :id="`feedback-${reply.id}`"
                            :feedback="reply"
                            :skill="skill"
                            style="scroll-margin-top: 70px"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="page-section d-flex justify-content-center" v-else>
                      <div class="card card-block card-stretch card-height col-md-12">
                        <div class="card-body text-center">
                          <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
                          <h4>No Feedback Given</h4>

                          <p class="text-muted">
                            No feedback posted for the skill.
                            <span v-if="get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL"
                              >Give constructive feedback to
                              {{ get(skill, 'student.user.first_name', 'the student') }} for skills improvement.</span
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                    <infinite-loading @infinite="infiniteFeedbackLoadHandler">
                      <div slot="spinner">
                        <div class="text-center">
                          <i class="fas fa-circle-notch fa-spin" />
                        </div>
                      </div>
                      <div slot="no-more"></div>
                      <div slot="no-results"></div>
                    </infinite-loading>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
    </div>
  </div>
  <!-- </div>
  </div> -->
</template>

<script>
import Page from '@/components/Page.vue';
import { mapActions, mapGetters } from 'vuex';
import UserAvatar from '@/components/User/UserAvatar.vue';
import { get, update } from 'lodash';
import { formatDateTime } from '@/common/utils';
import { USER_ROLE_TYPES, APPROVAL_STATUS } from '@/common/constants';
import InfiniteLoading from 'vue-infinite-loading';
import PostComment from './Comments/PostComment.vue';
import CommentListItem from './Comments/CommentListItem.vue';
import PostFeedback from './Feedback/PostFeedback.vue';
import FeedbackListItem from './Feedback/FeedbackListItem.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  extends: Page,
  name: 'SkillDetailPage',
  components: {
    UserAvatar,
    PostComment,
    CommentListItem,
    InfiniteLoading,
    PostFeedback,
    FeedbackListItem,
    PageSeparator,
  },
  data() {
    return {
      title: 'Skills',
      allComments: [],
      areCommentsLoading: false,
      isSkillLoading: true,
      skill: {},
      windowWidth: window.innerWidth,
      pageNum: 1,
      pageSize: 20,
      totalComments: 0,

      isLoadingMore: false,
      isFeedbackLoading: false,
      isLikingVideo: false,
      likeCountLoading: false,

      USER_ROLE_TYPES,
      allFeedbacks: [],
      totalFeedback: 0,
      connectionFeedback: null,
      connection: null,

      tabIndex: 0,
      commentsCount: 0,
      feedbackCountLoading: false,
      feedbackCount: 0,
      isApproving: false,
      APPROVAL_STATUS,
      isLoadingFeedbackMore: false,
      feedbackPageNum: 1,

      likesCount: { mylike: false, like_count: 0 },
    };
  },
  computed: {
    ...mapGetters('auth', ['getAuthToken', 'getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Skills', to: { name: 'student-skill' } },
        { text: 'Skill Detail', active: true },
      ];
    },
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
  },
  methods: {
    ...mapActions('skills', ['getSkill', 'updateApprovalSkill']),
    ...mapActions('feedback', ['getFeedback', 'getFeedbackCount']),
    ...mapActions('comments', ['getComments', 'getCommentCount']),
    ...mapActions('likes', ['getLikesCount', 'createLike', 'unLike']),

    get,
    formatDateTime,

    async unapproveSkill() {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(`Please confirm that you want to undo your approval.`, {
          title: 'Are you sure?',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        });
        if (isConfirmed) {
          const data = {
            approval_status: APPROVAL_STATUS.PENDING,
          };
          await this.updateApprovalSkill({ id: this.skill.id, data });
          this.fetchSkill();
          this.makeToast({ variant: 'success', msg: 'Approval removed' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    async approveSkill() {
      this.isApproving = true;
      const data = {
        approval_status: APPROVAL_STATUS.APPROVED,
      };
      try {
        await this.updateApprovalSkill({ id: this.skill.id, data });
        this.fetchSkill();
        this.makeToast({ variant: 'success', msg: 'Skill approved successfully!' });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        this.$router.push({ name: 'student-skill' });
      }
      this.isApproving = false;
    },

    async unlikeSkill() {
      if (this.isLikingVideo) return;

      this.isLikingVideo = true;
      try {
        const data = { like_on_id: this.$route.params.id, like_on_type: 'student_skill_video' };

        update(this.likesCount, 'mylike', () => false);
        await this.unLike(data);

        this.fetchLikeCount();
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLikingVideo = false;
    },

    async likeSkill() {
      if (this.isLikingVideo) return;

      this.isLikingVideo = true;
      try {
        const data = { like_on_id: this.$route.params.id, like_on_type: 'student_skill_video' };

        update(this.likesCount, 'mylike', () => true);
        await this.createLike(data);

        this.fetchLikeCount();
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLikingVideo = false;
    },

    async fetchLikeCount() {
      this.likeCountLoading = true;

      const resp = await this.getLikesCount({
        like_on_type: 'studentskillvideo',
        like_on_object_ids: [this.$route.params.id],
      });
      this.likesCount = get(resp.data, 'count[0]', { like_count: 0, mylike: false });

      this.likeCountLoading = false;
    },

    goBack() {
      this.$router.go(-1);
    },

    async fetchFeedbackCount() {
      this.feedbackCountLoading = true;
      const resp = await this.getFeedbackCount({
        feedback_on_type: 'studentskillvideo',
        feedback_on_object_ids: [this.skill.id],
      });
      this.feedbackCount = get(resp.data, 'count.0.feedback_count', 0);
      this.feedbackCountLoading = false;
    },
    async fetchCommentCount() {
      this.commentCountLoading = true;
      const resp = await this.getCommentCount({
        commented_on_type: 'studentskillvideo',
        commented_on_object_ids: [this.skill.id],
      });
      this.commentsCount = get(resp.data, 'count.0.comments_count', 0);
      this.commentCountLoading = false;
    },

    async fetchComments(params = {}) {
      this.areCommentsLoading = true;
      const response = await this.getComments({
        limit: this.pageSize,
        offset: (this.pageNum - 1) * this.pageSize,
        comment_on_id: this.skill.id,
        comment_on_type: 'studentskillvideo',
        ...params,
      });
      if (response.data.results) {
        this.allComments.push(...response.data.results);
        this.totalComments = response.data.count;
      }
      if (this.$route.params.commentId) {
        this.$nextTick(() => {
          const cm = document.getElementById(`comment-${this.$route.params.commentId}`);
          cm.scrollIntoView({ behavior: 'smooth' });
        });
      }
      if (this.$route.params.replyId) {
        this.$nextTick(() => {
          const cm = document.getElementById(`comment-${this.$route.params.replyId}`);
          cm.scrollIntoView({ behavior: 'smooth' });
        });
      }
      this.areCommentsLoading = false;
    },
    async fetchFeedback(params = {}) {
      this.isFeedbackLoading = true;
      const response = await this.getFeedback({
        limit: this.pageSize,
        offset: (this.pageNum - 1) * this.pageSize,
        feedback_on_id: this.skill.id,
        feedback_on_type: 'studentskillvideo',
        ...params,
      });
      if (response.data.results) {
        this.allFeedbacks.push(...response.data.results);
        this.totalFeedback = response.data.count;
      }
      if (this.$route.params.feedbackId) {
        this.tabIndex = 1;
        this.$nextTick(() => {
          const cm = document.getElementById(`feedback-${this.$route.params.feedbackId}`);
          cm.scrollIntoView({ behavior: 'smooth' });
        });
      }
      if (this.$route.params.feedbackReplyId) {
        this.tabIndex = 1;
        this.$nextTick(() => {
          const cm = document.getElementById(`feedback-${this.$route.params.feedbackReplyId}`);
          cm.scrollIntoView({ behavior: 'smooth' });
        });
      }

      this.isFeedbackLoading = false;
    },

    async loadMore() {
      this.isLoadingMore = true;
      this.pageNum++;
      await this.fetchComments();
      this.isLoadingMore = false;
    },
    async infiniteLoadHandler($state) {
      if (this.allComments.length < this.totalComments) {
        await this.loadMore();
        $state.loaded();
      } else $state.complete();
    },
    async loadMoreFeedback() {
      this.isLoadingFeedbackMore = true;
      this.feedbackPageNum++;
      await this.fetchFeedback();
      this.isLoadingFeedbackMore = false;
    },
    async infiniteFeedbackLoadHandler($state) {
      if (this.allFeedbacks.length < this.allFeedbacks) {
        await this.loadMoreFeedback();
        $state.loaded();
      } else $state.complete();
    },

    async fetchSkill() {
      this.isSkillLoading = true;
      try {
        const res = await this.getSkill(this.$route.params.id);
        this.skill = res.data;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        this.$router.push({ name: 'student-skill' });
      }
      this.isSkillLoading = false;
    },

    hookCommentsWS() {
      if ([USER_ROLE_TYPES.SCHOOL, USER_ROLE_TYPES.STUDENT].includes(get(this.getLoggedInUser, 'role_type'))) {
        this.connection = new WebSocket(
          `${process.env.VUE_APP_BACKEND_WS_URL}/ws/comments/student_skill_video/${this.skill.id}/?token=${this.getAuthToken}`,
        );
        this.connection.onmessage = event => {
          const data = JSON.parse(event.data).message;

          if (data.action === 'Create') {
            if (!data.comment.parent_comment) {
              if (!data.comment.replies) data.comment['replies'] = [];

              this.allComments.unshift(data.comment);
            } else {
              const comment = this.allComments.find(com => com.id === data.comment.parent_comment);
              if (comment) {
                comment.replies.push(data.comment);
              }
            }
            this.commentsCount += 1;
          } else this.allComments = this.allComments.filter(comment => comment.id !== data.comment.id);
        };
        this.connection.onerror = () => {};
      }
    },
    hookFeedbackWS() {
      if ([USER_ROLE_TYPES.SCHOOL, USER_ROLE_TYPES.STUDENT].includes(get(this.getLoggedInUser, 'role_type'))) {
        this.connectionFeedback = new WebSocket(
          `${process.env.VUE_APP_BACKEND_WS_URL}/ws/feedbacks/${this.skill.id}/?token=${this.getAuthToken}`,
        );
        this.connectionFeedback.onmessage = event => {
          const data = JSON.parse(event.data).message;

          if (data.action === 'Create') {
            if (!data.feedback.parent_feedback) {
              if (!data.feedback.replies) data.feedback['replies'] = [];

              this.allFeedbacks.unshift(data.feedback);
            } else {
              const feedback = this.allFeedbacks.find(feedback => feedback.id === data.feedback.parent_feedback);
              if (feedback) {
                feedback.replies.push(data.feedback);
              }
            }
            this.feedbackCount += 1;
          } else this.allFeedbacks = this.allFeedbacks.filter(feedback => feedback.id !== data.feedback.id);
        };
        this.connectionFeedback.onerror = () => {};
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
    if (this.$route.params.id) {
      await this.fetchSkill();
      this.fetchLikeCount();
      if (
        [USER_ROLE_TYPES.SCHOOL].includes(get(this.getLoggedInUser, 'role_type')) ||
        get(this.skill, 'student.user.id') === get(this.getLoggedInUser, 'id')
      ) {
        this.fetchFeedbackCount();
        await this.fetchFeedback();
        this.hookFeedbackWS();
      }
      if ([USER_ROLE_TYPES.SCHOOL, USER_ROLE_TYPES.STUDENT].includes(get(this.getLoggedInUser, 'role_type'))) {
        this.fetchCommentCount();
        await this.fetchComments();
        this.hookCommentsWS();
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  destroyed() {
    if (this.connection) this.connection.close();
    if (this.connectionFeedback) this.connectionFeedback.close();
  },
};
</script>

<style lang="scss" scoped>
.skill-video {
  max-height: calc(100vh - 220px);
  width: 100%;
  margin: auto;
}
</style>
