<template>
  <b-modal
    ref="candidateContactModal"
    hide-footer
    :title="`Contact ${get(candidate, 'user.first_name')} ${get(candidate, 'user.last_name')}`"
    centered
    lazy
    scrollable
    @hide="hideModal"
    size="md"
  >
    <b-form @submit.prevent="onSubmit">
      <div class="form-group">
        <textarea
          class="form-control"
          rows="10"
          placeholder="Write a message to send."
          v-model="message"
          required
        ></textarea>
      </div>
      <div class="d-flex justify-content-end">
        <b-btn variant="primary" :disabled="isLoading" type="submit">Send Message</b-btn>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';

export default {
  name: 'CandidateContactModal',

  props: {
    showModal: { type: Boolean, default: false },
    candidate: { type: Object, required: true },
  },

  data() {
    return {
      isLoading: false,
      message: null,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },

  watch: {
    showModal(value) {
      if (value) {
        this.message = `Hi ${this.candidate.user.first_name},
 
My name is ${this.getLoggedInUser.first_name} ${this.getLoggedInUser.last_name}. I found your profile on Transition<jointransition.com> and I'm interested in speaking to you about a career opportunity. I work for ${this.getLoggedInUser.linked_entity.name}. You can reach me at ${this.getLoggedInUser.linked_entity.phone} or ${this.getLoggedInUser.email}. Looking forward to speaking with you soon.

Best,
${this.getLoggedInUser.linked_entity.name}`;

        this.$refs.candidateContactModal.show();
      }
    },
  },

  methods: {
    ...mapActions('student', ['contactStudent']),
    get,

    hideModal() {
      this.$emit('close');
      this.message = null;
      this.isLoading = false;
    },

    hideModalManual() {
      this.$refs.candidateContactModal.hide();
      this.hideModal();
    },

    async onSubmit() {
      this.isLoading = true;
      try {
        let htmlMessage = this.message;
        const htmlEntities = [
          { regex: '&', entity: '&amp;' },
          { regex: '>', entity: '&gt;' },
          { regex: '<', entity: '&lt;' },
        ];
        htmlEntities.forEach((ent) => {
          const reg = new RegExp(ent.regex, 'g');
          htmlMessage = htmlMessage.replace(reg, ent.entity);
        });
        htmlMessage = htmlMessage.replace(/(?:\r\n|\r|\n)/g, '<br>');

        await this.contactStudent({
          student: this.candidate.id,
          message: htmlMessage,
          text_message: this.message,
        });
        this.makeToast({ variant: 'success', msg: 'Message Sent!' });
        this.$emit('onContact');
        this.hideModalManual();
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
  },

  async mounted() {},
};
</script>
