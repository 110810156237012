<template>
  <div :class="containerClass">
    <div class="back-result-lg font-weight-bold">
      <a href="#" @click.prevent="goBack()" style="text-decoration: underline">
        <span class="material-icons icon-32pt">navigate_before</span>
      </a>
    </div>

    <!-- <span style="font-size: 2rem"><b>Ask A Question</b></span> -->
    <h2>Ask a Question</h2>
    <b-form-group label="Audience" label-for="audience" label-class="form-label">
      <div class="custom-select-icon">
        <b-form-select
          class="custom-select-icon__select"
          style="min-width: 185px"
          v-model="audience"
          :options="optionsForAudience"
          :state="!$v.audience.required && $v.audience.$dirty ? false : null"
          :disabled="$route.params.id ? true : false"
        ></b-form-select>
        <span class="material-icons custom-select-icon__icon">sort</span>
      </div>
      <b-form-invalid-feedback :state="!$v.audience.required && $v.audience.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group label="Topic" label-for="topic" label-class="form-label">
      <div class="custom-select-icon">
        <b-form-select
          class="custom-select-icon__select"
          style="min-width: 185px"
          v-model="topic"
          :options="quesOptions"
          :state="!$v.topic.required && $v.topic.$dirty ? false : null"
        ></b-form-select>
        <span class="material-icons custom-select-icon__icon">sort</span>
      </div>
      <b-form-invalid-feedback :state="!$v.topic.required && $v.topic.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group label="Title" label-for="quesTitle" label-class="form-label">
        <b-form-input
          id="quesTitle"
          placeholder="Enter question title"
          v-model="quesTitle"
          maxlength="250"
          :state="!$v.quesTitle.required && $v.quesTitle.$dirty ? false : null"
        />
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Details" label-for="description" label-class="form-label">
        <b-form-textarea
          id="description"
          v-model="description"
          rows="15"
          placeholder="Enter question details"
          :state="!$v.description.required && $v.description.$dirty ? false : null"
        />
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>

      <b-btn variant="primary" :disabled="isLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $route.params.id ? $t('update') : 'Ask' }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Page from '@/components/Page.vue';
import { get } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import {
  USER_ROLE_TYPES,
  SCL_AUDIENCE_OPT,
  AUDIENCE_OPTIONS,
  PAST_STD_AUDIENCE_OPTION,
  CURRENT_STD_AUDIENCE_OPTION,
} from '@/common/constants';
// import { formatToAPIDate } from '@/common/utils';
// import moment from 'moment';
export default {
  extends: Page,

  data() {
    return {
      title: 'Add Question',
      quesTitle: '',
      topic: null,
      audience: null,
      description: '',
      isLoading: false,
      isQuestionFetching: false,
      quesOptions: [
        { value: null, text: 'Please select a topic', disabled: true },
        { value: 'jobs_options', text: 'Jobs/Options' },
        { value: 'exam_testing', text: 'Exam/Testing' },
        { value: 'skills', text: 'Skills' },
        { value: 'class', text: 'Class' },
        { value: 'equipment', text: 'Equipment' },
        { value: 'general', text: 'General' },
        { value: 'miscellaneous', text: 'Miscellaneous' },
      ],
      stdStatus: false,
      isStdStatusFetching: false,
      USER_ROLE_TYPES,
      SCL_AUDIENCE_OPT,
      AUDIENCE_OPTIONS,
      PAST_STD_AUDIENCE_OPTION,
      CURRENT_STD_AUDIENCE_OPTION,
    };
  },

  validations() {
    return {
      quesTitle: { required },
      topic: { required },
      description: { required },
      audience: { required },
    };
  },
  watch: {
    stdStatus: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.audience = this.stdStatus ? 'student_from_my_program_and_instructors' : null;
        }
      },
    },
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
    optionsForAudience() {
      if (this.getLoggedInUser.role_type !== USER_ROLE_TYPES.SCHOOL) {
        if (this.stdStatus) {
          return CURRENT_STD_AUDIENCE_OPTION;
        } else {
          return PAST_STD_AUDIENCE_OPTION;
        }
      } else {
        return SCL_AUDIENCE_OPT;
      }
    },
  },

  methods: {
    ...mapActions('questions', ['createQuestion', 'getQuestion', 'updateQuestion']),
    ...mapActions('student', ['getStudentStatus']),
    get,
    goBack() {
      this.$router.go(-1);
    },
    async fetchStdStatus() {
      this.isStdStatusFetching = true;
      try {
        const res = await this.getStudentStatus();
        this.stdStatus = res.data.current_student;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isStdStatusFetching = false;
    },
    async fetchQuestion() {
      this.isQuestionFetching = true;
      try {
        const res = await this.getQuestion(this.$route.params.id);
        this.quesTitle = res.data.title;
        this.description = res.data.description;
        this.audience = res.data.audience;
        this.topic = res.data.topic;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isQuestionFetching = false;
    },
    async onSubmit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isLoading = true;
        try {
          const data = {
            title: this.quesTitle,
            topic: this.topic,
            description: this.description,
            audience: this.audience,
          };

          let response;
          if (this.$route.params.id) {
            response = await this.updateQuestion({ id: this.$route.params.id, data });
            this.makeToast({ variant: 'success', msg: 'Question updated!' });
          } else {
            response = await this.createQuestion(data);
            this.makeToast({ variant: 'success', msg: 'Question asked!' });
          }

          if (get(response, 'data.data.id')) {
            setTimeout(
              () => this.$router.push({ name: 'student-question-details', params: { id: response.data.data.id } }),
              250
            );
          } else {
            setTimeout(() => this.$router.push({ name: 'my-questions' }), 250);
          }
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
        this.isLoading = false;
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields.' });
      }
    },
  },
  async mounted() {
    if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.STUDENT) {
      this.fetchStdStatus();
    }
    if (this.$route.params.id) {
      this.fetchQuestion();
    }
  },
};
</script>

<style>
</style>