<template>
  <b-modal
    ref="appTermsModal"
    hide-footer
    title="Sponsorship Terms and Conditions"
    centered
    lazy
    scrollable
    @hide="hideModal"
    :size="size"
  >
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div class="mb-2">
          <b-skeleton width="60%"></b-skeleton>
          <b-skeleton width="80%"></b-skeleton>
        </div>
      </template>

      <div class="d-flex align-items-start">
        <div v-safe-html="termsAndConditions" v-if="termsAndConditions" />

        <i v-else>No content to display</i>
      </div>
    </b-skeleton-wrapper>
  </b-modal>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'AppTermsModal',

  props: {
    showModal: { type: Boolean, default: false },
    size: { type: String, default: 'lg' },
    termsAndConditions: { type: String, required: false },
  },

  data() {
    return {
      isLoading: false,
      school: {},
    };
  },

  computed: {},

  watch: {
    showModal(value) {
      if (value) {
        this.$refs.appTermsModal.show();
      }
    },
  },

  methods: {
    get,

    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.appTermsModal.hide();
      this.hideModal();
    },
  },

  async mounted() {},
};
</script>
