<template>
  <div class="position-relative carousel-card">
    <swiper ref="businessAttireSwiper" :options="swiperOptions">
      <swiper-slide v-for="item in items" :key="item.id">
        <div class="col-12">
          <div
            class="
              card card-sm card--elevated
              p-relative
              o-hidden
              overlay overlay--primary-dodger-blue
              js-overlay
              mdk-reveal
              js-mdk-reveal
            "
          >
            <span class="js-image d-flex justify-content-center" data-position="left" :data-height="168">
              <img :src="item.avatarURL || DefaultCareerImg" alt="cna" class="career-card-img" />
            </span>
          </div>
        </div>
      </swiper-slide>

      <a class="carousel-control-next mt-n24pt" href="#" @click.prevent slot="button-next" style="right: 0rem">
        <span class="carousel-control-icon material-icons" aria-hidden="true">keyboard_arrow_right</span>
        <span class="sr-only">Next</span>
      </a>
    </swiper>
  </div>
</template>

<script>
// Dress images
import dress1 from '@/assets/images/school_landing/dress/dress11.jpeg';
import dress2 from '@/assets/images/school_landing/dress/dress10.jpeg';
import dress3 from '@/assets/images/school_landing/dress/dress9.jpeg';
import dress4 from '@/assets/images/school_landing/dress/dress8.jpeg';
import dress5 from '@/assets/images/school_landing/dress/dress7.jpeg';
import dress6 from '@/assets/images/school_landing/dress/dress6.jpeg';
import dress7 from '@/assets/images/school_landing/dress/dress5.jpeg';
import dress8 from '@/assets/images/school_landing/dress/dress4.jpeg';
import dress9 from '@/assets/images/school_landing/dress/dress3.jpeg';
import dress10 from '@/assets/images/school_landing/dress/dress2.jpeg';
import dress11 from '@/assets/images/school_landing/dress/dress1.jpeg';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
  components: { Swiper, SwiperSlide },

  props: {
    controls: {
      type: Boolean,
      default: true,
    },
    account: {
      type: Boolean,
    },
    items: {
      type: Array,
      default() {
        return [
          { avatarURL: dress1 },
          { avatarURL: dress2 },
          { avatarURL: dress3 },
          { avatarURL: dress4 },
          { avatarURL: dress5 },
          { avatarURL: dress6 },
          { avatarURL: dress7 },
          { avatarURL: dress8 },
          { avatarURL: dress9 },
          { avatarURL: dress10 },
          { avatarURL: dress11 },
        ];
      },
    },
  },

  computed: {
    swiper() {
      return this.$refs.businessAttireSwiper.$swiper;
    },
    swiperOptions() {
      return {
        navigation: { nextEl: '.carousel-control-next' },
        slidesPerView: this.windowWidth <= 420 ? 1 : this.windowWidth < 768 ? 2 : this.windowWidth < 992 ? 3 : 4,
        loop: true,
      };
    },
  },

  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },

  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
