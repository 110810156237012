<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {};
  },
  methods: {
    ...mapActions('auth', ['verifyTemporaryToken']),
    async openDashboard() {
      try {
        await this.verifyTemporaryToken({ token: this.$route.query.token });

        this.$router.push({ name: 'student-dashboard' });
      } catch (e) {
        //
      }
    },
  },
  async mounted() {
    setTimeout(() => {
      if (this.$route.query.token) {
        this.openDashboard(this.$route.query.token);
      }
    }, 200);
  },
};
</script>
