<template>
  <div>
    <page-header :title="title" :breadcrumb="breadcrumb" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <!-- TODO: Add page design here -->
        <page-separator title="" />
        <div class="row">
          <div class="col-lg-12 d-flex">
            <div class="flex" style="max-width: 100%">
              <div class="mb-0">
                <p class="" style="font-size: 15px">
                  The following is information that will help prepare you for an interview as a Certified Nursing
                  Assistant.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-32pt">
          <div class="col-lg-12 d-flex">
            <div class="flex" style="max-width: 100%">
              <div class="">
                <h5>1. how to dress</h5>
                <p class="text-90" style="font-size: 15px">
                  Interview attire varies greatly by company or organization. Business professional is the attire most
                  often worn to interviews. This typically include suits, dress shirts, dress shoes, and neutral colors
                  and hues. Attire should be crisp and clean and often reflect a more conservative look rather than bold
                  prints and/or colors.
                </p>
                <p class="text-90" style="font-size: 15px">
                  Below are some tips and examples for business professional dress.
                </p>
                <ul>
                  <li>All clothing should be neatly pressed.</li>
                  <li>All clothing should reflect a neutral color palette.</li>
                  <li>
                    Carry a portfolio/ padfolio. Briefcases and handbags should be black, navy, or other neutral tones.
                  </li>
                  <li>
                    Dress shoes including flats, closed-toe shoes, heels, and loafers should be worn and polished. If
                    heels are your preferred option, the heel should be no higher than 3.5 inches.
                  </li>
                  <li>Limit perfume or cologne.</li>
                  <li>Nails should be clean and neat, as should any polish.</li>
                  <li>
                    Suits with matching pieces should be worn (can include skirts, dresses, or pants as you prefer).
                  </li>
                  <li>Use simple accessories and avoid large watches and jewelry.</li>
                  <li>Wear neat, simple makeup if you feel inclined to wear any.</li>
                </ul>
                <div class="page-headline text-center">
                  <h6>Business Professional Dress Examples</h6>
                </div>
                <business-attire-carousel />
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-32pt">
          <div class="col-lg-12 d-flex">
            <div class="flex" style="max-width: 100%">
              <div class="">
                <h5>2. Sample Interview Questions</h5>
                <p class="text-90" style="font-size: 15px">
                  Here's a list of the most common CNA interview questions and example answers to help you plan what
                  you're going to say.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-12 d-flex">
            <div class="flex" style="max-width: 100%">
              <div class="">
                <div class="page-headline">
                  <h6>Tell me about yourself.</h6>
                  <p class="text-90" style="font-size: 15px">
                    Share something interesting about yourself that will pique the interviewer's interest. Provide
                    information on your accomplishments, how you've helped others, how you've excelled in life, and any
                    obstacles you've faced to paint a good picture of yourself.
                  </p>
                  <p class="text-primary font-weight-bold" style="font-size: 15px">
                    Example answer - "I come from a long line of lawyers. I used to volunteer at a local hospice center
                    when I was younger. People had a variety of problems, and I was willing to assist them with nearly
                    anything. Since then, I've been hooked on assisting others. It's really satisfying to assist
                    someone. I like to run and have completed a few CrossFit events, and I also volunteer with the
                    disabled children at our local hospital.”.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 d-flex">
            <div class="flex" style="max-width: 100%">
              <div class="">
                <div class="page-headline">
                  <h6>Why did you decide to become a CNA?</h6>
                  <p class="text-90" style="font-size: 14px">
                    Working as a CNA in a high-stress atmosphere may be physically and emotionally taxing. Employers may
                    use this question to gauge your enthusiasm for the job, as enthusiastic individuals are more likely
                    to meet the difficulties and stay motivated.
                  </p>
                  <p class="text-primary font-weight-bold" style="font-size: 15px">
                    Example answer - "My grandma fell down some steps when I was 16 years old and suffered serious
                    injuries. She had to spend time in the hospital and later in a rehabilitation center as a result of
                    her injuries. She was often complimenting the workers on how wonderfully they handled and cared for
                    her, and I know my family enjoyed the services they offered when we couldn't be there all of the
                    time."<br /><br />"Her accident motivated me to volunteer at an elderly home and eventually earn my
                    CNA certification. I'm committed to treating others with the same care and respect that my grandma
                    got. Despite the difficulties of the work, knowing that I am helping these people and their families
                    makes every difficult day worthwhile. I also recognize that living in these institutions may be
                    isolating, so I like forming personal relationships with individuals to help them feel more at
                    ease.".
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 d-flex">
            <div class="flex" style="max-width: 100%">
              <div class="">
                <div class="page-headline">
                  <h6>What are your strengths?</h6>
                  <p class="text-90" style="font-size: 15px">
                    It's critical to emphasize your talents, particularly how they may benefit the business. Don't say
                    anything like "tooting your own horn" or "not to boast," since the interviewer will assume that's
                    precisely what you're doing. Tell them about your strengths, but do it in a humble manner.
                  </p>
                  <p class="text-primary font-weight-bold" style="font-size: 14px">
                    Example answer - "My capacity to interact with individuals from all walks of life is my greatest
                    asset. I am better able to comprehend not just what individuals require, but also how I may best
                    serve them, since I am able to communicate effectively. This guarantees that I do the proper thing
                    the first time and that there are no misunderstandings."
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 d-flex">
            <div class="flex" style="max-width: 100%">
              <div class="">
                <div class="page-headline">
                  <h6>What are your weaknesses?</h6>
                  <p class="text-90" style="font-size: 15px">
                    This is a question to which you should always be prepared to respond prior to the interview. When
                    you're trying to focus on the positives, it might be tough to conceive of a flaw. It's critical to
                    demonstrate that you're aware of whatever flaws you have and that you're actively striving to better
                    yourself and transform those flaws into strength.
                  </p>
                  <p class="text-primary font-weight-bold" style="font-size: 14px">
                    Example answer - "I find it difficult to watch people struggle with things, so I try to assist
                    others when I see them. I have to constantly remind myself to ask people if they require assistance,
                    since this allows them to determine whether or not they want my assistance. I've learned to be more
                    patient and understanding of others as a result of taking a step back."
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 d-flex">
            <div class="flex" style="max-width: 100%">
              <div class="">
                <div class="page-headline">
                  <h6>As a CNA, what sets you apart from others?</h6>
                  <p class="text-90" style="font-size: 15px">
                    This question allows you to elaborate on why you feel this position is a good fit for you and how
                    you will stand out from the competition. Share the specifics of your schooling if it permitted you
                    to specialize in a certain sort of care.
                  </p>
                  <p class="text-primary font-weight-bold" style="font-size: 14px">
                    Example answer - "As a recent graduate, I have the most up-to-date talents you'll discover. I've
                    done a lot of on-the-job training. I've done my training in hospitals, residential facilities, and
                    private residences. I've studied psychology extensively and have learnt how to not only physically
                    assist people, but also how to react to and understand their mental states."
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 d-flex">
            <div class="flex" style="max-width: 100%">
              <div class="page-headline">
                <h6>Other CNA questions</h6>
                <p class="text-90" style="font-size: 15px">
                  Below are questions to prepare for during an upcoming CNA job interview.
                </p>

                <ul class="text-primary font-weight-bold">
                  <li>What kind of CNA experience do you have?</li>
                  <li>Why did you leave your last job?</li>
                  <li>How would you handle managing several patients at the same time?</li>
                  <li>Have you completed all of your education?</li>
                  <li>Where would you like go to in the long-term?</li>
                  <li>What are your areas of competence, in your opinion?</li>
                  <li>What kinds of medical equipment and software are you most at ease with?</li>
                  <li>What talents or experiences from your previous positions qualify you for this position?</li>
                  <li>What do you like best about working as a CNA?</li>
                  <li>What do you find most challenging about being a CNA?</li>
                  <li>What has been your most significant professional success to date?</li>
                  <li>What traits or abilities do you think a CNA should have to be successful?</li>
                  <li>What are your strategies for dealing with high-pressure situations at work?</li>
                  <li>What method do you employ to de-stress and exercise self-care?</li>
                  <li>How would your former coworkers and bosses characterize you?</li>
                  <li>
                    Do you plan to pursue education or training to become a licensed practical nurse or registered
                    nurse?
                  </li>
                  <li>
                    What have you done in the last year to keep your nursing assistant knowledge and abilities up to
                    date?
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { mapActions } from 'vuex';
import Page from '@/components/Page.vue';
import BusinessAttireCarousel from './BusinessAttire/BusinessAttireCarousel.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  components: { PageHeader, BusinessAttireCarousel, PageSeparator },
  extends: Page,
  name: 'PublicInterview',

  data() {
    return {
      title: 'Interview Prep',
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: { name: 'sch-public-home' } },
        { text: 'Interviews', active: true },
      ];
    },
  },

  methods: {
    ...mapActions('settings', ['setSettings']),
  },

  async mounted() {
    this.setSettings({ layout: { layout: 'fixed' } });
  },
};
</script>
