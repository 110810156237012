<template>
  <b-modal
    ref="addMetaTagsModal"
    hide-footer
    title="Apply Now"
    centered
    lazy
    @hide="hideModal"
    size="lg"
    body-class="apply-sch-modal"
  >
    <div>
      <div class="mb-2" style="font-size: 17px">
        In order to apply for this sponsorship, you need to enroll in a program offered by the one of the school listed
        below.
      </div>

      <div class="row">
        <div class="col-md-6 col-12 mx-auto mb-2" v-for="school in schools" :key="school.id">
          <span
            :href="school.enrollment_link ? `${school.enrollment_link}/programs/?category_id=${getPrograms}` : null"
            :style="school.enrollment_link ? 'cursor: pointer' : ''"
          >
            <div
              class="card d-flex align-content-center justify-content-center"
              style="height: 100%"
              :class="school.enrollment_link ? 'event-mod-item' : ''"
            >
              <a
                :href="school.enrollment_link ? `${school.enrollment_link}/programs/?category_id=${getPrograms}` : null"
                target="_blank"
                class="ml-2"
                style="color: black"
                :style="school.enrollment_link ? 'cursor: pointer' : ''"
              >
                <div class="">
                  <img :src="school.logo_url" :alt="school.name" width="50px" height="50px" />
                  <strong class="ml-2">{{ school.name }} </strong>
                </div>
              </a>
            </div>
          </span>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'SchoolsModal',

  props: {
    showModal: { type: Boolean, default: false },
    scholarship: { type: Object, default: () => {} },
    schools: { type: Array, default: () => [] },
  },

  data() {
    return {
      isLoading: false,
    };
  },
  validations() {
    return {};
  },
  computed: {
    getPrograms() {
      const programs = this.scholarship.programs.map((program) => program.id);

      return programs.toString();
    },
  },
  methods: {
    get,

    async hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.addMetaTagsModal.hide();
      this.hideModal();
    },
  },
  watch: {
    showModal(value) {
      if (value) {
        this.$refs.addMetaTagsModal.show();
      }
    },
  },
  mounted() {},
};
</script>

<style>
.apply-sch-modal {
  background-color: #f8f9fa !important;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
</style>
