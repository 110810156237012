<template>
  <router-link
    @click.native.prevent="
      () => (isPublic ? $emit('viewMore', { name: 'get-spotlight', params: { id: std.id } }) : null)
    "
    :to="{ name: isPublic ? null : 'get-spotlight', params: { id: std.id } }"
  >
    <div class="card card-sm card--elevated p-relative h-100" style="scroll-margin-top: 70px">
      <div style="position: relative; text-align: center; color: white">
        <span
          class="js-image d-flex justify-content-center clickable-item overlay"
          style="width: 100%"
          data-position="left"
          :data-height="168"
        >
          <b-img
            v-if="std.image_url"
            :src="std.image_url"
            style="border-top-left-radius: 0.5rem; border-top-right-radius: 0.5rem; height: 250px"
            class="career-card-img"
          />
          <div
            v-else
            style="border-top-left-radius: 0.5rem; border-top-right-radius: 0.5rem; height: 250px; width: 100%"
            class="bg-primary"
          >
            <md-icon class="mt-4" style="font-size: 130px; color: #ffffff">person</md-icon>
          </div>
        </span>
        <div
          style="
            position: absolute;
            bottom: 0px;
            background: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgba(20, 18, 18, 1));
            width: 100%;
          "
        >
          <div class="d-flex align-items-start ml-3" style="color: white; font-size: 24px">
            <strong>{{ std.first_name }} {{ std.last_name }}</strong>
          </div>
          <div class="d-flex align-items-start ml-3" style="color: white">
            <i>{{ formatDateTime(std.created_at) }}</i>
          </div>
          <div class="d-flex align-items-start ml-3 mb-2" style="color: white">
            {{ $t(`StudentType.${std.student_type}`) }}
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="d-flex justify-content-between">
          <p class="text-70 measure-paragraph">
            <span v-if="trim(std.personal_story)">{{
              truncate(trim(stripHtmlTags(std.personal_story)), {
                length: 120,
                separator: /,? +/,
              })
            }}</span>
          </p>
        </div>
        <template v-if="get(std, 'programs.length')">
          <hr class="mb-4" />
          <div class="d-flex flex-wrap justify-content-center align-items-center">
            <!-- v-for="program in get(std, 'programs')"
            :key="program.id" -->
            <b-button class="mr-2 mb-1" pill variant="outline-primary" size="sm">
              {{ get(std, 'programs')[0].title }}
            </b-button>
            <b-button v-if="get(std, 'programs').length > 1" class="mb-1" pill variant="outline-light" size="sm">
              +{{ get(std, 'programs').length - 1 }} more
            </b-button>
            <!-- <div v-if="get(std, 'programs').length > 1">+ {{ get(std, 'programs').length - 1 }} more</div> -->
          </div>
        </template>
      </div>
    </div>
  </router-link>
</template>

<script>
import { trim, truncate, get } from 'lodash';
import { formatDateTime, stripHtmlTags } from '@/common/utils';
export default {
  props: {
    std: { type: Object, default: () => [] },
    isPublic: { type: Boolean },
  },
  components: {},
  methods: {
    trim,
    truncate,
    formatDateTime,
    get,
    stripHtmlTags,
  },
  mounted() {},
};
</script>