<template>
  <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
    <h2 class="text-center mb-1">Welcome</h2>
    <p class="text-center mb-5 std-onboarding-subtitle">
      Welcome! We’re excited to help you navigate your career. Let's setup your account details!
    </p>
    <!-- <b-form-group
      :label="$t('userMsgs.avatar')"
      description="This will be your profile photo. Ensure there is good lighting and that no other people or objects are in the photo."
      label-for="avatar"
      label-class="form-label"
      class="row-align-items-center"
    >
      <b-media class="align-items-center" vertical-align="center">
        <user-avatar
          slot="aside"
          size="lg"
          :isLoading="isUploading.avatar"
          :user="user"
          url-field="avatar_url"
          first-name-field="first_name"
          last-name-field="last_name"
          variant="dark"
        >
        </user-avatar>

        <b-form-file
          id="avatar"
          placeholder="Select professional looking headshot photo (max size: 10MB)."
          :browse-text="$t('generalMsgs.browse')"
          v-model="selectedUploadFile.avatar"
          @input="uploadFile('avatar', 'images')"
          :disabled="isUploading.avatar"
          accept="image/*"
          :state="fileSizeExceed.avatar || invalidFileType.avatar ? false : null"
        />
        <b-form-invalid-feedback v-if="fileSizeExceed.avatar"
          >File size exceeded. Please make sure the file is under 10MB.</b-form-invalid-feedback
        >
        <b-form-invalid-feedback v-else-if="invalidFileType.avatar"
          >Invalid file type. Please select an image.</b-form-invalid-feedback
        >
      </b-media>
    </b-form-group> -->

    <div class="row">
      <b-form-group :label="$t('userMsgs.firstName')" label-for="firstname" label-class="form-label" class="col-md-6">
        <b-form-input
          id="firstname"
          :placeholder="$t('userMsgs.firstName')"
          v-model="user.first_name"
          required
          :state="!$v.user.first_name.required && $v.user.first_name.$dirty ? false : null"
        />

        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group :label="$t('userMsgs.lastName')" label-for="lastname" label-class="form-label" class="col-md-6">
        <b-form-input
          id="lastname"
          :placeholder="$t('userMsgs.lastName')"
          v-model="user.last_name"
          required
          :state="!$v.user.last_name.required && $v.user.last_name.$dirty ? false : null"
        />
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>
    </div>

    <div class="row">
      <b-form-group :label="$t('authMsgs.newPassword')" label-for="npass" label-class="form-label" class="col-md-6">
        <b-form-input
          id="npass"
          name="password"
          :placeholder="$t('authMsgs.newPassword')"
          v-model="user.password"
          :required="!!user.repeatPassword"
          :type="fieldTypes.password"
          autocomplete="off"
          @focus="handlePassType"
          @blur="handlePassType"
          :state="
            (!$v.user.password.required || !$v.user.password.isCorrectFormat) && $v.user.password.$dirty ? false : null
          "
        />
        <b-form-invalid-feedback v-if="!$v.user.password.required">This field is required.</b-form-invalid-feedback>
        <b-form-invalid-feedback v-else>{{ $t(`passValidationMsgs.${passErrorCode}`) }}</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        :label="$t('profileMsgs.repeatPassword')"
        label-for="repeatPassword"
        label-class="form-label"
        class="col-md-6"
      >
        <b-form-input
          id="repeatPassword"
          name="repeatPassword"
          :placeholder="$t('profileMsgs.repeatPassword')"
          v-model="user.repeatPassword"
          :required="!!user.password"
          :type="fieldTypes.repeatPassword"
          autocomplete="off"
          @focus="handlePassType"
          @blur="handlePassType"
          :state="
            (!$v.user.repeatPassword.required || !$v.user.repeatPassword.isCorrectFormat) &&
            $v.user.repeatPassword.$dirty
              ? false
              : null
          "
        />
        <b-form-invalid-feedback v-if="!$v.user.repeatPassword.required"
          >This field is required.</b-form-invalid-feedback
        >
        <b-form-invalid-feedback v-else>{{ $t(`passValidationMsgs.${repPassErrorCode}`) }}</b-form-invalid-feedback>
      </b-form-group>
    </div>
    <b-form-group
      :label="$t('authMsgs.emailAddr')"
      label-for="email"
      label-class="form-label"
      v-if="$route.name === 'accept-invite'"
    >
      <b-form-input
        id="email"
        :placeholder="$t('authMsgs.emailAddr')"
        :value="getInviteData.email"
        disabled
        type="email"
      />
    </b-form-group>

    <b-form-group :label="$t('studentMsgs.phone')" label-for="phone" label-class="form-label">
      <b-form-input
        id="phone"
        placeholder="(888) 689 - 1235"
        v-model="user.phone"
        v-mask="'(###) ### - ####'"
        :state="(!$v.user.phone.required || !$v.user.phone.isCorrectFormat) && $v.user.phone.$dirty ? false : null"
      />
      <b-form-invalid-feedback v-if="!$v.user.phone.required">This field is required.</b-form-invalid-feedback>
      <b-form-invalid-feedback v-else>
        Invalid phone number. Please make sure that it's in correct format i.e. (XXX) XXX - XXXX.
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group label="Gender" label-for="gender" label-class="form-label">
      <v-select
        id="gender"
        class="form-control v-select-custom"
        :class="!$v.user.gender.required && $v.user.gender.$dirty ? 'form-control is-invalid' : ''"
        label="text"
        v-model="user.gender"
        :reduce="item => item.value"
        placeholder="Select Your Gender"
        :options="genderOptions"
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!user.gender" v-bind="attributes" v-on="events" />
        </template>
      </v-select>
      <b-form-invalid-feedback :state="!$v.user.gender.required && $v.user.gender.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Enrolled Program" label-for="enrolled_program" label-class="form-label">
      <v-select
        id="enrolled_program"
        class="form-control v-select-custom"
        :class="
          !$v.profile.enrolled_program.required && $v.profile.enrolled_program.$dirty ? 'form-control is-invalid' : ''
        "
        label="title"
        v-model="profile.enrolled_program"
        :reduce="enrolled_program => enrolled_program.id"
        placeholder="Select Enrolled Program"
        :loading="areProgramsLoading"
        :options="allPrograms"
        @input="fetchTags(profile.enrolled_program)"
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!profile.enrolled_program" v-bind="attributes" v-on="events" />
        </template>
        <template slot="option" slot-scope="option">
          <span>{{ option.title }}</span>
        </template>
      </v-select>
      <b-form-invalid-feedback
        :state="!$v.profile.enrolled_program.required && $v.profile.enrolled_program.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      v-if="getLoggedInUserDashboard === DASHBOARD_TYPES.SCHOOL"
      label="Tag"
      label-for="tag"
      label-class="form-label"
    >
      <v-select
        id="tag"
        class="form-control v-select-custom"
        label="title"
        v-model="profile.selected_tag"
        :value="profile.selected_tag"
        :reduce="sc => sc.id"
        placeholder="Select Tag"
        :options="allTags"
        :loading="areTagsLoading && !allTags.length"
        @search="fetchOptions"
        :disabled="
          $route.name === 'edit-attendance' || (areTagsLoading && !allTags.length) || !profile.enrolled_program
        "
      >
        <template #list-footer>
          <li
            v-observe-visibility="visibilityChanged"
            v-show="allTags.length && allTags.length < totalTags"
            class="loader"
          ></li>
        </template>
        <template slot="option" slot-scope="option">
          <span :id="`tag-${option.id}`">
            {{ option.title }}
          </span>
        </template>
        <template slot="selected-option" slot-scope="option">
          {{ option.title }}
        </template>
      </v-select>
      <!-- <b-form-invalid-feedback :state="!$v.profile.selected_tag.required && $v.profile.selected_tag.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      > -->
    </b-form-group>
    <b-form-group label="Program Type" label-for="program_type" label-class="form-label">
      <v-select
        id="program_type"
        class="form-control v-select-custom"
        label="text"
        v-model="profile.program_type"
        :reduce="p => p.value"
        placeholder="Select Program Type"
        :options="programTypeOptions"
        :class="!$v.profile.program_type.required && $v.profile.program_type.$dirty ? 'form-control is-invalid' : ''"
      >
      </v-select>
      <b-form-invalid-feedback
        :state="!$v.profile.program_type.required && $v.profile.program_type.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Approx. Program Weeks" label-for="prog_weeks" label-class="form-label">
      <b-form-input
        id="prog_weeks"
        placeholder="Enter Approx. Program Weeks"
        type="number"
        min="1"
        v-model="profile.prog_weeks"
        :state="
          (!$v.profile.prog_weeks.minValue || !$v.profile.prog_weeks.required) && $v.profile.prog_weeks.$dirty
            ? false
            : null
        "
      />
      <b-form-invalid-feedback v-if="!$v.profile.prog_weeks.minValue"
        >Must be greater or equal to 1.</b-form-invalid-feedback
      >
      <b-form-invalid-feedback v-else-if="!$v.profile.prog_weeks.required"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Start Date" label-for="start_date" label-class="form-label">
      <date-picker
        v-model="profile.start_date"
        type="date"
        format="MM/DD/YYYY"
        value-type="YYYY-MM-DD"
        style="width: 100%"
        lang="en"
        placeholder="MM/DD/YYYY"
        id="start_date"
        :input-class="
          `mx-input ${!$v.profile.start_date.required && $v.profile.start_date.$dirty ? 'form-control is-invalid' : ''}`
        "
      ></date-picker>
      <b-form-invalid-feedback :state="!$v.profile.start_date.required && $v.profile.start_date.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="CNA License Number" label-for="cnaLicenseNo" label-class="form-label">
      <b-form-input
        id="cnaLicenseNo"
        placeholder="CNXXXXXXXXXX (leave blank if not received)"
        v-model="profile.cna_license_no"
        v-mask="'CN##########'"
        :state="!$v.profile.cna_license_no.isCorrectFormat && $v.profile.cna_license_no.$dirty ? false : null"
      />
      <b-form-invalid-feedback
        >Invalid number. Please make sure that it's in correct format i.e. CNXXXXXXXXXX.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group label="CMA License Number" label-for="cmaLicenseNo" label-class="form-label">
      <b-form-input id="cmaLicenseNo" placeholder="Enter CMA License Number" v-model="profile.cma_license_no" />
    </b-form-group>
    <b-form-group :label="$t('studentMsgs.quote')" label-for="quote" label-class="form-label">
      <b-form-input
        id="quote"
        :placeholder="$t('studentMsgs.quotePlaceholder')"
        v-model="profile.quote"
        maxlength="70"
      />
    </b-form-group>

    <b-form-group :label="$t('userMsgs.bio')" label-for="about" label-class="form-label">
      <b-form-textarea id="about" :placeholder="`${$t('studentMsgs.bioPlaceholder')}`" :rows="5" v-model="user.bio" />
    </b-form-group>

    <!-- Address Fields -->
    <h5>Home Address</h5>
    <h6>Your address won't be shown to employers. We use your address to match you up with jobs in your area.</h6>

    <b-form-group label="Street Address" label-for="address" label-class="form-label">
      <b-form-input
        id="address"
        placeholder="Street Address"
        v-model="profile.address"
        :state="!$v.profile.address.required && $v.profile.address.$dirty ? false : null"
      />
      <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
    </b-form-group>

    <div class="row">
      <b-form-group :label="$t('addressMsgs.zipcode')" label-for="zipcode" label-class="form-label" class="col-md-6">
        <b-form-input
          id="zipcode"
          :placeholder="$t('addressMsgs.zipcode')"
          v-model="profile.zipcode"
          :state="!$v.profile.zipcode.required && $v.profile.zipcode.$dirty ? false : null"
        />
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group :label="$t('addressMsgs.city')" label-for="city" label-class="form-label" class="col-md-6">
        <b-form-input
          id="city"
          :placeholder="$t('addressMsgs.city')"
          v-model="profile.city"
          :state="!$v.profile.city.required && $v.profile.city.$dirty ? false : null"
        />
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>
    </div>

    <div class="row">
      <b-form-group :label="$t('addressMsgs.state')" label-for="state" label-class="form-label" class="col-md-6">
        <v-select
          id="state"
          class="form-control v-select-custom"
          label="text"
          v-model="profile.state"
          :state="!$v.profile.state.required && $v.profile.state.$dirty ? false : null"
          :reduce="state => state.value"
          placeholder="Select State"
          :options="stateOptions"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!profile.state" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group :label="$t('addressMsgs.country')" label-for="country" label-class="form-label" class="col-md-6">
        <v-select
          id="country"
          disabled
          class="form-control v-select-custom"
          :class="!$v.profile.country.required && $v.profile.country.$dirty ? 'form-control is-invalid' : ''"
          label="text"
          v-model="profile.country"
          :reduce="country => country.value"
          :placeholder="$t('addressMsgs.countryPlaceholder')"
          :options="countryOptions"
          :clearable="false"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!profile.country" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.profile.country.required && $v.profile.country.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>
    </div>
  </b-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required, minValue, requiredIf } from 'vuelidate/lib/validators';
import axios from 'axios';
import { map, pick, debounce } from 'lodash';
import { countries } from 'countries-list';
import { CNA_LICENSE_NUM_REGEX } from '../../../../common/constants';
import DatePicker from 'vue2-datepicker';
// import UserAvatar from '../../../../components/User/UserAvatar.vue';
import {
  PASSWORD_ALL_NUMERIC_REGEX,
  PASSWORD_VALIDATIONS,
  PHONE_FORMAT_REGEX,
  PROGRAM_TYPE_OPTIONS,
  DASHBOARD_TYPES,
} from '../../../../common/constants';
import Vue from 'vue';
import { ObserveVisibility } from 'vue-observe-visibility';

Vue.directive('observe-visibility', ObserveVisibility);

var UsaStates = require('usa-states').UsaStates;

export default {
  components: { DatePicker },

  props: {
    userData: { type: Object, required: true },
    profileData: { type: Object, required: true },
    schoolName: { type: String, required: true },
    allPrograms: { type: Array, default: () => [] },
    areProgramsLoading: { type: Boolean, default: false },
    // allTags: { type: Array, default: () => [] },
    // areTagsLoading: { type: Boolean, default: false }
  },

  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        password: '',
        repeatPassword: '',
        phone: '',
        gender: '',
        bio: '',
        //  avatar_url: '',
      },
      profile: {
        grad_date: null,
        quote: '',
        address: '',
        zipcode: '',
        city: '',
        state: '',
        start_date: null,
        country: 'United States',
        enrolled_program: null,
        prog_weeks: null,
        cna_license_no: null,
        cma_license_no: null,
        program_type: '',
        selected_tag: null,
      },

      countryOptions: [],
      stateOptions: [],
      programTypeOptions: PROGRAM_TYPE_OPTIONS,
      genderOptions: [
        { value: 'male', text: 'Male' },
        { value: 'female', text: 'Female' },
        { value: 'other', text: 'Other' },
      ],

      isUploading: { avatar: false },
      selectedUploadFile: { avatar: null },
      uploadPercent: { avatar: 0 },
      uploadCancelTokenSource: { avatar: null },
      fileSizeExceed: { avatar: false },
      invalidFileType: { avatar: false },

      errorStates: { password: null, phone: null },
      passErrorCode: null,
      repPassErrorCode: null,

      fieldTypes: { password: 'text', repeatPassword: 'text' },

      allTags: [],
      areTagsLoading: false,
      limit: 15,
      offset: 0,
      totalTags: 0,
      search: '',
      DASHBOARD_TYPES,
    };
  },

  validations() {
    return {
      user: {
        first_name: { required },
        last_name: { required },
        password: {
          required,
          isCorrectFormat(value) {
            if (value.length < 5) {
              this.passErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_MIN_LENGTH;
              return false;
            } else if (PASSWORD_ALL_NUMERIC_REGEX.test(value)) {
              this.passErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_ALL_NUMERIC;
              return false;
            } else if (value !== this.user.repeatPassword) {
              this.passErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_NOT_MATCH;
              return false;
            }
            return true;
          },
        },
        repeatPassword: {
          required,
          isCorrectFormat() {
            if (this.user.password !== this.user.repeatPassword) {
              this.repPassErrorCode = PASSWORD_VALIDATIONS.PASSWORDS_NOT_MATCH;
              return false;
            }
            return true;
          },
        },
        phone: {
          required,
          isCorrectFormat(value) {
            return PHONE_FORMAT_REGEX.test(value);
          },
        },
        gender: { required },
        bio: {},
        // avatar_url: {
        //   validFile() {
        //     return !this.fileSizeExceed.avatar && !this.invalidFileType.avatar;
        //   },
        // },
      },
      profile: {
        grad_date: {},
        quote: {},
        address: { required },
        zipcode: { required },
        city: { required },
        state: { required },
        country: { required },
        enrolled_program: { required: requiredIf(() => this.getLoggedInUserDashboard === DASHBOARD_TYPES.SCHOOL) },
        selected_tag: {},
        prog_weeks: {
          required: requiredIf(() => this.getLoggedInUserDashboard === DASHBOARD_TYPES.SCHOOL),
          minValue: minValue(1),
        },
        start_date: { required: requiredIf(() => this.getLoggedInUserDashboard === DASHBOARD_TYPES.SCHOOL) },
        program_type: { required: requiredIf(() => this.getLoggedInUserDashboard === DASHBOARD_TYPES.SCHOOL) },

        cna_license_no: {
          isCorrectFormat(value) {
            return !value || CNA_LICENSE_NUM_REGEX.test(value);
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters('invite', ['getInviteData']),
    ...mapGetters('auth', ['getLoggedInUserDashboard']),
  },

  watch: {
    userData: {
      handler() {
        this.user = { ...this.userData };
      },
      deep: true,
    },
    profileData: {
      handler() {
        this.profile = pick(this.profileData, [
          // 'grad_date',
          'quote',
          'address',
          'zipcode',
          'city',
          'state',
          'country',
          'enrolled_program',
          'prog_weeks',
          'start_date',
          'cna_license_no',
          'cma_license_no',
          'program_type',
          'selected_tag',
        ]);
      },
      deep: true,
    },
    'profile.enrolled_program': {
      handler() {
        this.allTags = [];
        this.offset = 0;
        this.tag_id = null;
        this.search = '';
      },
    },
  },

  methods: {
    ...mapActions('student', ['getMyStudentProfile', 'updateMyStudentProfile', 'getStudentUploadPresignedUrl']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('attendance', ['getTags']),

    handlePassType(event) {
      const { srcElement, type } = event;
      const { name, value } = srcElement;

      if (type === 'blur' && !value) {
        this.fieldTypes[name] = 'text';
      } else {
        this.fieldTypes[name] = 'password';
      }
    },

    validateFormData() {
      this.$v.$touch();
      return {
        isValid: !this.$v.$invalid,
        formData: { user: { ...this.user }, profile: { ...this.profile } },
      };
    },

    visibilityChanged(reached) {
      if (reached) {
        this.offset += 15;
        this.fetchTags();
      }
    },

    async fetchTags(program) {
      this.profile.selected_tag = null;
      if (!this.search) this.areTagsLoading = true;
      const response = await this.getTags({
        ...(this.search && { search: this.search }),
        program__id: program,
        limit: this.limit,
        offset: this.offset,
      });

      this.allTags = this.allTags.concat(response.data.data.results);
      this.totalTags = response.data.data.count;
      this.areTagsLoading = false;

      if (this.allTags.length > 15) {
        setTimeout(() => {
          const el = document.getElementById(`tag-${this.allTags.slice(-15)[0].id}`);

          if (el) {
            el.scrollIntoView({ behavior: 'instant', block: 'nearest' });
          }
        }, 100);
      }
    },

    debouncedSearchTag: debounce(async (loading, search, vm) => {
      vm.search = search;
      const response = await vm.getTags({
        search,
        program__id: vm.profile.enrolled_program,
        limit: vm.limit,
        offset: vm.offset,
      });
      loading(false);

      vm.allTags = [];
      vm.totalTags = 0;
      vm.allTags = vm.allTags.concat(response.data.data.results);
      vm.totalTags = response.data.data.count;
    }, 500),

    async fetchOptions(search, loading) {
      if (!this.profile.selected_tag) {
        this.offset = 0;
        this.allTags = [];
        this.search = search;
        loading(true);
        this.debouncedSearchTag(loading, search, this);
      }
    },

    fillInOptions() {
      var usStates = new UsaStates();
      this.stateOptions = this.stateOptions.concat(
        map(usStates.states, state => ({
          value: state.name,
          text: state.name,
        })),
      );
      this.countryOptions = this.countryOptions.concat(
        map(countries, country => ({
          value: country.name,
          text: country.name,
        })),
      );
    },

    async uploadFile(fileType, uploadType = 'images', profileUrlField = 'resume_url', allowedType = 'image') {
      this.fileSizeExceed[fileType] = this.invalidFileType[fileType] = false;

      if (this.selectedUploadFile[fileType].size > 10 * 1024 * 1024) {
        this.fileSizeExceed[fileType] = true;
        return;
      } else if (!this.selectedUploadFile[fileType].type.includes(allowedType)) {
        this.invalidFileType[fileType] = true;
        return;
      }

      this.isUploading[fileType] = true;
      this.$emit('isUploading', this.isUploading);
      this.uploadCancelTokenSource[fileType] = axios.CancelToken.source();

      try {
        const urlResp = await this.getStudentUploadPresignedUrl({
          file_name: this.selectedUploadFile[fileType].name,
          content_type: this.selectedUploadFile[fileType].type,
          upload_type: uploadType,
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.selectedUploadFile[fileType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[fileType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[fileType].token,
          },
        });
        if (fileType === 'avatar') this.user.avatar_url = urlResp.upload_url.split('?')[0];
        else this.profile[profileUrlField] = urlResp.upload_url.split('?')[0];
      } catch (error) {
        this.selectedUploadFile[fileType] = null;
      }

      this.uploadCancelTokenSource[fileType] = null;
      this.uploadPercent[fileType] = 0;

      this.isUploading[fileType] = false;
      this.$emit('isUploading', this.isUploading);
    },
  },

  mounted() {
    this.fillInOptions();
  },
};
</script>
